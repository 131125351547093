/* eslint-disable no-restricted-syntax */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Inject, Injectable } from '@angular/core';
import { arrayUpsert } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { AppFeatureEnum, SplitIOService } from '@wc/core/services/split-io.service';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import {
  CustomStyleContextEnum,
  EditGeometryOptions,
  FeaturesPropsByLayerName,
  generateFeatureID,
  InteractionsEnum,
  InteractionsEvents,
  isLayerMapNameValid,
  LocationOptions,
  MapCenterOptions,
  MapStyleNameOnServer,
  normalizedModifiedData,
  normalizedRemovedData,
  StyleOptionsPerGeometryType,
  ThemeName,
  wcCoordinate,
  wcCoordinateTypes,
  wcFeatureEventTarget,
  wcFeatureProperties,
  WcGeometryType,
  wcMapInteraction,
  WcMapViewerService,
} from '@wc/wc-map-viewer/src';
import { LAYER_PANEL_STORE_ITEMS, TREE_PANEL_UI_REPRESENTATION } from '@wc/wc-map-viewer/src/injection-tokens';
import {
  AbstractLayerTreePanelService,
  AdditionalLayerTypesEnum,
  AppTypeUnion,
  EntitiesDiff,
  EntityStyleStatusEnum,
  LayerNamesOptionsType,
  LayerPanelStoreItem,
  LayerPanelUIStoreItem,
  LayerTreePanelNode,
  LayerVisibility,
  LayerVisibilityItem,
  LiveMapEntityType,
  LiveMapNormalizedData,
  LiveMapState,
  ModifiedEntities,
  NewLiveMapEntity,
  RemovedEntities,
  ScopeAccessModifier,
  TransitLayerType,
  WorkspaceSelection,
} from '@wc/wc-models/src';
import { getFlatTreeData } from '@wc/wc-ui/src';
import * as jsonIcons from 'assets/map-icons/sprite@1.json';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, filter, first, map, mergeAll, switchMap, take, tap } from 'rxjs/operators';
import { getLayerName } from 'wc-common';
import { AppVersion } from '../../../../core/app-version';
import { environment } from '../../../../core/environments/environment';
import { entitySubType } from '../../../../core/models/enums';
import { Account, FeatureType, LayerType, Workspace } from '../../../../core/models/gql.models';
import { CustomRxOperatorsService } from '../../../../core/services/custom-rx-operators.service';
import { liveMapConfig } from '../../../../wc-ui/src/components/map-viewers/live-map-viewer/live-map-viewer.config';
import { APP_TYPE_TOKEN } from '../injection-tokens';
import { EntitiesQuery } from '../stores/entities/entities.query';
import { LiveMapQuery } from '../stores/live-map/live-map.query';
import { LiveMapStore } from '../stores/live-map/live-map.store';
import { TransitBusStopsQuery } from '../stores/transit/transit_bus_stops.query';
import { TransitFixedRouteBusQuery } from '../stores/transit/transit_fixed_route_bus.query';
import { TransitRoutesQuery } from '../stores/transit/transit_routes.query';
import { AccountService } from './account.service';
import { AuthUserService } from './auth-user.service';
import { EntitiesServiceV2 } from './entities.service';
import { liveMapFilters, LiveMapFiltersService } from './live-map-filters.service';
import { LiveMapStylesService } from './live-map-styles.service';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';

type ExtraLiveMapOptions = {
  centerFromUrl?: MapCenterOptions<wcCoordinate> | null;
};

type LayerPanelStoreItems = LayerPanelStoreItem<LayerNamesOptionsType>[];
@Injectable({
  providedIn: 'root',
})
export class LiveMapService implements AbstractLayerTreePanelService {
  // OLM MAP API VARIABLES:
  //-------------------------------------------------------------------------------
  liveMapPoistionUpdate$ = new BehaviorSubject<number[]>([]);
  staticVisibleLayers: LiveMapEntityType[] = [AdditionalLayerTypesEnum.unIdentified];
  //-------------------------------------------------------------------------------
  private liveMapReady = new ReplaySubject<boolean>(1);
  private layerPanelTreeDataSubject = new ReplaySubject<void>();
  private layerPanelTreeNodesUpdateSubject = new ReplaySubject<LayerVisibilityItem[]>();
  private subscriptions: Subscription[] = [];
  private modifiedEntities$ = merge([
    this.entitiesQuery.modifiedEntities$,
    this.transitRoutesQuery.modifiedTransitRoutes$,
    this.transitFixedRouteBusQuery.modifiedTransitFixedRouteBuses$,
    this.transitBusStopsQuery.modifiedBusStops$,
  ]).pipe(
    mergeAll(),
    map(mEntities => this.getNormalizedModifiedData(mEntities)),
    tap(mData => {
      mData.featuresPropsFlat.forEach(({ workspaces, entityType, id }) => {
        this.addFeatureIdToItsWorkspaceDictionary(workspaces, generateFeatureID(entityType, id));
      });
    }),
    tap(({ featuresPropsByLayerName }) => this.mapViewerService.handleModifiedData(featuresPropsByLayerName))
  );

  private removedEntities$ = this.entitiesQuery.removedEntities$.pipe(
    map(rEntities => this.getNormalizedRemovedData(rEntities)),
    tap(rData => this.mapViewerService.handleRemovedData(rData))
  );
  private _featuresPerWorkspaceMap = new Map<number, { isVisible: boolean; featureIds: string[] }>();
  private readonly _availableLayersInPanel: Record<string, boolean>;
  workspaces!: number[];
  userAccount!: Account;

  getNormalizedMapData(entitiesDiff: EntitiesDiff): LiveMapNormalizedData<EntityStyleStatusEnum> {
    return {
      modified: this.getNormalizedModifiedData(entitiesDiff.modified),
      removed: this.getNormalizedRemovedData(entitiesDiff.removed),
    };
  }

  setOverlayToFeaturePosition(interactionName: InteractionsEnum, layerType: LayerType, id: number) {
    this.mapViewerService.setOverlayToFeaturePosition(interactionName, generateFeatureID(layerType, id));
  }

  constructor(
    private accountService: AccountService,
    private mapViewerService: WcMapViewerService,
    private localStorage: LocalStorageService,
    private liveMapStore: LiveMapStore,
    private liveMapFiltersService: LiveMapFiltersService,
    private authUserService: AuthUserService,
    private permissionsFacadeService: PermissionsFacadeService,
    private liveMapStyleService: LiveMapStylesService,
    private entitiesService: EntitiesServiceV2,
    private liveMapQuery: LiveMapQuery,
    private customRxOperators: CustomRxOperatorsService,
    private translateService: TranslateService,
    private transitRoutesQuery: TransitRoutesQuery,
    private transitFixedRouteBusQuery: TransitFixedRouteBusQuery,
    private transitBusStopsQuery: TransitBusStopsQuery,
    private entitiesQuery: EntitiesQuery,
    private splitIoService: SplitIOService,
    @Inject(LAYER_PANEL_STORE_ITEMS) private liveMapLayerStoreItems: LayerPanelStoreItem<LayerNamesOptionsType>[],
    @Inject(TREE_PANEL_UI_REPRESENTATION) private liveMapTreePanelUIRepresentation: LayerPanelUIStoreItem[],
    @Inject(APP_TYPE_TOKEN) private _appType: AppTypeUnion
  ) {
    this._availableLayersInPanel = getFlatTreeData(liveMapTreePanelUIRepresentation).reduce((acc, curr) => {
      acc[curr.name] = true;
      return acc;
    }, {});
  }

  handleUITreePanelStateUpdates(updatedUINodes: LayerVisibilityItem[]): void {
    this.handleLayerVisibilityChange(updatedUINodes.filter(node => isLayerMapNameValid(node.name)));
  }

  getNodesUpdate(): Observable<LayerVisibilityItem[]> {
    return this.layerPanelTreeNodesUpdateSubject.asObservable();
  }

  getTreeData(): Observable<LayerTreePanelNode[]> {
    return this.layerPanelTreeDataSubject
      .asObservable()
      .pipe(
        map(() =>
          this.liveMapTreePanelUIRepresentation
            .filter(UINode => this.hasPermission(UINode.appFeatureName))
            .map(UINode => this.storeItemToPanelNode(UINode, false))
        )
      );
  }

  resetDataUpdatesReplaySubject() {
    this.layerPanelTreeNodesUpdateSubject = new ReplaySubject<LayerVisibilityItem[]>();
  }

  init(
    el: HTMLElement,
    liveMapEventTree: LayerPanelStoreItems | [],
    extras?: ExtraLiveMapOptions
  ): Observable<boolean> {
    this.unSubscribeAll();
    this.listenToThemeChanges();
    this.initiateLayersPanelVisibility(liveMapEventTree);
    this.updateLayerPanelOnSplitIOChange();

    const sub = this.mapViewerService
      .init(el, liveMapConfig)
      .pipe(
        map(() => this.accountService.account),
        tap(userAccount => {
          const nextMapCenter =
            extras?.centerFromUrl?.mapCenter ||
            userAccount?.mapCenter.coordinates ||
            liveMapConfig.centerOptions.mapCenter;
          this.mapViewerService.updateMapCenter(nextMapCenter, {
            zoomLevel: extras?.centerFromUrl?.zoomLevel || liveMapConfig.centerOptions.zoomLevel,
            duration: 0,
          });
          this.entitiesService.startEntitiesDiffPolling();
          this.userAccount = userAccount;
          this.initWorkSpaces(userAccount?.workspaces.workspaces as Workspace[]);
          this.applyFiltersOnFeaturePropChange();
          this.listenToFilterChange();
          this.emitVisibleFeatureIdsOnChange();
          this.mapViewerService.setInteractionsState([{ name: 'dragPan', isActive: true }]);
        }),
        this.customRxOperators.tapOnce(() => {
          this.oldApiSupport();
          this.liveMapReady.next(true);
        }),
        switchMap(() => merge([this.removedEntities$, this.modifiedEntities$]).pipe(mergeAll()))
      )
      .subscribe();

    this.subscriptions.push(sub);
    return this.isMapReady$;
  }

  get isMapReady$() {
    return combineLatest([this.mapViewerService.ready$, this.liveMapReady]).pipe(
      filter(args => args.every(arg => !!arg)),
      map(() => true)
    );
  }

  get selectEvent$() {
    return this.mapViewerService.selectEvent$.pipe(
      tap(event => {
        // If an event has clicked coordinates it means it came from directly selecting a feature from the map.
        if (event.additionalInfo?.clickedCoordinates) {
          this.emitHeapEventOnFeatureSelectionFromMap(event.targets?.[0]?.featureId);
        }
      })
    );
  }

  get selectedFeatureId() {
    return this.mapViewerService.selectedFeatureId;
  }

  get mapZoomLevel() {
    return this.mapViewerService.getMapZoomLevel();
  }

  getViewExtentCoordinates(bufferValue: number = 10) {
    return this.mapViewerService.getViewExtentCoordinates(bufferValue);
  }

  addOverlayToInteraction(interactionName: wcMapInteraction, elm: HTMLElement) {
    this.mapViewerService.addOverlayToInteraction(interactionName, elm);
  }

  get dragEndEvent$() {
    return this.mapViewerService.dragEndEvent$;
  }

  get rightClickEvent$() {
    return this.mapViewerService.rightClickEvent$;
  }

  get moveEndEvent$() {
    return this.mapViewerService.moveEndEvent$;
  }

  get zoomEndEvent$() {
    return this.mapViewerService.zoomEndEvent$;
  }

  get dragStartEvent$() {
    return this.mapViewerService.dragStartEvent$;
  }

  handleModifiedData(modifiedFeaturesByLayer: FeaturesPropsByLayerName<string>) {
    this.mapViewerService.handleModifiedData(modifiedFeaturesByLayer);
  }

  handleRemovedData(featureIds: string[]) {
    this.mapViewerService.handleRemovedData(featureIds);
  }

  setFeaturesWithStyleContext(
    layerType: LayerType | TransitLayerType | LiveMapEntityType,
    ids: number[] | string[],
    context: CustomStyleContextEnum
  ) {
    const featureIds = ids.map(id => generateFeatureID(layerType, id));
    this.mapViewerService.setFeaturesWithStyleContext(featureIds, context);
  }

  private initiateLayersPanelVisibility(liveMapEventTreeItems: LayerPanelStoreItems) {
    if (!liveMapEventTreeItems.length) {
      throw new Error(` provided invalid tree items, value: ${liveMapEventTreeItems}`);
    }

    this.liveMapStore.update({
      layerPanelStoreItemsMap: liveMapEventTreeItems.reduce((acc, curr) => {
        acc.set(curr.name, curr);
        return acc;
      }, new Map() as LiveMapState['layerPanelStoreItemsMap']),
    });

    liveMapConfig.defaultLayersVisibility = liveMapEventTreeItems
      .filter(item => item.checked)
      .reduce((acc, curr) => {
        acc[curr.name] = curr.checked;
        return acc;
      }, {});
    this.layerPanelTreeDataSubject.next();
  }

  private updateLayerPanelOnSplitIOChange() {
    this.subscriptions.push(this.splitIoService.featureToggles.subscribe(() => this.layerPanelTreeDataSubject.next()));
  }

  updateFeaturePropByFeatureId<T extends keyof wcFeatureProperties>(
    featureId: string,
    propName: T,
    value: T extends 'isVisible' | 'isRelatedEvent' ? boolean : string,
    emitEvent = true
  ) {
    this.mapViewerService.updateFeatureProp(featureId, propName, value, emitEvent);
  }

  selectFeature(layerType: LayerType | TransitLayerType, id: number, emitEvent = true): Observable<string | undefined> {
    if (layerType === undefined || id === undefined) {
      throw new Error(`can't select a feature with layerType: ${layerType} and id: ${id}`);
    }
    const selectIsReady = new ReplaySubject<string | undefined>();
    this.liveMapQuery
      .select(({ visibleEntityIdsByLayerType }) => visibleEntityIdsByLayerType[layerType])
      .pipe(
        take(5),
        map(ids => ids?.find(_id => +_id === id)),
        first(_id => _id !== undefined)
      )
      .subscribe({
        next: id => {
          const selectedFeatureId = this.mapViewerService
            .selectFeature(generateFeatureID(layerType, id), emitEvent)
            ?.getId() as string | undefined;
          selectIsReady.next(selectedFeatureId);
        },
      });

    return selectIsReady.asObservable();
  }

  selectFeatureAndOverlay(layerType: LayerType, id: number, emitEvent = true) {
    this.unselectFeature();
    this.selectFeature(layerType, id, emitEvent).subscribe(() => {
      this.setOverlayToFeaturePosition(InteractionsEnum.select, layerType, id);
    });
  }

  getSelectedEntityId() {
    return this.mapViewerService.selectedFeatureId?.split('|')[1];
  }

  oldApiSupport() {
    merge([this.mapViewerService.modifyEndEvent$, this.mapViewerService.drawEndEvent$])
      .pipe(
        mergeAll(),
        filter(l => l.targets?.[0].geometry?.type === 'Point'),
        tap(location => {
          location;
        })
      )
      .subscribe((featureEvent: any) =>
        this.liveMapPoistionUpdate$.next(
          featureEvent !== undefined ? (featureEvent.targets?.[0]?.geometry?.coordinates as number[]) : []
        )
      );
  }

  getInitLiveMapLayersVisibilityState(): LayerPanelStoreItem<LayerNamesOptionsType>[] {
    const layersStatusFromLocalStorage = this.getValueFromLocalStorage(
      LocalStorageKeys.LayerPanelStoreItemsMap
    ) as LayerPanelStoreItem<LayerNamesOptionsType>[];
    let updateLocalStorageLayers: Pick<LayerPanelStoreItem<LayerNamesOptionsType>, 'name' | 'checked'>[] = [];

    if (layersStatusFromLocalStorage === null) {
      this.updateLocalStorage(
        LocalStorageKeys.LayerPanelStoreItemsMap,
        this.liveMapLayerStoreItems.map<LayerVisibilityItem>(({ checked, name }) => ({ checked, name }))
      );
      return this.liveMapLayerStoreItems;
    } else {
      const allLayerNames = this.liveMapLayerStoreItems?.map(({ name }) => name); // Add new layers to localStorage
      updateLocalStorageLayers = [...layersStatusFromLocalStorage];
      const allLocalStorageLayersNames = updateLocalStorageLayers.map(({ name }) => name);
      allLayerNames.forEach(name => {
        if (!allLocalStorageLayersNames.includes(name)) {
          updateLocalStorageLayers.push({
            name: name,
            checked: false,
          });
        }
      });

      const incidentLayers = updateLocalStorageLayers.filter(
        layer =>
          layer.name.includes(LayerType.Incident) &&
          layer.name !== 'INCIDENT-traffic_anomaly' &&
          layer.name !== 'INCIDENT-unidentified' &&
          layer.checked === true
      );
      if (incidentLayers.length > 0) {
        // Add new layers to localStorage
        updateLocalStorageLayers = updateLocalStorageLayers.map(layer => {
          if (layer.name === 'INCIDENT-traffic_anomaly' || layer.name === 'INCIDENT-unidentified') {
            layer.checked = true;
          }
          return layer;
        });
        this.updateLocalStorage(LocalStorageKeys.LayerPanelStoreItemsMap, updateLocalStorageLayers);
      }
    }
    return this.liveMapLayerStoreItems.map(item => ({
      ...item,
      checked: updateLocalStorageLayers.find(l => l.name === item.name)?.checked || false,
    }));
  }

  filterAllFeatures() {
    this.mapViewerService.allFeatureIds.forEach(featureId => {
      this.mapViewerService.updateFeatureProp(featureId, 'isVisible', this.isFeatureVisibleFilter(featureId));
    });
  }

  setMapCenter(coords: wcCoordinateTypes | wcCoordinateTypes[], options?: LocationOptions) {
    return this.mapViewerService.setMapCenter(coords, options);
  }

  private listenToThemeChanges(): void {
    this.updateMapConfigStyleByTheme(jsonIcons, '', MapStyleNameOnServer.light, ThemeName.light);
  }

  private updateMapConfigStyleByTheme(
    theme,
    iconSrcPath: '' | 'dark_' = '',
    mapSrcPath: MapStyleNameOnServer,
    themeName: ThemeName
  ) {
    liveMapConfig.mapTheme = {
      themeName,
      themeIcons: {
        json: theme,
        path: `assets/map-icons/${iconSrcPath}sprite@1.png?${AppVersion}`,
      },
      mapThemeUrl: `${environment.mapBoxUrl}/${mapSrcPath}`,
    };
  }

  get featurePropUpdate$() {
    return this.mapViewerService?.featurePropUpdate$;
  }

  get layerPropUpdate$() {
    return this.mapViewerService?.layerPropUpdate$;
  }

  private emitVisibleFeatureIdsOnChange() {
    merge(this.mapViewerService.featurePropUpdate$, this.mapViewerService.layerPropUpdate$)
      .pipe(
        debounceTime(50),
        map(() => {
          return this.mapViewerService.allVisibleFeatureIdsAndTypes.filter(
            ({ entitySubType }) => entitySubType !== AdditionalLayerTypesEnum.workSpace
          );
        }),
        map(data => {
          const featureIds = data.map(({ id }) => id);
          const _data: {
            featureIds: (string | undefined)[];
            entityIdsByLayerType: {
              [key in `${LayerType}`]: number[];
            };
          } = {
            featureIds,
            entityIdsByLayerType: featureIds.reduce((acc, curr) => {
              const [layerType, id] = curr?.split('|') as [keyof `${LayerType}`, string];
              !acc[layerType] ? (acc[layerType] = [id]) : acc[layerType].push(+id);
              return acc;
            }, {} as Record<`${LayerType}`, number[]>),
          };
          return _data;
        })
      )
      .subscribe(({ entityIdsByLayerType }) => {
        this.liveMapStore.update({ visibleEntityIdsByLayerType: entityIdsByLayerType });
      });
  }

  applyFiltersOnFeature(featureId: string, emitEvent: boolean = true) {
    this.mapViewerService.updateFeatureProp(featureId, 'isVisible', this.isFeatureVisibleFilter(featureId), emitEvent);
  }

  isFeatureVisibleFilter(featureId: string): boolean {
    const featureProps = this.mapViewerService.getFeatureProps(featureId);
    if (
      featureProps?.parentLayerName === AdditionalLayerTypesEnum.unIdentified &&
      featureProps.status !== 'completed' &&
      featureProps.status !== 'rejected'
    ) {
      return true;
    }

    if (
      featureProps?.parentLayerName &&
      this.liveMapQuery.getMapLayerStateByName(featureProps.parentLayerName as LayerNamesOptionsType)
        ?.isExcludedFromFilters
    ) {
      return !!(typeof featureProps?.show === 'boolean' ? featureProps.show : featureProps?.isVisible);
    }

    if (!this.filterByWorkspace(featureProps)) {
      return false;
    }

    if (!this.liveMapFiltersService.applyFiltersOnFeatureProps(featureProps)) {
      return false;
    }

    return true;
  }

  filterByWorkspace<T extends string>(featureProps: wcFeatureProperties<T> | undefined) {
    return featureProps?.workspaces?.length
      ? featureProps.workspaces.some(WSId => this._featuresPerWorkspaceMap.get(+WSId)?.isVisible)
      : true;
  }

  updateLiveMapFilter<T extends keyof liveMapFilters>(key: T, value: liveMapFilters[T], updateLocaleStorage = true) {
    this.liveMapFiltersService.updateLiveMapFilterObject(key, value, updateLocaleStorage);
  }

  getCurrentFilters() {
    return this.liveMapFiltersService.currentFilters;
  }

  addFeatureIdToItsWorkspaceDictionary(workSpacesIds: (string | number)[] | undefined, featureId: string): void {
    workSpacesIds?.forEach(WSId => {
      this._featuresPerWorkspaceMap.get(+WSId)?.featureIds.push(featureId);
    });
  }

  get mapContainerResized$() {
    return this.mapViewerService.mapContainerResized$;
  }

  get leaveEvent$() {
    return this.mapViewerService.leaveEvent$;
  }

  get hoverEvent$() {
    return this.mapViewerService.hoverEvent$;
  }

  get unselectEvent$() {
    return this.mapViewerService.unselectEvent$;
  }

  get modifyEndEvent$() {
    return this.mapViewerService.modifyEndEvent$;
  }

  get drawEndEvent$() {
    return this.mapViewerService.drawEndEvent$;
  }
  get mapCenterPoint() {
    return this.mapViewerService.getMapCenterPoint();
  }

  get mapCenter() {
    return this.mapViewerService.getMapCenter();
  }

  initWorkSpaces(workSpaces: Workspace[] = []) {
    this.workspaces = workSpaces.map<number>(({ id }) => id);
    const workspaceSelection = workSpaces.map<WorkspaceSelection>(({ id, title, featureTypes }) => ({
      checked: true,
      id,
      title: title,
      isNearCamerasLabel: featureTypes.includes(FeatureType.FilterNearCameras),
    }));
    const initializedWorkspacesStatuses = this.initiateWorkspacesStatus(workspaceSelection);
    initializedWorkspacesStatuses.forEach(({ id, checked }) => {
      this._featuresPerWorkspaceMap.set(+id, {
        isVisible: checked,
        featureIds: [],
      });
    });
    this.mapViewerService.updateMapData(
      this.getNormalizedWorkSpacesData(workSpaces, initializedWorkspacesStatuses),
      []
    );
    this.liveMapFiltersService.updateLiveMapFilterObject(
      'onlyNearCamerasIncidents',
      this.checkNearCamerasActiveInAllSelectedWorkspace(initializedWorkspacesStatuses)
    );
  }

  private checkNearCamerasActiveInAllSelectedWorkspace(workspaces: WorkspaceSelection[]): boolean {
    return workspaces.filter(w => !!w.checked).every(workspace => workspace.isNearCamerasLabel === true);
  }

  updateTransitLayersVisibility(layersVisibility: LayerVisibility[]) {
    this.liveMapStore.update({ visibleTransitLayers: layersVisibility });
    this.updateMapWithLayersVisibility(
      layersVisibility.map(layer => ({
        name: `${layer.layerTypeName}-${layer.layerName}`,
        checked: layer.checked,
      }))
    );
  }

  updateMapWithLayersVisibility(layersVisibility: LayerVisibilityItem[]) {
    this.mapViewerService.updateLayersVisibility(layersVisibility);
  }

  editFeatureGeometry(
    featureId: string,
    options: EditGeometryOptions,
    type: WcGeometryType
  ): Observable<{
    eventType: InteractionsEvents;
    targets: wcFeatureEventTarget[] | undefined;
  }> {
    this.mapViewerService.editFeatureGeometry(featureId, options, type);
    return this.mapViewerService.modifyEndEvent$;
  }

  createGeometry(
    type: WcGeometryType,
    coords: wcCoordinateTypes | null = null,
    createStyleArgs?: StyleOptionsPerGeometryType,
    modifyOptions: EditGeometryOptions = {}
  ): Observable<{
    eventType: InteractionsEvents;
    targets: wcFeatureEventTarget[] | undefined;
  }> {
    this.mapViewerService.createNewGeometry(type, coords, createStyleArgs, modifyOptions);

    return merge([this.mapViewerService.modifyEndEvent$, this.mapViewerService.drawEndEvent$]).pipe(mergeAll());
  }

  stopEditGeometry(keepChanges = true) {
    this.mapViewerService.stopEditGeometry(keepChanges);
  }

  stopCreateGeometry() {
    this.mapViewerService.stopCreatingNewGeometry();
  }

  getMapLocationAsHash(): string {
    const coords = this.mapViewerService.getMapCenter();
    const zoom = this.mapViewerService.getMapZoomLevel() || 16;
    return `${coords[1]},${coords[0]},${Math.floor(zoom * 100) / 100}z`;
  }

  initiateWorkspacesStatus(workspaces: WorkspaceSelection[]): WorkspaceSelection[] {
    const workspacesStatus: WorkspaceSelection[] | undefined = this.getValueFromLocalStorage('availableWorkspaces');
    if (workspacesStatus?.length) {
      workspacesStatus.forEach(_workspace => {
        const workspace = workspaces.find(({ id }) => id === _workspace.id);
        if (workspace) workspace.checked = _workspace.checked;
      });
    } else this.updateLocalStorage('availableWorkspaces', workspaces);
    this.liveMapStore.update({ availableWorkspaces: workspaces });
    return workspaces;
  }

  updateWorkSpaceSelection(selectedWorkspace: WorkspaceSelection) {
    this.mapViewerService.updateFeatureProp(
      generateFeatureID(AdditionalLayerTypesEnum.workSpace, selectedWorkspace.id),
      'isVisible',
      selectedWorkspace.checked
    );

    const WSFromDictionary = this._featuresPerWorkspaceMap.get(+selectedWorkspace.id);
    if (WSFromDictionary) {
      WSFromDictionary.isVisible = !!selectedWorkspace.checked;
    }
    this._featuresPerWorkspaceMap
      .get(+selectedWorkspace.id)
      ?.featureIds.forEach(featureId => this.applyFiltersOnFeature(featureId));

    this.liveMapStore.update(({ availableWorkspaces }) => {
      const updatedWorkspaces = arrayUpsert(availableWorkspaces, selectedWorkspace.id, {
        checked: selectedWorkspace.checked,
      });
      this.updateLocalStorage('availableWorkspaces', updatedWorkspaces);
      this.liveMapFiltersService.updateLiveMapFilterObject(
        'onlyNearCamerasIncidents',
        this.checkNearCamerasActiveInAllSelectedWorkspace(updatedWorkspaces)
      );
      return {
        availableWorkspaces: updatedWorkspaces,
      };
    });
  }

  private handleLayerVisibilityChange(shallowLayerNodes: LayerVisibilityItem[]) {
    const updatedLayersVisibilityState = this.getUpdatedLayerMap(shallowLayerNodes);
    this.liveMapStore.update({ layerPanelStoreItemsMap: updatedLayersVisibilityState });

    this.updateLocalStorage(
      'layerPanelStoreItemsMap',
      Array.from(updatedLayersVisibilityState.entries()).map(([, { name, checked }]) => ({
        name,
        checked,
      }))
    );
    this.isMapReady$.pipe(take(1)).subscribe(() => {
      this.updateMapWithLayersVisibility(shallowLayerNodes);
    });
  }

  setLayersVisibilityUpdates(shallowLayerPanelNodes: LayerVisibilityItem[] | []) {
    this.handleLayerVisibilityChange(shallowLayerPanelNodes);
    this.layerPanelTreeNodesUpdateSubject.next(
      shallowLayerPanelNodes.filter(({ name }) => this._availableLayersInPanel[name])
    );
  }

  setEntityLayerAsVisible(layerType: LayerType) {
    return tap((entity: any) => {
      this.layerPanelTreeDataSubject.pipe(take(1)).subscribe(() => {
        this.setLayersVisibilityUpdates([{ name: getLayerName(layerType, entity.type), checked: true }]);
      });
    });
  }

  toggleWorkspaceVisibilityOnEntityUpdate() {
    /**
     * @description this function will exit if an entity workspace is already checked.
     * If checked workspace was not found, it will take the last shared workspace (both entity and account) and check it as true
     */
    return tap((entity: any) => {
      this.liveMapQuery.availableWorkspaces$
        .pipe(
          take(1),
          filter(() => entity.workspaces)
        )
        .subscribe({
          next: workspaces => {
            let sharedUncheckedWorkspace: WorkspaceSelection | undefined;
            workspaces.every(ws => {
              if (entity.workspaces.includes(+ws.id)) {
                if (ws.checked) {
                  sharedUncheckedWorkspace = undefined;
                  return false;
                }
                sharedUncheckedWorkspace = ws;
              }
              return true;
            });
            if (sharedUncheckedWorkspace) {
              sharedUncheckedWorkspace.checked = true;
              this.updateWorkSpaceSelection(sharedUncheckedWorkspace);
            }
          },
        });
    });
  }

  private emitHeapEventOnFeatureSelectionFromMap(featureId: string | undefined): void {
    // this.heapService.trackUserSpecificAction(`heap-${this._appType}-feature-select-from-map`, { featureId });
  }

  private hasPermission(featureName?: AppFeatureEnum | ScopeAccessModifier[]): boolean {
    if (featureName !== undefined) {
      if (featureName instanceof Array) {
        return featureName.some(permission => this.permissionsFacadeService.hasPermission(permission));
      }
      return this.splitIoService.isActiveFeatureToggle(featureName as AppFeatureEnum);
    }
    return true;
  }

  private storeItemToPanelNode(
    item: LayerPanelStoreItem | null | LayerPanelUIStoreItem | undefined,
    hasParent: boolean
  ): LayerTreePanelNode {
    if (!item) {
      throw new Error('item is undefined or null');
    }
    return {
      checked: item.checked,
      name: item.name,
      hasParent,
      isDisabled: item.isDisabled,
      action: item.action,
      cssClasses: item.cssClasses,
      iconClass: item.iconClass,
      children:
        item.children !== null
          ? item.children
              .filter(item => this.hasPermission(item.appFeatureName))
              .map(child =>
                this.storeItemToPanelNode(
                  child.isMapLayer
                    ? this.liveMapQuery.getMapLayerStateByName(child.name as LayerNamesOptionsType)
                    : child,
                  true
                )
              )
          : null,
    };
  }

  private getUpdatedLayerMap(shallowLayerPanelNodes: LayerVisibilityItem[]) {
    const layersState = this.liveMapQuery.getValue().layerPanelStoreItemsMap;
    shallowLayerPanelNodes.forEach(node => {
      if (isLayerMapNameValid(node.name)) {
        const layerStoreItem = layersState.get(node.name);
        if (layerStoreItem) {
          layerStoreItem.checked = node.checked;
        } else {
          throw new Error(
            `could not find layerStoreItem:, ${node.name}, please check the provided LAYER_PANEL_STORE_ITEMS token in your module`
          );
        }
      } else throw new Error(`${node.name} is not a valid layer name`);
    });

    return layersState;
  }

  private listenToFilterChange() {
    this.subscriptions.push(this.liveMapFiltersService.filterChanged$.subscribe(() => this.filterAllFeatures()));
  }

  private updateLocalStorage<T extends keyof LiveMapState>(
    key: T,
    value: T extends 'layerPanelStoreItemsMap' ? LayerVisibilityItem[] : LiveMapState[T]
  ) {
    this.localStorage.set(key, value);
  }

  private getValueFromLocalStorage<T extends keyof LiveMapState>(
    key: T
  ): T extends 'layerPanelStoreItemsMap' ? LayerVisibilityItem[] : LiveMapState[T] | undefined {
    return this.localStorage.get(key);
  }

  private applyFiltersOnFeaturePropChange() {
    this.subscriptions.push(
      this.mapViewerService.featurePropUpdate$.subscribe(({ featureId }) =>
        this.applyFiltersOnFeature(featureId, false)
      )
    );
  }

  private getNormalizedWorkSpacesData(
    workSpaces: Workspace[],
    workspaceSelection: WorkspaceSelection[]
  ): normalizedModifiedData<EntityStyleStatusEnum> {
    const normalizedData: normalizedModifiedData<EntityStyleStatusEnum> = {
      featuresPropsFlat: [],
      featuresPropsByLayerName: {},
    };
    const currentLayer: wcFeatureProperties<EntityStyleStatusEnum>[] = (normalizedData.featuresPropsByLayerName[
      AdditionalLayerTypesEnum.workSpace
    ] = []);
    workSpaces.forEach(workSpace => {
      const isVisible = !!workspaceSelection.find(({ id }) => workSpace.id === id)?.checked;
      const featureProps = this.transformWorkspaceToFeatureProps(workSpace, isVisible);
      currentLayer.push(featureProps);
      normalizedData.featuresPropsFlat.push(featureProps);
    });
    return normalizedData;
  }

  private getNormalizedModifiedData(data: ModifiedEntities): normalizedModifiedData<EntityStyleStatusEnum> {
    const normalizedData: normalizedModifiedData<EntityStyleStatusEnum> = {
      featuresPropsFlat: [],
      featuresPropsByLayerName: {},
    };

    Object.values(data).forEach((dataPerLayerType: any) => {
      Object.values(dataPerLayerType).forEach((entity: any) => {
        const { type, layerType } = entity;
        const layerName = this.getLayerName(layerType, type);
        if (!normalizedData.featuresPropsByLayerName[layerName]) {
          normalizedData.featuresPropsByLayerName[layerName] = [];
        }
        const featureProps = this.transformToFeatureProps(entity, layerName);
        if (featureProps) {
          normalizedData.featuresPropsByLayerName[layerName].push(featureProps);
          normalizedData.featuresPropsFlat.push(featureProps);
        }
      });
    });

    return normalizedData;
  }

  private getNormalizedRemovedData(data: RemovedEntities | undefined): normalizedRemovedData {
    if (!data) return [];
    return Object.keys(data).reduce<string[]>(
      (acc, layerType) => acc.concat(data[layerType].map(id => generateFeatureID(layerType, id))),
      []
    );
  }

  private getLayerName(layerType: LayerType, type: string | undefined): string {
    if (layerType === LayerType.Incident) {
      return `${layerType}-${type?.toLowerCase()}`;
    }

    if (type === 'OTHER') {
      return `${layerType}-${layerType?.toLowerCase()}`;
    }
    return `${layerType}-${(entitySubType[type || layerType] || type || layerType)?.toLowerCase()}`;
  }

  private transformWorkspaceToFeatureProps(
    workspace: Workspace,
    isVisible: boolean
  ): wcFeatureProperties<EntityStyleStatusEnum> {
    return {
      geomType: workspace.area.type,
      entityType: AdditionalLayerTypesEnum.workSpace,
      coordinates: workspace.area.coordinates,
      id: workspace.id,
      entitySubType: AdditionalLayerTypesEnum.workSpace,
      parentLayerName: AdditionalLayerTypesEnum.workSpace,
      title: workspace.title,
      status: AdditionalLayerTypesEnum.workSpace,
      isVisible,
      styleKey: AdditionalLayerTypesEnum.workSpace,
      entityStatusForStyle: EntityStyleStatusEnum.default,
    };
  }

  private transformToFeatureProps(
    data: NewLiveMapEntity,
    layerName: string
  ): wcFeatureProperties<EntityStyleStatusEnum> | undefined {
    const featureAccountWS = this.findFeatureWSFromAccountWS(this.workspaces, data.workspaces as number[]);
    const parentLayerName = layerName;
    const entityStatusForStyle = this.liveMapStyleService.getEntityStyleStatus(data);

    if (!(data && data.location)) {
      return;
    }
    return {
      geomType: data.location?.type,
      entityType: data.layerType,
      coordinates: data.location?.coordinates,
      id: data.id,
      mitigatedByMyAccount: !data.mitigatedAccounts
        ? undefined
        : data.mitigatedAccounts.some(id => id === this.userAccount?.id),
      assignedToMe: data.associatedUnits
        ?.map(({ driverDetails }) => driverDetails?.userId)
        .some(userId => userId === this.authUserService.user.id),
      entitySubType: layerName,
      parentLayerName,
      title: data.title,
      status: data.status?.toLocaleLowerCase(),
      styleKey: this.liveMapStyleService.getStyleKey(
        entityStatusForStyle,
        data,
        ThemeName.light,
        liveMapConfig?.layers?.[parentLayerName]
      ),
      isVisible: true,
      show: data.show === undefined ? true : data.show,
      workspaces: featureAccountWS,
      entityStatusForStyle,
      bearing: data.bearing,
      color: data.color,
      isMyAccount: data.isMyAccount || data.accountId === this.accountService.account.id,
      nearCameras: data.nearCameras,
      isEmergency: data.isEmergency,
    };
  }

  private findFeatureWSFromAccountWS(WSAccountIds: number[], WSFeatureIds: number[]) {
    const foundWS = {};
    WSAccountIds.forEach(id => (foundWS[id] = 1));
    WSFeatureIds?.forEach(id => foundWS[id] && foundWS[id]++);
    return Object.entries(foundWS)
      .filter(([_key, value]) => value === 2)
      .map(([key, _value]) => +key);
  }

  oldApiUsageAlert(...fnArguments) {
    try {
      // Throw an error to generate a stack trace
      throw new Error();
    } catch (e: any) {
      const traceStr = e.stack
        .split('\n')
        .slice(2, 4)
        .reverse()
        .map(str => str.split(' ')[5])
        .reduce((acc, curr, idx) => (acc += `${curr}\n${idx === 1 ? '' : 'called '}`), '');
      console.info(
        `\n%cOLD API USAGE DETECTED:\n------------------------------------\n${traceStr}
        )}\n------------------------------------\n`,
        `color: #2f0aa1`
      );
    }
  }
  /**
   * @todo:
   * 1) render map while animation of the sideBar occurring.
   * 2) add feature toggles into the layer's config file.
   */

  // TEMP OLD API:
  // ----------------------------------------------------------------------------------------------------

  checkFeatureToggleLayerVisibility(appFeatureEnum: AppFeatureEnum) {
    this.oldApiUsageAlert(appFeatureEnum);
  }

  setWCMapConfig(account) {
    this.oldApiUsageAlert(account);
  }

  initMap(wcMap, centerFromUrl?: string | null) {
    this.oldApiUsageAlert();
  }

  setSelectedWorkspacesFromCache() {
    this.oldApiUsageAlert();
  }

  setSelectedWorkspaces(workspacesIds: number[], override: boolean = true) {
    this.oldApiUsageAlert();
  }

  updateTrafficDisruptionStatusFilter(value: any) {
    this.oldApiUsageAlert(value);
  }

  refilterMap() {
    this.oldApiUsageAlert();
  }

  refreshMap() {
    this.oldApiUsageAlert();
  }

  changeLayersVisibility(checked: boolean, layerNames: LiveMapEntityType[]) {
    this.oldApiUsageAlert(layerNames);
  }

  toggleDataLayer(layerName) {
    this.oldApiUsageAlert(layerName);
  }

  changeOpenedLayerDisplay(changedLayer) {
    this.oldApiUsageAlert(changedLayer);
  }

  get currentLayerDisplay() {
    this.oldApiUsageAlert();
    return undefined;
  }

  get mapLayersTreeMenu(): [] {
    this.oldApiUsageAlert();
    return [];
  }

  get visibleLayers(): LiveMapEntityType[] {
    this.oldApiUsageAlert();
    return [];
  }

  unselectFeature() {
    this.mapViewerService.unselectFeature();
  }

  get liveEntities(): { [layerName in LiveMapEntityType]?: { LiveMapEntity } } {
    this.oldApiUsageAlert();
    return [] as any;
  }

  updateMapConfig(account: any) {
    this.oldApiUsageAlert(account);
  }

  accountLocationCenter(): number[] {
    this.oldApiUsageAlert();
    // const coordinates: { latitude: number; longitude: number }[] = [];
    // this.wcMapConfig.mapCenter[0][0].forEach(cord => {
    //   coordinates.push({ latitude: cord[0], longitude: cord[1] });
    // });
    // const res = getCenterOfBounds(coordinates);
    return [];
  }

  updateModifiedEntities(layers, refreshAll?: boolean) {
    this.oldApiUsageAlert();

    // if (!this.wcMap) return;
    // const { transit_demand_response_unit, transit_on_demand_unit, transit_fixed_route_bus_unit, ..._layers } = layers;
    // this.wcMap.wcMapService.updateLayers(
    //   _layers as {
    //     [layerName in LiveMapEntityType]?: { [id in any]: LiveMapEntity };
    //   },
    //   refreshAll,
    //   this.filters
    // );
  }

  updateRemovedEntities(layers) {
    this.oldApiUsageAlert();
  }

  applyCurrentMapCenter(coordinates: [][], options?) {
    this.oldApiUsageAlert();
  }

  setCurrentCenter(coordinates: Array<Array<Array<number>>>, options?) {
    this.oldApiUsageAlert(coordinates);
    // if (options && !options.bufferSize) options.bufferSize = 6000;

    // this.lastCenter = { ...{}, ...this.currentCenter };
    // this.currentCenter = {
    //   coordinates: coordinates,
    //   options: options,
    // };
  }

  zoomOnIncident(incident) {
    this.oldApiUsageAlert(incident.id);

    // const padding = this.mapPadding();
    // const camerasCoordinates = []; // incident.cameras?.map( camera => camera.camera?.location?.coordinates);
    // if (camerasCoordinates && incident.location) {
    //   const coords = [incident.location.coordinates[0], incident.location.coordinates[1]];
    //   this.wcMap?.moveMapCenterToCoordinatesWithOffset(coords);
    //   // this.applyCurrentMapCenter([[[incident.location.coordinates], camerasCoordinates]],
    //   //   {dontKeepLast: true, bufferSize: 1000, duration: 1000, padding});
    // }
  }

  zoomOnEntity(entity, _zoom?) {
    this.oldApiUsageAlert(entity.location.type);
  }

  applyLastMapCenter() {
    this.oldApiUsageAlert();
  }

  entitiesInArea(coord: Array<number>, distance: number) {
    this.oldApiUsageAlert();
    return null as any;
  }

  addInteractiveEntity(entity) {
    this.oldApiUsageAlert(entity.id);
  }

  removeInteractiveEntity() {
    this.oldApiUsageAlert();
  }

  startMapDraw(type: 'Point' | 'LineString' | 'Polygon', location?) {
    this.oldApiUsageAlert(type);
  }

  mapDrawChanged() {
    this.oldApiUsageAlert();
    return null;
  }

  clearMapDraw() {
    this.oldApiUsageAlert();
  }

  allLayersVisibilityDetectionMode(vis: boolean) {
    this.oldApiUsageAlert();
  }

  mapPadding() {
    this.oldApiUsageAlert();
    return [0, 0, 0, 0];
  }

  unselectAllFeaturesInLayer(featureSubTypeOf, id?) {
    this.oldApiUsageAlert();
  }

  selectEntityFeature(entity) {
    this.oldApiUsageAlert(entity.id);
  }

  editEntityFeature(entity, featureType?) {
    this.oldApiUsageAlert(entity.id);
  }

  stopEditEntityFeature(entity, featureType?) {
    this.oldApiUsageAlert(entity.id);
  }

  destroyMap() {
    this.entitiesService.unSubscribeAll();
    this.unSubscribeAll();
    this.mapViewerService.destroy();
    this.liveMapStore.update({ visibleEntityIdsByLayerType: {} });
    this.resetDataUpdatesReplaySubject();
  }

  unSubscribeAll() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }
}
