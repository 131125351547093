import { ChangeDetectionStrategy, Component, Input, OnDestroy, Optional, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroupDirective } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { AppFeatureEnum, HeapAnalyticsService, LiveMapQuery, LiveMapService, SplitIOService } from '@wc-core';
import {
  ConstructionRelatedEventDto,
  IncidentRelatedEventDto,
  IncidentStatus,
  LayerType,
  RelatedEventEntityTypeDto,
  RelatedEventUnionDto,
  SearchActiveEventsGQL,
  TrafficDisruptionStatus,
} from '@wc/core';
import { requiredArrayValidator } from '@wc/features/ui/form-validators';
import { PermissionsService } from '@wc/permissions/domain/src/lib/infrastructure/permissions.service';
import { generateFeatureID } from '@wc/wc-map-viewer/src';
import { LayerNamesOptionsType, LayerPanelStoreItem } from '@wc/wc-models/src';
import { FormFieldOption } from '@wc/wc-ui/src/lib/base';
import { cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AutocompleteComponent, OptionsMapper } from '../../autocomplete/autocomplete.component';
import { RelatedEventEntityTypeToLayerMap, RelatedEventsService } from '../related-events.service';

const hasPermissionByEntityMap: { [key in RelatedEventEntityTypeDto]: boolean } = {
  [RelatedEventEntityTypeDto.Construction]: false,
  [RelatedEventEntityTypeDto.Incident]: false,
  [RelatedEventEntityTypeDto.RoadClosure]: false,
  [RelatedEventEntityTypeDto.SpecialEvent]: false,
};

const MAX_RELATED_EVENTS = 99;

@Component({
  selector: 'wc-related-events-form',
  templateUrl: './related-events-form.component.html',
  styleUrls: ['./related-events-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedEventsFormComponent implements OnDestroy {
  @ViewChild('autoComplete') autoComplete!: AutocompleteComponent<any>;
  @ViewChild('showCheckbox') showCheckbox!: MatCheckbox;

  @Input() editMode = true;
  @Input() isParentFormSubmitted = false;
  @Input() entityType: RelatedEventEntityTypeDto = RelatedEventEntityTypeDto.Incident;
  @Input() dataHubMode = false;

  private _entityId?: number;
  private _hasPermissionMap = hasPermissionByEntityMap;
  private _allViewRelatedEvents: RelatedEventUnionDto[] = [];
  private _userLayersState: LayerPanelStoreItem<LayerNamesOptionsType>[] = [];

  filteredViewRelatedEvents: RelatedEventUnionDto[] = [];
  expandedPanel = new BehaviorSubject(false);
  showRequired = false;
  maxRelatedEvents = MAX_RELATED_EVENTS;
  autocompleteFormControl = new FormControl('');
  form: FormArray = this.fb.array([]);

  get featureToggleOn() {
    return this.splitIOService.isActiveFeatureToggle(AppFeatureEnum.FE_RELATED_EVENTS);
  }

  @Input() set entityId(value: number) {
    if (!this.featureToggleOn) return;

    if (this._entityId && this._entityId !== value) {
      this.handleEventChange();
    }

    this._entityId = value;
    if (this._entityId) {
      this.relatedEventsService.getRelatedEvents(this._entityId).subscribe(relatedEvents => {
        this._allViewRelatedEvents = relatedEvents;
        this.filteredViewRelatedEvents = relatedEvents.filter(event => this._hasPermissionMap[event.entityType]);
        if (relatedEvents.length > 0) this.expandedPanel.next(true);
        this.relatedEventsService.clearCachedCompletedEvents();
        this.initForm();
      });
    }
    this.initForm();
    if (this.showCheckbox?.checked) {
      this.showCheckbox.toggle();
    }
  }
  @Input() set isMandatory(value: boolean) {
    this.showRequired = value;
    if (value) {
      this.form.setValidators([requiredArrayValidator()]);
    } else {
      this.form.clearValidators();
    }

    this.form.updateValueAndValidity();
  }

  mapper: OptionsMapper<FormFieldOption<unknown, RelatedEventUnionDto>> = {
    map: async filteredEvents => {
      return new Promise<FormFieldOption<unknown, RelatedEventUnionDto>[]>(resolve => {
        this.relatedEventsService
          .getCompletedEvents(this.autoComplete.currentSearchedValue)
          .subscribe(filteredCompleted => {
            const options: FormFieldOption<unknown, RelatedEventUnionDto>[] = [];
            filteredEvents.forEach(entity => {
              options.push(this.relatedEventsService.mapEventToOption(entity as RelatedEventUnionDto));
            });
            const uniqueOptions = [...options, ...filteredCompleted].filter(
              event => !this.filteredViewRelatedEvents.some(e => e.id === event.value) && event.value !== this._entityId
            );

            resolve(uniqueOptions);
          });
      });
    },
  };

  constructor(
    public searchActiveEventsGQL: SearchActiveEventsGQL,
    private fb: FormBuilder,
    private relatedEventsService: RelatedEventsService,
    private permissionsService: PermissionsService,
    private splitIOService: SplitIOService,
    private liveMapQuery: LiveMapQuery,
    private liveMapService: LiveMapService,
    @Optional() private formGroupDirective: FormGroupDirective,
    private heapService: HeapAnalyticsService
  ) {
    if (this.featureToggleOn) {
      this._hasPermissionMap = this.initializePermissionMap();
    }
  }

  private handleEventChange() {
    this.toggleShowRelatedOnMap(false, false);
  }

  private initializePermissionMap(): Record<RelatedEventEntityTypeDto, boolean> {
    return {
      [RelatedEventEntityTypeDto.Construction]: this.permissionsService.hasPermission('CONSTRUCTION:READ'),
      [RelatedEventEntityTypeDto.Incident]: this.permissionsService.hasPermission('INCIDENT:READ'),
      [RelatedEventEntityTypeDto.RoadClosure]: this.permissionsService.hasPermission('ROAD_CLOSURE:READ'),
      [RelatedEventEntityTypeDto.SpecialEvent]: this.permissionsService.hasPermission('SPECIAL_EVENT:READ'),
    };
  }

  private initForm() {
    if (!this.editMode) return;

    if (this.formGroupDirective?.form) {
      this.formGroupDirective.form.setControl('relatedEvents', this.fb.array([]));
      this.form = this.formGroupDirective.form.get('relatedEvents') as FormArray;
      if (this.showRequired) this.form.setValidators([requiredArrayValidator()]);

      this._allViewRelatedEvents.forEach(event => {
        this.form.push(this.createEventFormGroup(event));
      });
      this.form.updateValueAndValidity();
    }
  }

  private createEventFormGroup(roadEvent: RelatedEventUnionDto) {
    return this.fb.group({
      id: roadEvent.id,
      type: roadEvent.entityType,
      externalId: roadEvent.externalId,
    });
  }

  removeEvent(eventId: number, index: number) {
    const formIndex = this.form.controls.findIndex(c => c.value.id === eventId);
    this.form.removeAt(formIndex);
    const deleteEvent = this.filteredViewRelatedEvents.splice(index, 1);

    this.heapService.trackUserSpecificAction('bi-analytics-remove-related-event', {
      id: deleteEvent[0].id,
      entityType: deleteEvent[0].entityType,
      eventStatus:
        (deleteEvent[0] as IncidentRelatedEventDto).incidentStatus ||
        (deleteEvent[0] as ConstructionRelatedEventDto).trafficDisruptionStatus,
      constructionType: (deleteEvent[0] as ConstructionRelatedEventDto).constructionType,
      incidentType: (deleteEvent[0] as IncidentRelatedEventDto).incidentType,
    });
  }

  addEvent(option: FormFieldOption<unknown, RelatedEventUnionDto>) {
    if (!option.data) {
      console.warn('Related event - no entity data provided in addEvent');
      return;
    }

    this.form.push(this.createEventFormGroup(option.data));
    this.filteredViewRelatedEvents.push(option.data);
    this.autoComplete.resetFormControl();
    this.heapService.trackUserSpecificAction('bi-analytics-add-related-event', {
      id: option.data.id,
      entityType: option.data.entityType,
      eventStatus:
        (option.data as IncidentRelatedEventDto).incidentStatus ||
        (option.data as ConstructionRelatedEventDto).trafficDisruptionStatus,
      constructionType: (option.data as ConstructionRelatedEventDto).constructionType,
      incidentType: (option.data as IncidentRelatedEventDto).incidentType,
    });
  }

  toggleShowRelatedOnMap(checked: boolean, userAction = true) {
    this.updateIsRelatedEventFilter(this.entityType, this._entityId || -1, checked);

    const completedEvent: RelatedEventUnionDto[] = [];
    this.filteredViewRelatedEvents.forEach(_event => {
      if (
        _event['incidentStatus'] === IncidentStatus.Completed ||
        _event['incidentStatus'] === IncidentStatus.Rejected ||
        _event['trafficDisruptionStatus'] === TrafficDisruptionStatus.Completed
      ) {
        completedEvent.push(_event);
      } else {
        this.updateIsRelatedEventFilter(_event.entityType, _event.id, checked);
      }
    });

    this.liveMapService.updateLiveMapFilter('isRelatedEvent', checked, false);
    this.cacheLocalStorageLayers(checked);

    if (checked) {
      this.relatedEventsService.addCompletedEntitiesToMap(completedEvent);
    } else {
      this.relatedEventsService.removeAllCompletedEntitiesFromMap();
    }

    if (userAction) {
      this.heapService.trackUserSpecificAction('bi-analytics-related-event-show-only-related-on-map', {
        show: checked,
      });
    }
  }

  private cacheLocalStorageLayers(checked: boolean) {
    if (checked) {
      const mapFilters = Array.from(this.liveMapQuery.getValue().layerPanelStoreItemsMap.values()).filter(
        value =>
          value.name.includes(LayerType.Incident) ||
          value.name.includes(LayerType.RoadClosure) ||
          value.name.includes(LayerType.SpecialEvent) ||
          value.name.includes(LayerType.Construction)
      );
      this._userLayersState = cloneDeep(mapFilters);

      mapFilters.map(layerFilter => {
        layerFilter.checked = true;
        return layerFilter;
      });
      this.liveMapService.setLayersVisibilityUpdates(mapFilters);
    } else {
      this.liveMapService.setLayersVisibilityUpdates(this._userLayersState);
    }
  }

  private updateIsRelatedEventFilter(entityType: RelatedEventEntityTypeDto, id: number, checked: boolean) {
    this.liveMapService.updateFeaturePropByFeatureId(
      generateFeatureID(RelatedEventEntityTypeToLayerMap[entityType], id),
      'isRelatedEvent',
      checked
    );
  }

  ngOnDestroy(): void {
    if (!this.dataHubMode) {
      this.toggleShowRelatedOnMap(false, false);
      this.relatedEventsService.clearCachedCompletedEvents();
    }
  }
}
