import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CustomFormControlComponent } from '../../lib/base/custom-form-control';

@Component({
  selector: 'wc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent extends CustomFormControlComponent {
  @Input() isDisabled = false;
  @Input() size: 'small' | 'medium' = 'small';
  @Input() checked = false;
  @Output() changed = new EventEmitter<boolean>();

  change(event: MatCheckboxChange) {
    this.onChanged(event.checked);
    this.changed.emit(event.checked);
  }
}
