import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FocusDirectiveModule } from '@wc/wc-common/src';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { RelatedEventCardComponent } from './related-event-card.component';

@NgModule({
  declarations: [RelatedEventCardComponent],
  imports: [CommonModule, WcIconModule, TranslateModule, FocusDirectiveModule],
  exports: [RelatedEventCardComponent],
})
export class RelatedEventCardComponentModule {}
