import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ActionValue: any;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: any;
  Geometry: any;
  LineString: any;
  /** A 64-bit signed integer */
  Long: any;
  MultiLineString: any;
  MultiPolygon: any;
  Point: any;
  Polygon: any;
  /** A 16-bit signed integer */
  Short: any;
};



export enum AccessModifier {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export type Account = {
  __typename?: 'Account';
  additionalInfos: Array<AdditionalInfo>;
  analyticsReportsUrls?: Maybe<AnalyticsReportsUrls>;
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  contactPhoneNumber: Scalars['String'];
  customer: Customer;
  groups: Array<PermissionGroup>;
  hubspotCompany: Scalars['String'];
  id: Scalars['Int'];
  mapCenter: Scalars['Polygon'];
  mitigationTypes: Array<MitigationType>;
  name: Scalars['String'];
  publicationTemplates: Array<PublicationTemplate>;
  publicationVariables: AccountPublicationVariables;
  publishChannels: Array<NotificationDestination>;
  regionalSetting: RegionalSetting;
  reportSources: Array<ReportSource>;
  routeTypes: Array<RouteType>;
  shiftTypes: Array<ShiftType>;
  status: Status;
  workspaces: AccountWorkspaces;
};

export type AccountActiveUnitsAndDrivers = {
  __typename?: 'AccountActiveUnitsAndDrivers';
  drivers: Array<User>;
  units: Array<UnitVehicle>;
};

export type AccountIncidentTypes = {
  __typename?: 'AccountIncidentTypes';
  incidentAbandonedVehicleSubType: Array<IncidentAbandonedVehicleSubType>;
  incidentCrashSubTypes: Array<IncidentCrashSubType>;
  incidentDamageSubType: Array<IncidentDamageSubType>;
  incidentDebrisSubTypes: Array<IncidentDebrisSubType>;
  incidentHazardSubTypes: Array<IncidentHazardSubType>;
  incidentPoliceActivitySubTypes: Array<IncidentPoliceActivitySubType>;
  incidentStalledVehicleSubTypes: Array<IncidentStalledVehicleSubType>;
  incidentTrafficStopSubTypes: Array<IncidentTrafficStopSubType>;
  incidentTypes: Array<IncidentType>;
};

export type AccountPermit = {
  __typename?: 'AccountPermit';
  accountId: Scalars['Int'];
  id: Scalars['Int'];
  type: PermitType;
  url?: Maybe<Scalars['String']>;
};

export type AccountPermitInput = {
  type: PermitType;
  url?: Maybe<Scalars['String']>;
};

export type AccountPublicationVariables = {
  __typename?: 'AccountPublicationVariables';
  constructionVariables: Array<ConstructionPublicationMessageTemplateVariables>;
  incidentVariables: Array<IncidentPublicationMessageTemplateVariables>;
  roadClosureVariables: Array<RoadClosurePublicationMessageTemplateVariables>;
};

export type AccountWorkspaces = {
  __typename?: 'AccountWorkspaces';
  singleMultiPolygon: Scalars['MultiPolygon'];
  workspaces: Array<Workspace>;
};

export type Action = {
  actionField: ResponsePlanActionField;
  index: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  time: Scalars['Float'];
  unit: ResponsePlanUnits;
  value?: Maybe<Scalars['ActionValue']>;
};


export type ActivityFieldValue = AdditionalInfoField | AddressField | BooleanField | CameraField | ConstructionTypeField | ContactPersonField | DateTimeField | DmsField | GeometryField | IncidentAutoPublishField | IncidentLaneField | IncidentMediaIdField | IncidentSeverityField | IncidentStatusField | IncidentSubTypeField | IncidentTypeField | InjurySeveritiesField | IntField | LanesClosureTypeField | LocationField | MitigationField | NoteField | NullableTextField | OccupancyRangeField | PermitField | PublishField | RelatedEventField | ReportSourceField | RouteTypeIdsField | ScheduleField | ShiftEndReasonField | ShiftTypeIdField | SpecialEventTypeField | TextField | TrafficDisruptionLaneField | TrafficDisruptionMediaIdField | TrafficDisruptionStatusField | UnitField | UnitIdField | VehicleField | VenueField;

export type ActivityLogAggregated = {
  __typename?: 'ActivityLogAggregated';
  activitiesPerDay: Array<ActivityPerDay>;
  timeByDay: Scalars['DateTime'];
};

export enum ActivityLogEntityType {
  Construction = 'CONSTRUCTION',
  Incident = 'INCIDENT',
  RoadClosure = 'ROAD_CLOSURE',
  SpecialEvent = 'SPECIAL_EVENT'
}

export enum ActivityLogSectionType {
  Creation = 'CREATION',
  Lanes = 'LANES',
  Misc = 'MISC',
  Mitigation = 'MITIGATION',
  Notes = 'NOTES',
  RelatedEntity = 'RELATED_ENTITY',
  StatusChanged = 'STATUS_CHANGED',
  StatusRestored = 'STATUS_RESTORED',
  UnitResponse = 'UNIT_RESPONSE',
  UserAssignment = 'USER_ASSIGNMENT'
}

export type ActivityPerDay = {
  __typename?: 'ActivityPerDay';
  accountName: Scalars['String'];
  activitiesPerMinute: Array<ActivitySection>;
  timeByMinute: Scalars['DateTime'];
  userName: Scalars['String'];
};

export type ActivitySection = {
  __typename?: 'ActivitySection';
  activities: Array<ActivityStructure>;
  activityLogEntityType: ActivityLogEntityType;
  activityLogSectionType: ActivityLogSectionType;
  maxOfflineUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ActivitySourceType {
  Api = 'API',
  DataHub = 'DATA_HUB',
  Feed = 'FEED'
}

export type ActivityStructure = {
  __typename?: 'ActivityStructure';
  action: ActivityType;
  field?: Maybe<Scalars['String']>;
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  value: ActivityFieldValue;
};

export enum ActivityType {
  Created = 'CREATED',
  FieldCreated = 'FIELD_CREATED',
  FieldModified = 'FIELD_MODIFIED',
  FieldRemoved = 'FIELD_REMOVED',
  Published = 'PUBLISHED'
}

export type AdditionalInfo = {
  __typename?: 'AdditionalInfo';
  accountId: Scalars['Int'];
  id: Scalars['Int'];
  info: Scalars['String'];
  isDeleted: Scalars['Boolean'];
};

export type AdditionalInfoField = {
  __typename?: 'AdditionalInfoField';
  info: Scalars['String'];
};

export type AdditionalInfoInput = {
  id?: Maybe<Scalars['Int']>;
  info: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  corridor?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  crossroad?: Maybe<Scalars['String']>;
  direction?: Maybe<Direction>;
  milemarker?: Maybe<Scalars['String']>;
  orientation?: Maybe<Orientation>;
  point: Scalars['Point'];
  roadLevelType?: Maybe<RoadLevelType>;
  roadType?: Maybe<RoadType>;
  segmentId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressField = {
  __typename?: 'AddressField';
  address: Address;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  corridor?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  crossroad?: Maybe<Scalars['String']>;
  direction?: Maybe<Direction>;
  milemarker?: Maybe<Scalars['String']>;
  orientation?: Maybe<Orientation>;
  point: Scalars['Point'];
  roadLevelType?: Maybe<RoadLevelType>;
  roadType?: Maybe<RoadType>;
  segmentId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AffectedFixedBusRoute = {
  __typename?: 'AffectedFixedBusRoute';
  affectedBusStopsCount: Scalars['Int'];
  affectingEvents: Array<AffectingEventDetails>;
  color: Scalars['String'];
  durationMinutes: Scalars['Int'];
  estimatedEndTime: Scalars['String'];
  isRerouted: Scalars['Boolean'];
  routeAccountId?: Maybe<Scalars['Int']>;
  routeExternalId: Scalars['String'];
  routeId: Scalars['Int'];
  routeName: Scalars['String'];
  routeNumber: Scalars['String'];
};

export type AffectedTransitEntities = {
  __typename?: 'AffectedTransitEntities';
  affectedFixedBusRoutes: Array<AffectedFixedBusRoute>;
  affectedTransitUnits: Array<AffectedTransitUnit>;
};

export type AffectedTransitUnit = {
  __typename?: 'AffectedTransitUnit';
  affectingEvents: Array<AffectingEventDetails>;
  displayId: Scalars['String'];
  durationMinutes: Scalars['Int'];
  estimatedEndTime: Scalars['String'];
  type: VehicleType;
  unitAccountId: Scalars['Int'];
  unitExternalId: Scalars['String'];
  unitId: Scalars['Int'];
};

export type AffectingEventDetails = {
  __typename?: 'AffectingEventDetails';
  additionalInfo: EventAffectingTransitAdditionalInfo;
  address?: Maybe<Address>;
  affectedBusStops: Array<Scalars['Int']>;
  affectingEntityType: TransitAffectingEntityType;
  affectingEventId: Scalars['Int'];
  durationMinutes: Scalars['Int'];
  endTime?: Maybe<Scalars['DateTime']>;
  estimatedEndTime: Scalars['DateTime'];
  location: Scalars['Geometry'];
  startTime: Scalars['DateTime'];
};

export type AliasColumn = {
  __typename?: 'AliasColumn';
  alias: Scalars['String'];
  segmentAliasLogColumnName: SegmentAliasLogColumnName;
};

export type AliasSubmitter = {
  __typename?: 'AliasSubmitter';
  displayName: Scalars['String'];
  value: Scalars['Int'];
};

export enum AliasType {
  Corridor = 'CORRIDOR',
  Crossroad = 'CROSSROAD'
}

export type AnalyticsAssociatedUnitsColumn = {
  __typename?: 'AnalyticsAssociatedUnitsColumn';
  associatedUnits: Array<Scalars['String']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsAtmsIdColumn = {
  __typename?: 'AnalyticsAtmsIdColumn';
  atmsId?: Maybe<Scalars['String']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsAttributesColumn = {
  __typename?: 'AnalyticsAttributesColumn';
  attributes: Array<Scalars['String']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsCadIdColumn = {
  __typename?: 'AnalyticsCadIdColumn';
  cadId?: Maybe<Scalars['String']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsColumnValue = AnalyticsAssociatedUnitsColumn | AnalyticsAtmsIdColumn | AnalyticsAttributesColumn | AnalyticsCadIdColumn | AnalyticsCorridorColumn | AnalyticsCrossroadColumn | AnalyticsDirectionColumn | AnalyticsEndReasonColumn | AnalyticsEndReasonCommentColumn | AnalyticsFatalitiesColumn | AnalyticsIncidentAdditionalInfoColumn | AnalyticsIncidentAddressColumn | AnalyticsIncidentCreationSourceColumn | AnalyticsIncidentDurationColumn | AnalyticsIncidentEndTimeColumn | AnalyticsIncidentIdColumn | AnalyticsIncidentMitigationTypesColumn | AnalyticsIncidentRelatedEventsColumn | AnalyticsIncidentSeverityColumn | AnalyticsIncidentStartTimeColumn | AnalyticsIncidentStatusColumn | AnalyticsIncidentSubTypeColumn | AnalyticsIncidentTypeColumn | AnalyticsInjuriesColumn | AnalyticsInvolvedVehiclesColumn | AnalyticsLaneColumn | AnalyticsLanesDirectionColumn | AnalyticsLatitudeColumn | AnalyticsLongitudeColumn | AnalyticsMileMarkerColumn | AnalyticsNoteColumn | AnalyticsOrientationColumn | AnalyticsReportSourceColumn | AnalyticsRoadTypeColumn | AnalyticsShiftDurationColumn | AnalyticsShiftEndMileageColumn | AnalyticsShiftEndTimeColumn | AnalyticsShiftFirstMitigationTimeColumn | AnalyticsShiftIdColumn | AnalyticsShiftIncidentsCountColumn | AnalyticsShiftIsCompleteColumn | AnalyticsShiftLastMitigationTimeColumn | AnalyticsShiftMitigationTypesColumn | AnalyticsShiftOvertimeColumn | AnalyticsShiftOvertimeNoteColumn | AnalyticsShiftPausesColumn | AnalyticsShiftRoutesColumn | AnalyticsShiftStartMileageColumn | AnalyticsShiftStartTimeColumn | AnalyticsShiftTotalMileageColumn | AnalyticsShiftTypeColumn | AnalyticsShiftUnitColumn | AnalyticsShiftUserNameColumn | AnalyticsTdEndTimeColumn | AnalyticsTdIdColumn | AnalyticsTdPermitIdColumn | AnalyticsTdRelatedEventsColumn | AnalyticsTdStartTimeColumn | AnalyticsTdStatusColumn | AnalyticsTdSubTypeColumn | AnalyticsTdTitleColumn | AnalyticsTdTypeColumn | AnalyticsUsersInvolvedColumn;

export type AnalyticsCorridorColumn = {
  __typename?: 'AnalyticsCorridorColumn';
  corridor?: Maybe<Scalars['String']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsCrossroadColumn = {
  __typename?: 'AnalyticsCrossroadColumn';
  crossroad?: Maybe<Scalars['String']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsDirectionColumn = {
  __typename?: 'AnalyticsDirectionColumn';
  direction?: Maybe<Direction>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsEndReasonColumn = {
  __typename?: 'AnalyticsEndReasonColumn';
  endReason?: Maybe<IncidentEndReason>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsEndReasonCommentColumn = {
  __typename?: 'AnalyticsEndReasonCommentColumn';
  endReasonComment?: Maybe<Scalars['String']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsFatalitiesColumn = {
  __typename?: 'AnalyticsFatalitiesColumn';
  fatalities?: Maybe<Scalars['Int']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsFilterAdditionalInfoInput = {
  additionalInfoIds: Array<Scalars['Int']>;
};

export type AnalyticsFilterAliasTypesInput = {
  aliasType: AliasType;
};

export type AnalyticsFilterAreaInput = {
  area: Scalars['Polygon'];
};

export type AnalyticsFilterAssociatedUnitByAccountInput = {
  accountId: Scalars['Int'];
};

export type AnalyticsFilterAtmsIdInput = {
  atmsId: Scalars['String'];
};

export type AnalyticsFilterCadIdInput = {
  cadId: Scalars['String'];
};

export type AnalyticsFilterCreatedByAccountInput = {
  accountId: Scalars['Int'];
};

export type AnalyticsFilterDurationInput = {
  lowerLimitSeconds: Scalars['Int'];
  upperLimitSeconds: Scalars['Int'];
};

export type AnalyticsFilterIncidentAttributesInput = {
  attributes: Array<Scalars['String']>;
};

export type AnalyticsFilterIncidentEndReasonCommentRegexInput = {
  endReasonCommentSubString: Scalars['String'];
};

export type AnalyticsFilterIncidentEndReasonsInput = {
  endReasons: Array<IncidentEndReason>;
};

export type AnalyticsFilterIncidentIdInput = {
  incidentId: Scalars['Int'];
};

export type AnalyticsFilterIncidentSeverityInput = {
  severity: IncidentSeverity;
};

export type AnalyticsFilterIncidentStatusInput = {
  statusList: Array<IncidentStatus>;
};

export type AnalyticsFilterIncidentTypeDescriptionRegexInput = {
  typeDescriptionSubString: Scalars['String'];
};

export type AnalyticsFilterIncidentTypesInput = {
  incidentTypes: Array<IncidentTypeWithSubTypesInput>;
};

export type AnalyticsFilterIncidentVehicleLicencePlateRegexInput = {
  licensePlateSubString: Scalars['String'];
};

export type AnalyticsFilterIncidentWithinWorkingHoursInput = {
  withinWorkingHours: Scalars['Boolean'];
};

export type AnalyticsFilterLocationAndAliasRegexInput = {
  locationAndAliasSubString: Scalars['String'];
};

export type AnalyticsFilterMitigatedByAccountInput = {
  accountId: Scalars['Int'];
};

export type AnalyticsFilterMitigationsInput = {
  mitigationTypeIds: Array<Scalars['Int']>;
};

export type AnalyticsFilterNoteRegexInput = {
  noteSubString: Scalars['String'];
};

export type AnalyticsFilterReportSourceInput = {
  reportSources: Array<Scalars['Int']>;
};

export type AnalyticsFilterRoadsInput = {
  roads: Array<Scalars['String']>;
};

export type AnalyticsFilterRoutesIdsInput = {
  routeIds: Array<Scalars['Int']>;
};

export type AnalyticsFilterShiftDriverNameInput = {
  names: Array<Scalars['String']>;
};

export type AnalyticsFilterShiftOvertimeInput = {
  isOvertime: Scalars['Boolean'];
};

export type AnalyticsFilterShiftStatusInput = {
  isComplete: Scalars['Boolean'];
};

export type AnalyticsFilterShiftTypesInput = {
  shiftTypes: Array<Scalars['Int']>;
};

export type AnalyticsFilterShiftUnitInput = {
  unitId: Scalars['Int'];
};

export type AnalyticsFilterSubmittedByInput = {
  submittedBy: Scalars['Int'];
};

export type AnalyticsFilterTdIdInput = {
  tdId: Scalars['Int'];
};

export type AnalyticsFilterTdPermitIdInput = {
  permitId: Scalars['String'];
};

export type AnalyticsFilterTdStatusInput = {
  tdStatus: Array<TrafficDisruptionAnalyticsFilterStatus>;
};

export type AnalyticsFilterTdTitleInput = {
  title: Scalars['String'];
};

export type AnalyticsFilterTdTypesInput = {
  tdTypes: Array<TrafficDisruptionTypeWithSubTypesInput>;
};

export type AnalyticsFilterTimeRangeInput = {
  daysOfTheWeek: Array<DayOfWeek>;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type AnalyticsFilteredIncidentsResultPage = {
  __typename?: 'AnalyticsFilteredIncidentsResultPage';
  incidentColumnNames: Array<AnalyticsIncidentColumnName>;
  rows: Array<FilteredAnalyticsView>;
  totalNumberOfResults?: Maybe<Scalars['Int']>;
};

export type AnalyticsFilteredShiftsResultPage = {
  __typename?: 'AnalyticsFilteredShiftsResultPage';
  rows: Array<FilteredAnalyticsView>;
  shiftColumnNames: Array<AnalyticsShiftColumnName>;
  totalNumberOfResults?: Maybe<Scalars['Int']>;
};

export type AnalyticsFilteredTrafficDisruptionsResultPage = {
  __typename?: 'AnalyticsFilteredTrafficDisruptionsResultPage';
  rows: Array<FilteredAnalyticsView>;
  tdColumnNames: Array<AnalyticsTrafficDisruptionColumnName>;
  totalNumberOfResults?: Maybe<Scalars['Int']>;
};

export type AnalyticsIncidentAdditionalInfoColumn = {
  __typename?: 'AnalyticsIncidentAdditionalInfoColumn';
  additionalInfo: Array<Scalars['String']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsIncidentAddressColumn = {
  __typename?: 'AnalyticsIncidentAddressColumn';
  address: Address;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export enum AnalyticsIncidentColumnName {
  AdditionalInfo = 'ADDITIONAL_INFO',
  Address = 'ADDRESS',
  AssociatedUnits = 'ASSOCIATED_UNITS',
  AtmsId = 'ATMS_ID',
  Attributes = 'ATTRIBUTES',
  CadId = 'CAD_ID',
  Corridor = 'CORRIDOR',
  CreationSource = 'CREATION_SOURCE',
  Crossroad = 'CROSSROAD',
  Direction = 'DIRECTION',
  Duration = 'DURATION',
  EndReason = 'END_REASON',
  EndReasonComment = 'END_REASON_COMMENT',
  EndTime = 'END_TIME',
  Fatalities = 'FATALITIES',
  Id = 'ID',
  IncidentStatus = 'INCIDENT_STATUS',
  IncidentSubType = 'INCIDENT_SUB_TYPE',
  IncidentType = 'INCIDENT_TYPE',
  Injuries = 'INJURIES',
  InvolvedVehiclesCount = 'INVOLVED_VEHICLES_COUNT',
  Lanes = 'LANES',
  LanesDirection = 'LANES_DIRECTION',
  Latitude = 'LATITUDE',
  Longitude = 'LONGITUDE',
  MileMarker = 'MILE_MARKER',
  MitigationTypes = 'MITIGATION_TYPES',
  Note = 'NOTE',
  Orientation = 'ORIENTATION',
  RelatedEventsCount = 'RELATED_EVENTS_COUNT',
  ReportSource = 'REPORT_SOURCE',
  RoadType = 'ROAD_TYPE',
  Severity = 'SEVERITY',
  StartTime = 'START_TIME',
  UsersInvolved = 'USERS_INVOLVED'
}

export type AnalyticsIncidentCreationSourceColumn = {
  __typename?: 'AnalyticsIncidentCreationSourceColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  source: CreationSource;
  userName?: Maybe<Scalars['String']>;
};

export type AnalyticsIncidentDurationColumn = {
  __typename?: 'AnalyticsIncidentDurationColumn';
  durationMillis?: Maybe<Scalars['Long']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsIncidentEndTimeColumn = {
  __typename?: 'AnalyticsIncidentEndTimeColumn';
  endTime?: Maybe<Scalars['DateTime']>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsIncidentFiltersListInput = {
  additionalInfoFilter?: Maybe<AnalyticsFilterAdditionalInfoInput>;
  areaFilter?: Maybe<AnalyticsFilterAreaInput>;
  associatedUnitByAccount?: Maybe<AnalyticsFilterAssociatedUnitByAccountInput>;
  atmsIdFilter?: Maybe<AnalyticsFilterAtmsIdInput>;
  attributesFilter?: Maybe<AnalyticsFilterIncidentAttributesInput>;
  cadIdFilter?: Maybe<AnalyticsFilterCadIdInput>;
  createdByAccount?: Maybe<AnalyticsFilterCreatedByAccountInput>;
  durationFilter?: Maybe<AnalyticsFilterDurationInput>;
  endReasonCommentRegexFilter?: Maybe<AnalyticsFilterIncidentEndReasonCommentRegexInput>;
  endReasonsFilter?: Maybe<AnalyticsFilterIncidentEndReasonsInput>;
  incidentIdFilter?: Maybe<AnalyticsFilterIncidentIdInput>;
  incidentStatusFilter?: Maybe<AnalyticsFilterIncidentStatusInput>;
  incidentTypeFilter?: Maybe<AnalyticsFilterIncidentTypesInput>;
  mitigatedByAccount?: Maybe<AnalyticsFilterMitigatedByAccountInput>;
  mitigationsFilter?: Maybe<AnalyticsFilterMitigationsInput>;
  noteRegexFilter?: Maybe<AnalyticsFilterNoteRegexInput>;
  reportSourcesFilter?: Maybe<AnalyticsFilterReportSourceInput>;
  roadsFilter?: Maybe<AnalyticsFilterRoadsInput>;
  routesFilter?: Maybe<AnalyticsFilterRoutesIdsInput>;
  severityFilter?: Maybe<AnalyticsFilterIncidentSeverityInput>;
  startTimeFilter?: Maybe<AnalyticsFilterTimeRangeInput>;
  typeDescriptionRegexFilter?: Maybe<AnalyticsFilterIncidentTypeDescriptionRegexInput>;
  vehicleLicencePlateRegexFilter?: Maybe<AnalyticsFilterIncidentVehicleLicencePlateRegexInput>;
  workingHours?: Maybe<AnalyticsFilterIncidentWithinWorkingHoursInput>;
  workspaceIdsFilter?: Maybe<AnalyticsWorkspacesFilterInput>;
};

export type AnalyticsIncidentIdColumn = {
  __typename?: 'AnalyticsIncidentIdColumn';
  id: Scalars['Int'];
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsIncidentMitigationTypesColumn = {
  __typename?: 'AnalyticsIncidentMitigationTypesColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  mitigationTypes: Array<Scalars['String']>;
};

export type AnalyticsIncidentRelatedEventsColumn = {
  __typename?: 'AnalyticsIncidentRelatedEventsColumn';
  counter: Scalars['Long'];
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsIncidentSeverityColumn = {
  __typename?: 'AnalyticsIncidentSeverityColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  severity?: Maybe<IncidentSeverity>;
};

export enum AnalyticsIncidentSortColumn {
  Address = 'ADDRESS',
  AtmsId = 'ATMS_ID',
  CadId = 'CAD_ID',
  Corridor = 'CORRIDOR',
  CreationSource = 'CREATION_SOURCE',
  Crossroad = 'CROSSROAD',
  Direction = 'DIRECTION',
  Duration = 'DURATION',
  EndReason = 'END_REASON',
  EndTime = 'END_TIME',
  Fatalities = 'FATALITIES',
  Id = 'ID',
  IncidentStatus = 'INCIDENT_STATUS',
  IncidentSubType = 'INCIDENT_SUB_TYPE',
  IncidentType = 'INCIDENT_TYPE',
  Injuries = 'INJURIES',
  InvolvedVehiclesCount = 'INVOLVED_VEHICLES_COUNT',
  Latitude = 'LATITUDE',
  Longitude = 'LONGITUDE',
  MileMarker = 'MILE_MARKER',
  Orientation = 'ORIENTATION',
  RelatedEventsCount = 'RELATED_EVENTS_COUNT',
  Severity = 'SEVERITY',
  StartTime = 'START_TIME'
}

export type AnalyticsIncidentStartTimeColumn = {
  __typename?: 'AnalyticsIncidentStartTimeColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  startTime: Scalars['DateTime'];
};

export type AnalyticsIncidentStatusColumn = {
  __typename?: 'AnalyticsIncidentStatusColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  status: IncidentStatus;
};

export type AnalyticsIncidentSubTypeColumn = {
  __typename?: 'AnalyticsIncidentSubTypeColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  subType?: Maybe<Scalars['String']>;
};

export type AnalyticsIncidentTypeColumn = {
  __typename?: 'AnalyticsIncidentTypeColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  type: IncidentType;
};

export type AnalyticsInjuriesColumn = {
  __typename?: 'AnalyticsInjuriesColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  injuries?: Maybe<Scalars['Int']>;
};

export type AnalyticsInvolvedVehiclesColumn = {
  __typename?: 'AnalyticsInvolvedVehiclesColumn';
  counter: Scalars['Long'];
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsLaneColumn = {
  __typename?: 'AnalyticsLaneColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  lanes: Array<IncidentLane>;
};

export type AnalyticsLanesDirectionColumn = {
  __typename?: 'AnalyticsLanesDirectionColumn';
  direction?: Maybe<Direction>;
  incidentColumnName: AnalyticsIncidentColumnName;
};

export type AnalyticsLatitudeColumn = {
  __typename?: 'AnalyticsLatitudeColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  latitude: Scalars['Float'];
};

export type AnalyticsLongitudeColumn = {
  __typename?: 'AnalyticsLongitudeColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  longitude: Scalars['Float'];
};

export type AnalyticsMileMarkerColumn = {
  __typename?: 'AnalyticsMileMarkerColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  milemarker?: Maybe<Scalars['String']>;
};

export type AnalyticsNoteColumn = {
  __typename?: 'AnalyticsNoteColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  notes: Array<Scalars['String']>;
};

export type AnalyticsOrientationColumn = {
  __typename?: 'AnalyticsOrientationColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  orientation?: Maybe<Orientation>;
};

export type AnalyticsReportSourceColumn = {
  __typename?: 'AnalyticsReportSourceColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  reportSources: Array<Scalars['String']>;
};

export type AnalyticsReportSrc = {
  __typename?: 'AnalyticsReportSrc';
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type AnalyticsReportsResponse = {
  __typename?: 'AnalyticsReportsResponse';
  dashboardUrl: Scalars['String'];
};

export type AnalyticsReportsUrls = {
  __typename?: 'AnalyticsReportsUrls';
  fixedRouteTransitReport?: Maybe<Array<AnalyticsReportSrc>>;
  fspReport?: Maybe<Array<AnalyticsReportSrc>>;
  incidentAnalysisReport?: Maybe<Array<AnalyticsReportSrc>>;
  mitigationsReport?: Maybe<Array<AnalyticsReportSrc>>;
  speedMeasurementReport?: Maybe<Array<AnalyticsReportSrc>>;
  timPerformanceReport?: Maybe<Array<AnalyticsReportSrc>>;
  transitReport?: Maybe<Array<AnalyticsReportSrc>>;
  travelReliabilityReport?: Maybe<Array<AnalyticsReportSrc>>;
  unitsPerformanceReport?: Maybe<Array<AnalyticsReportSrc>>;
};

export type AnalyticsRoadTypeColumn = {
  __typename?: 'AnalyticsRoadTypeColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  roadType?: Maybe<RoadType>;
};

export enum AnalyticsShiftColumnName {
  Duration = 'DURATION',
  EndMileage = 'END_MILEAGE',
  EndTime = 'END_TIME',
  FirstMitigationTime = 'FIRST_MITIGATION_TIME',
  Id = 'ID',
  IsComplete = 'IS_COMPLETE',
  LastMitigationTime = 'LAST_MITIGATION_TIME',
  MitigationTypes = 'MITIGATION_TYPES',
  NumOfIncidents = 'NUM_OF_INCIDENTS',
  NumOfPauses = 'NUM_OF_PAUSES',
  Overtime = 'OVERTIME',
  OvertimeNote = 'OVERTIME_NOTE',
  Routes = 'ROUTES',
  StartMileage = 'START_MILEAGE',
  StartTime = 'START_TIME',
  TotalMileage = 'TOTAL_MILEAGE',
  Type = 'TYPE',
  Unit = 'UNIT',
  User = 'USER'
}

export type AnalyticsShiftDurationColumn = {
  __typename?: 'AnalyticsShiftDurationColumn';
  durationMillis?: Maybe<Scalars['Long']>;
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftEndMileageColumn = {
  __typename?: 'AnalyticsShiftEndMileageColumn';
  mileage?: Maybe<Scalars['Int']>;
  modified: Scalars['Boolean'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftEndTimeColumn = {
  __typename?: 'AnalyticsShiftEndTimeColumn';
  endTime?: Maybe<Scalars['DateTime']>;
  modified: Scalars['Boolean'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftFiltersListInput = {
  driverNameFilter?: Maybe<AnalyticsFilterShiftDriverNameInput>;
  durationFilter?: Maybe<AnalyticsFilterDurationInput>;
  mitigationsFilter?: Maybe<AnalyticsFilterMitigationsInput>;
  overtimeFilter?: Maybe<AnalyticsFilterShiftOvertimeInput>;
  routesFilter?: Maybe<AnalyticsFilterRoutesIdsInput>;
  shiftTypesFilter?: Maybe<AnalyticsFilterShiftTypesInput>;
  startTimeFilter?: Maybe<AnalyticsFilterTimeRangeInput>;
  statusFilter?: Maybe<AnalyticsFilterShiftStatusInput>;
  unitFilter?: Maybe<AnalyticsFilterShiftUnitInput>;
};

export type AnalyticsShiftFirstMitigationTimeColumn = {
  __typename?: 'AnalyticsShiftFirstMitigationTimeColumn';
  shiftColumnName: AnalyticsShiftColumnName;
  time?: Maybe<Scalars['DateTime']>;
};

export type AnalyticsShiftIdColumn = {
  __typename?: 'AnalyticsShiftIdColumn';
  shiftColumnName: AnalyticsShiftColumnName;
  shiftId: Scalars['Int'];
};

export type AnalyticsShiftIncidentsCountColumn = {
  __typename?: 'AnalyticsShiftIncidentsCountColumn';
  counter: Scalars['Long'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftIsCompleteColumn = {
  __typename?: 'AnalyticsShiftIsCompleteColumn';
  isComplete: Scalars['Boolean'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftLastMitigationTimeColumn = {
  __typename?: 'AnalyticsShiftLastMitigationTimeColumn';
  shiftColumnName: AnalyticsShiftColumnName;
  time?: Maybe<Scalars['DateTime']>;
};

export type AnalyticsShiftMitigationTypesColumn = {
  __typename?: 'AnalyticsShiftMitigationTypesColumn';
  mitigationTypes: Array<Scalars['String']>;
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftOvertimeColumn = {
  __typename?: 'AnalyticsShiftOvertimeColumn';
  isOvertime: Scalars['Boolean'];
  modified: Scalars['Boolean'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftOvertimeNoteColumn = {
  __typename?: 'AnalyticsShiftOvertimeNoteColumn';
  modified: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftPausesColumn = {
  __typename?: 'AnalyticsShiftPausesColumn';
  counter: Scalars['Long'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftRoutesColumn = {
  __typename?: 'AnalyticsShiftRoutesColumn';
  modified: Scalars['Boolean'];
  names: Array<Scalars['String']>;
  shiftColumnName: AnalyticsShiftColumnName;
};

export enum AnalyticsShiftSortColumn {
  Duration = 'DURATION',
  EndMileage = 'END_MILEAGE',
  EndTime = 'END_TIME',
  FirstMitigationTime = 'FIRST_MITIGATION_TIME',
  Id = 'ID',
  IsComplete = 'IS_COMPLETE',
  LastMitigationTime = 'LAST_MITIGATION_TIME',
  NumOfIncidents = 'NUM_OF_INCIDENTS',
  NumOfPauses = 'NUM_OF_PAUSES',
  Overtime = 'OVERTIME',
  OvertimeNote = 'OVERTIME_NOTE',
  StartMileage = 'START_MILEAGE',
  StartTime = 'START_TIME',
  TotalMileage = 'TOTAL_MILEAGE',
  Type = 'TYPE',
  Unit = 'UNIT',
  User = 'USER'
}

export type AnalyticsShiftStartMileageColumn = {
  __typename?: 'AnalyticsShiftStartMileageColumn';
  mileage?: Maybe<Scalars['Int']>;
  modified: Scalars['Boolean'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftStartTimeColumn = {
  __typename?: 'AnalyticsShiftStartTimeColumn';
  modified: Scalars['Boolean'];
  shiftColumnName: AnalyticsShiftColumnName;
  startTime: Scalars['DateTime'];
};

export type AnalyticsShiftTotalMileageColumn = {
  __typename?: 'AnalyticsShiftTotalMileageColumn';
  mileage?: Maybe<Scalars['Int']>;
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftTypeColumn = {
  __typename?: 'AnalyticsShiftTypeColumn';
  modified: Scalars['Boolean'];
  shiftColumnName: AnalyticsShiftColumnName;
  shiftType?: Maybe<Scalars['String']>;
};

export type AnalyticsShiftUnitColumn = {
  __typename?: 'AnalyticsShiftUnitColumn';
  displayId?: Maybe<Scalars['String']>;
  modified: Scalars['Boolean'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsShiftUserNameColumn = {
  __typename?: 'AnalyticsShiftUserNameColumn';
  name: Scalars['String'];
  shiftColumnName: AnalyticsShiftColumnName;
};

export type AnalyticsTdEndTimeColumn = {
  __typename?: 'AnalyticsTDEndTimeColumn';
  endTime?: Maybe<Scalars['DateTime']>;
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
};

export type AnalyticsTdIdColumn = {
  __typename?: 'AnalyticsTDIdColumn';
  id: Scalars['Int'];
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
};

export type AnalyticsTdPermitIdColumn = {
  __typename?: 'AnalyticsTDPermitIdColumn';
  permitId?: Maybe<Scalars['String']>;
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
};

export type AnalyticsTdRelatedEventsColumn = {
  __typename?: 'AnalyticsTDRelatedEventsColumn';
  counter: Scalars['Long'];
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
};

export type AnalyticsTdStartTimeColumn = {
  __typename?: 'AnalyticsTDStartTimeColumn';
  startTime: Scalars['DateTime'];
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
};

export type AnalyticsTdStatusColumn = {
  __typename?: 'AnalyticsTDStatusColumn';
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
  tdStatus: TrafficDisruptionStatus;
};

export type AnalyticsTdSubTypeColumn = {
  __typename?: 'AnalyticsTDSubTypeColumn';
  subType?: Maybe<Scalars['String']>;
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
};

export type AnalyticsTdTitleColumn = {
  __typename?: 'AnalyticsTDTitleColumn';
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
  title?: Maybe<Scalars['String']>;
};

export type AnalyticsTdTypeColumn = {
  __typename?: 'AnalyticsTDTypeColumn';
  tdColumnName: AnalyticsTrafficDisruptionColumnName;
  tdType: TrafficDisruptionType;
};

export enum AnalyticsTrafficDisruptionColumnName {
  EndTime = 'END_TIME',
  Id = 'ID',
  PermitId = 'PERMIT_ID',
  RelatedEventsCount = 'RELATED_EVENTS_COUNT',
  StartTime = 'START_TIME',
  Status = 'STATUS',
  SubType = 'SUB_TYPE',
  Title = 'TITLE',
  Type = 'TYPE'
}

export type AnalyticsTrafficDisruptionFiltersListInput = {
  areaFilter?: Maybe<AnalyticsFilterAreaInput>;
  permitIdFilter?: Maybe<AnalyticsFilterTdPermitIdInput>;
  status?: Maybe<AnalyticsFilterTdStatusInput>;
  tdIdFilter?: Maybe<AnalyticsFilterTdIdInput>;
  tdTypeFilter?: Maybe<AnalyticsFilterTdTypesInput>;
  timeRangeFilter?: Maybe<AnalyticsFilterTimeRangeInput>;
  titleFilter?: Maybe<AnalyticsFilterTdTitleInput>;
  workspaceIdsFilter?: Maybe<AnalyticsWorkspacesFilterInput>;
};

export enum AnalyticsTrafficDisruptionSortColumn {
  Duration = 'DURATION',
  EndTime = 'END_TIME',
  Id = 'ID',
  PermitId = 'PERMIT_ID',
  RelatedEventsCount = 'RELATED_EVENTS_COUNT',
  StartTime = 'START_TIME',
  Status = 'STATUS',
  SubType = 'SUB_TYPE',
  Title = 'TITLE',
  Type = 'TYPE'
}

export type AnalyticsUsersInvolvedColumn = {
  __typename?: 'AnalyticsUsersInvolvedColumn';
  incidentColumnName: AnalyticsIncidentColumnName;
  users: Array<Scalars['String']>;
};

export type AnalyticsWorkspacesFilterInput = {
  workspacesIds: Array<Scalars['Int']>;
};

export type AssetsByLocation = {
  __typename?: 'AssetsByLocation';
  cameras: Array<AssociatedCamera>;
  dmses: Array<AssociatedDms>;
};

export type AssociatedCamera = {
  __typename?: 'AssociatedCamera';
  camera: StreetCamera;
  default: Scalars['Boolean'];
  positionIndex: Scalars['Int'];
};

export type AssociatedCameraInput = {
  cameraExternalId: Scalars['String'];
  default: Scalars['Boolean'];
  positionIndex?: Maybe<Scalars['Int']>;
};

export type AssociatedDms = {
  __typename?: 'AssociatedDMS';
  dms: Dms;
  positionIndex: Scalars['Int'];
};

export type AssociatedDmsInput = {
  dmsId: Scalars['Int'];
  positionIndex?: Maybe<Scalars['Int']>;
};

export enum AuditActionType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ'
}

export type BearingInput = {
  value: Scalars['Int'];
};

export type BooleanField = {
  __typename?: 'BooleanField';
  bool: Scalars['Boolean'];
};

export type BreakDetails = {
  __typename?: 'BreakDetails';
  reason: ShiftPauseReasons;
  startedAt: Scalars['String'];
};

export type BusStopBusArrivalDetails = {
  __typename?: 'BusStopBusArrivalDetails';
  accountId: Scalars['Int'];
  busId: Scalars['Int'];
  displayId: Scalars['String'];
  eta: Scalars['Int'];
  route: BusStopPassingRouteDetails;
  status: FixedRouteBusStatus;
  statusInMinutes: Scalars['Int'];
};

export type BusStopDetails = {
  __typename?: 'BusStopDetails';
  activeBuses: Array<BusStopBusArrivalDetails>;
  displayId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  routes: Array<BusStopPassingRouteDetails>;
};

export type BusStopPassingRouteDetails = {
  __typename?: 'BusStopPassingRouteDetails';
  color: Scalars['String'];
  number: Scalars['String'];
  routeId: Scalars['Int'];
};

export type BusStopPassingRouteView = {
  __typename?: 'BusStopPassingRouteView';
  color: Scalars['String'];
  number: Scalars['String'];
  routeId: Scalars['Int'];
};

export type BusStopView = {
  __typename?: 'BusStopView';
  busStopId: Scalars['Int'];
  displayId: Scalars['String'];
  hasBusRouteFromContextAccount: Scalars['Boolean'];
  location: Scalars['Point'];
  routes: Array<BusStopPassingRouteView>;
};

export type Camera = {
  imageUrl?: Maybe<Scalars['String']>;
  isVideo: Scalars['Boolean'];
  streamType?: Maybe<CameraStreamType>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type CameraField = {
  __typename?: 'CameraField';
  default: Scalars['Boolean'];
  externalId: Scalars['String'];
  title: Scalars['String'];
};

export type CameraInternal = {
  __typename?: 'CameraInternal';
  default: Scalars['Boolean'];
  externalId: Scalars['String'];
  positionIndex: Scalars['Int'];
};

export enum CameraPosition {
  Back = 'BACK',
  Driver = 'DRIVER',
  Front = 'FRONT'
}

export enum CameraStreamType {
  ImageStream = 'IMAGE_STREAM',
  SnapshotStream = 'SNAPSHOT_STREAM',
  VideoStream = 'VIDEO_STREAM'
}

export enum Color {
  Beige = 'BEIGE',
  Black = 'BLACK',
  Blue = 'BLUE',
  Bronze = 'BRONZE',
  Brown = 'BROWN',
  Burgandy = 'BURGANDY',
  BurntOrange = 'BURNT_ORANGE',
  Camouflage = 'CAMOUFLAGE',
  Champange = 'CHAMPANGE',
  Charcoal = 'CHARCOAL',
  Copper = 'COPPER',
  Cream = 'CREAM',
  DarkBlue = 'DARK_BLUE',
  DarkBrown = 'DARK_BROWN',
  DarkGold = 'DARK_GOLD',
  DarkGreen = 'DARK_GREEN',
  DarkGrey = 'DARK_GREY',
  DarkPurple = 'DARK_PURPLE',
  DarkRed = 'DARK_RED',
  Gold = 'GOLD',
  Green = 'GREEN',
  Grey = 'GREY',
  Ivory = 'IVORY',
  LightBlue = 'LIGHT_BLUE',
  LightBrown = 'LIGHT_BROWN',
  LightGreen = 'LIGHT_GREEN',
  LightGrey = 'LIGHT_GREY',
  LightPurple = 'LIGHT_PURPLE',
  Maroon = 'MAROON',
  Multicolored = 'MULTICOLORED',
  NavyBlue = 'NAVY_BLUE',
  OffWhite = 'OFF_WHITE',
  OliveGreen = 'OLIVE_GREEN',
  Orange = 'ORANGE',
  Peach = 'PEACH',
  Pearl = 'PEARL',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Rust = 'RUST',
  Silver = 'SILVER',
  Tan = 'TAN',
  Turquoise = 'TURQUOISE',
  UnknownColor = 'UNKNOWN_COLOR',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export type CongestionAffectingTransitAdditionalInfo = {
  __typename?: 'CongestionAffectingTransitAdditionalInfo';
  lengthMeters: Scalars['Float'];
  type: Scalars['String'];
};

export type CongestionView = View & {
  __typename?: 'CongestionView';
  delay?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  endAddress?: Maybe<Address>;
  endPointLocation?: Maybe<Scalars['Point']>;
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  isIrregular: Scalars['Boolean'];
  layerType: LayerType;
  location: Scalars['Geometry'];
  speed?: Maybe<Scalars['Float']>;
  startAddress?: Maybe<Address>;
  startPointLocation?: Maybe<Scalars['Point']>;
  startTime: Scalars['String'];
  status: Status;
  updatedAt: Scalars['String'];
  workspaces: Array<Scalars['Int']>;
};

export type Construction = TrafficDisruption & {
  __typename?: 'Construction';
  address?: Maybe<Address>;
  affectedLanes?: Maybe<Array<TrafficDisruptionLane>>;
  allLanesAffected: Scalars['Boolean'];
  cameras: Array<AssociatedCamera>;
  contactPerson?: Maybe<TrafficDisruptionContactPerson>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dmses: Array<AssociatedDms>;
  endTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  feedPriorityType: FeedPriorityType;
  id: Scalars['Int'];
  isAllDay: Scalars['Boolean'];
  lanesClosureType: LanesClosureType;
  location: Scalars['Geometry'];
  media?: Maybe<Array<TrafficDisruptionMedia>>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  otherTypeDescription?: Maybe<Scalars['String']>;
  permit?: Maybe<Permit>;
  schedule?: Maybe<TrafficDisruptionScheduleDto>;
  startTime: Scalars['DateTime'];
  status: TrafficDisruptionStatus;
  title: Scalars['String'];
  type: ConstructionType;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['Int'];
  workzoneDataExchange?: Maybe<WorkzoneDataExchangeDto>;
};

export enum ConstructionPublicationMessageTemplateVariables {
  AffectedLanes = 'AFFECTED_LANES',
  Corridor = 'CORRIDOR',
  Crossroad = 'CROSSROAD',
  Description = 'DESCRIPTION',
  Direction = 'DIRECTION',
  EndTime = 'END_TIME',
  MileMarker = 'MILE_MARKER',
  Orientation = 'ORIENTATION',
  PublishTime = 'PUBLISH_TIME',
  RoadType = 'ROAD_TYPE',
  Schedule = 'SCHEDULE',
  StartTime = 'START_TIME',
  Subtype = 'SUBTYPE'
}

export type ConstructionRelatedEventDto = RelatedEventDto & {
  __typename?: 'ConstructionRelatedEventDTO';
  constructionType: ConstructionType;
  entityType: RelatedEventEntityTypeDto;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location: Scalars['Geometry'];
  trafficDisruptionAddress?: Maybe<Address>;
  trafficDisruptionStatus: TrafficDisruptionStatus;
  trafficDisruptionTitle: Scalars['String'];
};

export enum ConstructionType {
  AtmsInstallation = 'ATMS_INSTALLATION',
  BikeLanes = 'BIKE_LANES',
  CctvInstallation = 'CCTV_INSTALLATION',
  CctvMaintenance = 'CCTV_MAINTENANCE',
  DitchCleaning = 'DITCH_CLEANING',
  DmsInstallation = 'DMS_INSTALLATION',
  DmsMaintenance = 'DMS_MAINTENANCE',
  Drainage = 'DRAINAGE',
  Landscaping = 'LANDSCAPING',
  Lighting = 'LIGHTING',
  NewRoad = 'NEW_ROAD',
  Other = 'OTHER',
  Paving = 'PAVING',
  Pothole = 'POTHOLE',
  RoadwaySweeping = 'ROADWAY_SWEEPING',
  RoadMarking = 'ROAD_MARKING',
  RoadSafetyBarriers = 'ROAD_SAFETY_BARRIERS',
  SensorInstallation = 'SENSOR_INSTALLATION',
  SensorMaintenance = 'SENSOR_MAINTENANCE',
  ServiceRoads = 'SERVICE_ROADS',
  SewerPipeReplacement = 'SEWER_PIPE_REPLACEMENT',
  SidewalkRepair = 'SIDEWALK_REPAIR',
  TrafficSignalAllWayFlash = 'TRAFFIC_SIGNAL_ALL_WAY_FLASH',
  TrafficSignalPowerOutage = 'TRAFFIC_SIGNAL_POWER_OUTAGE',
  TreeTrimming = 'TREE_TRIMMING'
}

export type ConstructionTypeField = {
  __typename?: 'ConstructionTypeField';
  constructionType: ConstructionType;
};

export type ConstructionView = View & {
  __typename?: 'ConstructionView';
  address?: Maybe<Address>;
  allLanesAffected: Scalars['Boolean'];
  createdAt: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  lanesClosureType: LanesClosureType;
  layerType: LayerType;
  location: Scalars['Geometry'];
  multiDirectionLanesAffected: Scalars['Boolean'];
  otherTypeDescription?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  status: TrafficDisruptionStatus;
  title: Scalars['String'];
  type: ConstructionType;
  updatedAt: Scalars['String'];
  workspaces: Array<Scalars['Int']>;
};

export type ContactPersonField = {
  __typename?: 'ContactPersonField';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum CrashRiskAreaStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Predicted = 'PREDICTED'
}

export type CrashRiskAreaView = View & {
  __typename?: 'CrashRiskAreaView';
  distance: Scalars['Float'];
  endAddress?: Maybe<Address>;
  endTime: Scalars['String'];
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  layerType: LayerType;
  location: Scalars['Geometry'];
  startAddress?: Maybe<Address>;
  startTime: Scalars['String'];
  status: CrashRiskAreaStatus;
  workspaces: Array<Scalars['Int']>;
};

export type CreateAdditionalInfoInput = {
  accountId: Scalars['Int'];
  info: Scalars['String'];
};

export type CreateConstructionInput = {
  address?: Maybe<AddressInput>;
  affectedLanes?: Maybe<Array<CreateLaneInput>>;
  allLanesAffected: Scalars['Boolean'];
  cameras?: Maybe<Array<AssociatedCameraInput>>;
  contactPerson?: Maybe<TrafficDisruptionContactPersonInput>;
  description?: Maybe<Scalars['String']>;
  dmses?: Maybe<Array<AssociatedDmsInput>>;
  endTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  feedPriorityType?: Maybe<FeedPriorityType>;
  isAllDay: Scalars['Boolean'];
  lanesClosureType: LanesClosureType;
  location: Scalars['Geometry'];
  media?: Maybe<Array<MediaFileInput>>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  otherTypeDescription?: Maybe<Scalars['String']>;
  permit?: Maybe<PermitInput>;
  relatedEvents?: Maybe<Array<RelatedEventInputDtoInput>>;
  relatedImages: Array<RelatedImageRequestInput>;
  schedule?: Maybe<TrafficDisruptionScheduleDtoInput>;
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
  type: ConstructionType;
};

export type CreateDetailedShiftInput = {
  pauses: Array<ShiftPauseInput>;
  shiftInput: DetailedShiftDataInput;
};

export type CreateIncidentInvolvedVehicleInput = {
  color?: Maybe<Color>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  make?: Maybe<VehicleBrand>;
  model?: Maybe<Scalars['String']>;
  state?: Maybe<State>;
  type?: Maybe<VehicleCategory>;
};

export type CreateIncidentMitigationInput = {
  driverId: Scalars['Int'];
  interval: DateIntervalInput;
  mitigationTypeId: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type CreateIncidentMutationInput = {
  additionalInfos?: Maybe<Array<IncidentAdditionalInfoInput>>;
  address: AddressInput;
  affectedLanes?: Maybe<Array<CreateLaneInput>>;
  allLanesAffected: Scalars['Boolean'];
  associatedUnits?: Maybe<Array<IncidentUnitInput>>;
  atmsId?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Scalars['String']>>;
  autoPublish: Scalars['Boolean'];
  cadId?: Maybe<Scalars['String']>;
  cameras?: Maybe<Array<AssociatedCameraInput>>;
  endedAt?: Maybe<Scalars['DateTime']>;
  estimatedEndTime?: Maybe<OptionalDateTimeInput>;
  injuries?: Maybe<Scalars['Int']>;
  injurySeverities?: Maybe<Array<InjurySeverity>>;
  involvedVehicles?: Maybe<Array<CreateIncidentInvolvedVehicleInput>>;
  involvedVehiclesCount?: Maybe<Scalars['Int']>;
  lanesStatus?: Maybe<LanesStatus>;
  location: Scalars['Point'];
  mitigations?: Maybe<Array<CreateIncidentMitigationInput>>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  notes?: Maybe<Array<CreateIncidentNoteInput>>;
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  relatedEvents?: Maybe<Array<RelatedEventInputDtoInput>>;
  reportSources?: Maybe<Array<IncidentReportSourceInput>>;
  responsePlan?: Maybe<ResponsePlanInput>;
  severity?: Maybe<IncidentSeverity>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: IncidentStatus;
  subType?: Maybe<Scalars['String']>;
  type: IncidentType;
  typeDescription?: Maybe<Scalars['String']>;
};

export type CreateIncidentNoteInput = {
  note: Scalars['String'];
};

export type CreateLaneInput = {
  direction?: Maybe<Direction>;
  isAffected: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  number?: Maybe<Scalars['Int']>;
  positionIndex: Scalars['Int'];
  roadType: RoadType;
  type: LaneType;
};

export type CreateMediaAccessLogInput = {
  auditActionType: AuditActionType;
  incidentMedia: IncidentMediaInput;
};

export type CreateMediaAccessedLogInput = {
  auditActionType: AuditActionType;
  id: Scalars['Int'];
  mediaType?: Maybe<MediaType>;
  url: Scalars['String'];
};

export type CreateMitigationTypeInput = {
  accountId: Scalars['Int'];
  description: Scalars['String'];
};

export type CreateOrUpdateWorkingHourItemInput = {
  dailyRanges: Array<DailyRangeInput>;
  day: Scalars['Int'];
};

export type CreateReportSourceInput = {
  accountId: Scalars['Int'];
  reportSource: Scalars['String'];
};

export type CreateResponsePlanTemplateInput = {
  actions: Array<TemplateActionInput>;
  entityType: ResponsePlanEntityType;
  title: Scalars['String'];
};

export type CreateRoadClosureInput = {
  address?: Maybe<AddressInput>;
  affectedLanes?: Maybe<Array<CreateLaneInput>>;
  allLanesAffected: Scalars['Boolean'];
  cameras?: Maybe<Array<AssociatedCameraInput>>;
  contactPerson?: Maybe<TrafficDisruptionContactPersonInput>;
  description?: Maybe<Scalars['String']>;
  dmses?: Maybe<Array<AssociatedDmsInput>>;
  endTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  feedPriorityType?: Maybe<FeedPriorityType>;
  isAllDay: Scalars['Boolean'];
  lanesClosureType: LanesClosureType;
  location: Scalars['Geometry'];
  media?: Maybe<Array<MediaFileInput>>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  permit?: Maybe<PermitInput>;
  relatedEvents?: Maybe<Array<RelatedEventInputDtoInput>>;
  relatedImages: Array<RelatedImageRequestInput>;
  schedule?: Maybe<TrafficDisruptionScheduleDtoInput>;
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type CreateRoleInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Scalars['Int']>;
};

export type CreateRouteTypeInput = {
  accountId: Scalars['Int'];
  area: Scalars['Polygon'];
  name: Scalars['String'];
};

export type CreateShiftIncidentMitigationInput = {
  incidentMitigations: Array<IncidentMitigationCreateInput>;
  shiftId: Scalars['Int'];
};

export type CreateShiftInput = {
  routeTypesIds?: Maybe<Array<Scalars['Int']>>;
  shiftTypeId?: Maybe<Scalars['Int']>;
  startMileage?: Maybe<Scalars['Int']>;
  unitId?: Maybe<Scalars['Int']>;
};

export type CreateShiftPauseInput = {
  reason: ShiftPauseReasons;
  reasonNote?: Maybe<Scalars['String']>;
  shiftId: Scalars['Int'];
};

export type CreateShiftTypeInput = {
  accountId: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateSpecialEventInput = {
  address?: Maybe<AddressInput>;
  affectedLanes?: Maybe<Array<CreateLaneInput>>;
  allLanesAffected: Scalars['Boolean'];
  contactPerson?: Maybe<TrafficDisruptionContactPersonInput>;
  description?: Maybe<Scalars['String']>;
  endReason?: Maybe<DeletedEndReason>;
  endTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  feedPriorityType?: Maybe<FeedPriorityType>;
  isAllDay: Scalars['Boolean'];
  lanesClosureType: LanesClosureType;
  location: Scalars['Geometry'];
  media?: Maybe<Array<MediaFileInput>>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  occupancy?: Maybe<Scalars['Int']>;
  occupancyRange?: Maybe<OccupancyRange>;
  otherTypeDescription?: Maybe<Scalars['String']>;
  relatedEvents?: Maybe<Array<RelatedEventInputDtoInput>>;
  relatedImages: Array<RelatedImageRequestInput>;
  responsePlan?: Maybe<ResponsePlanInput>;
  schedule?: Maybe<TrafficDisruptionScheduleDtoInput>;
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
  type: SpecialEventType;
  venueAddress?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  roles: Array<Scalars['Int']>;
  status: Status;
  unitRelationType: UnitRelationType;
};

export enum CreationSource {
  Atms = 'ATMS',
  Cad = 'CAD',
  Cvid = 'CVID',
  Platform = 'PLATFORM',
  User = 'USER'
}

export enum CrossroadSource {
  NearEnd = 'NEAR_END',
  NearStart = 'NEAR_START',
  NearStartAndEnd = 'NEAR_START_AND_END',
  NoCrossroad = 'NO_CROSSROAD',
  SegmentName = 'SEGMENT_NAME'
}

export type Customer = {
  __typename?: 'Customer';
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  contactPhoneNumber: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Dms = {
  __typename?: 'DMS';
  address: Address;
  editable: Scalars['Boolean'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  location: Scalars['Point'];
  messages: Array<Array<Scalars['String']>>;
  status: Status;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DailyRange = {
  __typename?: 'DailyRange';
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type DailyRangeInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export enum DateFormat {
  DdMmmYyyy = 'DD_MMM_YYYY',
  MmmDdCommaYyyy = 'MMM_DD_COMMA_YYYY',
  MmmDdYyyy = 'MMM_DD_YYYY'
}

export type DateInterval = {
  __typename?: 'DateInterval';
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
};

export type DateIntervalInput = {
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
};


export type DateTimeField = {
  __typename?: 'DateTimeField';
  dateTime: Scalars['DateTime'];
};

export type DateTimePeriodInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DeleteAccountPublicationChannelInput = {
  publicationChannelId: Scalars['Int'];
};

export type DeleteIncidentMediaInput = {
  incidentId: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  removed: Array<Scalars['Int']>;
};

export type DeleteIncidentMitigationInput = {
  driverId: Scalars['Int'];
  id: Scalars['Int'];
};

export type DeleteIncidentUnitInput = {
  driverId?: Maybe<Scalars['Int']>;
  incidentId: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  unitId: Scalars['Int'];
};

export type DeleteIncidentsInput = {
  incidentIds: Array<Scalars['Int']>;
};

export type DeleteRoleInput = {
  roleId: Scalars['Int'];
};

export type DeleteRouteTypeInput = {
  routeTypeId: Scalars['Int'];
};

export type DeleteShiftTypeInput = {
  shiftTypeId: Scalars['Int'];
};

export type DeleteTrafficDisruptionMediaInput = {
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  removed: Array<Scalars['Int']>;
  trafficDisruptionId: Scalars['Int'];
};

export type DeleteTrafficDisruptionsInput = {
  trafficDisruptionIds: Array<Scalars['Int']>;
};

export type DeleteUserInput = {
  userId: Scalars['Int'];
};

export type DeleteUserUnitInput = {
  unitId: Scalars['Int'];
  userId: Scalars['Int'];
};

export enum DeletedEndReason {
  Cancelled = 'CANCELLED',
  Duplicate = 'DUPLICATE',
  Irrelevant = 'IRRELEVANT',
  Rescheduled = 'RESCHEDULED'
}

export type DetailedShiftDataInput = {
  endMileage?: Maybe<Scalars['Int']>;
  endReason: ShiftEndReasons;
  endReasonNote?: Maybe<Scalars['String']>;
  endedAt: Scalars['DateTime'];
  overtimeNote?: Maybe<Scalars['String']>;
  routeTypesIds?: Maybe<Array<Scalars['Int']>>;
  shiftTypeId?: Maybe<Scalars['Int']>;
  startMileage?: Maybe<Scalars['Int']>;
  startedAt: Scalars['DateTime'];
  unitId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

export enum DeviationSeverity {
  Critical = 'CRITICAL',
  Normal = 'NORMAL',
  Significant = 'SIGNIFICANT'
}

export enum Direction {
  Eb = 'EB',
  Nb = 'NB',
  Sb = 'SB',
  Wb = 'WB'
}

export type DmsDetails = {
  __typename?: 'DmsDetails';
  externalId: Scalars['String'];
  location: Scalars['Point'];
  title: Scalars['String'];
};

export type DmsField = {
  __typename?: 'DmsField';
  dmsId: Scalars['Int'];
  title: Scalars['String'];
};

export type DmsView = View & {
  __typename?: 'DmsView';
  address: Address;
  editable: Scalars['Boolean'];
  externalId: Scalars['String'];
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  lastUpdated: Scalars['String'];
  layerType: LayerType;
  location: Scalars['Geometry'];
  messages: Array<Array<Scalars['String']>>;
  status: Status;
  title: Scalars['String'];
  workspaces: Array<Scalars['Int']>;
};

export type EndIncidentInput = {
  endReason: IncidentEndReason;
  endReasonComment?: Maybe<Scalars['String']>;
  hazardRemains: Scalars['Boolean'];
  incidentId: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  targetIncidentId?: Maybe<Scalars['Int']>;
};

export type EndShiftInput = {
  endMileage?: Maybe<Scalars['Int']>;
  endReason: ShiftEndReasons;
  endReasonNote?: Maybe<Scalars['String']>;
  overtimeNote?: Maybe<Scalars['String']>;
  shiftId: Scalars['Int'];
};

export enum EntityType {
  Account = 'ACCOUNT',
  AccountIncidentTypes = 'ACCOUNT_INCIDENT_TYPES',
  Alias = 'ALIAS',
  Camera = 'CAMERA',
  Congestion = 'CONGESTION',
  Construction = 'CONSTRUCTION',
  CrashRisk = 'CRASH_RISK',
  Customer = 'CUSTOMER',
  Dms = 'DMS',
  DomoFspReport = 'DOMO_FSP_REPORT',
  DomoIncidentReport = 'DOMO_INCIDENT_REPORT',
  DomoTransitReport = 'DOMO_TRANSIT_REPORT',
  DownloadWidget = 'DOWNLOAD_WIDGET',
  HistoricalIncident = 'HISTORICAL_INCIDENT',
  HistoricalShift = 'HISTORICAL_SHIFT',
  HistoricalTrafficDisruption = 'HISTORICAL_TRAFFIC_DISRUPTION',
  Incident = 'INCIDENT',
  IncidentAccounts = 'INCIDENT_ACCOUNTS',
  IncidentWorkspaces = 'INCIDENT_WORKSPACES',
  Permission = 'PERMISSION',
  PersistenceScore = 'PERSISTENCE_SCORE',
  PersistenceScoreV2 = 'PERSISTENCE_SCORE_V2',
  PriorityScore = 'PRIORITY_SCORE',
  PublicationChannel = 'PUBLICATION_CHANNEL',
  ResponsePlan = 'RESPONSE_PLAN',
  ResponsePlanTemplates = 'RESPONSE_PLAN_TEMPLATES',
  RoadwayStatusMetric = 'ROADWAY_STATUS_METRIC',
  RoadClosure = 'ROAD_CLOSURE',
  Role = 'ROLE',
  RouteType = 'ROUTE_TYPE',
  ShareConstruction = 'SHARE_CONSTRUCTION',
  ShareIncident = 'SHARE_INCIDENT',
  ShareRoadClosure = 'SHARE_ROAD_CLOSURE',
  Shift = 'SHIFT',
  ShiftType = 'SHIFT_TYPE',
  SpecialEvent = 'SPECIAL_EVENT',
  TrafficImpact = 'TRAFFIC_IMPACT',
  Transit = 'TRANSIT',
  Unit = 'UNIT',
  User = 'USER',
  Weather = 'WEATHER',
  WrongWayAlert = 'WRONG_WAY_ALERT'
}

export type EventAffectingTransitAdditionalInfo = CongestionAffectingTransitAdditionalInfo | IncidentAffectingTransitAdditionalInfo | TrafficDisruptionAffectingTransitAdditionalInfo | WeatherAlertAffectingTransitAdditionalInfo;

export enum FeatureType {
  ConnectedVehiclesIncidentDetection = 'CONNECTED_VEHICLES_INCIDENT_DETECTION',
  CrashPrediction = 'CRASH_PREDICTION',
  FilterNearCameras = 'FILTER_NEAR_CAMERAS',
  IncidentDetection = 'INCIDENT_DETECTION',
  IrregularCongestion = 'IRREGULAR_CONGESTION'
}

export enum FeedPriorityType {
  PrimaryFeed = 'PRIMARY_FEED',
  SecondPriorityFeed = 'SECOND_PRIORITY_FEED',
  ThirdPriorityFeed = 'THIRD_PRIORITY_FEED'
}

export enum FeedSource {
  Accuweather = 'ACCUWEATHER',
  AdKnight = 'AD_KNIGHT',
  Airnow = 'AIRNOW',
  Arity = 'ARITY',
  AustinTexasGov = 'AUSTIN_TEXAS_GOV',
  Barons = 'BARONS',
  BlueToad = 'BLUE_TOAD',
  Blyncsy = 'BLYNCSY',
  Caltrans = 'CALTRANS',
  Chp = 'CHP',
  Climacell = 'CLIMACELL',
  Cmwg = 'CMWG',
  ColumbusOhio = 'COLUMBUS_OHIO',
  CombinedCv = 'COMBINED_CV',
  Cota = 'COTA',
  Cvid = 'CVID',
  DelawareCountyCad = 'DELAWARE_COUNTY_CAD',
  Divas = 'DIVAS',
  Eia = 'EIA',
  Fast = 'FAST',
  Fdot = 'FDOT',
  Fl511 = 'FL511',
  Flhsmv = 'FLHSMV',
  Ford = 'FORD',
  Geotab = 'GEOTAB',
  Gobus = 'GOBUS',
  Haas = 'HAAS',
  Hcso = 'HCSO',
  Here = 'HERE',
  HopOn = 'HOP_ON',
  IconeConstruction = 'ICONE_CONSTRUCTION',
  Idot = 'IDOT',
  ImpactService = 'IMPACT_SERVICE',
  Inrix = 'INRIX',
  Itic = 'ITIC',
  Ituran = 'ITURAN',
  Kaptch = 'KAPTCH',
  Louisiana_511 = 'LOUISIANA_511',
  LouisianaDot = 'LOUISIANA_DOT',
  LvConvention = 'LV_CONVENTION',
  MakewaySafety = 'MAKEWAY_SAFETY',
  MobilEye = 'MOBIL_EYE',
  Modot = 'MODOT',
  Mojio = 'MOJIO',
  Moovit = 'MOOVIT',
  Morpc = 'MORPC',
  Nasa = 'NASA',
  Ncdot = 'NCDOT',
  Ndex = 'NDEX',
  Nexar = 'NEXAR',
  Nhp = 'NHP',
  Noaa = 'NOAA',
  Odot = 'ODOT',
  Ohgo = 'OHGO',
  OhioDelawareCountyBusses = 'OHIO_DELAWARE_COUNTY_BUSSES',
  OhioHapcapBusses = 'OHIO_HAPCAP_BUSSES',
  OhioKnoxBusses = 'OHIO_KNOX_BUSSES',
  OhioLancasterBusses = 'OHIO_LANCASTER_BUSSES',
  OhioOsuCabs = 'OHIO_OSU_CABS',
  OhioPickawayBusses = 'OHIO_PICKAWAY_BUSSES',
  OhioStateUniversity = 'OHIO_STATE_UNIVERSITY',
  OhioUcatsBusses = 'OHIO_UCATS_BUSSES',
  OklahomaDot = 'OKLAHOMA_DOT',
  OregonDot = 'OREGON_DOT',
  Otonomo = 'OTONOMO',
  PinellasCounty = 'PINELLAS_COUNTY',
  Pointer = 'POINTER',
  PredictHq = 'PREDICT_HQ',
  Rekor = 'REKOR',
  SaferPlace = 'SAFER_PLACE',
  Saucon = 'SAUCON',
  Seeclickfix = 'SEECLICKFIX',
  Simulator = 'SIMULATOR',
  Spillman = 'SPILLMAN',
  StLouisCountyCad = 'ST_LOUIS_COUNTY_CAD',
  Swri = 'SWRI',
  TampaElectric = 'TAMPA_ELECTRIC',
  TampaGov = 'TAMPA_GOV',
  Ticketmaster = 'TICKETMASTER',
  Tims = 'TIMS',
  Tomtom = 'TOMTOM',
  Trafficvision = 'TRAFFICVISION',
  Trafikverket = 'TRAFIKVERKET',
  Transcore = 'TRANSCORE',
  Txdot = 'TXDOT',
  Udot = 'UDOT',
  UnionCountyCad = 'UNION_COUNTY_CAD',
  UnknownSource = 'UNKNOWN_SOURCE',
  Verizon = 'VERIZON',
  Volvo = 'VOLVO',
  Waze = 'WAZE',
  WazeTt = 'WAZE_TT',
  Wejo = 'WEJO'
}

export enum FeedbackType {
  IncidentInsightImpact = 'INCIDENT_INSIGHT_IMPACT',
  IncidentInsightPersistenceScore = 'INCIDENT_INSIGHT_PERSISTENCE_SCORE',
  IncidentInsightPersistenceScoreV2 = 'INCIDENT_INSIGHT_PERSISTENCE_SCORE_V2',
  IncidentPriorityScore = 'INCIDENT_PRIORITY_SCORE'
}

export type FilterIncidentsInput = {
  columns: Array<AnalyticsIncidentColumnName>;
  filters: AnalyticsIncidentFiltersListInput;
  includeTotalRecordsNumber: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Long'];
  sortColumn: AnalyticsIncidentSortColumn;
  sortOrder: SortOrder;
};

export type FilterSegmentAliasInput = {
  columns: Array<SegmentAliasLogColumnName>;
  filters: SegmentAliasLogFiltersListInput;
  includeTotalRecordsNumber: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Long'];
  sortColumn: SegmentAliasLogSortColumn;
  sortOrder: SortOrder;
};

export type FilterShiftsInput = {
  columns: Array<AnalyticsShiftColumnName>;
  filters: AnalyticsShiftFiltersListInput;
  includeTotalRecordsNumber: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Long'];
  sortColumn: AnalyticsShiftSortColumn;
  sortOrder: SortOrder;
};

export type FilterTrafficDisruptionInput = {
  columns: Array<AnalyticsTrafficDisruptionColumnName>;
  filters: AnalyticsTrafficDisruptionFiltersListInput;
  includeTotalRecordsNumber: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Long'];
  sortColumn: AnalyticsTrafficDisruptionSortColumn;
  sortOrder: SortOrder;
};

export type FilteredAliasResultPage = {
  __typename?: 'FilteredAliasResultPage';
  aliasColumnNames: Array<SegmentAliasLogColumnName>;
  rows: Array<FilteredSegmentAliasView>;
  totalNumberOfResults?: Maybe<Scalars['Int']>;
};

export type FilteredAnalyticsView = {
  __typename?: 'FilteredAnalyticsView';
  data: Array<AnalyticsColumnValue>;
};

export type FilteredSegmentAliasView = {
  __typename?: 'FilteredSegmentAliasView';
  data: Array<SegmentAliasColumnValue>;
};

export type FindBusStopViewsInput = {
  area: Scalars['Polygon'];
  excluded: Array<Scalars['Int']>;
};

export type FindIncidentsInPointRadiusInput = {
  location: Scalars['Point'];
  radiusMeters: Scalars['Float'];
};

export type FindStreetCamerasByAreaInput = {
  area: Scalars['Polygon'];
};

export type FindStreetCamerasByPointAndRadiusInput = {
  point: Scalars['Point'];
  radius: Scalars['Float'];
};

export type FixedBusRouteDetails = {
  __typename?: 'FixedBusRouteDetails';
  id: Scalars['Int'];
  isRerouted: Scalars['Boolean'];
  number: Scalars['String'];
  patterns: Array<FixedBusRoutePatternDetails>;
};

export type FixedBusRoutePatternDetails = {
  __typename?: 'FixedBusRoutePatternDetails';
  buses: Array<FixedRouteActiveBusDetails>;
  direction: TransitPatternDirection;
  id: Scalars['Int'];
  name: Scalars['String'];
  numberOfStops: Scalars['Int'];
  stops: Array<FixedRouteBusStopDetails>;
};

export type FixedBusRoutePatternView = {
  __typename?: 'FixedBusRoutePatternView';
  direction: TransitPatternDirection;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FixedBusRouteView = {
  __typename?: 'FixedBusRouteView';
  accountId?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  number: Scalars['String'];
  path?: Maybe<Scalars['MultiLineString']>;
  patterns: Array<FixedBusRoutePatternView>;
};

export type FixedRouteActiveBusDetails = {
  __typename?: 'FixedRouteActiveBusDetails';
  displayId: Scalars['String'];
  id: Scalars['Int'];
  status?: Maybe<FixedRouteBusStatus>;
  statusInMinutes?: Maybe<Scalars['Int']>;
};

export enum FixedRouteBusStatus {
  Early = 'EARLY',
  Late = 'LATE',
  OnTime = 'ON_TIME'
}

export type FixedRouteBusStopActiveBusDetails = {
  __typename?: 'FixedRouteBusStopActiveBusDetails';
  displayId: Scalars['String'];
  id: Scalars['Int'];
  nextEtaInMinutes: Scalars['Int'];
  patternId: Scalars['Int'];
  status: FixedRouteBusStatus;
  statusInMinutes: Scalars['Int'];
};

export type FixedRouteBusStopDetails = {
  __typename?: 'FixedRouteBusStopDetails';
  buses: Array<FixedRouteBusStopActiveBusDetails>;
  displayId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  nextEtaInMinutes?: Maybe<Scalars['Int']>;
};


export type GeometryField = {
  __typename?: 'GeometryField';
  geometry: Scalars['Geometry'];
};

export type GovDeliveryAccessDetails = PublicationAccessDetails & {
  __typename?: 'GovDeliveryAccessDetails';
  account: Scalars['String'];
  decode: PublicationAccessDetails;
  email: Scalars['String'];
  encode: PublicationAccessDetails;
  password: Scalars['String'];
  topics: Array<Scalars['String']>;
};

export type GovDeliveryAccessDetailsInput = {
  account: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  topics: Array<Scalars['String']>;
};

export type IdColumn = {
  __typename?: 'IdColumn';
  id: Scalars['Int'];
  segmentAliasLogColumnName: SegmentAliasLogColumnName;
};

export enum ImpactLevel {
  HighImpactTimeDelay = 'HIGH_IMPACT_TIME_DELAY',
  LowImpactTimeDelay = 'LOW_IMPACT_TIME_DELAY',
  MediumImpactTimeDelay = 'MEDIUM_IMPACT_TIME_DELAY',
  NegligibleImpactTimeDelay = 'NEGLIGIBLE_IMPACT_TIME_DELAY',
  NoImpact = 'NO_IMPACT'
}

export type Incident = {
  __typename?: 'Incident';
  additionalInfos: Array<AdditionalInfo>;
  address: Address;
  affectedLanes: Array<IncidentLane>;
  allLanesAffected: Scalars['Boolean'];
  associatedUnits: Array<IncidentUnit>;
  atmsId?: Maybe<Scalars['String']>;
  attributes: Array<Scalars['String']>;
  autoPublish: Scalars['Boolean'];
  cadId?: Maybe<Scalars['String']>;
  cameras: Array<AssociatedCamera>;
  camerasInternalDetails: Array<CameraInternal>;
  completedBy?: Maybe<Scalars['Int']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  confirmedBy?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  createdByUserName?: Maybe<Scalars['String']>;
  creationSource: CreationSource;
  endReason?: Maybe<IncidentEndReason>;
  endReasonComment?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  estimatedEndTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  fatalities?: Maybe<Scalars['Int']>;
  feedSource?: Maybe<FeedSource>;
  hazardRemains: Scalars['Boolean'];
  id: Scalars['Int'];
  injuries?: Maybe<Scalars['Int']>;
  injurySeverities: Array<InjurySeverity>;
  insights?: Maybe<IncidentInsights>;
  involvedVehicles: Array<IncidentInvolvedVehicle>;
  involvedVehiclesCount?: Maybe<Scalars['Int']>;
  isAddressEstimated: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  isRejected: Scalars['Boolean'];
  isUnconfirmed: Scalars['Boolean'];
  lanesStatus: LanesStatus;
  location: Scalars['Point'];
  media: Array<IncidentMedia>;
  mergeDetails?: Maybe<MergeDetails>;
  mitigations: Array<IncidentMitigation>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  nearCameras: Scalars['Boolean'];
  notes: Array<IncidentNote>;
  reportSources: Array<ReportSource>;
  responsePlan?: Maybe<ResponsePlan>;
  restoreAt?: Maybe<Scalars['DateTime']>;
  severity?: Maybe<IncidentSeverity>;
  startedAt: Scalars['DateTime'];
  status: IncidentStatus;
  subType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trafficDisturbance: Scalars['Boolean'];
  type: IncidentType;
  typeDescription?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['Int'];
  userDetails?: Maybe<IncidentUserNamesDetails>;
};

export enum IncidentAbandonedVehicleSubType {
  HpTagged = 'HP_TAGGED',
  Tagged = 'TAGGED'
}

export type IncidentAdditionalInfoDetails = {
  __typename?: 'IncidentAdditionalInfoDetails';
  info: Scalars['String'];
};

export type IncidentAdditionalInfoInput = {
  additionalInfoId: Scalars['Int'];
};

export type IncidentAffectingTransitAdditionalInfo = {
  __typename?: 'IncidentAffectingTransitAdditionalInfo';
  incidentSubtype?: Maybe<Scalars['String']>;
  incidentType: IncidentType;
  type: Scalars['String'];
};

export enum IncidentAttribute {
  AssistanceWaitingAttribute = 'ASSISTANCE_WAITING_ATTRIBUTE',
  CommercialVehicleInvolvedAttribute = 'COMMERCIAL_VEHICLE_INVOLVED_ATTRIBUTE',
  MotorcycleInvolvedAttribute = 'MOTORCYCLE_INVOLVED_ATTRIBUTE',
  OverturnedCarAttribute = 'OVERTURNED_CAR_ATTRIBUTE',
  PropertyDamageOnlyAttribute = 'PROPERTY_DAMAGE_ONLY_ATTRIBUTE',
  RefusedAssistanceAttribute = 'REFUSED_ASSISTANCE_ATTRIBUTE',
  TaggedAttribute = 'TAGGED_ATTRIBUTE',
  TowWaitingAttribute = 'TOW_WAITING_ATTRIBUTE',
  VehicleOnFireAttribute = 'VEHICLE_ON_FIRE_ATTRIBUTE'
}

export type IncidentAttributeStringValue = {
  __typename?: 'IncidentAttributeStringValue';
  checked: Scalars['Boolean'];
  value: Scalars['String'];
};

export type IncidentAttributeStringValueInput = {
  checked: Scalars['Boolean'];
  value: Scalars['String'];
};

export type IncidentAutoPublishField = {
  __typename?: 'IncidentAutoPublishField';
  autoPublish: Scalars['Boolean'];
};

export type IncidentCameraDetails = {
  __typename?: 'IncidentCameraDetails';
  default: Scalars['Boolean'];
  externalId: Scalars['String'];
  location: Scalars['Point'];
  title: Scalars['String'];
};

export type IncidentConfiguration = {
  __typename?: 'IncidentConfiguration';
  additionalInfoMandatory: Scalars['Boolean'];
  additionalInfoValues: Array<AdditionalInfo>;
  affectedLanesMandatory: Scalars['Boolean'];
  associatedUnitsMandatory: Scalars['Boolean'];
  attributeFixedValues: Array<IncidentAttribute>;
  attributeFreeTextValues: Array<IncidentAttributeStringValue>;
  attributesMandatory: Scalars['Boolean'];
  casualtiesMandatory: Scalars['Boolean'];
  directionMandatory: Scalars['Boolean'];
  incidentSubTypes: IncidentConfigurationSubTypes;
  incidentTypeValues: Array<IncidentType>;
  mitigationTypeValues: Array<MitigationType>;
  notesMandatory: Scalars['Boolean'];
  orientationMandatory: Scalars['Boolean'];
  orientationValues: Array<Orientation>;
  responsePlanMandatory: Scalars['Boolean'];
  roadTypeValues: Array<RoadType>;
  severityMandatory: Scalars['Boolean'];
  severityValues: Array<IncidentSeverity>;
  sourceMandatory: Scalars['Boolean'];
  sourceValues: Array<ReportSource>;
};

export type IncidentConfigurationSubTypes = {
  __typename?: 'IncidentConfigurationSubTypes';
  abandonedVehicleSubTypeValues: Array<IncidentAbandonedVehicleSubType>;
  crashSubTypeValues: Array<IncidentCrashSubType>;
  damageSubTypeValues: Array<IncidentDamageSubType>;
  debrisSubTypeValues: Array<IncidentDebrisSubType>;
  hazardSubTypeValues: Array<IncidentHazardSubType>;
  policeActivitySubTypeValues: Array<IncidentPoliceActivitySubType>;
  stalledVehicleSubTypeValues: Array<IncidentStalledVehicleSubType>;
  trafficStopSubTypeValues: Array<IncidentTrafficStopSubType>;
};

export type IncidentCoordinatesColumn = {
  __typename?: 'IncidentCoordinatesColumn';
  incidentCoordinates: Scalars['Point'];
  segmentAliasLogColumnName: SegmentAliasLogColumnName;
};

export enum IncidentCrashSubType {
  PrimarySubType = 'PRIMARY_SUB_TYPE',
  SecondaryCrash = 'SECONDARY_CRASH'
}

export enum IncidentDamageSubType {
  BarrierDamage = 'BARRIER_DAMAGE',
  BridgeDamage = 'BRIDGE_DAMAGE',
  DamagedLightPole = 'DAMAGED_LIGHT_POLE',
  DamagedSigns = 'DAMAGED_SIGNS',
  DownedPowerLines = 'DOWNED_POWER_LINES',
  Drain = 'DRAIN',
  DrainageOrManholeCover = 'DRAINAGE_OR_MANHOLE_COVER',
  EnergyAbsorbingBarrier = 'ENERGY_ABSORBING_BARRIER',
  EquipmentFailure = 'EQUIPMENT_FAILURE',
  Fencing = 'FENCING',
  GasLeak = 'GAS_LEAK',
  Graffiti = 'GRAFFITI',
  GuardCable = 'GUARD_CABLE',
  GuideRail = 'GUIDE_RAIL',
  InfrastructureDamage = 'INFRASTRUCTURE_DAMAGE',
  PotholeDamage = 'POTHOLE_DAMAGE',
  SignDown = 'SIGN_DOWN',
  StolenEquipment = 'STOLEN_EQUIPMENT',
  StreetLightNotWorking = 'STREET_LIGHT_NOT_WORKING',
  TrafficLightsNotWorking = 'TRAFFIC_LIGHTS_NOT_WORKING'
}

export enum IncidentDebrisSubType {
  CargoDispersal = 'CARGO_DISPERSAL',
  DeadAnimal = 'DEAD_ANIMAL',
  DebrisOnLane = 'DEBRIS_ON_LANE',
  ImpactingTraffic = 'IMPACTING_TRAFFIC',
  NotImpactingTraffic = 'NOT_IMPACTING_TRAFFIC',
  OilSpill = 'OIL_SPILL',
  Vegetation = 'VEGETATION'
}

export type IncidentDetailedAnalyticsLog = {
  __typename?: 'IncidentDetailedAnalyticsLog';
  additionalInfos: Array<IncidentAdditionalInfoDetails>;
  address: Address;
  affectedLanes: Array<IncidentLaneDetails>;
  allLanesAffected: Scalars['Boolean'];
  arrivalToClearedRoadDurationSeconds?: Maybe<Scalars['Long']>;
  associatedDmss: Array<DmsDetails>;
  associatedUnits: Array<IncidentUnitDetails>;
  atmsId?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Scalars['String']>>;
  cadId?: Maybe<Scalars['String']>;
  cameras: Array<IncidentCameraDetails>;
  clearedRoadToCompletedDurationSeconds?: Maybe<Scalars['Long']>;
  createdAt: Scalars['DateTime'];
  createdByUserName?: Maybe<Scalars['String']>;
  creationSource: CreationSource;
  discoverToArrivalDurationSeconds?: Maybe<Scalars['Long']>;
  durationSec?: Maybe<Scalars['Long']>;
  endReason?: Maybe<IncidentEndReason>;
  endReasonComment?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  estimatedEndTime?: Maybe<Scalars['DateTime']>;
  fatalities?: Maybe<Scalars['Int']>;
  firstUnitArrivedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  incidentSubType?: Maybe<Scalars['String']>;
  injuries?: Maybe<Scalars['Int']>;
  injurySeverities?: Maybe<Array<InjurySeverity>>;
  involvedVehicles: Array<IncidentInvolvedVehicle>;
  involvedVehiclesCount?: Maybe<Scalars['Int']>;
  location: Scalars['Point'];
  media: Array<IncidentMedia>;
  notes: Array<IncidentNoteDetails>;
  relatedEvents: Array<RelatedEventDto>;
  reportSources: Array<ReportSource>;
  severity?: Maybe<IncidentSeverity>;
  startedAt: Scalars['DateTime'];
  status: IncidentStatus;
  title?: Maybe<Scalars['String']>;
  type: IncidentType;
  typeDescription?: Maybe<Scalars['String']>;
};

export type IncidentDetails = {
  __typename?: 'IncidentDetails';
  incidentId: Scalars['Int'];
  unitResponse: UnitResponse;
};

export enum IncidentEndReason {
  CausedByMaintenanceWork = 'CAUSED_BY_MAINTENANCE_WORK',
  CompletedByFeed = 'COMPLETED_BY_FEED',
  DuplicateIncident = 'DUPLICATE_INCIDENT',
  Expired = 'EXPIRED',
  FalseIncident = 'FALSE_INCIDENT',
  IncidentResolved = 'INCIDENT_RESOLVED',
  LocationNotRelevant = 'LOCATION_NOT_RELEVANT',
  NotFound = 'NOT_FOUND',
  NotRequested = 'NOT_REQUESTED',
  NoVisualConfirmation = 'NO_VISUAL_CONFIRMATION',
  Other = 'OTHER',
  ResourceConstraints = 'RESOURCE_CONSTRAINTS',
  TrafficNotAffected = 'TRAFFIC_NOT_AFFECTED',
  TypeNotRelevant = 'TYPE_NOT_RELEVANT',
  UnavailableCamera = 'UNAVAILABLE_CAMERA'
}

export type IncidentError = {
  __typename?: 'IncidentError';
  errMsg?: Maybe<Scalars['String']>;
  errorCode: Scalars['Int'];
  incidentId: Scalars['Int'];
};

export enum IncidentHazardSubType {
  AnimalOnRoad = 'ANIMAL_ON_ROAD',
  CyclistOnRoad = 'CYCLIST_ON_ROAD',
  Fire = 'FIRE',
  FloodingOnRoad = 'FLOODING_ON_ROAD',
  HazardOnRoad = 'HAZARD_ON_ROAD',
  Hazmat = 'HAZMAT',
  OverweightLoad = 'OVERWEIGHT_LOAD',
  PedestrianOnRoad = 'PEDESTRIAN_ON_ROAD',
  Suicide = 'SUICIDE',
  TreeFall = 'TREE_FALL',
  WaterLeak = 'WATER_LEAK'
}

export type IncidentImpact = {
  __typename?: 'IncidentImpact';
  corridors: Array<IncidentImpactedCorridor>;
  externalId?: Maybe<Scalars['String']>;
  incidentId: Scalars['Int'];
  level: ImpactLevel;
  updatedAt: Scalars['DateTime'];
  usersFeedback?: Maybe<Scalars['Boolean']>;
};

export type IncidentImpactedCorridor = {
  __typename?: 'IncidentImpactedCorridor';
  currentSpd: Scalars['Int'];
  expectedSpd: Scalars['Int'];
  impactedSegments: Array<IncidentImpactedSegment>;
  name?: Maybe<Scalars['String']>;
};

export type IncidentImpactedSegment = {
  __typename?: 'IncidentImpactedSegment';
  currentSpd: Scalars['Int'];
  expectedSpd: Scalars['Int'];
  forecastDecrease: Scalars['Int'];
  level: SegmentImpactLevel;
  linestring: Scalars['LineString'];
  segmentId: Scalars['String'];
  segmentName?: Maybe<Scalars['String']>;
};

export enum IncidentIndicationType {
  FullyMitigated = 'FULLY_MITIGATED',
  ReopenedLanes = 'REOPENED_LANES',
  ResurrectedIncident = 'RESURRECTED_INCIDENT',
  StaleIncident = 'STALE_INCIDENT',
  UnconfirmedOverTime = 'UNCONFIRMED_OVER_TIME'
}

export type IncidentInput = {
  additionalInfoInput?: Maybe<Array<IncidentAdditionalInfoInput>>;
  cameras?: Maybe<Array<AssociatedCameraInput>>;
  incidentId: Scalars['Int'];
  involvedVehicles?: Maybe<Array<IncidentInvolvedVehicleInput>>;
  lanes?: Maybe<Array<IncidentLaneInput>>;
  media?: Maybe<Array<MediaInput>>;
  mitigations?: Maybe<Array<IncidentMitigationInput>>;
  notes?: Maybe<Array<IncidentNoteInput>>;
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  relatedEvents?: Maybe<Array<RelatedEventInputDtoInput>>;
  reportSources?: Maybe<Array<IncidentReportSourceInput>>;
  responsePlan?: Maybe<ResponsePlanInput>;
  units?: Maybe<Array<IncidentUnitInput>>;
  updateIncidentInput: UpdateIncidentInput;
};

export type IncidentInsights = {
  __typename?: 'IncidentInsights';
  impact?: Maybe<IncidentImpact>;
  persistenceType?: Maybe<PersistenceScoreType>;
  priorityScore?: Maybe<IncidentPriorityScore>;
};

export type IncidentInvolvedVehicle = {
  __typename?: 'IncidentInvolvedVehicle';
  color?: Maybe<Color>;
  id: Scalars['Int'];
  licensePlateNumber?: Maybe<Scalars['String']>;
  make?: Maybe<VehicleBrand>;
  model?: Maybe<Scalars['String']>;
  state?: Maybe<State>;
  type?: Maybe<VehicleCategory>;
};

export type IncidentInvolvedVehicleInput = {
  id?: Maybe<Scalars['Int']>;
  involvedVehicle: InvolvedVehicleInput;
};

export type IncidentLane = {
  __typename?: 'IncidentLane';
  direction?: Maybe<Direction>;
  id: Scalars['Int'];
  isAffected: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  number?: Maybe<Scalars['Int']>;
  positionIndex: Scalars['Int'];
  roadType: RoadType;
  type: LaneType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IncidentLaneDetails = {
  __typename?: 'IncidentLaneDetails';
  closureTimeSec: Scalars['Long'];
  closureUpdates: Array<LaneClosureUpdateDetails>;
  incidentLane: IncidentLane;
};

export type IncidentLaneField = {
  __typename?: 'IncidentLaneField';
  incidentLane: IncidentLane;
};

export type IncidentLaneInput = {
  id?: Maybe<Scalars['Int']>;
  lane: LaneInput;
};

export type IncidentMedia = {
  __typename?: 'IncidentMedia';
  id: Scalars['Int'];
  media: MediaFile;
};

export type IncidentMediaIdField = {
  __typename?: 'IncidentMediaIdField';
  incidentMediaId: Scalars['Int'];
};

export type IncidentMediaInput = {
  id: Scalars['Int'];
  media: MediaFileInput;
};

export type IncidentMitigation = {
  __typename?: 'IncidentMitigation';
  id: Scalars['Int'];
  interval: DateInterval;
  mitigationType: MitigationType;
  unitDisplayId: Scalars['String'];
  unitId: Scalars['Int'];
  unitType: VehicleType;
  userId: Scalars['Int'];
};

export type IncidentMitigationCreateInput = {
  incidentId: Scalars['Int'];
  mitigations: Array<CreateIncidentMitigationInput>;
};

export type IncidentMitigationInput = {
  driverId: Scalars['Int'];
  id?: Maybe<Scalars['Int']>;
  interval: DateIntervalInput;
  mitigationTypeId: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type IncidentNote = {
  __typename?: 'IncidentNote';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  note: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['Int'];
  updatedByUsername: Scalars['String'];
};

export type IncidentNoteDetails = {
  __typename?: 'IncidentNoteDetails';
  addTime: Scalars['DateTime'];
  text: Scalars['String'];
};

export type IncidentNoteInput = {
  id?: Maybe<Scalars['Int']>;
  note: Scalars['String'];
};

export enum IncidentPoliceActivitySubType {
  BombThreat = 'BOMB_THREAT',
  DomesticViolence = 'DOMESTIC_VIOLENCE',
  Explosion = 'EXPLOSION',
  Felony = 'FELONY',
  HomelandSecurityIncident = 'HOMELAND_SECURITY_INCIDENT',
  Protests = 'PROTESTS',
  RoadBlock = 'ROAD_BLOCK',
  StreetViolence = 'STREET_VIOLENCE',
  SuspiciousPerson = 'SUSPICIOUS_PERSON'
}

export type IncidentPriorityScore = {
  __typename?: 'IncidentPriorityScore';
  heavyInfluencedBy: Array<PriorityScoreParameter>;
  level: PriorityScoreLevel;
};

export enum IncidentPublicationMessageTemplateVariables {
  AffectedLanes = 'AFFECTED_LANES',
  Attributes = 'ATTRIBUTES',
  Corridor = 'CORRIDOR',
  Crossroad = 'CROSSROAD',
  Direction = 'DIRECTION',
  MileMarker = 'MILE_MARKER',
  Orientation = 'ORIENTATION',
  PublishTime = 'PUBLISH_TIME',
  RoadType = 'ROAD_TYPE',
  Severity = 'SEVERITY',
  StartTime = 'START_TIME',
  Subtype = 'SUBTYPE',
  Type = 'TYPE'
}

export type IncidentRelatedEventDto = RelatedEventDto & {
  __typename?: 'IncidentRelatedEventDTO';
  entityType: RelatedEventEntityTypeDto;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  incidentAddress: Address;
  incidentStatus: IncidentStatus;
  incidentTitle?: Maybe<Scalars['String']>;
  incidentType: IncidentType;
  subType?: Maybe<Scalars['String']>;
};

export type IncidentReportSourceInput = {
  reportSourceId: Scalars['Int'];
};

export enum IncidentSeverity {
  CriticalSeverity = 'CRITICAL_SEVERITY',
  IntermediateSeverity = 'INTERMEDIATE_SEVERITY',
  MajorSeverity = 'MAJOR_SEVERITY',
  MinorSeverity = 'MINOR_SEVERITY'
}

export type IncidentSeverityField = {
  __typename?: 'IncidentSeverityField';
  incidentSeverity?: Maybe<IncidentSeverity>;
};

export enum IncidentStalledVehicleSubType {
  AssistanceWaiting = 'ASSISTANCE_WAITING',
  CargoLoading = 'CARGO_LOADING',
  Electrical = 'ELECTRICAL',
  FlatTire = 'FLAT_TIRE',
  LostMotorist = 'LOST_MOTORIST',
  MechanicalIssue = 'MECHANICAL_ISSUE',
  MotoristRefusedAssistance = 'MOTORIST_REFUSED_ASSISTANCE',
  MotoristWaitingForOther = 'MOTORIST_WAITING_FOR_OTHER',
  OnPhone = 'ON_PHONE',
  OutOfGas = 'OUT_OF_GAS',
  Overheated = 'OVERHEATED',
  RandomStop = 'RANDOM_STOP',
  SickMotorist = 'SICK_MOTORIST',
  TowWaiting = 'TOW_WAITING',
  WellnessCheck = 'WELLNESS_CHECK'
}

export enum IncidentStatus {
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
  Unconfirmed = 'UNCONFIRMED'
}

export type IncidentStatusField = {
  __typename?: 'IncidentStatusField';
  automaticAction: Scalars['Boolean'];
  endReason?: Maybe<IncidentEndReason>;
  endReasonComment?: Maybe<Scalars['String']>;
  status: IncidentStatus;
};

export type IncidentSubTypeField = {
  __typename?: 'IncidentSubTypeField';
  subType?: Maybe<Scalars['String']>;
};

export enum IncidentTrafficStopSubType {
  MotoristAssist = 'MOTORIST_ASSIST',
  TrafficControl = 'TRAFFIC_CONTROL'
}

export enum IncidentType {
  AbandonedVehicle = 'ABANDONED_VEHICLE',
  Crash = 'CRASH',
  Damage = 'DAMAGE',
  Debris = 'DEBRIS',
  Ems = 'EMS',
  Hazard = 'HAZARD',
  LeftOnArrival = 'LEFT_ON_ARRIVAL',
  Other = 'OTHER',
  PoliceActivity = 'POLICE_ACTIVITY',
  StalledVehicle = 'STALLED_VEHICLE',
  TrafficAnomaly = 'TRAFFIC_ANOMALY',
  TrafficStop = 'TRAFFIC_STOP',
  Unidentified = 'UNIDENTIFIED',
  UnknownIncidentType = 'UNKNOWN_INCIDENT_TYPE',
  VehicleOnFire = 'VEHICLE_ON_FIRE',
  WrongWay = 'WRONG_WAY'
}

export type IncidentTypeField = {
  __typename?: 'IncidentTypeField';
  incidentType: IncidentType;
};

export type IncidentTypeWithSubTypesInput = {
  incidentType: IncidentType;
  subTypes: Array<Scalars['String']>;
};

export type IncidentUnit = {
  __typename?: 'IncidentUnit';
  accountId: Scalars['Int'];
  dashCameras: Array<UnitDashCamera>;
  displayId: Scalars['String'];
  driverDetails?: Maybe<UserDetails>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  status: Status;
  type: VehicleType;
  unitResponse: UnitResponse;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IncidentUnitDetails = {
  __typename?: 'IncidentUnitDetails';
  displayId: Scalars['String'];
  driverDetails?: Maybe<IncidentUnitDriverDetails>;
  id: Scalars['Int'];
  mitigations: Array<IncidentUnitMitigationDetails>;
  responses: Array<IncidentUnitResponseUpdateDetails>;
  type: VehicleType;
};

export type IncidentUnitDriverDetails = {
  __typename?: 'IncidentUnitDriverDetails';
  accountName: Scalars['String'];
  routeNames: Array<Scalars['String']>;
  userId: Scalars['Int'];
  userName: Scalars['String'];
};

export type IncidentUnitInput = {
  driverId?: Maybe<Scalars['Int']>;
  response: UnitResponse;
  unitId: Scalars['Int'];
};

export type IncidentUnitMitigationDetails = {
  __typename?: 'IncidentUnitMitigationDetails';
  interval: DateInterval;
  mitigationDescription: Scalars['String'];
  mitigationId: Scalars['Int'];
  mitigationTypeId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type IncidentUnitResponseUpdateDetails = {
  __typename?: 'IncidentUnitResponseUpdateDetails';
  responseTime: Scalars['DateTime'];
  unitResponse: UnitResponse;
};

export type IncidentUserNamesDetails = {
  __typename?: 'IncidentUserNamesDetails';
  completedBy?: Maybe<Scalars['String']>;
  confirmedBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type IncidentView = View & {
  __typename?: 'IncidentView';
  address: Address;
  affectedLanes: Array<IncidentLane>;
  allLanesAffected: Scalars['Boolean'];
  appended: Scalars['Boolean'];
  associatedUnits: Array<IncidentUnit>;
  atmsId?: Maybe<Scalars['String']>;
  cadId?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['String']>;
  confirmedBy?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['Int'];
  createdByUserName?: Maybe<Scalars['String']>;
  creationSource: CreationSource;
  externalId?: Maybe<Scalars['String']>;
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  indications: Array<IncidentIndicationType>;
  isFullClosure: Scalars['Boolean'];
  isUnconfirmed: Scalars['Boolean'];
  lanesStatus: LanesStatus;
  layerType: LayerType;
  location: Scalars['Geometry'];
  mergeDetails?: Maybe<MergeDetails>;
  mitigatedAccounts: Array<Scalars['Int']>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  nearCameras: Scalars['Boolean'];
  priorityScore?: Maybe<PriorityScoreView>;
  roadClosure?: Maybe<Scalars['String']>;
  severity?: Maybe<IncidentSeverity>;
  startedAt: Scalars['String'];
  status: IncidentStatus;
  subType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: IncidentType;
  typeDescription?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['Int'];
  withinWorkingHours: Scalars['Boolean'];
  workspaces: Array<Scalars['Int']>;
};

export type InjurySeveritiesField = {
  __typename?: 'InjurySeveritiesField';
  injurySeverity: InjurySeverity;
};

export enum InjurySeverity {
  FatalInjurySeverity = 'FATAL_INJURY_SEVERITY',
  IntermediateInjurySeverity = 'INTERMEDIATE_INJURY_SEVERITY',
  MajorInjurySeverity = 'MAJOR_INJURY_SEVERITY',
  MinorInjurySeverity = 'MINOR_INJURY_SEVERITY',
  UndeterminedInjurySeverity = 'UNDETERMINED_INJURY_SEVERITY'
}

export type IntField = {
  __typename?: 'IntField';
  n: Scalars['Int'];
};

export type InvolvedVehicleInput = {
  color?: Maybe<Color>;
  licensePlateNumber?: Maybe<Scalars['String']>;
  make?: Maybe<VehicleBrand>;
  model?: Maybe<Scalars['String']>;
  state?: Maybe<State>;
  type?: Maybe<VehicleCategory>;
};

export type LaneClosureUpdateDetails = {
  __typename?: 'LaneClosureUpdateDetails';
  isClosed: Scalars['Boolean'];
  updateTime: Scalars['DateTime'];
};

export type LaneInput = {
  direction?: Maybe<Direction>;
  isAffected: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  number?: Maybe<Scalars['Int']>;
  positionIndex: Scalars['Int'];
  roadType: RoadType;
  type: LaneType;
};

export enum LaneType {
  CentralLane = 'CENTRAL_LANE',
  CommercialLane = 'COMMERCIAL_LANE',
  Express = 'EXPRESS',
  Gore = 'GORE',
  Hov = 'HOV',
  LeftLane = 'LEFT_LANE',
  LeftShoulder = 'LEFT_SHOULDER',
  OtherLane = 'OTHER_LANE',
  RightLane = 'RIGHT_LANE',
  RightShoulder = 'RIGHT_SHOULDER',
  TurnLeft = 'TURN_LEFT',
  TurnRight = 'TURN_RIGHT'
}

export enum LanesClosureType {
  Full = 'FULL',
  Partial = 'PARTIAL',
  Unknown = 'UNKNOWN'
}

export type LanesClosureTypeField = {
  __typename?: 'LanesClosureTypeField';
  lanesClosureType: LanesClosureType;
};

export enum LanesStatus {
  Close = 'CLOSE',
  Open = 'OPEN',
  ReOpened = 'RE_OPENED',
  Unknown = 'UNKNOWN'
}

export enum Language {
  En = 'EN',
  He = 'HE'
}

export enum LayerType {
  Camera = 'CAMERA',
  Congestion = 'CONGESTION',
  Construction = 'CONSTRUCTION',
  CrashRiskArea = 'CRASH_RISK_AREA',
  Dms = 'DMS',
  Incident = 'INCIDENT',
  IrregularCongestion = 'IRREGULAR_CONGESTION',
  RoadwayStatus = 'ROADWAY_STATUS',
  RoadClosure = 'ROAD_CLOSURE',
  SpecialEvent = 'SPECIAL_EVENT',
  Unit = 'UNIT',
  WeatherAlert = 'WEATHER_ALERT'
}


export type LocationField = {
  __typename?: 'LocationField';
  point: Scalars['Point'];
};

export type LocationInput = {
  geo: Scalars['Geometry'];
};


export enum MeasureUnitType {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC'
}

export type MediaFile = {
  __typename?: 'MediaFile';
  expiration: Scalars['DateTime'];
  fileSize: Scalars['Int'];
  isDocument: Scalars['Boolean'];
  key: Scalars['String'];
  source: MediaSource;
  url: Scalars['String'];
};

export type MediaFileInput = {
  expiration: Scalars['DateTime'];
  fileSize: Scalars['Int'];
  isDocument: Scalars['Boolean'];
  key: Scalars['String'];
  source: MediaSource;
  url: Scalars['String'];
};

export type MediaInput = {
  id?: Maybe<Scalars['Int']>;
};

export enum MediaSource {
  ExternalFeed = 'EXTERNAL_FEED',
  PlatformSnapshot = 'PLATFORM_SNAPSHOT',
  Upload = 'UPLOAD',
  UserSnapshot = 'USER_SNAPSHOT'
}

export enum MediaType {
  CrashRisk = 'CRASH_RISK',
  Incident = 'INCIDENT',
  PublicationMedia = 'PUBLICATION_MEDIA',
  TrafficDisruption = 'TRAFFIC_DISRUPTION'
}

export type MergeDetails = {
  __typename?: 'MergeDetails';
  isBlocked: Scalars['Boolean'];
  targetId: Scalars['Int'];
};

export type MetricVendorData = {
  __typename?: 'MetricVendorData';
  avgSpeedTotal?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['String']>;
  isDataExist: Scalars['Boolean'];
  isDefaultVendor: Scalars['Boolean'];
  isUpdated?: Maybe<Scalars['Boolean']>;
  maxSpeedDeviationPercent?: Maybe<Scalars['Float']>;
  maxSpeedDeviationSeverity?: Maybe<DeviationSeverity>;
  metricId: Scalars['Int'];
  refreshRateMinutes: Scalars['Int'];
  startedAt?: Maybe<Scalars['String']>;
  transportCountTotal?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  validRefreshRateIntervalMinutes: Scalars['Int'];
  vendorId: Scalars['Int'];
  vendorName: FeedSource;
};

export type MitigationField = {
  __typename?: 'MitigationField';
  description: Scalars['String'];
  interval?: Maybe<DateInterval>;
  unitDisplayId?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['Int']>;
};

export type MitigationInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
};

export type MitigationType = {
  __typename?: 'MitigationType';
  accountId: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
};



export type Mutation = {
  __typename?: 'Mutation';
  addHistoricMitigationsToIncident: Array<IncidentError>;
  assignUnitToUser: User;
  completeConstruction: Scalars['Boolean'];
  completeIncident: Scalars['Boolean'];
  completeRoadClosure: Scalars['Boolean'];
  completeSpecialEvent: Scalars['Boolean'];
  confirmIncident: Scalars['Boolean'];
  createAdditionalInfo: AdditionalInfo;
  createConstruction: Construction;
  createDetailedShift: Scalars['Int'];
  createIncident: Incident;
  createMitigationType: MitigationType;
  createReportSource: ReportSource;
  createResponsePlanTemplate: ResponsePlanTemplate;
  createRoadClosure: RoadClosure;
  createRole: Role;
  createRouteType: RouteType;
  createShift: ShiftDto;
  createShiftType: ShiftType;
  createSpecialEvent: SpecialEvent;
  createUser: User;
  deleteAccountPublishDetails: Scalars['Boolean'];
  deleteAdditionalInfo: Scalars['Boolean'];
  deleteConstructionsNonProd: Scalars['Int'];
  deleteIncidentMedia: Scalars['Int'];
  deleteIncidentsNonProd: Scalars['Int'];
  deleteMitigationType: Scalars['Boolean'];
  deleteReportSource: Scalars['Boolean'];
  deleteResponsePlanTemplate: Scalars['Boolean'];
  deleteRoadClosuresNonProd: Scalars['Int'];
  deleteRole: Scalars['Boolean'];
  deleteRouteType: RouteType;
  deleteShift: Scalars['Boolean'];
  deleteShiftType: ShiftType;
  deleteSpecialEventsNonProd: Scalars['Int'];
  deleteTrafficDisruptionMedia: Scalars['Int'];
  deleteUser: Scalars['Boolean'];
  disassociateUnitFromIncident: Scalars['Boolean'];
  endShift: ShiftDto;
  endShiftPause: ShiftPauseDto;
  logMediaAccess: Scalars['Boolean'];
  logMediaAccessed: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  publishConstruction: Scalars['Boolean'];
  publishIncident: Scalars['Boolean'];
  publishRoadClosure: Scalars['Boolean'];
  publishSpecialEvent: Scalars['Boolean'];
  rejectIncident: Scalars['Boolean'];
  renewIncidentMediaUrl: Scalars['String'];
  renewTrafficDisruptionMediaUrl: Scalars['String'];
  rerouteFixedBusRoute: Scalars['Boolean'];
  setFeedback: Scalars['Boolean'];
  setRolePermissions: Role;
  setUserRoles: User;
  startShiftPause: ShiftPauseDto;
  unassignUnitToUser: User;
  /** @deprecated method is not in use anymore */
  unconfirmIncident: Scalars['Boolean'];
  undoComplete: Scalars['Boolean'];
  undoReject: Scalars['Boolean'];
  updateAccountPermits: Array<AccountPermit>;
  updateActionStatus: Scalars['Boolean'];
  updateAdditionalInfo: AdditionalInfo;
  updateConstruction: Construction;
  updateDetailedShift: ShiftDto;
  updateGovDeliveryPublishDetails: PublicationChannel;
  updateIncidentConfiguration: IncidentConfiguration;
  updateIncidentMitigations: Array<IncidentMitigation>;
  updateIncidentOneTime: Incident;
  updateIncidentTypePriority: Array<IncidentType>;
  updateIncidentUnitResponse: IncidentUnit;
  updateMitigationType: MitigationType;
  updatePublicationMessageTemplate: PublicationTemplate;
  updateReportSource: ReportSource;
  updateResponsePlanTemplate: ResponsePlanTemplate;
  updateRoadClosure: RoadClosure;
  updateRole: Role;
  updateRouteType: RouteType;
  updateShift: ShiftDto;
  updateShiftType: ShiftType;
  updateSpecialEvent: SpecialEvent;
  updateTrafficDisruptionLanes: Array<TrafficDisruptionLane>;
  updateTwitterPublicationDetails: PublicationChannel;
  updateUnitLocation: Scalars['Boolean'];
  updateUser: User;
  updateWorkingHoursConfiguration: WorkingHoursDto;
};


export type MutationAddHistoricMitigationsToIncidentArgs = {
  input: CreateShiftIncidentMitigationInput;
};


export type MutationAssignUnitToUserArgs = {
  input: SetUserUnitInput;
};


export type MutationCompleteConstructionArgs = {
  id: Scalars['Int'];
};


export type MutationCompleteIncidentArgs = {
  input: EndIncidentInput;
};


export type MutationCompleteRoadClosureArgs = {
  id: Scalars['Int'];
};


export type MutationCompleteSpecialEventArgs = {
  id: Scalars['Int'];
};


export type MutationConfirmIncidentArgs = {
  id: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
};


export type MutationCreateAdditionalInfoArgs = {
  input: CreateAdditionalInfoInput;
};


export type MutationCreateConstructionArgs = {
  input: CreateConstructionInput;
};


export type MutationCreateDetailedShiftArgs = {
  input: CreateDetailedShiftInput;
};


export type MutationCreateIncidentArgs = {
  input: CreateIncidentMutationInput;
};


export type MutationCreateMitigationTypeArgs = {
  input: CreateMitigationTypeInput;
};


export type MutationCreateReportSourceArgs = {
  input: CreateReportSourceInput;
};


export type MutationCreateResponsePlanTemplateArgs = {
  input: CreateResponsePlanTemplateInput;
};


export type MutationCreateRoadClosureArgs = {
  input: CreateRoadClosureInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateRouteTypeArgs = {
  input: CreateRouteTypeInput;
};


export type MutationCreateShiftArgs = {
  input: CreateShiftInput;
};


export type MutationCreateShiftTypeArgs = {
  input: CreateShiftTypeInput;
};


export type MutationCreateSpecialEventArgs = {
  input: CreateSpecialEventInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteAccountPublishDetailsArgs = {
  input: DeleteAccountPublicationChannelInput;
};


export type MutationDeleteAdditionalInfoArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteConstructionsNonProdArgs = {
  input: DeleteTrafficDisruptionsInput;
};


export type MutationDeleteIncidentMediaArgs = {
  input: DeleteIncidentMediaInput;
};


export type MutationDeleteIncidentsNonProdArgs = {
  input: DeleteIncidentsInput;
};


export type MutationDeleteMitigationTypeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteReportSourceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteResponsePlanTemplateArgs = {
  templateId: Scalars['Int'];
};


export type MutationDeleteRoadClosuresNonProdArgs = {
  input: DeleteTrafficDisruptionsInput;
};


export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};


export type MutationDeleteRouteTypeArgs = {
  input: DeleteRouteTypeInput;
};


export type MutationDeleteShiftArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteShiftTypeArgs = {
  input: DeleteShiftTypeInput;
};


export type MutationDeleteSpecialEventsNonProdArgs = {
  input: DeleteTrafficDisruptionsInput;
};


export type MutationDeleteTrafficDisruptionMediaArgs = {
  input: DeleteTrafficDisruptionMediaInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDisassociateUnitFromIncidentArgs = {
  input: DeleteIncidentUnitInput;
};


export type MutationEndShiftArgs = {
  input: EndShiftInput;
};


export type MutationEndShiftPauseArgs = {
  id: Scalars['Int'];
};


export type MutationLogMediaAccessArgs = {
  input: CreateMediaAccessLogInput;
};


export type MutationLogMediaAccessedArgs = {
  input: CreateMediaAccessedLogInput;
};


export type MutationPublishConstructionArgs = {
  input: PublishInput;
};


export type MutationPublishIncidentArgs = {
  input: PublishInput;
};


export type MutationPublishRoadClosureArgs = {
  input: PublishInput;
};


export type MutationPublishSpecialEventArgs = {
  input: PublishInput;
};


export type MutationRejectIncidentArgs = {
  input: EndIncidentInput;
};


export type MutationRenewIncidentMediaUrlArgs = {
  id: Scalars['Int'];
};


export type MutationRenewTrafficDisruptionMediaUrlArgs = {
  id: Scalars['Int'];
};


export type MutationRerouteFixedBusRouteArgs = {
  id: Scalars['Int'];
  reroute: Scalars['Boolean'];
};


export type MutationSetFeedbackArgs = {
  userFeedBackInput: UserFeedBackInput;
};


export type MutationSetRolePermissionsArgs = {
  input: SetRolePermissionsInput;
};


export type MutationSetUserRolesArgs = {
  input: SetUserRolesInput;
};


export type MutationStartShiftPauseArgs = {
  input: CreateShiftPauseInput;
};


export type MutationUnassignUnitToUserArgs = {
  input: DeleteUserUnitInput;
};


export type MutationUnconfirmIncidentArgs = {
  id: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
};


export type MutationUndoCompleteArgs = {
  id: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
};


export type MutationUndoRejectArgs = {
  id: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
};


export type MutationUpdateAccountPermitsArgs = {
  input: Array<AccountPermitInput>;
};


export type MutationUpdateActionStatusArgs = {
  actionId: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationUpdateAdditionalInfoArgs = {
  input: UpdateAdditionalInfoInput;
};


export type MutationUpdateConstructionArgs = {
  input: UpdateConstructionInput;
};


export type MutationUpdateDetailedShiftArgs = {
  input: UpdateDetailedShiftInput;
};


export type MutationUpdateGovDeliveryPublishDetailsArgs = {
  input: UpdateGovDeliveryPublicationChannelInput;
};


export type MutationUpdateIncidentConfigurationArgs = {
  input: UpdateIncidentConfigurationInput;
};


export type MutationUpdateIncidentMitigationsArgs = {
  input: UpdateIncidentMitigationsInput;
};


export type MutationUpdateIncidentOneTimeArgs = {
  input: IncidentInput;
};


export type MutationUpdateIncidentTypePriorityArgs = {
  input: Array<IncidentType>;
};


export type MutationUpdateIncidentUnitResponseArgs = {
  input: UpdateIncidentUnitInput;
};


export type MutationUpdateMitigationTypeArgs = {
  input: UpdateMitigationTypeInput;
};


export type MutationUpdatePublicationMessageTemplateArgs = {
  input: UpdatePublicationTemplateInput;
};


export type MutationUpdateReportSourceArgs = {
  input: UpdateReportSourceInput;
};


export type MutationUpdateResponsePlanTemplateArgs = {
  input: UpdateResponsePlanTemplateInput;
};


export type MutationUpdateRoadClosureArgs = {
  input: UpdateRoadClosureInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateRouteTypeArgs = {
  input: UpdateRouteTypeInput;
};


export type MutationUpdateShiftArgs = {
  input: UpdateShiftInput;
};


export type MutationUpdateShiftTypeArgs = {
  input: UpdateShiftTypeInput;
};


export type MutationUpdateSpecialEventArgs = {
  input: UpdateSpecialEventInput;
};


export type MutationUpdateTrafficDisruptionLanesArgs = {
  input: UpdateTrafficDisruptionLanesInput;
};


export type MutationUpdateTwitterPublicationDetailsArgs = {
  input: UpdateTwitterPublicationChannelInput;
};


export type MutationUpdateUnitLocationArgs = {
  input: UpdateUnitLocationInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWorkingHoursConfigurationArgs = {
  input: UpdateWorkingHoursInput;
};

export type NoteField = {
  __typename?: 'NoteField';
  note: Scalars['String'];
};

export enum NotificationDestination {
  GovDelivery = 'GOV_DELIVERY',
  Twitter = 'TWITTER',
  Waze = 'WAZE'
}

export type NullableTextField = {
  __typename?: 'NullableTextField';
  nullableText?: Maybe<Scalars['String']>;
};

export enum OccupancyRange {
  Above_40K = 'ABOVE_40K',
  Between_10KAnd_20K = 'BETWEEN_10K_AND_20K',
  Between_20KAnd_40K = 'BETWEEN_20K_AND_40K',
  Between_5KAnd_10K = 'BETWEEN_5K_AND_10K',
  Under_5K = 'UNDER_5K'
}

export type OccupancyRangeField = {
  __typename?: 'OccupancyRangeField';
  occupancyRange: OccupancyRange;
};

export type OptionalAddressInput = {
  value?: Maybe<AddressInput>;
};

export type OptionalDateTimeInput = {
  value?: Maybe<Scalars['DateTime']>;
};

export type OptionalDateTimePeriodInput = {
  value?: Maybe<DateTimePeriodInput>;
};

export type OptionalIntInput = {
  value?: Maybe<Scalars['Int']>;
};

export type OptionalPermitInput = {
  value?: Maybe<PermitInput>;
};

export type OptionalSeverityInput = {
  value?: Maybe<IncidentSeverity>;
};

export type OptionalShiftEndReasonsInput = {
  value?: Maybe<ShiftEndReasons>;
};

export type OptionalStringInput = {
  value?: Maybe<Scalars['String']>;
};

export type OptionalTrafficDisruptionContactPersonInput = {
  value?: Maybe<TrafficDisruptionContactPersonInput>;
};

export type OptionalTrafficDisruptionScheduleDtoInput = {
  value?: Maybe<TrafficDisruptionScheduleDtoInput>;
};

export enum Orientation {
  After = 'AFTER',
  At = 'AT',
  Before = 'BEFORE',
  EastOf = 'EAST_OF',
  Exit = 'EXIT',
  From = 'FROM',
  NorthOf = 'NORTH_OF',
  Over = 'OVER',
  SouthOf = 'SOUTH_OF',
  To = 'TO',
  Under = 'UNDER',
  WestOf = 'WEST_OF'
}

export type OriginalLocationNameColumn = {
  __typename?: 'OriginalLocationNameColumn';
  originalLocationName: Scalars['String'];
  segmentAliasLogColumnName: SegmentAliasLogColumnName;
};

export type Permission = {
  __typename?: 'Permission';
  dependsOnAll?: Maybe<Array<Scope>>;
  dependsOnAny?: Maybe<Array<Scope>>;
  id: Scalars['Int'];
  scope: Scope;
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  id: Scalars['Int'];
  level: PermissionLevel;
  permissions: Array<Permission>;
  type: PermissionGroupType;
};

export enum PermissionGroupType {
  AccountManagement = 'ACCOUNT_MANAGEMENT',
  Asset = 'ASSET',
  BasicIncident = 'BASIC_INCIDENT',
  BasicTrafficDisruption = 'BASIC_TRAFFIC_DISRUPTION',
  CannedReport = 'CANNED_REPORT',
  Congestion = 'CONGESTION',
  CrashRisk = 'CRASH_RISK',
  FspReport = 'FSP_REPORT',
  HistoryLog = 'HISTORY_LOG',
  PublicNotice = 'PUBLIC_NOTICE',
  ResponsePlan = 'RESPONSE_PLAN',
  RoadwayStatus = 'ROADWAY_STATUS',
  ShiftRouteManagement = 'SHIFT_ROUTE_MANAGEMENT',
  TrafficImpact = 'TRAFFIC_IMPACT',
  TransitImpact = 'TRANSIT_IMPACT',
  TransitReport = 'TRANSIT_REPORT',
  UnitManagement = 'UNIT_MANAGEMENT',
  Weather = 'WEATHER',
  Workstation = 'WORKSTATION',
  WrongWayAlert = 'WRONG_WAY_ALERT'
}

export enum PermissionLevel {
  Account = 'ACCOUNT',
  Role = 'ROLE'
}

export type Permit = {
  __typename?: 'Permit';
  permitId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PermitField = {
  __typename?: 'PermitField';
  permitId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PermitInput = {
  permitId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum PermitType {
  Construction = 'CONSTRUCTION',
  RoadClosure = 'ROAD_CLOSURE'
}

export enum PersistenceScoreType {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  VeryLow = 'VERY_LOW'
}



export enum PriorityScoreLevel {
  HighLevel = 'HIGH_LEVEL',
  LowLevel = 'LOW_LEVEL',
  MediumLevel = 'MEDIUM_LEVEL'
}

export enum PriorityScoreParameter {
  CrashRisk = 'CRASH_RISK',
  IncidentImpact = 'INCIDENT_IMPACT',
  IncidentType = 'INCIDENT_TYPE',
  PersistenceScore = 'PERSISTENCE_SCORE',
  RoadType = 'ROAD_TYPE',
  Severity = 'SEVERITY'
}

export type PriorityScoreView = {
  __typename?: 'PriorityScoreView';
  level: PriorityScoreLevel;
  score: Scalars['Float'];
};

export type PublicationAccessDetails = {
  decode: PublicationAccessDetails;
  encode: PublicationAccessDetails;
};

export type PublicationChannel = {
  __typename?: 'PublicationChannel';
  accountId: Scalars['Int'];
  destination: NotificationDestination;
  id: Scalars['Int'];
  publicationAccessDetails: PublicationAccessDetails;
};

export enum PublicationEntityType {
  Construction = 'CONSTRUCTION',
  Incident = 'INCIDENT',
  RoadClosure = 'ROAD_CLOSURE',
  SpecialEvent = 'SPECIAL_EVENT'
}

export type PublicationTemplate = {
  __typename?: 'PublicationTemplate';
  entityType: PublicationEntityType;
  id: Scalars['Int'];
  phrases: Array<Scalars['String']>;
  template: Scalars['String'];
};

export type PublishField = {
  __typename?: 'PublishField';
  description: Scalars['String'];
  destinations: Array<NotificationDestination>;
};

export type PublishInput = {
  description: Scalars['String'];
  destinations: Array<NotificationDestination>;
  entityType: PublicationEntityType;
  id: Scalars['Int'];
  mediaS3Key?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountAdditionalInfos: Array<AdditionalInfo>;
  accountMitigationTypes: Array<MitigationType>;
  accountPublicationChannels: Array<PublicationChannel>;
  accountReportSource?: Maybe<ReportSource>;
  accountReportSources: Array<ReportSource>;
  accountRouteTypes: Array<RouteType>;
  accountShiftTypes: Array<ShiftType>;
  affectedTransitServices: AffectedTransitEntities;
  aliasChangesLog: FilteredAliasResultPage;
  allDMSs: Array<Dms>;
  assetsByLocation: AssetsByLocation;
  busStopDetails: BusStopDetails;
  busStops: Array<BusStopView>;
  completedConstructions: Array<ConstructionView>;
  completedRoadClosures: Array<RoadClosureView>;
  completedSpecialEvents: Array<SpecialEventView>;
  construction?: Maybe<Construction>;
  constructionActivityLog: Array<ActivityLogAggregated>;
  customer?: Maybe<Customer>;
  dms?: Maybe<Dms>;
  dmssByAccount: Array<Dms>;
  findAccountActiveUnitsAndDrivers: AccountActiveUnitsAndDrivers;
  findLatestIncidents: Array<IncidentView>;
  findLatestReleaseNotes: Array<ReleaseNote>;
  findRelatedIncidentsByPointRadius: Array<RelatedIncident>;
  findUsersRoles: Array<UserRolesDto>;
  fixedBusRouteDetails: FixedBusRouteDetails;
  fixedBusRoutes: Array<FixedBusRouteView>;
  getCurrentShift?: Maybe<ShiftDto>;
  getDetailedShift: ShiftDetailedDto;
  getEmbedDashboardUrl: AnalyticsReportsResponse;
  getIncidentInsights?: Maybe<IncidentInsights>;
  getIncidentTypes: AccountIncidentTypes;
  getIncidentsWithUserMitigationsByTimePeriod: Array<Incident>;
  getRoadwayStatusSegmentMetricDetailsById: Array<RoadwayStatusDetailedMetricDto>;
  getShiftsByUserAndTime: Array<ShiftDto>;
  getSubmittedByOptionsList: Array<AliasSubmitter>;
  getWorkingHours: WorkingHoursDto;
  inProgressIncidents: Array<Incident>;
  incident?: Maybe<Incident>;
  incidentActivityLog: Array<ActivityLogAggregated>;
  incidentCamerasByPoint: Array<AssociatedCamera>;
  incidentConfiguration: IncidentConfiguration;
  incidentDetailsLogPDFExport: MediaFile;
  incidentTypePriority: Array<IncidentType>;
  incidentsAnalyticsDetails: IncidentDetailedAnalyticsLog;
  incidentsAnalyticsLog: AnalyticsFilteredIncidentsResultPage;
  incidentsAnalyticsLogCsvExport: MediaFile;
  incidentsAnalyticsLogCsvExportEmail: Scalars['Boolean'];
  isResponsePlanDone?: Maybe<Scalars['Boolean']>;
  me: User;
  permitsByAccountId: Array<AccountPermit>;
  relatedEvents: Array<RelatedEventUnionDto>;
  responsePlansByEntityType: Array<ResponsePlanTemplate>;
  roadClosure?: Maybe<RoadClosure>;
  roadClosureActivityLog: Array<ActivityLogAggregated>;
  roadClosureByAccount: Array<TrafficDisruption>;
  roadNamesByAccount: Array<Scalars['String']>;
  role: Role;
  roles: Array<Role>;
  searchActiveEvents: Array<RelatedEventUnionDto>;
  searchCompletedEvents: Array<RelatedEventUnionDto>;
  segment: SegmentDetails;
  shift?: Maybe<ShiftDto>;
  shiftsAnalyticsLog: AnalyticsFilteredShiftsResultPage;
  shiftsAnalyticsLogCsvExport: MediaFile;
  shiftsAnalyticsLogCsvExportEmail: Scalars['Boolean'];
  specialEvent?: Maybe<SpecialEvent>;
  specialEventActivityLog: Array<ActivityLogAggregated>;
  streetCamerasByArea: Array<StreetCamera>;
  streetCamerasByPoint: Array<StreetCamera>;
  trafficDisruptionAnalyticsLog: AnalyticsFilteredTrafficDisruptionsResultPage;
  trafficDisruptionAnalyticsLogCsvExport: MediaFile;
  trafficDisruptionAnalyticsLogCsvExportEmail: Scalars['Boolean'];
  trafficDisruptionDetailsLogPDFExport: MediaFile;
  unit?: Maybe<UnitVehicle>;
  units: Array<UnitVehicle>;
  unitsByWorkspaces: Array<UnitVehicle>;
  user?: Maybe<User>;
  userRoles: Array<Role>;
  users: Array<User>;
  venues: Array<VenueDetails>;
  weatherAlertsSupportedTypes: Array<WeatherAlertSupportedType>;
  workspacesRoadReferences: RoadReferences;
};


export type QueryAccountArgs = {
  id: Scalars['Int'];
};


export type QueryAccountMitigationTypesArgs = {
  getAll: Scalars['Boolean'];
};


export type QueryAccountReportSourceArgs = {
  id: Scalars['Int'];
};


export type QueryAliasChangesLogArgs = {
  input: FilterSegmentAliasInput;
};


export type QueryAssetsByLocationArgs = {
  input: LocationInput;
};


export type QueryBusStopDetailsArgs = {
  id: Scalars['Int'];
};


export type QueryBusStopsArgs = {
  input: FindBusStopViewsInput;
};


export type QueryConstructionArgs = {
  id: Scalars['Int'];
};


export type QueryConstructionActivityLogArgs = {
  id: Scalars['Int'];
};


export type QueryCustomerArgs = {
  id: Scalars['Int'];
};


export type QueryDmsArgs = {
  id: Scalars['Int'];
};


export type QueryFindLatestReleaseNotesArgs = {
  pastMonths: Scalars['Int'];
};


export type QueryFindRelatedIncidentsByPointRadiusArgs = {
  input: FindIncidentsInPointRadiusInput;
};


export type QueryFixedBusRouteDetailsArgs = {
  id: Scalars['Int'];
};


export type QueryGetDetailedShiftArgs = {
  id: Scalars['Int'];
};


export type QueryGetEmbedDashboardUrlArgs = {
  dashboardId: Scalars['String'];
};


export type QueryGetIncidentInsightsArgs = {
  externalId?: Maybe<Scalars['String']>;
  incidentId: Scalars['Int'];
  status: IncidentStatus;
};


export type QueryGetIncidentsWithUserMitigationsByTimePeriodArgs = {
  input: UserAndTimeInput;
};


export type QueryGetRoadwayStatusSegmentMetricDetailsByIdArgs = {
  segmentId: Scalars['Int'];
};


export type QueryGetShiftsByUserAndTimeArgs = {
  input: UserAndTimeInput;
};


export type QueryIncidentArgs = {
  id: Scalars['Int'];
};


export type QueryIncidentActivityLogArgs = {
  id: Scalars['Int'];
};


export type QueryIncidentCamerasByPointArgs = {
  input: FindStreetCamerasByPointAndRadiusInput;
};


export type QueryIncidentDetailsLogPdfExportArgs = {
  id: Scalars['Int'];
};


export type QueryIncidentsAnalyticsDetailsArgs = {
  id: Scalars['Int'];
};


export type QueryIncidentsAnalyticsLogArgs = {
  input: FilterIncidentsInput;
};


export type QueryIncidentsAnalyticsLogCsvExportArgs = {
  input: FilterIncidentsInput;
};


export type QueryIncidentsAnalyticsLogCsvExportEmailArgs = {
  input: FilterIncidentsInput;
};


export type QueryIsResponsePlanDoneArgs = {
  entityId: Scalars['Int'];
  entityType: ResponsePlanEntityType;
};


export type QueryRelatedEventsArgs = {
  eventId: Scalars['Int'];
};


export type QueryResponsePlansByEntityTypeArgs = {
  entityType: ResponsePlanEntityType;
};


export type QueryRoadClosureArgs = {
  id: Scalars['Int'];
};


export type QueryRoadClosureActivityLogArgs = {
  id: Scalars['Int'];
};


export type QueryRoadNamesByAccountArgs = {
  subString: Scalars['String'];
};


export type QueryRoleArgs = {
  id: Scalars['Int'];
};


export type QuerySearchActiveEventsArgs = {
  searchString: Scalars['String'];
};


export type QuerySegmentArgs = {
  point: Scalars['Point'];
  radiusMeters?: Maybe<Scalars['Short']>;
};


export type QueryShiftArgs = {
  id: Scalars['Int'];
};


export type QueryShiftsAnalyticsLogArgs = {
  input: FilterShiftsInput;
};


export type QueryShiftsAnalyticsLogCsvExportArgs = {
  input: FilterShiftsInput;
};


export type QueryShiftsAnalyticsLogCsvExportEmailArgs = {
  input: FilterShiftsInput;
};


export type QuerySpecialEventArgs = {
  id: Scalars['Int'];
};


export type QuerySpecialEventActivityLogArgs = {
  id: Scalars['Int'];
};


export type QueryStreetCamerasByAreaArgs = {
  input: FindStreetCamerasByAreaInput;
};


export type QueryStreetCamerasByPointArgs = {
  input: FindStreetCamerasByPointAndRadiusInput;
};


export type QueryTrafficDisruptionAnalyticsLogArgs = {
  input: FilterTrafficDisruptionInput;
};


export type QueryTrafficDisruptionAnalyticsLogCsvExportArgs = {
  input: FilterTrafficDisruptionInput;
};


export type QueryTrafficDisruptionAnalyticsLogCsvExportEmailArgs = {
  input: FilterTrafficDisruptionInput;
};


export type QueryTrafficDisruptionDetailsLogPdfExportArgs = {
  id: Scalars['Int'];
};


export type QueryUnitArgs = {
  id: Scalars['Int'];
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUsersArgs = {
  optionalDateTimePeriodInput: OptionalDateTimePeriodInput;
};


export type QueryVenuesArgs = {
  point: Scalars['Point'];
  radiusMeters?: Maybe<Scalars['Int']>;
};

export type RegionalSetting = {
  __typename?: 'RegionalSetting';
  dateFormat: DateFormat;
  firstDayOfWeek: Scalars['Int'];
  language: Language;
  measureUnitType: MeasureUnitType;
  timeFormat: TimeFormat;
  timeZone: TimeZone;
};

export type RelatedEventDto = {
  entityType: RelatedEventEntityTypeDto;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export enum RelatedEventEntityTypeDto {
  Construction = 'CONSTRUCTION',
  Incident = 'INCIDENT',
  RoadClosure = 'ROAD_CLOSURE',
  SpecialEvent = 'SPECIAL_EVENT'
}

export type RelatedEventField = {
  __typename?: 'RelatedEventField';
  endTime?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['Int'];
  relatedEventStatus?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  type: EntityType;
};

export type RelatedEventInputDtoInput = {
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  type: RelatedEventEntityTypeDto;
};

/** return all TrafficDisruptionRelatedEventDTO and IncidentRelatedEventDTO */
export type RelatedEventUnionDto = ConstructionRelatedEventDto | IncidentRelatedEventDto | RoadClosureRelatedEventDto | SpecialEventRelatedEventDto;

export type RelatedImageRequestInput = {
  fileSize: Scalars['Int'];
  key: Scalars['String'];
  url: Scalars['String'];
};

export type RelatedIncident = {
  __typename?: 'RelatedIncident';
  address: Address;
  id: Scalars['Int'];
  status: IncidentStatus;
  subType?: Maybe<Scalars['String']>;
  type: IncidentType;
};

export type ReleaseFeature = {
  __typename?: 'ReleaseFeature';
  description: Scalars['String'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  positionIndex: Scalars['Int'];
  releaseNoteId: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  features: Array<ReleaseFeature>;
  generatedReleaseId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  releaseLanguage: Language;
  releaseName: Scalars['String'];
  releaseTitle: Scalars['String'];
  scheduleReleaseDate: Scalars['DateTime'];
  tags: Array<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ReportSource = {
  __typename?: 'ReportSource';
  accountId: Scalars['Int'];
  id: Scalars['Int'];
  reportSource: Scalars['String'];
  status: Status;
};

export type ReportSourceField = {
  __typename?: 'ReportSourceField';
  accountId: Scalars['Int'];
  reportSource: Scalars['String'];
};

export type ReportSourceInput = {
  id?: Maybe<Scalars['Int']>;
  reportSource: Scalars['String'];
};

export type ResponsePlan = {
  __typename?: 'ResponsePlan';
  actions: Array<ResponsePlanAction>;
  entityId: Scalars['Int'];
  entityType: ResponsePlanEntityType;
  id: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  templateId: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ResponsePlanAction = Action & {
  __typename?: 'ResponsePlanAction';
  actionField: ResponsePlanActionField;
  index: Scalars['Int'];
  isDone: Scalars['Boolean'];
  responsePlanActionId: Scalars['Int'];
  responsePlanId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  time: Scalars['Float'];
  unit: ResponsePlanUnits;
  value?: Maybe<Scalars['ActionValue']>;
};

export enum ResponsePlanActionField {
  ChangeDms = 'CHANGE_DMS',
  ChangeTrafficSignal = 'CHANGE_TRAFFIC_SIGNAL',
  ConfirmIncidentDetails = 'CONFIRM_INCIDENT_DETAILS',
  Contacts = 'CONTACTS',
  OpenParkingLots = 'OPEN_PARKING_LOTS',
  OpenRoadClosure = 'OPEN_ROAD_CLOSURE',
  Other = 'OTHER',
  RoadClosure = 'ROAD_CLOSURE',
  SendEmail = 'SEND_EMAIL',
  ShareToPublic = 'SHARE_TO_PUBLIC',
  UpdateIncidentDetails = 'UPDATE_INCIDENT_DETAILS',
  UpdateIncidentDetailsExternalPlatform = 'UPDATE_INCIDENT_DETAILS_EXTERNAL_PLATFORM'
}

export type ResponsePlanActionInput = {
  actionField: ResponsePlanActionField;
  index: Scalars['Int'];
  isDone: Scalars['Boolean'];
  responsePlanActionId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  time: Scalars['Float'];
  unit: ResponsePlanUnits;
  value?: Maybe<Scalars['ActionValue']>;
};

export enum ResponsePlanEntityType {
  Construction = 'CONSTRUCTION',
  Incident = 'INCIDENT',
  RoadClosure = 'ROAD_CLOSURE',
  SpecialEvent = 'SPECIAL_EVENT'
}

export type ResponsePlanInput = {
  actions: Array<ResponsePlanActionInput>;
  entityId?: Maybe<Scalars['Int']>;
  entityType: ResponsePlanEntityType;
  responsePlanId?: Maybe<Scalars['Int']>;
  templateId: Scalars['Int'];
  title: Scalars['String'];
};

export type ResponsePlanTemplate = {
  __typename?: 'ResponsePlanTemplate';
  accountId: Scalars['Int'];
  actions: Array<TemplateAction>;
  entityType: ResponsePlanEntityType;
  id: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ResponsePlanUnits {
  DaysAfterStart = 'DAYS_AFTER_START',
  DaysBeforeStart = 'DAYS_BEFORE_START',
  HoursAfterEnd = 'HOURS_AFTER_END',
  HoursAfterStart = 'HOURS_AFTER_START',
  HoursBeforeEnd = 'HOURS_BEFORE_END',
  HoursBeforeStart = 'HOURS_BEFORE_START',
  MinAfterStart = 'MIN_AFTER_START'
}

export type RoadClosure = TrafficDisruption & {
  __typename?: 'RoadClosure';
  address?: Maybe<Address>;
  affectedLanes?: Maybe<Array<TrafficDisruptionLane>>;
  allLanesAffected: Scalars['Boolean'];
  cameras: Array<AssociatedCamera>;
  contactPerson?: Maybe<TrafficDisruptionContactPerson>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dmses: Array<AssociatedDms>;
  endTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  feedPriorityType: FeedPriorityType;
  id: Scalars['Int'];
  isAllDay: Scalars['Boolean'];
  lanesClosureType: LanesClosureType;
  location: Scalars['Geometry'];
  media?: Maybe<Array<TrafficDisruptionMedia>>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  permit?: Maybe<Permit>;
  schedule?: Maybe<TrafficDisruptionScheduleDto>;
  startTime: Scalars['DateTime'];
  status: TrafficDisruptionStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['Int'];
  workzoneDataExchange?: Maybe<WorkzoneDataExchangeDto>;
};

export enum RoadClosurePublicationMessageTemplateVariables {
  AffectedLanes = 'AFFECTED_LANES',
  Corridor = 'CORRIDOR',
  Crossroad = 'CROSSROAD',
  Description = 'DESCRIPTION',
  Direction = 'DIRECTION',
  EndTime = 'END_TIME',
  MileMarker = 'MILE_MARKER',
  Orientation = 'ORIENTATION',
  PublishTime = 'PUBLISH_TIME',
  RoadType = 'ROAD_TYPE',
  Schedule = 'SCHEDULE',
  StartTime = 'START_TIME'
}

export type RoadClosureRelatedEventDto = RelatedEventDto & {
  __typename?: 'RoadClosureRelatedEventDTO';
  entityType: RelatedEventEntityTypeDto;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location: Scalars['Geometry'];
  trafficDisruptionAddress?: Maybe<Address>;
  trafficDisruptionStatus: TrafficDisruptionStatus;
  trafficDisruptionTitle: Scalars['String'];
};

export type RoadClosureView = View & {
  __typename?: 'RoadClosureView';
  address?: Maybe<Address>;
  allLanesAffected: Scalars['Boolean'];
  createdAt: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  lanesClosureType: LanesClosureType;
  layerType: LayerType;
  location: Scalars['Geometry'];
  multiDirectionLanesAffected: Scalars['Boolean'];
  startTime: Scalars['String'];
  status: TrafficDisruptionStatus;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  workspaces: Array<Scalars['Int']>;
};

export enum RoadLevelType {
  County = 'COUNTY',
  Highway = 'HIGHWAY',
  Intercity = 'INTERCITY',
  International = 'INTERNATIONAL',
  Interstate = 'INTERSTATE',
  Local = 'LOCAL',
  National = 'NATIONAL',
  Regional = 'REGIONAL',
  Standard = 'STANDARD',
  Street = 'STREET'
}

export type RoadReferences = {
  __typename?: 'RoadReferences';
  refs: Array<Scalars['String']>;
};

export enum RoadType {
  Bridge = 'BRIDGE',
  CdRoad = 'CD_ROAD',
  Freeway = 'FREEWAY',
  Highway = 'HIGHWAY',
  Intersection = 'INTERSECTION',
  OffRamp = 'OFF_RAMP',
  OnRamp = 'ON_RAMP',
  Other = 'OTHER',
  Primary = 'PRIMARY',
  Ramp = 'RAMP',
  Roadway = 'ROADWAY',
  Secondary = 'SECONDARY',
  Street = 'STREET',
  Trail = 'TRAIL',
  TransitionRamp = 'TRANSITION_RAMP',
  UnknownRoadType = 'UNKNOWN_ROAD_TYPE'
}

export type RoadwayStatusDetailedMetricDto = {
  __typename?: 'RoadwayStatusDetailedMetricDTO';
  avgSpeedTotal?: Maybe<Scalars['Float']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isDataExist: Scalars['Boolean'];
  isDefaultVendor: Scalars['Boolean'];
  isUpdated?: Maybe<Scalars['Boolean']>;
  lanesData: Array<RoadwayStatusLaneMetricDto>;
  maxSpeedDeviationPercent?: Maybe<Scalars['Float']>;
  maxSpeedDeviationSeverity?: Maybe<DeviationSeverity>;
  nonBusesAvgSpeed?: Maybe<Scalars['Float']>;
  nonBusesCount?: Maybe<Scalars['Int']>;
  roadwayStatusSegmentId: Scalars['Int'];
  startedAt?: Maybe<Scalars['DateTime']>;
  transportCountTotal?: Maybe<Scalars['Int']>;
  transportsData: Array<RoadwayStatusTransportMetricsDto>;
  updatedAt: Scalars['DateTime'];
  vendorId: Scalars['Int'];
  vendorName: FeedSource;
};

export type RoadwayStatusLaneMetricDto = {
  __typename?: 'RoadwayStatusLaneMetricDTO';
  avgSpeed?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  type: LaneType;
};

export type RoadwayStatusSegmentView = View & {
  __typename?: 'RoadwayStatusSegmentView';
  accountId: Scalars['Int'];
  addressTitle: Scalars['String'];
  defaultVendorId?: Maybe<Scalars['Int']>;
  direction: Direction;
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  layerType: LayerType;
  length: Scalars['Float'];
  location: Scalars['Geometry'];
  maxSpeed: Scalars['Float'];
  vendorMetrics: Array<MetricVendorData>;
  workspaces: Array<Scalars['Int']>;
};

export type RoadwayStatusTransportMetricsDto = {
  __typename?: 'RoadwayStatusTransportMetricsDTO';
  avgSpeed?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  type: TransportType;
};

export type Role = {
  __typename?: 'Role';
  accountId: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Permission>;
};

export type RouteType = {
  __typename?: 'RouteType';
  accountId: Scalars['Int'];
  area: Scalars['Polygon'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Status;
};

export type RouteTypeIdsField = {
  __typename?: 'RouteTypeIdsField';
  routeTypesIds: Array<Scalars['Int']>;
};

export type ScheduleField = {
  __typename?: 'ScheduleField';
  endTime?: Maybe<Scalars['DateTime']>;
  schedule?: Maybe<TrafficDisruptionScheduleDto>;
  startTime?: Maybe<Scalars['DateTime']>;
};

export type Scope = {
  __typename?: 'Scope';
  entity: EntityType;
  modifier: AccessModifier;
};

export type SegmentAliasColumnValue = AliasColumn | IdColumn | IncidentCoordinatesColumn | OriginalLocationNameColumn | SubmittedAtColumn | SubmittedByColumn | TypeColumn;

export enum SegmentAliasLogColumnName {
  Alias = 'ALIAS',
  Id = 'ID',
  IncidentCoordinates = 'INCIDENT_COORDINATES',
  OriginalLocationName = 'ORIGINAL_LOCATION_NAME',
  SubmittedAt = 'SUBMITTED_AT',
  SubmittedBy = 'SUBMITTED_BY',
  Type = 'TYPE'
}

export type SegmentAliasLogFiltersListInput = {
  locationAndAliasFilter?: Maybe<AnalyticsFilterLocationAndAliasRegexInput>;
  submittedAtFilter?: Maybe<AnalyticsFilterTimeRangeInput>;
  submittedByFilter?: Maybe<AnalyticsFilterSubmittedByInput>;
  typeFilter?: Maybe<AnalyticsFilterAliasTypesInput>;
};

export enum SegmentAliasLogSortColumn {
  Alias = 'ALIAS',
  OriginalLocationName = 'ORIGINAL_LOCATION_NAME',
  SubmittedAt = 'SUBMITTED_AT',
  SubmittedBy = 'SUBMITTED_BY',
  Type = 'TYPE'
}

export type SegmentDetails = {
  __typename?: 'SegmentDetails';
  alias?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  crossRoads: Array<SegmentsDropdownDetails>;
  crossroadSource?: Maybe<CrossroadSource>;
  direction?: Maybe<Direction>;
  lanesQuantity?: Maybe<Scalars['Short']>;
  milemarkers: Array<Scalars['String']>;
  names: Array<Scalars['String']>;
  orientation?: Maybe<Orientation>;
  overridingCorridorAlias?: Maybe<Scalars['String']>;
  overridingCrossroadAlias?: Maybe<Scalars['String']>;
  refs: Array<Scalars['String']>;
  roadLevelTypes: Array<RoadLevelType>;
  roadTypes: Array<RoadType>;
  segmentId: Scalars['String'];
  segmentsNames: Array<SegmentsDropdownDetails>;
  state?: Maybe<Scalars['String']>;
};

export enum SegmentImpactLevel {
  HighImpact = 'HIGH_IMPACT',
  LowImpact = 'LOW_IMPACT',
  MediumImpact = 'MEDIUM_IMPACT',
  NoData = 'NO_DATA',
  NoImpact = 'NO_IMPACT'
}

export type SegmentsDropdownDetails = {
  __typename?: 'SegmentsDropdownDetails';
  distance: Scalars['Float'];
  name: Scalars['String'];
  nearestPoint: Scalars['Point'];
  roadType: RoadType;
};

export type SetRolePermissionsInput = {
  permissions: Array<Scalars['Int']>;
  roleId: Scalars['Int'];
};

export type SetUserRolesInput = {
  roles: Array<Scalars['Int']>;
  userId: Scalars['Int'];
};

export type SetUserUnitInput = {
  forceOverride: Scalars['Boolean'];
  unitId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ShiftDto = {
  __typename?: 'ShiftDTO';
  endMileage?: Maybe<Scalars['Int']>;
  endReason?: Maybe<ShiftEndReasons>;
  endReasonNote?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  overtimeNote?: Maybe<Scalars['String']>;
  routeTypes: Array<RouteType>;
  shiftId: Scalars['Int'];
  shiftPauses: Array<ShiftPauseDto>;
  shiftType?: Maybe<ShiftType>;
  startMileage?: Maybe<Scalars['Int']>;
  startedAt: Scalars['DateTime'];
  unit?: Maybe<UnitVehicle>;
  user: User;
};

export type ShiftDetailedDto = {
  __typename?: 'ShiftDetailedDTO';
  baseData: ShiftDto;
  durationMillis?: Maybe<Scalars['Long']>;
  durationMillisRounded?: Maybe<Scalars['Long']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  endedAtRounded?: Maybe<Scalars['DateTime']>;
  firstIncidentMitigationTime?: Maybe<Scalars['DateTime']>;
  incidentsDetails: Array<ShiftIncidentDetailsDto>;
  isOvertime: Scalars['Boolean'];
  lastIncidentMitigationTime?: Maybe<Scalars['DateTime']>;
  startedAt: Scalars['DateTime'];
  startedAtRounded: Scalars['DateTime'];
  totalBreaksDurationMillis: Scalars['Long'];
};

export type ShiftEndReasonField = {
  __typename?: 'ShiftEndReasonField';
  shiftEndReason: ShiftEndReasons;
};

export enum ShiftEndReasons {
  DriverInjury = 'DRIVER_INJURY',
  EndOfShift = 'END_OF_SHIFT',
  Other = 'OTHER',
  VehicleOutOfOrder = 'VEHICLE_OUT_OF_ORDER'
}

export type ShiftIncidentDetailsDto = {
  __typename?: 'ShiftIncidentDetailsDTO';
  address: Address;
  firstMitigationTime?: Maybe<Scalars['DateTime']>;
  incidentId: Scalars['Int'];
  lastMitigationTime?: Maybe<Scalars['DateTime']>;
  location: Scalars['Point'];
  mitigations: Array<IncidentUnitMitigationDetails>;
  responses: Array<IncidentUnitResponseUpdateDetails>;
  subType?: Maybe<Scalars['String']>;
  type: IncidentType;
};

export type ShiftPauseDto = {
  __typename?: 'ShiftPauseDTO';
  endedAt?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  pauseId: Scalars['Int'];
  reason: ShiftPauseReasons;
  reasonNote?: Maybe<Scalars['String']>;
  shiftId: Scalars['Int'];
  startedAt: Scalars['DateTime'];
};

export type ShiftPauseInput = {
  endedAt: Scalars['DateTime'];
  reason: ShiftPauseReasons;
  reasonNote?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTime'];
};

export enum ShiftPauseReasons {
  BathroomBrake = 'BATHROOM_BRAKE',
  DriverInjury = 'DRIVER_INJURY',
  LunchBreak = 'LUNCH_BREAK',
  Other = 'OTHER',
  Refueling = 'REFUELING',
  VehicleDamaged = 'VEHICLE_DAMAGED',
  VehicleOutOfOrder = 'VEHICLE_OUT_OF_ORDER'
}

export type ShiftType = {
  __typename?: 'ShiftType';
  accountId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Status;
};

export type ShiftTypeIdField = {
  __typename?: 'ShiftTypeIdField';
  shiftTypeId: Scalars['Int'];
};


export enum SortOrder {
  Asc = 'ASC',
  AscNullsFirst = 'ASC_NULLS_FIRST',
  AscNullsLast = 'ASC_NULLS_LAST',
  Desc = 'DESC',
  DescNullsFirst = 'DESC_NULLS_FIRST',
  DescNullsLast = 'DESC_NULLS_LAST'
}

export type SpecialEvent = TrafficDisruption & {
  __typename?: 'SpecialEvent';
  address?: Maybe<Address>;
  affectedLanes?: Maybe<Array<TrafficDisruptionLane>>;
  allLanesAffected: Scalars['Boolean'];
  contactPerson?: Maybe<TrafficDisruptionContactPerson>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  feedPriorityType: FeedPriorityType;
  id: Scalars['Int'];
  isAllDay: Scalars['Boolean'];
  lanesClosureType: LanesClosureType;
  location: Scalars['Geometry'];
  media?: Maybe<Array<TrafficDisruptionMedia>>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  occupancy?: Maybe<Scalars['Int']>;
  occupancyRange?: Maybe<OccupancyRange>;
  otherTypeDescription?: Maybe<Scalars['String']>;
  responsePlan?: Maybe<ResponsePlan>;
  schedule?: Maybe<TrafficDisruptionScheduleDto>;
  startTime: Scalars['DateTime'];
  status: TrafficDisruptionStatus;
  title: Scalars['String'];
  type: SpecialEventType;
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['Int'];
  venue?: Maybe<Venue>;
  workzoneDataExchange?: Maybe<WorkzoneDataExchangeDto>;
};

export type SpecialEventRelatedEventDto = RelatedEventDto & {
  __typename?: 'SpecialEventRelatedEventDTO';
  entityType: RelatedEventEntityTypeDto;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location: Scalars['Geometry'];
  specialEventType: SpecialEventType;
  trafficDisruptionAddress?: Maybe<Address>;
  trafficDisruptionStatus: TrafficDisruptionStatus;
  trafficDisruptionTitle: Scalars['String'];
};

export enum SpecialEventType {
  Concert = 'CONCERT',
  Conference = 'CONFERENCE',
  Festival = 'FESTIVAL',
  Marathon = 'MARATHON',
  Other = 'OTHER',
  Parade = 'PARADE',
  Protest = 'PROTEST',
  Racing = 'RACING',
  Sport = 'SPORT'
}

export type SpecialEventTypeField = {
  __typename?: 'SpecialEventTypeField';
  specialEventType: SpecialEventType;
};

export type SpecialEventView = View & {
  __typename?: 'SpecialEventView';
  address?: Maybe<Address>;
  allLanesAffected: Scalars['Boolean'];
  createdAt: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  lanesClosureType: LanesClosureType;
  layerType: LayerType;
  location: Scalars['Geometry'];
  multiDirectionLanesAffected: Scalars['Boolean'];
  occupancy?: Maybe<Scalars['Int']>;
  occupancyRange?: Maybe<OccupancyRange>;
  otherTypeDescription?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  status: TrafficDisruptionStatus;
  title: Scalars['String'];
  type: SpecialEventType;
  updatedAt: Scalars['String'];
  venueAddress?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
  workspaces: Array<Scalars['Int']>;
};

export enum State {
  Aa = 'AA',
  Ak = 'AK',
  Al = 'AL',
  Ap = 'AP',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Um = 'UM',
  UnknownState = 'UNKNOWN_STATE',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type StreetCamera = Camera & {
  __typename?: 'StreetCamera';
  address: Address;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isVideo: Scalars['Boolean'];
  location: Scalars['Point'];
  status: Status;
  streamType?: Maybe<CameraStreamType>;
  title: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
  workspaces: Array<Scalars['Int']>;
};

export type StreetCameraView = View & {
  __typename?: 'StreetCameraView';
  address: Address;
  externalId: Scalars['String'];
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  imageUrl: Scalars['String'];
  layerType: LayerType;
  location: Scalars['Geometry'];
  status: Status;
  streamType?: Maybe<CameraStreamType>;
  title: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
  workspaces: Array<Scalars['Int']>;
};

export type SubmittedAtColumn = {
  __typename?: 'SubmittedAtColumn';
  segmentAliasLogColumnName: SegmentAliasLogColumnName;
  submittedAt: Scalars['DateTime'];
};

export type SubmittedByColumn = {
  __typename?: 'SubmittedByColumn';
  segmentAliasLogColumnName: SegmentAliasLogColumnName;
  submittedBy: Scalars['String'];
};

export type TemplateAction = Action & {
  __typename?: 'TemplateAction';
  actionField: ResponsePlanActionField;
  index: Scalars['Int'];
  templateActionId: Scalars['Int'];
  templateId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  time: Scalars['Float'];
  unit: ResponsePlanUnits;
  value?: Maybe<Scalars['ActionValue']>;
};

export type TemplateActionInput = {
  actionField: ResponsePlanActionField;
  index: Scalars['Int'];
  templateActionId?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  time: Scalars['Float'];
  unit: ResponsePlanUnits;
  value?: Maybe<Scalars['ActionValue']>;
};

export type TextField = {
  __typename?: 'TextField';
  text: Scalars['String'];
};

export enum TimeFormat {
  TwelveHours = 'TWELVE_HOURS',
  TwentyFourHours = 'TWENTY_FOUR_HOURS'
}

export type TimeRangeSchedule = {
  __typename?: 'TimeRangeSchedule';
  fromDayOfWeekLocal: Scalars['Int'];
  fromTimeMinutesLocal: Scalars['Int'];
  toDayOfWeekLocal: Scalars['Int'];
  toTimeMinutesLocal: Scalars['Int'];
};

export type TimeRangeScheduleInput = {
  fromDayOfWeekLocal: Scalars['Int'];
  fromTimeMinutesLocal: Scalars['Int'];
  toDayOfWeekLocal: Scalars['Int'];
  toTimeMinutesLocal: Scalars['Int'];
};

export enum TimeZone {
  AsiaBangkok = 'ASIA_BANGKOK',
  Indochina = 'INDOCHINA',
  Israel = 'ISRAEL',
  Sweden = 'SWEDEN',
  UsCentral = 'US_CENTRAL',
  UsEastern = 'US_EASTERN',
  UsMountain = 'US_MOUNTAIN',
  UsPacific = 'US_PACIFIC',
  Utc = 'UTC'
}

export type TrafficDisruption = {
  address?: Maybe<Address>;
  affectedLanes?: Maybe<Array<TrafficDisruptionLane>>;
  allLanesAffected: Scalars['Boolean'];
  contactPerson?: Maybe<TrafficDisruptionContactPerson>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  feedPriorityType: FeedPriorityType;
  id: Scalars['Int'];
  isAllDay: Scalars['Boolean'];
  lanesClosureType: LanesClosureType;
  location: Scalars['Geometry'];
  media?: Maybe<Array<TrafficDisruptionMedia>>;
  multiDirectionLanesAffected: Scalars['Boolean'];
  schedule?: Maybe<TrafficDisruptionScheduleDto>;
  startTime: Scalars['DateTime'];
  status: TrafficDisruptionStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedBy: Scalars['Int'];
  workzoneDataExchange?: Maybe<WorkzoneDataExchangeDto>;
};

export type TrafficDisruptionAffectingTransitAdditionalInfo = {
  __typename?: 'TrafficDisruptionAffectingTransitAdditionalInfo';
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  typeDetail?: Maybe<Scalars['String']>;
};

export enum TrafficDisruptionAnalyticsFilterStatus {
  Completed = 'COMPLETED',
  Live = 'LIVE'
}

export type TrafficDisruptionContactPerson = {
  __typename?: 'TrafficDisruptionContactPerson';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type TrafficDisruptionContactPersonInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type TrafficDisruptionLane = {
  __typename?: 'TrafficDisruptionLane';
  direction?: Maybe<Direction>;
  id: Scalars['Int'];
  isAffected: Scalars['Boolean'];
  isClosed: Scalars['Boolean'];
  number?: Maybe<Scalars['Int']>;
  positionIndex: Scalars['Int'];
  roadType: RoadType;
  type: LaneType;
};

export type TrafficDisruptionLaneField = {
  __typename?: 'TrafficDisruptionLaneField';
  trafficDisruptionLane: TrafficDisruptionLane;
};

export type TrafficDisruptionMedia = {
  __typename?: 'TrafficDisruptionMedia';
  id: Scalars['Int'];
  media: MediaFile;
};

export type TrafficDisruptionMediaIdField = {
  __typename?: 'TrafficDisruptionMediaIdField';
  trafficDisruptionMediaId: Scalars['Int'];
};

export type TrafficDisruptionRelatedEventDto = RelatedEventDto & {
  __typename?: 'TrafficDisruptionRelatedEventDTO';
  entityType: RelatedEventEntityTypeDto;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location: Scalars['Geometry'];
  trafficDisruptionAddress?: Maybe<Address>;
  trafficDisruptionStatus: TrafficDisruptionStatus;
  trafficDisruptionTitle: Scalars['String'];
};

export type TrafficDisruptionScheduleDto = {
  __typename?: 'TrafficDisruptionScheduleDTO';
  timeRanges: Array<TimeRangeSchedule>;
  timeZone: TimeZone;
};

export type TrafficDisruptionScheduleDtoInput = {
  timeRanges: Array<TimeRangeScheduleInput>;
  timeZone: TimeZone;
};

export enum TrafficDisruptionStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Inactive = 'INACTIVE',
  Planned = 'PLANNED'
}

export type TrafficDisruptionStatusField = {
  __typename?: 'TrafficDisruptionStatusField';
  trafficDisruptionEndReason?: Maybe<DeletedEndReason>;
  trafficDisruptionStatus: TrafficDisruptionStatus;
};

export enum TrafficDisruptionType {
  Construction = 'CONSTRUCTION',
  RoadClosure = 'ROAD_CLOSURE',
  SpecialEvent = 'SPECIAL_EVENT'
}

export type TrafficDisruptionTypeWithSubTypesInput = {
  subTypes: Array<Scalars['String']>;
  trafficDisruptionType: TrafficDisruptionType;
};

export enum TransitAffectingEntityType {
  Construction = 'CONSTRUCTION',
  Incident = 'INCIDENT',
  IrregularCongestion = 'IRREGULAR_CONGESTION',
  RoadClosure = 'ROAD_CLOSURE',
  SpecialEvent = 'SPECIAL_EVENT',
  WeatherAlert = 'WEATHER_ALERT'
}

export enum TransitPatternDirection {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export enum TransportType {
  Bus = 'BUS',
  Commercial = 'COMMERCIAL',
  Motorbike = 'MOTORBIKE',
  OtherTransport = 'OTHER_TRANSPORT',
  Private = 'PRIVATE',
  Truck = 'TRUCK'
}

export type TwitterAccessDetails = PublicationAccessDetails & {
  __typename?: 'TwitterAccessDetails';
  accessToken: Scalars['String'];
  accessTokenSecret: Scalars['String'];
  consumerKey: Scalars['String'];
  consumerKeySecret: Scalars['String'];
  decode: PublicationAccessDetails;
  encode: PublicationAccessDetails;
};

export type TwitterAccessDetailsInput = {
  accessToken: Scalars['String'];
  accessTokenSecret: Scalars['String'];
  consumerKey: Scalars['String'];
  consumerKeySecret: Scalars['String'];
};

export type TypeColumn = {
  __typename?: 'TypeColumn';
  segmentAliasLogColumnName: SegmentAliasLogColumnName;
  type: AliasType;
};

export enum UnitActivityStatus {
  BlowingSnow = 'BLOWING_SNOW',
  Flashers = 'FLASHERS',
  Lights = 'LIGHTS',
  Plowing = 'PLOWING',
  Responding = 'RESPONDING',
  Sirens = 'SIRENS',
  SpreadingSalt = 'SPREADING_SALT',
  SpreadingSand = 'SPREADING_SAND',
  StreetSweeping = 'STREET_SWEEPING'
}

export type UnitDashCamera = Camera & {
  __typename?: 'UnitDashCamera';
  imageUrl: Scalars['String'];
  isVideo: Scalars['Boolean'];
  position: CameraPosition;
  streamType: CameraStreamType;
  videoUrl?: Maybe<Scalars['String']>;
};

export enum UnitDriverStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  OnBreak = 'OnBreak'
}

export type UnitField = {
  __typename?: 'UnitField';
  displayId: Scalars['String'];
  driverId?: Maybe<Scalars['Int']>;
  response?: Maybe<UnitResponse>;
};

export type UnitIdField = {
  __typename?: 'UnitIdField';
  shiftUnitId: Scalars['Int'];
};

export type UnitLocationInput = {
  bearing?: Maybe<BearingInput>;
  point: Scalars['Point'];
};

export enum UnitRelationType {
  ChangingUnit = 'CHANGING_UNIT',
  ConstantUnit = 'CONSTANT_UNIT',
  NoUnit = 'NO_UNIT'
}

export enum UnitResponse {
  Accepted = 'ACCEPTED',
  Completed = 'COMPLETED',
  EnRoute = 'EN_ROUTE',
  Idle = 'IDLE',
  Mitigated = 'MITIGATED',
  OnScene = 'ON_SCENE',
  Rejected = 'REJECTED',
  UnknownUnitStatus = 'UNKNOWN_UNIT_STATUS'
}

export type UnitVehicle = {
  __typename?: 'UnitVehicle';
  accountId: Scalars['Int'];
  activityStatuses: Array<UnitActivityStatus>;
  dashCameras: Array<UnitDashCamera>;
  displayId: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['Int'];
  isBusy: Scalars['Boolean'];
  status: Status;
  type: VehicleType;
  userDetails?: Maybe<UserDetails>;
};

export type UnitView = View & {
  __typename?: 'UnitView';
  accountId: Scalars['Int'];
  accountName: Scalars['String'];
  activityStatuses: Array<UnitActivityStatus>;
  bearing?: Maybe<Scalars['Int']>;
  breakDetails?: Maybe<BreakDetails>;
  cameras: Array<UnitDashCamera>;
  displayId: Scalars['String'];
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  incidentDetails?: Maybe<IncidentDetails>;
  isBusy: Scalars['Boolean'];
  layerType: LayerType;
  location: Scalars['Geometry'];
  locationTime: Scalars['String'];
  routeNames: Array<Scalars['String']>;
  status: UnitDriverStatus;
  type: Scalars['String'];
  userDetails?: Maybe<UserDetails>;
  workspaces: Array<Scalars['Int']>;
};

export type UpdateAdditionalInfoInput = {
  id: Scalars['Int'];
  info: Scalars['String'];
};

export type UpdateConstructionInput = {
  address?: Maybe<OptionalAddressInput>;
  affectedLanes?: Maybe<Array<CreateLaneInput>>;
  allLanesAffected?: Maybe<Scalars['Boolean']>;
  cameras?: Maybe<Array<AssociatedCameraInput>>;
  contactPerson?: Maybe<OptionalTrafficDisruptionContactPersonInput>;
  description?: Maybe<OptionalStringInput>;
  dmses?: Maybe<Array<AssociatedDmsInput>>;
  endTime?: Maybe<OptionalDateTimeInput>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAllDay?: Maybe<Scalars['Boolean']>;
  lanesClosureType?: Maybe<LanesClosureType>;
  location?: Maybe<Scalars['Geometry']>;
  media?: Maybe<Array<MediaFileInput>>;
  multiDirectionLanesAffected?: Maybe<Scalars['Boolean']>;
  otherTypeDescription?: Maybe<Scalars['String']>;
  permit?: Maybe<OptionalPermitInput>;
  relatedEvents?: Maybe<Array<RelatedEventInputDtoInput>>;
  schedule?: Maybe<OptionalTrafficDisruptionScheduleDtoInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ConstructionType>;
};

export type UpdateDetailedShiftInput = {
  pauses: Array<ShiftPauseInput>;
  shiftInput: UpdateShiftInput;
};

export type UpdateGovDeliveryPublicationChannelInput = {
  accessDetails: GovDeliveryAccessDetailsInput;
  accountId: Scalars['Int'];
};

export type UpdateIncidentConfigurationInput = {
  abandonedVehicleSubTypeValues?: Maybe<Array<IncidentAbandonedVehicleSubType>>;
  additionalInfoMandatory?: Maybe<Scalars['Boolean']>;
  additionalInfosValues?: Maybe<Array<AdditionalInfoInput>>;
  affectedLanesMandatory?: Maybe<Scalars['Boolean']>;
  associatedUnitsMandatory?: Maybe<Scalars['Boolean']>;
  attributeFixedValues?: Maybe<Array<IncidentAttribute>>;
  attributeFreeTextValues?: Maybe<Array<IncidentAttributeStringValueInput>>;
  attributesMandatory?: Maybe<Scalars['Boolean']>;
  casualtiesMandatory?: Maybe<Scalars['Boolean']>;
  crashSubTypeValues?: Maybe<Array<IncidentCrashSubType>>;
  damageSubTypeValues?: Maybe<Array<IncidentDamageSubType>>;
  debrisSubTypeValues?: Maybe<Array<IncidentDebrisSubType>>;
  directionMandatory?: Maybe<Scalars['Boolean']>;
  hazardSubTypeValues?: Maybe<Array<IncidentHazardSubType>>;
  incidentTypeValues?: Maybe<Array<IncidentType>>;
  mitigationTypeValues?: Maybe<Array<MitigationInput>>;
  notesMandatory?: Maybe<Scalars['Boolean']>;
  orientationMandatory?: Maybe<Scalars['Boolean']>;
  orientationValues?: Maybe<Array<Orientation>>;
  policeActivitySubTypeValues?: Maybe<Array<IncidentPoliceActivitySubType>>;
  responsePlanMandatory?: Maybe<Scalars['Boolean']>;
  roadTypeMandatory?: Maybe<Scalars['Boolean']>;
  roadTypeValues?: Maybe<Array<RoadType>>;
  severityMandatory?: Maybe<Scalars['Boolean']>;
  severityValues?: Maybe<Array<IncidentSeverity>>;
  sourceMandatory?: Maybe<Scalars['Boolean']>;
  sourceValues?: Maybe<Array<ReportSourceInput>>;
  stalledVehicleSubTypeValues?: Maybe<Array<IncidentStalledVehicleSubType>>;
  trafficStopSubTypeValues?: Maybe<Array<IncidentTrafficStopSubType>>;
};

export type UpdateIncidentInput = {
  address?: Maybe<AddressInput>;
  allLanesAffected?: Maybe<Scalars['Boolean']>;
  atmsId?: Maybe<OptionalStringInput>;
  attributes?: Maybe<Array<Scalars['String']>>;
  autoPublish?: Maybe<Scalars['Boolean']>;
  cadId?: Maybe<OptionalStringInput>;
  endedAt?: Maybe<Scalars['DateTime']>;
  estimatedEndTime?: Maybe<OptionalDateTimeInput>;
  incidentId: Scalars['Int'];
  injuries?: Maybe<OptionalIntInput>;
  injurySeverities?: Maybe<Array<InjurySeverity>>;
  involvedVehiclesCount?: Maybe<OptionalIntInput>;
  lanesStatus?: Maybe<LanesStatus>;
  location?: Maybe<Scalars['Point']>;
  multiDirectionLanesAffected?: Maybe<Scalars['Boolean']>;
  nearCameras?: Maybe<Scalars['Boolean']>;
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  severity?: Maybe<OptionalSeverityInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  subType?: Maybe<OptionalStringInput>;
  title?: Maybe<OptionalStringInput>;
  trafficDisturbance?: Maybe<Scalars['Boolean']>;
  type?: Maybe<IncidentType>;
  typeDescription?: Maybe<OptionalStringInput>;
};

export type UpdateIncidentMitigationInput = {
  driverId: Scalars['Int'];
  id: Scalars['Int'];
  interval: DateIntervalInput;
  mitigationTypeId: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type UpdateIncidentMitigationsInput = {
  created: Array<CreateIncidentMitigationInput>;
  incidentId: Scalars['Int'];
  modified: Array<UpdateIncidentMitigationInput>;
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  removed: Array<DeleteIncidentMitigationInput>;
};

export type UpdateIncidentUnitInput = {
  driverId?: Maybe<Scalars['Int']>;
  incidentId: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  response: UnitResponse;
  unitId: Scalars['Int'];
};

export type UpdateMitigationTypeInput = {
  description: Scalars['String'];
  id: Scalars['Int'];
};

export type UpdatePublicationTemplateInput = {
  accountId: Scalars['Int'];
  entityType: PublicationEntityType;
  messageTemplate: Scalars['String'];
  phrases: Array<Scalars['String']>;
};

export type UpdateReportSourceInput = {
  id: Scalars['Int'];
  reportSource: Scalars['String'];
};

export type UpdateResponsePlanTemplateInput = {
  actions: Array<TemplateActionInput>;
  entityType: ResponsePlanEntityType;
  templateId: Scalars['Int'];
  title: Scalars['String'];
};

export type UpdateRoadClosureInput = {
  address?: Maybe<OptionalAddressInput>;
  affectedLanes?: Maybe<Array<CreateLaneInput>>;
  allLanesAffected?: Maybe<Scalars['Boolean']>;
  cameras?: Maybe<Array<AssociatedCameraInput>>;
  contactPerson?: Maybe<OptionalTrafficDisruptionContactPersonInput>;
  description?: Maybe<OptionalStringInput>;
  dmses?: Maybe<Array<AssociatedDmsInput>>;
  endTime?: Maybe<OptionalDateTimeInput>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAllDay?: Maybe<Scalars['Boolean']>;
  lanesClosureType?: Maybe<LanesClosureType>;
  location?: Maybe<Scalars['Geometry']>;
  media?: Maybe<Array<MediaFileInput>>;
  multiDirectionLanesAffected?: Maybe<Scalars['Boolean']>;
  permit?: Maybe<OptionalPermitInput>;
  relatedEvents?: Maybe<Array<RelatedEventInputDtoInput>>;
  schedule?: Maybe<OptionalTrafficDisruptionScheduleDtoInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateRoleInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  roleId: Scalars['Int'];
};

export type UpdateRouteTypeInput = {
  area?: Maybe<Scalars['Polygon']>;
  name?: Maybe<Scalars['String']>;
  routeTypeId: Scalars['Int'];
};

export type UpdateShiftInput = {
  activitySource: ActivitySourceType;
  endMileage?: Maybe<OptionalIntInput>;
  endReason?: Maybe<OptionalShiftEndReasonsInput>;
  endReasonNote?: Maybe<OptionalStringInput>;
  endedAt?: Maybe<OptionalDateTimeInput>;
  overtimeNote?: Maybe<OptionalStringInput>;
  routeTypesIds?: Maybe<Array<Scalars['Int']>>;
  shiftId: Scalars['Int'];
  shiftTypeId?: Maybe<OptionalIntInput>;
  startMileage?: Maybe<OptionalIntInput>;
  startedAt?: Maybe<Scalars['DateTime']>;
  unitId?: Maybe<OptionalIntInput>;
};

export type UpdateShiftTypeInput = {
  name?: Maybe<Scalars['String']>;
  shiftTypeId: Scalars['Int'];
};

export type UpdateSpecialEventInput = {
  address?: Maybe<OptionalAddressInput>;
  affectedLanes?: Maybe<Array<CreateLaneInput>>;
  allLanesAffected?: Maybe<Scalars['Boolean']>;
  contactPerson?: Maybe<OptionalTrafficDisruptionContactPersonInput>;
  description?: Maybe<OptionalStringInput>;
  endReason?: Maybe<DeletedEndReason>;
  endTime?: Maybe<OptionalDateTimeInput>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAllDay?: Maybe<Scalars['Boolean']>;
  lanesClosureType?: Maybe<LanesClosureType>;
  location?: Maybe<Scalars['Geometry']>;
  media?: Maybe<Array<MediaFileInput>>;
  multiDirectionLanesAffected?: Maybe<Scalars['Boolean']>;
  occupancy?: Maybe<Scalars['Int']>;
  occupancyRange?: Maybe<OccupancyRange>;
  otherTypeDescription?: Maybe<Scalars['String']>;
  relatedEvents?: Maybe<Array<RelatedEventInputDtoInput>>;
  responsePlan?: Maybe<ResponsePlanInput>;
  schedule?: Maybe<OptionalTrafficDisruptionScheduleDtoInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<SpecialEventType>;
  venueAddress?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
};

export type UpdateTrafficDisruptionLaneInput = {
  direction?: Maybe<Direction>;
  id: Scalars['Int'];
  isAffected?: Maybe<Scalars['Boolean']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['Int']>;
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  positionIndex?: Maybe<Scalars['Int']>;
  roadType?: Maybe<RoadType>;
  type?: Maybe<LaneType>;
};

export type UpdateTrafficDisruptionLanesInput = {
  created: Array<CreateLaneInput>;
  modified: Array<UpdateTrafficDisruptionLaneInput>;
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
  removed: Array<Scalars['Int']>;
  trafficDisruptionId: Scalars['Int'];
};

export type UpdateTwitterPublicationChannelInput = {
  accessDetails: TwitterAccessDetailsInput;
  accountId: Scalars['Int'];
};

export type UpdateUnitLocationInput = {
  unitId: Scalars['Int'];
  unitLocation?: Maybe<UnitLocationInput>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  unitRelationType?: Maybe<UnitRelationType>;
  userId: Scalars['Int'];
};

export type UpdateWorkingHoursInput = {
  id: Scalars['Int'];
  isCustom: Scalars['Boolean'];
  isHideEnabled: Scalars['Boolean'];
  workingHours: Array<CreateOrUpdateWorkingHourItemInput>;
};

export type User = {
  __typename?: 'User';
  account: Account;
  creationTime: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  phoneNumber: Scalars['String'];
  status: Status;
  unit?: Maybe<UnitVehicle>;
  unitRelationType: UnitRelationType;
};

export type UserAndTimeInput = {
  timePeriod: DateIntervalInput;
  userId: Scalars['Int'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  name: Scalars['String'];
  unitRelationType: UnitRelationType;
  userId: Scalars['Int'];
};

export type UserFeedBackInput = {
  externalId?: Maybe<Scalars['String']>;
  feedback: Scalars['Boolean'];
  feedbackType: FeedbackType;
  incidentId: Scalars['Int'];
};

export type UserRolesDto = {
  __typename?: 'UserRolesDTO';
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  userId: Scalars['Int'];
  userName: Scalars['String'];
};

export enum VehicleBrand {
  Acura = 'ACURA',
  AlfaRomeo = 'ALFA_ROMEO',
  AstonMartin = 'ASTON_MARTIN',
  Audi = 'AUDI',
  Austin = 'AUSTIN',
  Autobianchi = 'AUTOBIANCHI',
  Autocar = 'AUTOCAR',
  Avanti = 'AVANTI',
  Baja = 'BAJA',
  BbBuggies = 'BB_BUGGIES',
  Beal = 'BEAL',
  Bentley = 'BENTLEY',
  BlueBird = 'BLUE_BIRD',
  Bmw = 'BMW',
  Brinks = 'BRINKS',
  Bugatti = 'BUGATTI',
  Buick = 'BUICK',
  Cadillac = 'CADILLAC',
  Camper = 'CAMPER',
  CanAm = 'CAN_AM',
  Chevrolet = 'CHEVROLET',
  Chrysler = 'CHRYSLER',
  Citroen = 'CITROEN',
  Coachmen = 'COACHMEN',
  Dacia = 'DACIA',
  Daehan = 'DAEHAN',
  Daewoo = 'DAEWOO',
  Daihatsu = 'DAIHATSU',
  Datsun = 'DATSUN',
  DesotoMotors = 'DESOTO_MOTORS',
  Dodge = 'DODGE',
  Eagle = 'EAGLE',
  Ferrari = 'FERRARI',
  Fiat = 'FIAT',
  Ford = 'FORD',
  Freightliner = 'FREIGHTLINER',
  Fuso = 'FUSO',
  Genesis = 'GENESIS',
  Geo = 'GEO',
  Gm = 'GM',
  Gmc = 'GMC',
  GmnaOpelDaewoo = 'GMNA_OPEL_DAEWOO',
  HarleyDavidson = 'HARLEY_DAVIDSON',
  Hino = 'HINO',
  Honda = 'HONDA',
  Hummer = 'HUMMER',
  Hyosung = 'HYOSUNG',
  Hyundai = 'HYUNDAI',
  Indian = 'INDIAN',
  Infiniti = 'INFINITI',
  International = 'INTERNATIONAL',
  Isuzu = 'ISUZU',
  Jaguar = 'JAGUAR',
  Jeep = 'JEEP',
  Karma = 'KARMA',
  Kawasaki = 'KAWASAKI',
  Kenworth = 'KENWORTH',
  Kia = 'KIA',
  KoenigseggAutomotive = 'KOENIGSEGG_AUTOMOTIVE',
  Lada = 'LADA',
  Lamborghini = 'LAMBORGHINI',
  Lancia = 'LANCIA',
  LandRover = 'LAND_ROVER',
  Lexus = 'LEXUS',
  Lincoln = 'LINCOLN',
  Lotus = 'LOTUS',
  Mack = 'MACK',
  Mak = 'MAK',
  Maserati = 'MASERATI',
  Mazda = 'MAZDA',
  Mclaren = 'MCLAREN',
  MercedesBenz = 'MERCEDES_BENZ',
  Mercury = 'MERCURY',
  Mg = 'MG',
  Mini = 'MINI',
  Mitsubishi = 'MITSUBISHI',
  Nissan = 'NISSAN',
  Oldsmobile = 'OLDSMOBILE',
  Opel = 'OPEL',
  Other = 'OTHER',
  Peterbilt = 'PETERBILT',
  Peugeot = 'PEUGEOT',
  Plymouth = 'PLYMOUTH',
  Pontiac = 'PONTIAC',
  Porsche = 'PORSCHE',
  Ram = 'RAM',
  Renault = 'RENAULT',
  RollsRoyce = 'ROLLS_ROYCE',
  Rover = 'ROVER',
  Saab = 'SAAB',
  Saturn = 'SATURN',
  Scion = 'SCION',
  Seat = 'SEAT',
  Skoda = 'SKODA',
  Smart = 'SMART',
  Ssangyong = 'SSANGYONG',
  Starcraft = 'STARCRAFT',
  Sterling = 'STERLING',
  Subaru = 'SUBARU',
  Suzuki = 'SUZUKI',
  Tesla = 'TESLA',
  Toyota = 'TOYOTA',
  UnknownVehicleBrand = 'UNKNOWN_VEHICLE_BRAND',
  Utility = 'UTILITY',
  Volkswagen = 'VOLKSWAGEN',
  Volvo = 'VOLVO',
  WesternStar = 'WESTERN_STAR',
  Yamaha = 'YAMAHA',
  Yugo = 'YUGO'
}

export enum VehicleCategory {
  BackhoeLoader = 'BACKHOE_LOADER',
  Bicycle = 'BICYCLE',
  BoxTruckUtility = 'BOX_TRUCK_UTILITY',
  Bus = 'BUS',
  Car = 'CAR',
  Electric = 'ELECTRIC',
  Hybrid = 'HYBRID',
  Limousine = 'LIMOUSINE',
  Minibus = 'MINIBUS',
  MobilityScooter = 'MOBILITY_SCOOTER',
  Motorcycle = 'MOTORCYCLE',
  Motorhome = 'MOTORHOME',
  SchoolBus = 'SCHOOL_BUS',
  Scooter = 'SCOOTER',
  Semi = 'SEMI',
  Suv = 'SUV',
  Tractor = 'TRACTOR',
  Trailer = 'TRAILER',
  Truck = 'TRUCK',
  UnknownVehicleCategory = 'UNKNOWN_VEHICLE_CATEGORY',
  Van = 'VAN'
}

export type VehicleField = {
  __typename?: 'VehicleField';
  vehicle: IncidentInvolvedVehicle;
};

export enum VehicleType {
  CityPoliceUnit = 'CITY_POLICE_UNIT',
  ConstructionUnit = 'CONSTRUCTION_UNIT',
  EmsUnit = 'EMS_UNIT',
  FireDepartmentUnit = 'FIRE_DEPARTMENT_UNIT',
  FspUnit = 'FSP_UNIT',
  HighwayPoliceUnit = 'HIGHWAY_POLICE_UNIT',
  MaintenanceUnit = 'MAINTENANCE_UNIT',
  OtherUnit = 'OTHER_UNIT',
  SnowPlowUnit = 'SNOW_PLOW_UNIT',
  StreetSweepUnit = 'STREET_SWEEP_UNIT',
  TransitDemandResponseUnit = 'TRANSIT_DEMAND_RESPONSE_UNIT',
  TransitOnDemandUnit = 'TRANSIT_ON_DEMAND_UNIT'
}

export type Venue = {
  __typename?: 'Venue';
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type VenueDetails = {
  __typename?: 'VenueDetails';
  address: Scalars['String'];
  location: Scalars['Geometry'];
  name: Scalars['String'];
  type: VenueType;
};

export type VenueField = {
  __typename?: 'VenueField';
  venueAddress: Scalars['String'];
  venueName: Scalars['String'];
};

export enum VenueType {
  Casino = 'CASINO',
  Dance = 'DANCE',
  EventsVenue = 'EVENTS_VENUE',
  Nightclub = 'NIGHTCLUB',
  Park = 'PARK',
  Stadium = 'STADIUM',
  SwimmingPool = 'SWIMMING_POOL',
  Theatre = 'THEATRE'
}

export type View = {
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  layerType: LayerType;
  location: Scalars['Geometry'];
  workspaces: Array<Scalars['Int']>;
};

export type WeatherAlertAffectingTransitAdditionalInfo = {
  __typename?: 'WeatherAlertAffectingTransitAdditionalInfo';
  severity?: Maybe<WeatherAlertSeverity>;
  type: Scalars['String'];
  weatherAlertSubtype: Scalars['String'];
  weatherAlertType: WeatherAlertType;
};

export enum WeatherAlertCertainty {
  Likely = 'LIKELY',
  Possible = 'POSSIBLE',
  UnknownWeatherAlertCertainty = 'UNKNOWN_WEATHER_ALERT_CERTAINTY',
  Unlikely = 'UNLIKELY'
}

export enum WeatherAlertSeverity {
  Extreme = 'EXTREME',
  Minor = 'MINOR',
  Moderate = 'MODERATE',
  Severe = 'SEVERE',
  UnknownWeatherAlertSeverity = 'UNKNOWN_WEATHER_ALERT_SEVERITY'
}

export type WeatherAlertSupportedType = {
  __typename?: 'WeatherAlertSupportedType';
  subTypes: Array<Scalars['String']>;
  type: WeatherAlertType;
};

export enum WeatherAlertType {
  Air = 'AIR',
  Fire = 'FIRE',
  Floods = 'FLOODS',
  Fog = 'FOG',
  Marine = 'MARINE',
  SpecificRoadConditions = 'SPECIFIC_ROAD_CONDITIONS',
  Temperature = 'TEMPERATURE',
  Thunderstorms = 'THUNDERSTORMS',
  Tornado = 'TORNADO',
  Tropical = 'TROPICAL',
  Wind = 'WIND',
  Winter = 'WINTER'
}

export enum WeatherAlertUrgency {
  Expected = 'EXPECTED',
  Future = 'FUTURE',
  Immediate = 'IMMEDIATE',
  UnknownWeatherAlertUrgency = 'UNKNOWN_WEATHER_ALERT_URGENCY'
}

export type WeatherAlertView = View & {
  __typename?: 'WeatherAlertView';
  address: Address;
  certainty?: Maybe<WeatherAlertCertainty>;
  createdAt: Scalars['String'];
  endTime?: Maybe<Scalars['String']>;
  groupKey: Scalars['String'];
  id: Scalars['Int'];
  layerType: LayerType;
  location: Scalars['Geometry'];
  severity?: Maybe<WeatherAlertSeverity>;
  startTime?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  urgency?: Maybe<WeatherAlertUrgency>;
  workspaces: Array<Scalars['Int']>;
};

export type WorkingHoursDto = {
  __typename?: 'WorkingHoursDto';
  isCustom: Scalars['Boolean'];
  isHideEnabled: Scalars['Boolean'];
  workingHoursId: Scalars['Int'];
  workingHoursItems: Array<WorkingHoursItemDto>;
};

export type WorkingHoursItemDto = {
  __typename?: 'WorkingHoursItemDto';
  dailyRanges: Array<DailyRange>;
  dayOfWeek: Scalars['Int'];
};

export type Workspace = {
  __typename?: 'Workspace';
  accountId?: Maybe<Scalars['Int']>;
  area: Scalars['MultiPolygon'];
  featureTypes: Array<FeatureType>;
  id: Scalars['Int'];
  roadTypes: WorkspaceRoadTypes;
  title: Scalars['String'];
};

export enum WorkspaceRoadTypes {
  All = 'ALL',
  Arterial = 'ARTERIAL',
  Highway = 'HIGHWAY'
}

export type WorkzoneDataExchangeDto = {
  __typename?: 'WorkzoneDataExchangeDTO';
  bbox?: Maybe<Array<Scalars['Float']>>;
  beginningCrossStreet?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  endingCrossStreet?: Maybe<Scalars['String']>;
  isEndDateVerified: Scalars['Boolean'];
  isEndPositionVerified: Scalars['Boolean'];
  isStartDateVerified: Scalars['Boolean'];
  isStartPositionVerified: Scalars['Boolean'];
  locationMethod?: Maybe<Scalars['String']>;
  roadNames?: Maybe<Array<Scalars['String']>>;
  vehicleImpact?: Maybe<Scalars['String']>;
};

export type AddHistoricMitigationsToIncidentMutationVariables = Exact<{
  input: CreateShiftIncidentMitigationInput;
}>;


export type AddHistoricMitigationsToIncidentMutation = (
  { __typename?: 'Mutation' }
  & { addHistoricMitigationsToIncident: Array<(
    { __typename?: 'IncidentError' }
    & Pick<IncidentError, 'errMsg' | 'errorCode' | 'incidentId'>
  )> }
);

export type AssignUnitToUserMutationVariables = Exact<{
  input: SetUserUnitInput;
}>;


export type AssignUnitToUserMutation = (
  { __typename?: 'Mutation' }
  & { assignUnitToUser: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  ) }
);

export type CompleteConstructionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteConstructionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeConstruction'>
);

export type CompleteIncidentMutationVariables = Exact<{
  input: EndIncidentInput;
}>;


export type CompleteIncidentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeIncident'>
);

export type CompleteRoadClosureMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteRoadClosureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeRoadClosure'>
);

export type CompleteSpecialEventMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteSpecialEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeSpecialEvent'>
);

export type ConfirmIncidentMutationVariables = Exact<{
  id: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
}>;


export type ConfirmIncidentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmIncident'>
);

export type CreateAdditionalInfoMutationVariables = Exact<{
  input: CreateAdditionalInfoInput;
}>;


export type CreateAdditionalInfoMutation = (
  { __typename?: 'Mutation' }
  & { createAdditionalInfo: (
    { __typename?: 'AdditionalInfo' }
    & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
  ) }
);

export type CreateConstructionMutationVariables = Exact<{
  input: CreateConstructionInput;
}>;


export type CreateConstructionMutation = (
  { __typename?: 'Mutation' }
  & { createConstruction: (
    { __typename?: 'Construction' }
    & Pick<Construction, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'otherTypeDescription' | 'startTime' | 'status' | 'title' | 'type' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, dmses: Array<(
      { __typename?: 'AssociatedDMS' }
      & Pick<AssociatedDms, 'positionIndex'>
      & { dms: (
        { __typename?: 'DMS' }
        & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, permit?: Maybe<(
      { __typename?: 'Permit' }
      & Pick<Permit, 'permitId' | 'url'>
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  ) }
);

export type CreateDetailedShiftMutationVariables = Exact<{
  input: CreateDetailedShiftInput;
}>;


export type CreateDetailedShiftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDetailedShift'>
);

export type CreateIncidentMutationVariables = Exact<{
  input: CreateIncidentMutationInput;
}>;


export type CreateIncidentMutation = (
  { __typename?: 'Mutation' }
  & { createIncident: (
    { __typename?: 'Incident' }
    & Pick<Incident, 'allLanesAffected' | 'atmsId' | 'attributes' | 'autoPublish' | 'cadId' | 'completedBy' | 'confirmedAt' | 'confirmedBy' | 'createdAt' | 'createdBy' | 'createdByUserName' | 'creationSource' | 'endReason' | 'endReasonComment' | 'endedAt' | 'estimatedEndTime' | 'externalId' | 'fatalities' | 'feedSource' | 'hazardRemains' | 'id' | 'injuries' | 'injurySeverities' | 'involvedVehiclesCount' | 'isAddressEstimated' | 'isConfirmed' | 'isRejected' | 'isUnconfirmed' | 'lanesStatus' | 'location' | 'multiDirectionLanesAffected' | 'nearCameras' | 'restoreAt' | 'severity' | 'startedAt' | 'status' | 'subType' | 'title' | 'trafficDisturbance' | 'type' | 'typeDescription' | 'updatedAt' | 'updatedBy'>
    & { additionalInfos: Array<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
    )>, address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ), affectedLanes: Array<(
      { __typename?: 'IncidentLane' }
      & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
    )>, associatedUnits: Array<(
      { __typename?: 'IncidentUnit' }
      & Pick<IncidentUnit, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type' | 'unitResponse' | 'updatedAt'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, driverDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, camerasInternalDetails: Array<(
      { __typename?: 'CameraInternal' }
      & Pick<CameraInternal, 'default' | 'externalId' | 'positionIndex'>
    )>, insights?: Maybe<(
      { __typename?: 'IncidentInsights' }
      & Pick<IncidentInsights, 'persistenceType'>
      & { impact?: Maybe<(
        { __typename?: 'IncidentImpact' }
        & Pick<IncidentImpact, 'externalId' | 'incidentId' | 'level' | 'updatedAt' | 'usersFeedback'>
        & { corridors: Array<(
          { __typename?: 'IncidentImpactedCorridor' }
          & Pick<IncidentImpactedCorridor, 'currentSpd' | 'expectedSpd' | 'name'>
          & { impactedSegments: Array<(
            { __typename?: 'IncidentImpactedSegment' }
            & Pick<IncidentImpactedSegment, 'currentSpd' | 'expectedSpd' | 'forecastDecrease' | 'level' | 'linestring' | 'segmentId' | 'segmentName'>
          )> }
        )> }
      )>, priorityScore?: Maybe<(
        { __typename?: 'IncidentPriorityScore' }
        & Pick<IncidentPriorityScore, 'heavyInfluencedBy' | 'level'>
      )> }
    )>, involvedVehicles: Array<(
      { __typename?: 'IncidentInvolvedVehicle' }
      & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
    )>, media: Array<(
      { __typename?: 'IncidentMedia' }
      & Pick<IncidentMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>, mergeDetails?: Maybe<(
      { __typename?: 'MergeDetails' }
      & Pick<MergeDetails, 'isBlocked' | 'targetId'>
    )>, mitigations: Array<(
      { __typename?: 'IncidentMitigation' }
      & Pick<IncidentMitigation, 'id' | 'unitDisplayId' | 'unitId' | 'unitType' | 'userId'>
      & { interval: (
        { __typename?: 'DateInterval' }
        & Pick<DateInterval, 'from' | 'to'>
      ), mitigationType: (
        { __typename?: 'MitigationType' }
        & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
      ) }
    )>, notes: Array<(
      { __typename?: 'IncidentNote' }
      & Pick<IncidentNote, 'createdAt' | 'id' | 'note' | 'updatedAt' | 'updatedBy' | 'updatedByUsername'>
    )>, reportSources: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )>, responsePlan?: Maybe<(
      { __typename?: 'ResponsePlan' }
      & Pick<ResponsePlan, 'entityId' | 'entityType' | 'id' | 'isDeleted' | 'templateId' | 'title' | 'updatedAt'>
      & { actions: Array<(
        { __typename?: 'ResponsePlanAction' }
        & Pick<ResponsePlanAction, 'actionField' | 'index' | 'isDone' | 'responsePlanActionId' | 'responsePlanId' | 'text' | 'time' | 'unit' | 'value'>
      )> }
    )>, userDetails?: Maybe<(
      { __typename?: 'IncidentUserNamesDetails' }
      & Pick<IncidentUserNamesDetails, 'completedBy' | 'confirmedBy' | 'updatedBy'>
    )> }
  ) }
);

export type CreateMitigationTypeMutationVariables = Exact<{
  input: CreateMitigationTypeInput;
}>;


export type CreateMitigationTypeMutation = (
  { __typename?: 'Mutation' }
  & { createMitigationType: (
    { __typename?: 'MitigationType' }
    & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
  ) }
);

export type CreateReportSourceMutationVariables = Exact<{
  input: CreateReportSourceInput;
}>;


export type CreateReportSourceMutation = (
  { __typename?: 'Mutation' }
  & { createReportSource: (
    { __typename?: 'ReportSource' }
    & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
  ) }
);

export type CreateResponsePlanTemplateMutationVariables = Exact<{
  input: CreateResponsePlanTemplateInput;
}>;


export type CreateResponsePlanTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createResponsePlanTemplate: (
    { __typename?: 'ResponsePlanTemplate' }
    & Pick<ResponsePlanTemplate, 'accountId' | 'entityType' | 'id' | 'title' | 'updatedAt'>
    & { actions: Array<(
      { __typename?: 'TemplateAction' }
      & Pick<TemplateAction, 'actionField' | 'index' | 'templateActionId' | 'templateId' | 'text' | 'time' | 'unit' | 'value'>
    )> }
  ) }
);

export type CreateRoadClosureMutationVariables = Exact<{
  input: CreateRoadClosureInput;
}>;


export type CreateRoadClosureMutation = (
  { __typename?: 'Mutation' }
  & { createRoadClosure: (
    { __typename?: 'RoadClosure' }
    & Pick<RoadClosure, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'startTime' | 'status' | 'title' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, dmses: Array<(
      { __typename?: 'AssociatedDMS' }
      & Pick<AssociatedDms, 'positionIndex'>
      & { dms: (
        { __typename?: 'DMS' }
        & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, permit?: Maybe<(
      { __typename?: 'Permit' }
      & Pick<Permit, 'permitId' | 'url'>
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  ) }
);

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole: (
    { __typename?: 'Role' }
    & Pick<Role, 'accountId' | 'description' | 'id' | 'name'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )> }
  ) }
);

export type CreateRouteTypeMutationVariables = Exact<{
  input: CreateRouteTypeInput;
}>;


export type CreateRouteTypeMutation = (
  { __typename?: 'Mutation' }
  & { createRouteType: (
    { __typename?: 'RouteType' }
    & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
  ) }
);

export type CreateShiftMutationVariables = Exact<{
  input: CreateShiftInput;
}>;


export type CreateShiftMutation = (
  { __typename?: 'Mutation' }
  & { createShift: (
    { __typename?: 'ShiftDTO' }
    & Pick<ShiftDto, 'endMileage' | 'endReason' | 'endReasonNote' | 'endedAt' | 'isDeleted' | 'overtimeNote' | 'shiftId' | 'startMileage' | 'startedAt'>
    & { routeTypes: Array<(
      { __typename?: 'RouteType' }
      & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
    )>, shiftPauses: Array<(
      { __typename?: 'ShiftPauseDTO' }
      & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
    )>, shiftType?: Maybe<(
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
        & { additionalInfos: Array<(
          { __typename?: 'AdditionalInfo' }
          & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
        )>, analyticsReportsUrls?: Maybe<(
          { __typename?: 'AnalyticsReportsUrls' }
          & { fixedRouteTransitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, fspReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, incidentAnalysisReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, mitigationsReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, speedMeasurementReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, timPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, transitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, travelReliabilityReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, unitsPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>> }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
        ), groups: Array<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'id' | 'level' | 'type'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'id'>
            & { dependsOnAll?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, dependsOnAny?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, scope: (
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            ) }
          )> }
        )>, mitigationTypes: Array<(
          { __typename?: 'MitigationType' }
          & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
        )>, publicationTemplates: Array<(
          { __typename?: 'PublicationTemplate' }
          & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
        )>, publicationVariables: (
          { __typename?: 'AccountPublicationVariables' }
          & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
        ), regionalSetting: (
          { __typename?: 'RegionalSetting' }
          & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
        ), reportSources: Array<(
          { __typename?: 'ReportSource' }
          & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
        )>, routeTypes: Array<(
          { __typename?: 'RouteType' }
          & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
        )>, shiftTypes: Array<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
        )>, workspaces: (
          { __typename?: 'AccountWorkspaces' }
          & Pick<AccountWorkspaces, 'singleMultiPolygon'>
          & { workspaces: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
          )> }
        ) }
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      )> }
    ) }
  ) }
);

export type CreateShiftTypeMutationVariables = Exact<{
  input: CreateShiftTypeInput;
}>;


export type CreateShiftTypeMutation = (
  { __typename?: 'Mutation' }
  & { createShiftType: (
    { __typename?: 'ShiftType' }
    & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
  ) }
);

export type CreateSpecialEventMutationVariables = Exact<{
  input: CreateSpecialEventInput;
}>;


export type CreateSpecialEventMutation = (
  { __typename?: 'Mutation' }
  & { createSpecialEvent: (
    { __typename?: 'SpecialEvent' }
    & Pick<SpecialEvent, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'occupancy' | 'occupancyRange' | 'otherTypeDescription' | 'startTime' | 'status' | 'title' | 'type' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, responsePlan?: Maybe<(
      { __typename?: 'ResponsePlan' }
      & Pick<ResponsePlan, 'entityId' | 'entityType' | 'id' | 'isDeleted' | 'templateId' | 'title' | 'updatedAt'>
      & { actions: Array<(
        { __typename?: 'ResponsePlanAction' }
        & Pick<ResponsePlanAction, 'actionField' | 'index' | 'isDone' | 'responsePlanActionId' | 'responsePlanId' | 'text' | 'time' | 'unit' | 'value'>
      )> }
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, venue?: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'address' | 'name'>
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  ) }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
      & { additionalInfos: Array<(
        { __typename?: 'AdditionalInfo' }
        & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
      )>, analyticsReportsUrls?: Maybe<(
        { __typename?: 'AnalyticsReportsUrls' }
        & { fixedRouteTransitReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, fspReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, incidentAnalysisReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, mitigationsReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, speedMeasurementReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, timPerformanceReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, transitReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, travelReliabilityReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, unitsPerformanceReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>> }
      )>, customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
      ), groups: Array<(
        { __typename?: 'PermissionGroup' }
        & Pick<PermissionGroup, 'id' | 'level' | 'type'>
        & { permissions: Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id'>
          & { dependsOnAll?: Maybe<Array<(
            { __typename?: 'Scope' }
            & Pick<Scope, 'entity' | 'modifier'>
          )>>, dependsOnAny?: Maybe<Array<(
            { __typename?: 'Scope' }
            & Pick<Scope, 'entity' | 'modifier'>
          )>>, scope: (
            { __typename?: 'Scope' }
            & Pick<Scope, 'entity' | 'modifier'>
          ) }
        )> }
      )>, mitigationTypes: Array<(
        { __typename?: 'MitigationType' }
        & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
      )>, publicationTemplates: Array<(
        { __typename?: 'PublicationTemplate' }
        & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
      )>, publicationVariables: (
        { __typename?: 'AccountPublicationVariables' }
        & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
      ), regionalSetting: (
        { __typename?: 'RegionalSetting' }
        & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
      ), reportSources: Array<(
        { __typename?: 'ReportSource' }
        & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
      )>, routeTypes: Array<(
        { __typename?: 'RouteType' }
        & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
      )>, shiftTypes: Array<(
        { __typename?: 'ShiftType' }
        & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
      )>, workspaces: (
        { __typename?: 'AccountWorkspaces' }
        & Pick<AccountWorkspaces, 'singleMultiPolygon'>
        & { workspaces: Array<(
          { __typename?: 'Workspace' }
          & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
        )> }
      ) }
    ), permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  ) }
);

export type DeleteAccountPublishDetailsMutationVariables = Exact<{
  input: DeleteAccountPublicationChannelInput;
}>;


export type DeleteAccountPublishDetailsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAccountPublishDetails'>
);

export type DeleteAdditionalInfoMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAdditionalInfoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAdditionalInfo'>
);

export type DeleteConstructionsNonProdMutationVariables = Exact<{
  input: DeleteTrafficDisruptionsInput;
}>;


export type DeleteConstructionsNonProdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConstructionsNonProd'>
);

export type DeleteIncidentMediaMutationVariables = Exact<{
  input: DeleteIncidentMediaInput;
}>;


export type DeleteIncidentMediaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteIncidentMedia'>
);

export type DeleteIncidentsNonProdMutationVariables = Exact<{
  input: DeleteIncidentsInput;
}>;


export type DeleteIncidentsNonProdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteIncidentsNonProd'>
);

export type DeleteMitigationTypeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMitigationTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMitigationType'>
);

export type DeleteReportSourceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteReportSourceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteReportSource'>
);

export type DeleteResponsePlanTemplateMutationVariables = Exact<{
  templateId: Scalars['Int'];
}>;


export type DeleteResponsePlanTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteResponsePlanTemplate'>
);

export type DeleteRoadClosuresNonProdMutationVariables = Exact<{
  input: DeleteTrafficDisruptionsInput;
}>;


export type DeleteRoadClosuresNonProdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRoadClosuresNonProd'>
);

export type DeleteRoleMutationVariables = Exact<{
  input: DeleteRoleInput;
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRole'>
);

export type DeleteRouteTypeMutationVariables = Exact<{
  input: DeleteRouteTypeInput;
}>;


export type DeleteRouteTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteRouteType: (
    { __typename?: 'RouteType' }
    & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
  ) }
);

export type DeleteShiftMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteShiftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShift'>
);

export type DeleteShiftTypeMutationVariables = Exact<{
  input: DeleteShiftTypeInput;
}>;


export type DeleteShiftTypeMutation = (
  { __typename?: 'Mutation' }
  & { deleteShiftType: (
    { __typename?: 'ShiftType' }
    & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
  ) }
);

export type DeleteSpecialEventsNonProdMutationVariables = Exact<{
  input: DeleteTrafficDisruptionsInput;
}>;


export type DeleteSpecialEventsNonProdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSpecialEventsNonProd'>
);

export type DeleteTrafficDisruptionMediaMutationVariables = Exact<{
  input: DeleteTrafficDisruptionMediaInput;
}>;


export type DeleteTrafficDisruptionMediaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTrafficDisruptionMedia'>
);

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type DisassociateUnitFromIncidentMutationVariables = Exact<{
  input: DeleteIncidentUnitInput;
}>;


export type DisassociateUnitFromIncidentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disassociateUnitFromIncident'>
);

export type EndShiftMutationVariables = Exact<{
  input: EndShiftInput;
}>;


export type EndShiftMutation = (
  { __typename?: 'Mutation' }
  & { endShift: (
    { __typename?: 'ShiftDTO' }
    & Pick<ShiftDto, 'endMileage' | 'endReason' | 'endReasonNote' | 'endedAt' | 'isDeleted' | 'overtimeNote' | 'shiftId' | 'startMileage' | 'startedAt'>
    & { routeTypes: Array<(
      { __typename?: 'RouteType' }
      & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
    )>, shiftPauses: Array<(
      { __typename?: 'ShiftPauseDTO' }
      & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
    )>, shiftType?: Maybe<(
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
        & { additionalInfos: Array<(
          { __typename?: 'AdditionalInfo' }
          & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
        )>, analyticsReportsUrls?: Maybe<(
          { __typename?: 'AnalyticsReportsUrls' }
          & { fixedRouteTransitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, fspReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, incidentAnalysisReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, mitigationsReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, speedMeasurementReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, timPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, transitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, travelReliabilityReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, unitsPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>> }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
        ), groups: Array<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'id' | 'level' | 'type'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'id'>
            & { dependsOnAll?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, dependsOnAny?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, scope: (
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            ) }
          )> }
        )>, mitigationTypes: Array<(
          { __typename?: 'MitigationType' }
          & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
        )>, publicationTemplates: Array<(
          { __typename?: 'PublicationTemplate' }
          & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
        )>, publicationVariables: (
          { __typename?: 'AccountPublicationVariables' }
          & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
        ), regionalSetting: (
          { __typename?: 'RegionalSetting' }
          & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
        ), reportSources: Array<(
          { __typename?: 'ReportSource' }
          & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
        )>, routeTypes: Array<(
          { __typename?: 'RouteType' }
          & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
        )>, shiftTypes: Array<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
        )>, workspaces: (
          { __typename?: 'AccountWorkspaces' }
          & Pick<AccountWorkspaces, 'singleMultiPolygon'>
          & { workspaces: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
          )> }
        ) }
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      )> }
    ) }
  ) }
);

export type EndShiftPauseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EndShiftPauseMutation = (
  { __typename?: 'Mutation' }
  & { endShiftPause: (
    { __typename?: 'ShiftPauseDTO' }
    & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
  ) }
);

export type LogMediaAccessMutationVariables = Exact<{
  input: CreateMediaAccessLogInput;
}>;


export type LogMediaAccessMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logMediaAccess'>
);

export type LogMediaAccessedMutationVariables = Exact<{
  input: CreateMediaAccessedLogInput;
}>;


export type LogMediaAccessedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logMediaAccessed'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type PublishConstructionMutationVariables = Exact<{
  input: PublishInput;
}>;


export type PublishConstructionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publishConstruction'>
);

export type PublishIncidentMutationVariables = Exact<{
  input: PublishInput;
}>;


export type PublishIncidentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publishIncident'>
);

export type PublishRoadClosureMutationVariables = Exact<{
  input: PublishInput;
}>;


export type PublishRoadClosureMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publishRoadClosure'>
);

export type PublishSpecialEventMutationVariables = Exact<{
  input: PublishInput;
}>;


export type PublishSpecialEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'publishSpecialEvent'>
);

export type RejectIncidentMutationVariables = Exact<{
  input: EndIncidentInput;
}>;


export type RejectIncidentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectIncident'>
);

export type RenewIncidentMediaUrlMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RenewIncidentMediaUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'renewIncidentMediaUrl'>
);

export type RenewTrafficDisruptionMediaUrlMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RenewTrafficDisruptionMediaUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'renewTrafficDisruptionMediaUrl'>
);

export type RerouteFixedBusRouteMutationVariables = Exact<{
  id: Scalars['Int'];
  reroute: Scalars['Boolean'];
}>;


export type RerouteFixedBusRouteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rerouteFixedBusRoute'>
);

export type SetFeedbackMutationVariables = Exact<{
  userFeedBackInput: UserFeedBackInput;
}>;


export type SetFeedbackMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setFeedback'>
);

export type SetRolePermissionsMutationVariables = Exact<{
  input: SetRolePermissionsInput;
}>;


export type SetRolePermissionsMutation = (
  { __typename?: 'Mutation' }
  & { setRolePermissions: (
    { __typename?: 'Role' }
    & Pick<Role, 'accountId' | 'description' | 'id' | 'name'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )> }
  ) }
);

export type SetUserRolesMutationVariables = Exact<{
  input: SetUserRolesInput;
}>;


export type SetUserRolesMutation = (
  { __typename?: 'Mutation' }
  & { setUserRoles: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type'>
      & { userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  ) }
);

export type StartShiftPauseMutationVariables = Exact<{
  input: CreateShiftPauseInput;
}>;


export type StartShiftPauseMutation = (
  { __typename?: 'Mutation' }
  & { startShiftPause: (
    { __typename?: 'ShiftPauseDTO' }
    & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
  ) }
);

export type UnassignUnitToUserMutationVariables = Exact<{
  input: DeleteUserUnitInput;
}>;


export type UnassignUnitToUserMutation = (
  { __typename?: 'Mutation' }
  & { unassignUnitToUser: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type'>
      & { userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  ) }
);

export type UndoCompleteMutationVariables = Exact<{
  id: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
}>;


export type UndoCompleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'undoComplete'>
);

export type UndoRejectMutationVariables = Exact<{
  id: Scalars['Int'];
  offlineUpdatedAt?: Maybe<Scalars['DateTime']>;
}>;


export type UndoRejectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'undoReject'>
);

export type UpdateAccountPermitsMutationVariables = Exact<{
  input: Array<AccountPermitInput> | AccountPermitInput;
}>;


export type UpdateAccountPermitsMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountPermits: Array<(
    { __typename?: 'AccountPermit' }
    & Pick<AccountPermit, 'accountId' | 'id' | 'type' | 'url'>
  )> }
);

export type UpdateActionStatusMutationVariables = Exact<{
  actionId: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type UpdateActionStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActionStatus'>
);

export type UpdateAdditionalInfoMutationVariables = Exact<{
  input: UpdateAdditionalInfoInput;
}>;


export type UpdateAdditionalInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateAdditionalInfo: (
    { __typename?: 'AdditionalInfo' }
    & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
  ) }
);

export type UpdateConstructionMutationVariables = Exact<{
  input: UpdateConstructionInput;
}>;


export type UpdateConstructionMutation = (
  { __typename?: 'Mutation' }
  & { updateConstruction: (
    { __typename?: 'Construction' }
    & Pick<Construction, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'otherTypeDescription' | 'startTime' | 'status' | 'title' | 'type' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, dmses: Array<(
      { __typename?: 'AssociatedDMS' }
      & Pick<AssociatedDms, 'positionIndex'>
      & { dms: (
        { __typename?: 'DMS' }
        & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, permit?: Maybe<(
      { __typename?: 'Permit' }
      & Pick<Permit, 'permitId' | 'url'>
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  ) }
);

export type UpdateDetailedShiftMutationVariables = Exact<{
  input: UpdateDetailedShiftInput;
}>;


export type UpdateDetailedShiftMutation = (
  { __typename?: 'Mutation' }
  & { updateDetailedShift: (
    { __typename?: 'ShiftDTO' }
    & Pick<ShiftDto, 'endMileage' | 'endReason' | 'endReasonNote' | 'endedAt' | 'isDeleted' | 'overtimeNote' | 'shiftId' | 'startMileage' | 'startedAt'>
    & { routeTypes: Array<(
      { __typename?: 'RouteType' }
      & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
    )>, shiftPauses: Array<(
      { __typename?: 'ShiftPauseDTO' }
      & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
    )>, shiftType?: Maybe<(
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
        & { additionalInfos: Array<(
          { __typename?: 'AdditionalInfo' }
          & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
        )>, analyticsReportsUrls?: Maybe<(
          { __typename?: 'AnalyticsReportsUrls' }
          & { fixedRouteTransitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, fspReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, incidentAnalysisReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, mitigationsReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, speedMeasurementReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, timPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, transitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, travelReliabilityReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, unitsPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>> }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
        ), groups: Array<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'id' | 'level' | 'type'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'id'>
            & { dependsOnAll?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, dependsOnAny?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, scope: (
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            ) }
          )> }
        )>, mitigationTypes: Array<(
          { __typename?: 'MitigationType' }
          & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
        )>, publicationTemplates: Array<(
          { __typename?: 'PublicationTemplate' }
          & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
        )>, publicationVariables: (
          { __typename?: 'AccountPublicationVariables' }
          & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
        ), regionalSetting: (
          { __typename?: 'RegionalSetting' }
          & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
        ), reportSources: Array<(
          { __typename?: 'ReportSource' }
          & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
        )>, routeTypes: Array<(
          { __typename?: 'RouteType' }
          & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
        )>, shiftTypes: Array<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
        )>, workspaces: (
          { __typename?: 'AccountWorkspaces' }
          & Pick<AccountWorkspaces, 'singleMultiPolygon'>
          & { workspaces: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
          )> }
        ) }
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      )> }
    ) }
  ) }
);

export type UpdateGovDeliveryPublishDetailsMutationVariables = Exact<{
  input: UpdateGovDeliveryPublicationChannelInput;
}>;


export type UpdateGovDeliveryPublishDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateGovDeliveryPublishDetails: (
    { __typename?: 'PublicationChannel' }
    & Pick<PublicationChannel, 'accountId' | 'destination' | 'id'>
  ) }
);

export type UpdateIncidentConfigurationMutationVariables = Exact<{
  input: UpdateIncidentConfigurationInput;
}>;


export type UpdateIncidentConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateIncidentConfiguration: (
    { __typename?: 'IncidentConfiguration' }
    & Pick<IncidentConfiguration, 'additionalInfoMandatory' | 'affectedLanesMandatory' | 'associatedUnitsMandatory' | 'attributeFixedValues' | 'attributesMandatory' | 'casualtiesMandatory' | 'directionMandatory' | 'incidentTypeValues' | 'notesMandatory' | 'orientationMandatory' | 'orientationValues' | 'responsePlanMandatory' | 'roadTypeValues' | 'severityMandatory' | 'severityValues' | 'sourceMandatory'>
    & { additionalInfoValues: Array<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
    )>, attributeFreeTextValues: Array<(
      { __typename?: 'IncidentAttributeStringValue' }
      & Pick<IncidentAttributeStringValue, 'checked' | 'value'>
    )>, incidentSubTypes: (
      { __typename?: 'IncidentConfigurationSubTypes' }
      & Pick<IncidentConfigurationSubTypes, 'abandonedVehicleSubTypeValues' | 'crashSubTypeValues' | 'damageSubTypeValues' | 'debrisSubTypeValues' | 'hazardSubTypeValues' | 'policeActivitySubTypeValues' | 'stalledVehicleSubTypeValues' | 'trafficStopSubTypeValues'>
    ), mitigationTypeValues: Array<(
      { __typename?: 'MitigationType' }
      & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
    )>, sourceValues: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )> }
  ) }
);

export type UpdateIncidentMitigationsMutationVariables = Exact<{
  input: UpdateIncidentMitigationsInput;
}>;


export type UpdateIncidentMitigationsMutation = (
  { __typename?: 'Mutation' }
  & { updateIncidentMitigations: Array<(
    { __typename?: 'IncidentMitigation' }
    & Pick<IncidentMitigation, 'id' | 'unitDisplayId' | 'unitId' | 'unitType' | 'userId'>
    & { interval: (
      { __typename?: 'DateInterval' }
      & Pick<DateInterval, 'from' | 'to'>
    ), mitigationType: (
      { __typename?: 'MitigationType' }
      & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
    ) }
  )> }
);

export type UpdateIncidentOneTimeMutationVariables = Exact<{
  input: IncidentInput;
}>;


export type UpdateIncidentOneTimeMutation = (
  { __typename?: 'Mutation' }
  & { updateIncidentOneTime: (
    { __typename?: 'Incident' }
    & Pick<Incident, 'allLanesAffected' | 'atmsId' | 'attributes' | 'autoPublish' | 'cadId' | 'completedBy' | 'confirmedAt' | 'confirmedBy' | 'createdAt' | 'createdBy' | 'createdByUserName' | 'creationSource' | 'endReason' | 'endReasonComment' | 'endedAt' | 'estimatedEndTime' | 'externalId' | 'fatalities' | 'feedSource' | 'hazardRemains' | 'id' | 'injuries' | 'injurySeverities' | 'involvedVehiclesCount' | 'isAddressEstimated' | 'isConfirmed' | 'isRejected' | 'isUnconfirmed' | 'lanesStatus' | 'location' | 'multiDirectionLanesAffected' | 'nearCameras' | 'restoreAt' | 'severity' | 'startedAt' | 'status' | 'subType' | 'title' | 'trafficDisturbance' | 'type' | 'typeDescription' | 'updatedAt' | 'updatedBy'>
    & { additionalInfos: Array<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
    )>, address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ), affectedLanes: Array<(
      { __typename?: 'IncidentLane' }
      & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
    )>, associatedUnits: Array<(
      { __typename?: 'IncidentUnit' }
      & Pick<IncidentUnit, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type' | 'unitResponse' | 'updatedAt'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, driverDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, camerasInternalDetails: Array<(
      { __typename?: 'CameraInternal' }
      & Pick<CameraInternal, 'default' | 'externalId' | 'positionIndex'>
    )>, insights?: Maybe<(
      { __typename?: 'IncidentInsights' }
      & Pick<IncidentInsights, 'persistenceType'>
      & { impact?: Maybe<(
        { __typename?: 'IncidentImpact' }
        & Pick<IncidentImpact, 'externalId' | 'incidentId' | 'level' | 'updatedAt' | 'usersFeedback'>
        & { corridors: Array<(
          { __typename?: 'IncidentImpactedCorridor' }
          & Pick<IncidentImpactedCorridor, 'currentSpd' | 'expectedSpd' | 'name'>
          & { impactedSegments: Array<(
            { __typename?: 'IncidentImpactedSegment' }
            & Pick<IncidentImpactedSegment, 'currentSpd' | 'expectedSpd' | 'forecastDecrease' | 'level' | 'linestring' | 'segmentId' | 'segmentName'>
          )> }
        )> }
      )>, priorityScore?: Maybe<(
        { __typename?: 'IncidentPriorityScore' }
        & Pick<IncidentPriorityScore, 'heavyInfluencedBy' | 'level'>
      )> }
    )>, involvedVehicles: Array<(
      { __typename?: 'IncidentInvolvedVehicle' }
      & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
    )>, media: Array<(
      { __typename?: 'IncidentMedia' }
      & Pick<IncidentMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>, mergeDetails?: Maybe<(
      { __typename?: 'MergeDetails' }
      & Pick<MergeDetails, 'isBlocked' | 'targetId'>
    )>, mitigations: Array<(
      { __typename?: 'IncidentMitigation' }
      & Pick<IncidentMitigation, 'id' | 'unitDisplayId' | 'unitId' | 'unitType' | 'userId'>
      & { interval: (
        { __typename?: 'DateInterval' }
        & Pick<DateInterval, 'from' | 'to'>
      ), mitigationType: (
        { __typename?: 'MitigationType' }
        & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
      ) }
    )>, notes: Array<(
      { __typename?: 'IncidentNote' }
      & Pick<IncidentNote, 'createdAt' | 'id' | 'note' | 'updatedAt' | 'updatedBy' | 'updatedByUsername'>
    )>, reportSources: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )>, responsePlan?: Maybe<(
      { __typename?: 'ResponsePlan' }
      & Pick<ResponsePlan, 'entityId' | 'entityType' | 'id' | 'isDeleted' | 'templateId' | 'title' | 'updatedAt'>
      & { actions: Array<(
        { __typename?: 'ResponsePlanAction' }
        & Pick<ResponsePlanAction, 'actionField' | 'index' | 'isDone' | 'responsePlanActionId' | 'responsePlanId' | 'text' | 'time' | 'unit' | 'value'>
      )> }
    )>, userDetails?: Maybe<(
      { __typename?: 'IncidentUserNamesDetails' }
      & Pick<IncidentUserNamesDetails, 'completedBy' | 'confirmedBy' | 'updatedBy'>
    )> }
  ) }
);

export type UpdateIncidentTypePriorityMutationVariables = Exact<{
  input: Array<IncidentType> | IncidentType;
}>;


export type UpdateIncidentTypePriorityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateIncidentTypePriority'>
);

export type UpdateIncidentUnitResponseMutationVariables = Exact<{
  input: UpdateIncidentUnitInput;
}>;


export type UpdateIncidentUnitResponseMutation = (
  { __typename?: 'Mutation' }
  & { updateIncidentUnitResponse: (
    { __typename?: 'IncidentUnit' }
    & Pick<IncidentUnit, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type' | 'unitResponse' | 'updatedAt'>
    & { dashCameras: Array<(
      { __typename?: 'UnitDashCamera' }
      & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
    )>, driverDetails?: Maybe<(
      { __typename?: 'UserDetails' }
      & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
    )> }
  ) }
);

export type UpdateMitigationTypeMutationVariables = Exact<{
  input: UpdateMitigationTypeInput;
}>;


export type UpdateMitigationTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateMitigationType: (
    { __typename?: 'MitigationType' }
    & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
  ) }
);

export type UpdatePublicationMessageTemplateMutationVariables = Exact<{
  input: UpdatePublicationTemplateInput;
}>;


export type UpdatePublicationMessageTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicationMessageTemplate: (
    { __typename?: 'PublicationTemplate' }
    & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
  ) }
);

export type UpdateReportSourceMutationVariables = Exact<{
  input: UpdateReportSourceInput;
}>;


export type UpdateReportSourceMutation = (
  { __typename?: 'Mutation' }
  & { updateReportSource: (
    { __typename?: 'ReportSource' }
    & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
  ) }
);

export type UpdateResponsePlanTemplateMutationVariables = Exact<{
  input: UpdateResponsePlanTemplateInput;
}>;


export type UpdateResponsePlanTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateResponsePlanTemplate: (
    { __typename?: 'ResponsePlanTemplate' }
    & Pick<ResponsePlanTemplate, 'accountId' | 'entityType' | 'id' | 'title' | 'updatedAt'>
    & { actions: Array<(
      { __typename?: 'TemplateAction' }
      & Pick<TemplateAction, 'actionField' | 'index' | 'templateActionId' | 'templateId' | 'text' | 'time' | 'unit' | 'value'>
    )> }
  ) }
);

export type UpdateRoadClosureMutationVariables = Exact<{
  input: UpdateRoadClosureInput;
}>;


export type UpdateRoadClosureMutation = (
  { __typename?: 'Mutation' }
  & { updateRoadClosure: (
    { __typename?: 'RoadClosure' }
    & Pick<RoadClosure, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'startTime' | 'status' | 'title' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, dmses: Array<(
      { __typename?: 'AssociatedDMS' }
      & Pick<AssociatedDms, 'positionIndex'>
      & { dms: (
        { __typename?: 'DMS' }
        & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, permit?: Maybe<(
      { __typename?: 'Permit' }
      & Pick<Permit, 'permitId' | 'url'>
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  ) }
);

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole: (
    { __typename?: 'Role' }
    & Pick<Role, 'accountId' | 'description' | 'id' | 'name'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )> }
  ) }
);

export type UpdateRouteTypeMutationVariables = Exact<{
  input: UpdateRouteTypeInput;
}>;


export type UpdateRouteTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateRouteType: (
    { __typename?: 'RouteType' }
    & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
  ) }
);

export type UpdateShiftMutationVariables = Exact<{
  input: UpdateShiftInput;
}>;


export type UpdateShiftMutation = (
  { __typename?: 'Mutation' }
  & { updateShift: (
    { __typename?: 'ShiftDTO' }
    & Pick<ShiftDto, 'endMileage' | 'endReason' | 'endReasonNote' | 'endedAt' | 'isDeleted' | 'overtimeNote' | 'shiftId' | 'startMileage' | 'startedAt'>
    & { routeTypes: Array<(
      { __typename?: 'RouteType' }
      & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
    )>, shiftPauses: Array<(
      { __typename?: 'ShiftPauseDTO' }
      & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
    )>, shiftType?: Maybe<(
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
        & { additionalInfos: Array<(
          { __typename?: 'AdditionalInfo' }
          & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
        )>, analyticsReportsUrls?: Maybe<(
          { __typename?: 'AnalyticsReportsUrls' }
          & { fixedRouteTransitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, fspReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, incidentAnalysisReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, mitigationsReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, speedMeasurementReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, timPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, transitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, travelReliabilityReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, unitsPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>> }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
        ), groups: Array<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'id' | 'level' | 'type'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'id'>
            & { dependsOnAll?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, dependsOnAny?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, scope: (
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            ) }
          )> }
        )>, mitigationTypes: Array<(
          { __typename?: 'MitigationType' }
          & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
        )>, publicationTemplates: Array<(
          { __typename?: 'PublicationTemplate' }
          & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
        )>, publicationVariables: (
          { __typename?: 'AccountPublicationVariables' }
          & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
        ), regionalSetting: (
          { __typename?: 'RegionalSetting' }
          & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
        ), reportSources: Array<(
          { __typename?: 'ReportSource' }
          & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
        )>, routeTypes: Array<(
          { __typename?: 'RouteType' }
          & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
        )>, shiftTypes: Array<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
        )>, workspaces: (
          { __typename?: 'AccountWorkspaces' }
          & Pick<AccountWorkspaces, 'singleMultiPolygon'>
          & { workspaces: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
          )> }
        ) }
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      )> }
    ) }
  ) }
);

export type UpdateShiftTypeMutationVariables = Exact<{
  input: UpdateShiftTypeInput;
}>;


export type UpdateShiftTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateShiftType: (
    { __typename?: 'ShiftType' }
    & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
  ) }
);

export type UpdateSpecialEventMutationVariables = Exact<{
  input: UpdateSpecialEventInput;
}>;


export type UpdateSpecialEventMutation = (
  { __typename?: 'Mutation' }
  & { updateSpecialEvent: (
    { __typename?: 'SpecialEvent' }
    & Pick<SpecialEvent, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'occupancy' | 'occupancyRange' | 'otherTypeDescription' | 'startTime' | 'status' | 'title' | 'type' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, responsePlan?: Maybe<(
      { __typename?: 'ResponsePlan' }
      & Pick<ResponsePlan, 'entityId' | 'entityType' | 'id' | 'isDeleted' | 'templateId' | 'title' | 'updatedAt'>
      & { actions: Array<(
        { __typename?: 'ResponsePlanAction' }
        & Pick<ResponsePlanAction, 'actionField' | 'index' | 'isDone' | 'responsePlanActionId' | 'responsePlanId' | 'text' | 'time' | 'unit' | 'value'>
      )> }
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, venue?: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'address' | 'name'>
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  ) }
);

export type UpdateTrafficDisruptionLanesMutationVariables = Exact<{
  input: UpdateTrafficDisruptionLanesInput;
}>;


export type UpdateTrafficDisruptionLanesMutation = (
  { __typename?: 'Mutation' }
  & { updateTrafficDisruptionLanes: Array<(
    { __typename?: 'TrafficDisruptionLane' }
    & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
  )> }
);

export type UpdateTwitterPublicationDetailsMutationVariables = Exact<{
  input: UpdateTwitterPublicationChannelInput;
}>;


export type UpdateTwitterPublicationDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateTwitterPublicationDetails: (
    { __typename?: 'PublicationChannel' }
    & Pick<PublicationChannel, 'accountId' | 'destination' | 'id'>
  ) }
);

export type UpdateUnitLocationMutationVariables = Exact<{
  input: UpdateUnitLocationInput;
}>;


export type UpdateUnitLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUnitLocation'>
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type'>
      & { userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  ) }
);

export type UpdateWorkingHoursConfigurationMutationVariables = Exact<{
  input: UpdateWorkingHoursInput;
}>;


export type UpdateWorkingHoursConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkingHoursConfiguration: (
    { __typename?: 'WorkingHoursDto' }
    & Pick<WorkingHoursDto, 'isCustom' | 'isHideEnabled' | 'workingHoursId'>
    & { workingHoursItems: Array<(
      { __typename?: 'WorkingHoursItemDto' }
      & Pick<WorkingHoursItemDto, 'dayOfWeek'>
      & { dailyRanges: Array<(
        { __typename?: 'DailyRange' }
        & Pick<DailyRange, 'from' | 'to'>
      )> }
    )> }
  ) }
);

export type AccountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
    & { additionalInfos: Array<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
    )>, analyticsReportsUrls?: Maybe<(
      { __typename?: 'AnalyticsReportsUrls' }
      & { fixedRouteTransitReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>>, fspReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>>, incidentAnalysisReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>>, mitigationsReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>>, speedMeasurementReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>>, timPerformanceReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>>, transitReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>>, travelReliabilityReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>>, unitsPerformanceReport?: Maybe<Array<(
        { __typename?: 'AnalyticsReportSrc' }
        & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
      )>> }
    )>, customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
    ), groups: Array<(
      { __typename?: 'PermissionGroup' }
      & Pick<PermissionGroup, 'id' | 'level' | 'type'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
        & { dependsOnAll?: Maybe<Array<(
          { __typename?: 'Scope' }
          & Pick<Scope, 'entity' | 'modifier'>
        )>>, dependsOnAny?: Maybe<Array<(
          { __typename?: 'Scope' }
          & Pick<Scope, 'entity' | 'modifier'>
        )>>, scope: (
          { __typename?: 'Scope' }
          & Pick<Scope, 'entity' | 'modifier'>
        ) }
      )> }
    )>, mitigationTypes: Array<(
      { __typename?: 'MitigationType' }
      & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
    )>, publicationTemplates: Array<(
      { __typename?: 'PublicationTemplate' }
      & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
    )>, publicationVariables: (
      { __typename?: 'AccountPublicationVariables' }
      & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
    ), regionalSetting: (
      { __typename?: 'RegionalSetting' }
      & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
    ), reportSources: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )>, routeTypes: Array<(
      { __typename?: 'RouteType' }
      & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
    )>, shiftTypes: Array<(
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
    )>, workspaces: (
      { __typename?: 'AccountWorkspaces' }
      & Pick<AccountWorkspaces, 'singleMultiPolygon'>
      & { workspaces: Array<(
        { __typename?: 'Workspace' }
        & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
      )> }
    ) }
  )> }
);

export type AccountAdditionalInfosQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountAdditionalInfosQuery = (
  { __typename?: 'Query' }
  & { accountAdditionalInfos: Array<(
    { __typename?: 'AdditionalInfo' }
    & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
  )> }
);

export type AccountMitigationTypesQueryVariables = Exact<{
  getAll: Scalars['Boolean'];
}>;


export type AccountMitigationTypesQuery = (
  { __typename?: 'Query' }
  & { accountMitigationTypes: Array<(
    { __typename?: 'MitigationType' }
    & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
  )> }
);

export type AccountPublicationChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountPublicationChannelsQuery = (
  { __typename?: 'Query' }
  & { accountPublicationChannels: Array<(
    { __typename?: 'PublicationChannel' }
    & Pick<PublicationChannel, 'accountId' | 'destination' | 'id'>
  )> }
);

export type AccountReportSourceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AccountReportSourceQuery = (
  { __typename?: 'Query' }
  & { accountReportSource?: Maybe<(
    { __typename?: 'ReportSource' }
    & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
  )> }
);

export type AccountReportSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountReportSourcesQuery = (
  { __typename?: 'Query' }
  & { accountReportSources: Array<(
    { __typename?: 'ReportSource' }
    & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
  )> }
);

export type AccountRouteTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountRouteTypesQuery = (
  { __typename?: 'Query' }
  & { accountRouteTypes: Array<(
    { __typename?: 'RouteType' }
    & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
  )> }
);

export type AccountShiftTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountShiftTypesQuery = (
  { __typename?: 'Query' }
  & { accountShiftTypes: Array<(
    { __typename?: 'ShiftType' }
    & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
  )> }
);

export type AffectedTransitServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type AffectedTransitServicesQuery = (
  { __typename?: 'Query' }
  & { affectedTransitServices: (
    { __typename?: 'AffectedTransitEntities' }
    & { affectedFixedBusRoutes: Array<(
      { __typename?: 'AffectedFixedBusRoute' }
      & Pick<AffectedFixedBusRoute, 'affectedBusStopsCount' | 'color' | 'durationMinutes' | 'estimatedEndTime' | 'isRerouted' | 'routeAccountId' | 'routeExternalId' | 'routeId' | 'routeName' | 'routeNumber'>
      & { affectingEvents: Array<(
        { __typename?: 'AffectingEventDetails' }
        & Pick<AffectingEventDetails, 'affectedBusStops' | 'affectingEntityType' | 'affectingEventId' | 'durationMinutes' | 'estimatedEndTime' | 'startTime'>
        & { additionalInfo: (
          { __typename?: 'CongestionAffectingTransitAdditionalInfo' }
          & Pick<CongestionAffectingTransitAdditionalInfo, 'lengthMeters' | 'type'>
        ) | (
          { __typename?: 'IncidentAffectingTransitAdditionalInfo' }
          & Pick<IncidentAffectingTransitAdditionalInfo, 'incidentSubtype' | 'incidentType' | 'type'>
        ) | (
          { __typename?: 'TrafficDisruptionAffectingTransitAdditionalInfo' }
          & Pick<TrafficDisruptionAffectingTransitAdditionalInfo, 'title' | 'type'>
        ) | (
          { __typename?: 'WeatherAlertAffectingTransitAdditionalInfo' }
          & Pick<WeatherAlertAffectingTransitAdditionalInfo, 'severity' | 'type' | 'weatherAlertSubtype' | 'weatherAlertType'>
        ), address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        )> }
      )> }
    )>, affectedTransitUnits: Array<(
      { __typename?: 'AffectedTransitUnit' }
      & Pick<AffectedTransitUnit, 'displayId' | 'durationMinutes' | 'estimatedEndTime' | 'type' | 'unitAccountId' | 'unitExternalId' | 'unitId'>
      & { affectingEvents: Array<(
        { __typename?: 'AffectingEventDetails' }
        & Pick<AffectingEventDetails, 'affectedBusStops' | 'affectingEntityType' | 'affectingEventId' | 'durationMinutes' | 'estimatedEndTime' | 'startTime'>
        & { additionalInfo: (
          { __typename?: 'CongestionAffectingTransitAdditionalInfo' }
          & Pick<CongestionAffectingTransitAdditionalInfo, 'lengthMeters' | 'type'>
        ) | (
          { __typename?: 'IncidentAffectingTransitAdditionalInfo' }
          & Pick<IncidentAffectingTransitAdditionalInfo, 'incidentSubtype' | 'incidentType' | 'type'>
        ) | (
          { __typename?: 'TrafficDisruptionAffectingTransitAdditionalInfo' }
          & Pick<TrafficDisruptionAffectingTransitAdditionalInfo, 'title' | 'type'>
        ) | (
          { __typename?: 'WeatherAlertAffectingTransitAdditionalInfo' }
          & Pick<WeatherAlertAffectingTransitAdditionalInfo, 'severity' | 'type' | 'weatherAlertSubtype' | 'weatherAlertType'>
        ), address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        )> }
      )> }
    )> }
  ) }
);

export type AliasChangesLogQueryVariables = Exact<{
  input: FilterSegmentAliasInput;
}>;


export type AliasChangesLogQuery = (
  { __typename?: 'Query' }
  & { aliasChangesLog: (
    { __typename?: 'FilteredAliasResultPage' }
    & Pick<FilteredAliasResultPage, 'aliasColumnNames' | 'totalNumberOfResults'>
    & { rows: Array<(
      { __typename?: 'FilteredSegmentAliasView' }
      & { data: Array<(
        { __typename?: 'AliasColumn' }
        & Pick<AliasColumn, 'alias' | 'segmentAliasLogColumnName'>
      ) | (
        { __typename?: 'IdColumn' }
        & Pick<IdColumn, 'id' | 'segmentAliasLogColumnName'>
      ) | (
        { __typename?: 'IncidentCoordinatesColumn' }
        & Pick<IncidentCoordinatesColumn, 'incidentCoordinates' | 'segmentAliasLogColumnName'>
      ) | (
        { __typename?: 'OriginalLocationNameColumn' }
        & Pick<OriginalLocationNameColumn, 'originalLocationName' | 'segmentAliasLogColumnName'>
      ) | (
        { __typename?: 'SubmittedAtColumn' }
        & Pick<SubmittedAtColumn, 'segmentAliasLogColumnName' | 'submittedAt'>
      ) | (
        { __typename?: 'SubmittedByColumn' }
        & Pick<SubmittedByColumn, 'segmentAliasLogColumnName' | 'submittedBy'>
      ) | (
        { __typename?: 'TypeColumn' }
        & Pick<TypeColumn, 'segmentAliasLogColumnName' | 'type'>
      )> }
    )> }
  ) }
);

export type AllDmSsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllDmSsQuery = (
  { __typename?: 'Query' }
  & { allDMSs: Array<(
    { __typename?: 'DMS' }
    & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  )> }
);

export type AssetsByLocationQueryVariables = Exact<{
  input: LocationInput;
}>;


export type AssetsByLocationQuery = (
  { __typename?: 'Query' }
  & { assetsByLocation: (
    { __typename?: 'AssetsByLocation' }
    & { cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, dmses: Array<(
      { __typename?: 'AssociatedDMS' }
      & Pick<AssociatedDms, 'positionIndex'>
      & { dms: (
        { __typename?: 'DMS' }
        & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )> }
  ) }
);

export type BusStopDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type BusStopDetailsQuery = (
  { __typename?: 'Query' }
  & { busStopDetails: (
    { __typename?: 'BusStopDetails' }
    & Pick<BusStopDetails, 'displayId' | 'id' | 'name'>
    & { activeBuses: Array<(
      { __typename?: 'BusStopBusArrivalDetails' }
      & Pick<BusStopBusArrivalDetails, 'accountId' | 'busId' | 'displayId' | 'eta' | 'status' | 'statusInMinutes'>
      & { route: (
        { __typename?: 'BusStopPassingRouteDetails' }
        & Pick<BusStopPassingRouteDetails, 'color' | 'number' | 'routeId'>
      ) }
    )>, routes: Array<(
      { __typename?: 'BusStopPassingRouteDetails' }
      & Pick<BusStopPassingRouteDetails, 'color' | 'number' | 'routeId'>
    )> }
  ) }
);

export type BusStopsQueryVariables = Exact<{
  input: FindBusStopViewsInput;
}>;


export type BusStopsQuery = (
  { __typename?: 'Query' }
  & { busStops: Array<(
    { __typename?: 'BusStopView' }
    & Pick<BusStopView, 'busStopId' | 'displayId' | 'hasBusRouteFromContextAccount' | 'location'>
    & { routes: Array<(
      { __typename?: 'BusStopPassingRouteView' }
      & Pick<BusStopPassingRouteView, 'color' | 'number' | 'routeId'>
    )> }
  )> }
);

export type CompletedConstructionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedConstructionsQuery = (
  { __typename?: 'Query' }
  & { completedConstructions: Array<(
    { __typename?: 'ConstructionView' }
    & Pick<ConstructionView, 'allLanesAffected' | 'createdAt' | 'endTime' | 'externalId' | 'groupKey' | 'id' | 'lanesClosureType' | 'layerType' | 'location' | 'multiDirectionLanesAffected' | 'otherTypeDescription' | 'startTime' | 'status' | 'title' | 'type' | 'updatedAt' | 'workspaces'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  )> }
);

export type CompletedRoadClosuresQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedRoadClosuresQuery = (
  { __typename?: 'Query' }
  & { completedRoadClosures: Array<(
    { __typename?: 'RoadClosureView' }
    & Pick<RoadClosureView, 'allLanesAffected' | 'createdAt' | 'endTime' | 'externalId' | 'groupKey' | 'id' | 'lanesClosureType' | 'layerType' | 'location' | 'multiDirectionLanesAffected' | 'startTime' | 'status' | 'title' | 'updatedAt' | 'workspaces'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  )> }
);

export type CompletedSpecialEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletedSpecialEventsQuery = (
  { __typename?: 'Query' }
  & { completedSpecialEvents: Array<(
    { __typename?: 'SpecialEventView' }
    & Pick<SpecialEventView, 'allLanesAffected' | 'createdAt' | 'endTime' | 'externalId' | 'groupKey' | 'id' | 'lanesClosureType' | 'layerType' | 'location' | 'multiDirectionLanesAffected' | 'occupancy' | 'occupancyRange' | 'otherTypeDescription' | 'startTime' | 'status' | 'title' | 'type' | 'updatedAt' | 'venueAddress' | 'venueName' | 'workspaces'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  )> }
);

export type ConstructionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ConstructionQuery = (
  { __typename?: 'Query' }
  & { construction?: Maybe<(
    { __typename?: 'Construction' }
    & Pick<Construction, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'otherTypeDescription' | 'startTime' | 'status' | 'title' | 'type' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, dmses: Array<(
      { __typename?: 'AssociatedDMS' }
      & Pick<AssociatedDms, 'positionIndex'>
      & { dms: (
        { __typename?: 'DMS' }
        & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, permit?: Maybe<(
      { __typename?: 'Permit' }
      & Pick<Permit, 'permitId' | 'url'>
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  )> }
);

export type ConstructionActivityLogQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ConstructionActivityLogQuery = (
  { __typename?: 'Query' }
  & { constructionActivityLog: Array<(
    { __typename?: 'ActivityLogAggregated' }
    & Pick<ActivityLogAggregated, 'timeByDay'>
    & { activitiesPerDay: Array<(
      { __typename?: 'ActivityPerDay' }
      & Pick<ActivityPerDay, 'accountName' | 'timeByMinute' | 'userName'>
      & { activitiesPerMinute: Array<(
        { __typename?: 'ActivitySection' }
        & Pick<ActivitySection, 'activityLogEntityType' | 'activityLogSectionType' | 'maxOfflineUpdatedAt'>
        & { activities: Array<(
          { __typename?: 'ActivityStructure' }
          & Pick<ActivityStructure, 'action' | 'field' | 'offlineUpdatedAt'>
          & { value: (
            { __typename?: 'AdditionalInfoField' }
            & Pick<AdditionalInfoField, 'info'>
          ) | (
            { __typename?: 'AddressField' }
            & { address: (
              { __typename?: 'Address' }
              & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
            ) }
          ) | (
            { __typename?: 'BooleanField' }
            & Pick<BooleanField, 'bool'>
          ) | (
            { __typename?: 'CameraField' }
            & Pick<CameraField, 'default' | 'externalId' | 'title'>
          ) | (
            { __typename?: 'ConstructionTypeField' }
            & Pick<ConstructionTypeField, 'constructionType'>
          ) | (
            { __typename?: 'ContactPersonField' }
            & Pick<ContactPersonField, 'email' | 'name' | 'phoneNumber'>
          ) | (
            { __typename?: 'DateTimeField' }
            & Pick<DateTimeField, 'dateTime'>
          ) | (
            { __typename?: 'DmsField' }
            & Pick<DmsField, 'dmsId' | 'title'>
          ) | (
            { __typename?: 'GeometryField' }
            & Pick<GeometryField, 'geometry'>
          ) | (
            { __typename?: 'IncidentAutoPublishField' }
            & Pick<IncidentAutoPublishField, 'autoPublish'>
          ) | (
            { __typename?: 'IncidentLaneField' }
            & { incidentLane: (
              { __typename?: 'IncidentLane' }
              & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
            ) }
          ) | (
            { __typename?: 'IncidentMediaIdField' }
            & Pick<IncidentMediaIdField, 'incidentMediaId'>
          ) | (
            { __typename?: 'IncidentSeverityField' }
            & Pick<IncidentSeverityField, 'incidentSeverity'>
          ) | (
            { __typename?: 'IncidentStatusField' }
            & Pick<IncidentStatusField, 'automaticAction' | 'endReason' | 'endReasonComment' | 'status'>
          ) | (
            { __typename?: 'IncidentSubTypeField' }
            & Pick<IncidentSubTypeField, 'subType'>
          ) | (
            { __typename?: 'IncidentTypeField' }
            & Pick<IncidentTypeField, 'incidentType'>
          ) | (
            { __typename?: 'InjurySeveritiesField' }
            & Pick<InjurySeveritiesField, 'injurySeverity'>
          ) | (
            { __typename?: 'IntField' }
            & Pick<IntField, 'n'>
          ) | (
            { __typename?: 'LanesClosureTypeField' }
            & Pick<LanesClosureTypeField, 'lanesClosureType'>
          ) | (
            { __typename?: 'LocationField' }
            & Pick<LocationField, 'point'>
          ) | (
            { __typename?: 'MitigationField' }
            & Pick<MitigationField, 'description' | 'unitDisplayId' | 'unitId'>
            & { interval?: Maybe<(
              { __typename?: 'DateInterval' }
              & Pick<DateInterval, 'from' | 'to'>
            )> }
          ) | (
            { __typename?: 'NoteField' }
            & Pick<NoteField, 'note'>
          ) | (
            { __typename?: 'NullableTextField' }
            & Pick<NullableTextField, 'nullableText'>
          ) | (
            { __typename?: 'OccupancyRangeField' }
            & Pick<OccupancyRangeField, 'occupancyRange'>
          ) | (
            { __typename?: 'PermitField' }
            & Pick<PermitField, 'permitId' | 'url'>
          ) | (
            { __typename?: 'PublishField' }
            & Pick<PublishField, 'description' | 'destinations'>
          ) | (
            { __typename?: 'RelatedEventField' }
            & Pick<RelatedEventField, 'endTime' | 'eventId' | 'relatedEventStatus' | 'startTime' | 'type'>
          ) | (
            { __typename?: 'ReportSourceField' }
            & Pick<ReportSourceField, 'accountId' | 'reportSource'>
          ) | (
            { __typename?: 'RouteTypeIdsField' }
            & Pick<RouteTypeIdsField, 'routeTypesIds'>
          ) | (
            { __typename?: 'ScheduleField' }
            & Pick<ScheduleField, 'endTime' | 'startTime'>
            & { schedule?: Maybe<(
              { __typename?: 'TrafficDisruptionScheduleDTO' }
              & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
              & { timeRanges: Array<(
                { __typename?: 'TimeRangeSchedule' }
                & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
              )> }
            )> }
          ) | (
            { __typename?: 'ShiftEndReasonField' }
            & Pick<ShiftEndReasonField, 'shiftEndReason'>
          ) | (
            { __typename?: 'ShiftTypeIdField' }
            & Pick<ShiftTypeIdField, 'shiftTypeId'>
          ) | (
            { __typename?: 'SpecialEventTypeField' }
            & Pick<SpecialEventTypeField, 'specialEventType'>
          ) | (
            { __typename?: 'TextField' }
            & Pick<TextField, 'text'>
          ) | (
            { __typename?: 'TrafficDisruptionLaneField' }
            & { trafficDisruptionLane: (
              { __typename?: 'TrafficDisruptionLane' }
              & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
            ) }
          ) | (
            { __typename?: 'TrafficDisruptionMediaIdField' }
            & Pick<TrafficDisruptionMediaIdField, 'trafficDisruptionMediaId'>
          ) | (
            { __typename?: 'TrafficDisruptionStatusField' }
            & Pick<TrafficDisruptionStatusField, 'trafficDisruptionEndReason' | 'trafficDisruptionStatus'>
          ) | (
            { __typename?: 'UnitField' }
            & Pick<UnitField, 'displayId' | 'driverId' | 'response'>
          ) | (
            { __typename?: 'UnitIdField' }
            & Pick<UnitIdField, 'shiftUnitId'>
          ) | (
            { __typename?: 'VehicleField' }
            & { vehicle: (
              { __typename?: 'IncidentInvolvedVehicle' }
              & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
            ) }
          ) | (
            { __typename?: 'VenueField' }
            & Pick<VenueField, 'venueAddress' | 'venueName'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type CustomerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CustomerQuery = (
  { __typename?: 'Query' }
  & { customer?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
  )> }
);

export type DmsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DmsQuery = (
  { __typename?: 'Query' }
  & { dms?: Maybe<(
    { __typename?: 'DMS' }
    & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  )> }
);

export type DmssByAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type DmssByAccountQuery = (
  { __typename?: 'Query' }
  & { dmssByAccount: Array<(
    { __typename?: 'DMS' }
    & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  )> }
);

export type FindAccountActiveUnitsAndDriversQueryVariables = Exact<{ [key: string]: never; }>;


export type FindAccountActiveUnitsAndDriversQuery = (
  { __typename?: 'Query' }
  & { findAccountActiveUnitsAndDrivers: (
    { __typename?: 'AccountActiveUnitsAndDrivers' }
    & { drivers: Array<(
      { __typename?: 'User' }
      & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
        & { additionalInfos: Array<(
          { __typename?: 'AdditionalInfo' }
          & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
        )>, analyticsReportsUrls?: Maybe<(
          { __typename?: 'AnalyticsReportsUrls' }
          & { fixedRouteTransitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, fspReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, incidentAnalysisReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, mitigationsReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, speedMeasurementReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, timPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, transitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, travelReliabilityReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, unitsPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>> }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
        ), groups: Array<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'id' | 'level' | 'type'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'id'>
            & { dependsOnAll?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, dependsOnAny?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, scope: (
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            ) }
          )> }
        )>, mitigationTypes: Array<(
          { __typename?: 'MitigationType' }
          & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
        )>, publicationTemplates: Array<(
          { __typename?: 'PublicationTemplate' }
          & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
        )>, publicationVariables: (
          { __typename?: 'AccountPublicationVariables' }
          & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
        ), regionalSetting: (
          { __typename?: 'RegionalSetting' }
          & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
        ), reportSources: Array<(
          { __typename?: 'ReportSource' }
          & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
        )>, routeTypes: Array<(
          { __typename?: 'RouteType' }
          & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
        )>, shiftTypes: Array<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
        )>, workspaces: (
          { __typename?: 'AccountWorkspaces' }
          & Pick<AccountWorkspaces, 'singleMultiPolygon'>
          & { workspaces: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
          )> }
        ) }
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
        & { dashCameras: Array<(
          { __typename?: 'UnitDashCamera' }
          & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
        )>, userDetails?: Maybe<(
          { __typename?: 'UserDetails' }
          & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
        )> }
      )> }
    )>, units: Array<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  ) }
);

export type FindLatestIncidentsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindLatestIncidentsQuery = (
  { __typename?: 'Query' }
  & { findLatestIncidents: Array<(
    { __typename?: 'IncidentView' }
    & Pick<IncidentView, 'allLanesAffected' | 'appended' | 'atmsId' | 'cadId' | 'confirmedAt' | 'confirmedBy' | 'createdAt' | 'createdBy' | 'createdByUserName' | 'creationSource' | 'externalId' | 'groupKey' | 'id' | 'indications' | 'isFullClosure' | 'isUnconfirmed' | 'lanesStatus' | 'layerType' | 'location' | 'mitigatedAccounts' | 'multiDirectionLanesAffected' | 'nearCameras' | 'roadClosure' | 'severity' | 'startedAt' | 'status' | 'subType' | 'title' | 'type' | 'typeDescription' | 'updatedAt' | 'updatedBy' | 'withinWorkingHours' | 'workspaces'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ), affectedLanes: Array<(
      { __typename?: 'IncidentLane' }
      & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
    )>, associatedUnits: Array<(
      { __typename?: 'IncidentUnit' }
      & Pick<IncidentUnit, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type' | 'unitResponse' | 'updatedAt'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, driverDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, mergeDetails?: Maybe<(
      { __typename?: 'MergeDetails' }
      & Pick<MergeDetails, 'isBlocked' | 'targetId'>
    )>, priorityScore?: Maybe<(
      { __typename?: 'PriorityScoreView' }
      & Pick<PriorityScoreView, 'level' | 'score'>
    )> }
  )> }
);

export type FindLatestReleaseNotesQueryVariables = Exact<{
  pastMonths: Scalars['Int'];
}>;


export type FindLatestReleaseNotesQuery = (
  { __typename?: 'Query' }
  & { findLatestReleaseNotes: Array<(
    { __typename?: 'ReleaseNote' }
    & Pick<ReleaseNote, 'generatedReleaseId' | 'id' | 'imageUrl' | 'releaseLanguage' | 'releaseName' | 'releaseTitle' | 'scheduleReleaseDate' | 'tags' | 'updatedAt'>
    & { features: Array<(
      { __typename?: 'ReleaseFeature' }
      & Pick<ReleaseFeature, 'description' | 'id' | 'imageUrl' | 'positionIndex' | 'releaseNoteId' | 'title' | 'updatedAt'>
    )> }
  )> }
);

export type FindRelatedIncidentsByPointRadiusQueryVariables = Exact<{
  input: FindIncidentsInPointRadiusInput;
}>;


export type FindRelatedIncidentsByPointRadiusQuery = (
  { __typename?: 'Query' }
  & { findRelatedIncidentsByPointRadius: Array<(
    { __typename?: 'RelatedIncident' }
    & Pick<RelatedIncident, 'id' | 'status' | 'subType' | 'type'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  )> }
);

export type FindUsersRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type FindUsersRolesQuery = (
  { __typename?: 'Query' }
  & { findUsersRoles: Array<(
    { __typename?: 'UserRolesDTO' }
    & Pick<UserRolesDto, 'email' | 'phoneNumber' | 'userId' | 'userName'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'accountId' | 'description' | 'id' | 'name'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
        & { dependsOnAll?: Maybe<Array<(
          { __typename?: 'Scope' }
          & Pick<Scope, 'entity' | 'modifier'>
        )>>, dependsOnAny?: Maybe<Array<(
          { __typename?: 'Scope' }
          & Pick<Scope, 'entity' | 'modifier'>
        )>>, scope: (
          { __typename?: 'Scope' }
          & Pick<Scope, 'entity' | 'modifier'>
        ) }
      )> }
    )> }
  )> }
);

export type FixedBusRouteDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FixedBusRouteDetailsQuery = (
  { __typename?: 'Query' }
  & { fixedBusRouteDetails: (
    { __typename?: 'FixedBusRouteDetails' }
    & Pick<FixedBusRouteDetails, 'id' | 'isRerouted' | 'number'>
    & { patterns: Array<(
      { __typename?: 'FixedBusRoutePatternDetails' }
      & Pick<FixedBusRoutePatternDetails, 'direction' | 'id' | 'name' | 'numberOfStops'>
      & { buses: Array<(
        { __typename?: 'FixedRouteActiveBusDetails' }
        & Pick<FixedRouteActiveBusDetails, 'displayId' | 'id' | 'status' | 'statusInMinutes'>
      )>, stops: Array<(
        { __typename?: 'FixedRouteBusStopDetails' }
        & Pick<FixedRouteBusStopDetails, 'displayId' | 'id' | 'name' | 'nextEtaInMinutes'>
        & { buses: Array<(
          { __typename?: 'FixedRouteBusStopActiveBusDetails' }
          & Pick<FixedRouteBusStopActiveBusDetails, 'displayId' | 'id' | 'nextEtaInMinutes' | 'patternId' | 'status' | 'statusInMinutes'>
        )> }
      )> }
    )> }
  ) }
);

export type FixedBusRoutesQueryVariables = Exact<{ [key: string]: never; }>;


export type FixedBusRoutesQuery = (
  { __typename?: 'Query' }
  & { fixedBusRoutes: Array<(
    { __typename?: 'FixedBusRouteView' }
    & Pick<FixedBusRouteView, 'accountId' | 'accountName' | 'color' | 'externalId' | 'id' | 'name' | 'number' | 'path'>
    & { patterns: Array<(
      { __typename?: 'FixedBusRoutePatternView' }
      & Pick<FixedBusRoutePatternView, 'direction' | 'externalId' | 'id' | 'name'>
    )> }
  )> }
);

export type GetCurrentShiftQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentShiftQuery = (
  { __typename?: 'Query' }
  & { getCurrentShift?: Maybe<(
    { __typename?: 'ShiftDTO' }
    & Pick<ShiftDto, 'endMileage' | 'endReason' | 'endReasonNote' | 'endedAt' | 'isDeleted' | 'overtimeNote' | 'shiftId' | 'startMileage' | 'startedAt'>
    & { routeTypes: Array<(
      { __typename?: 'RouteType' }
      & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
    )>, shiftPauses: Array<(
      { __typename?: 'ShiftPauseDTO' }
      & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
    )>, shiftType?: Maybe<(
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
        & { additionalInfos: Array<(
          { __typename?: 'AdditionalInfo' }
          & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
        )>, analyticsReportsUrls?: Maybe<(
          { __typename?: 'AnalyticsReportsUrls' }
          & { fixedRouteTransitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, fspReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, incidentAnalysisReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, mitigationsReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, speedMeasurementReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, timPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, transitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, travelReliabilityReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, unitsPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>> }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
        ), groups: Array<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'id' | 'level' | 'type'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'id'>
            & { dependsOnAll?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, dependsOnAny?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, scope: (
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            ) }
          )> }
        )>, mitigationTypes: Array<(
          { __typename?: 'MitigationType' }
          & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
        )>, publicationTemplates: Array<(
          { __typename?: 'PublicationTemplate' }
          & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
        )>, publicationVariables: (
          { __typename?: 'AccountPublicationVariables' }
          & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
        ), regionalSetting: (
          { __typename?: 'RegionalSetting' }
          & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
        ), reportSources: Array<(
          { __typename?: 'ReportSource' }
          & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
        )>, routeTypes: Array<(
          { __typename?: 'RouteType' }
          & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
        )>, shiftTypes: Array<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
        )>, workspaces: (
          { __typename?: 'AccountWorkspaces' }
          & Pick<AccountWorkspaces, 'singleMultiPolygon'>
          & { workspaces: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
          )> }
        ) }
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      )> }
    ) }
  )> }
);

export type GetDetailedShiftQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDetailedShiftQuery = (
  { __typename?: 'Query' }
  & { getDetailedShift: (
    { __typename?: 'ShiftDetailedDTO' }
    & Pick<ShiftDetailedDto, 'durationMillis' | 'durationMillisRounded' | 'endedAt' | 'endedAtRounded' | 'firstIncidentMitigationTime' | 'isOvertime' | 'lastIncidentMitigationTime' | 'startedAt' | 'startedAtRounded' | 'totalBreaksDurationMillis'>
    & { baseData: (
      { __typename?: 'ShiftDTO' }
      & Pick<ShiftDto, 'endMileage' | 'endReason' | 'endReasonNote' | 'endedAt' | 'isDeleted' | 'overtimeNote' | 'shiftId' | 'startMileage' | 'startedAt'>
      & { routeTypes: Array<(
        { __typename?: 'RouteType' }
        & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
      )>, shiftPauses: Array<(
        { __typename?: 'ShiftPauseDTO' }
        & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
      )>, shiftType?: Maybe<(
        { __typename?: 'ShiftType' }
        & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
        & { dashCameras: Array<(
          { __typename?: 'UnitDashCamera' }
          & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
        )>, userDetails?: Maybe<(
          { __typename?: 'UserDetails' }
          & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
        )> }
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
        & { account: (
          { __typename?: 'Account' }
          & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
          & { additionalInfos: Array<(
            { __typename?: 'AdditionalInfo' }
            & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
          )>, analyticsReportsUrls?: Maybe<(
            { __typename?: 'AnalyticsReportsUrls' }
            & { fixedRouteTransitReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>>, fspReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>>, incidentAnalysisReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>>, mitigationsReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>>, speedMeasurementReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>>, timPerformanceReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>>, transitReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>>, travelReliabilityReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>>, unitsPerformanceReport?: Maybe<Array<(
              { __typename?: 'AnalyticsReportSrc' }
              & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
            )>> }
          )>, customer: (
            { __typename?: 'Customer' }
            & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
          ), groups: Array<(
            { __typename?: 'PermissionGroup' }
            & Pick<PermissionGroup, 'id' | 'level' | 'type'>
            & { permissions: Array<(
              { __typename?: 'Permission' }
              & Pick<Permission, 'id'>
              & { dependsOnAll?: Maybe<Array<(
                { __typename?: 'Scope' }
                & Pick<Scope, 'entity' | 'modifier'>
              )>>, dependsOnAny?: Maybe<Array<(
                { __typename?: 'Scope' }
                & Pick<Scope, 'entity' | 'modifier'>
              )>>, scope: (
                { __typename?: 'Scope' }
                & Pick<Scope, 'entity' | 'modifier'>
              ) }
            )> }
          )>, mitigationTypes: Array<(
            { __typename?: 'MitigationType' }
            & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
          )>, publicationTemplates: Array<(
            { __typename?: 'PublicationTemplate' }
            & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
          )>, publicationVariables: (
            { __typename?: 'AccountPublicationVariables' }
            & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
          ), regionalSetting: (
            { __typename?: 'RegionalSetting' }
            & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
          ), reportSources: Array<(
            { __typename?: 'ReportSource' }
            & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
          )>, routeTypes: Array<(
            { __typename?: 'RouteType' }
            & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
          )>, shiftTypes: Array<(
            { __typename?: 'ShiftType' }
            & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
          )>, workspaces: (
            { __typename?: 'AccountWorkspaces' }
            & Pick<AccountWorkspaces, 'singleMultiPolygon'>
            & { workspaces: Array<(
              { __typename?: 'Workspace' }
              & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
            )> }
          ) }
        ), permissions: Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id'>
        )>, unit?: Maybe<(
          { __typename?: 'UnitVehicle' }
          & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
        )> }
      ) }
    ), incidentsDetails: Array<(
      { __typename?: 'ShiftIncidentDetailsDTO' }
      & Pick<ShiftIncidentDetailsDto, 'firstMitigationTime' | 'incidentId' | 'lastMitigationTime' | 'location' | 'subType' | 'type'>
      & { address: (
        { __typename?: 'Address' }
        & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
      ), mitigations: Array<(
        { __typename?: 'IncidentUnitMitigationDetails' }
        & Pick<IncidentUnitMitigationDetails, 'mitigationDescription' | 'mitigationId' | 'mitigationTypeId' | 'userId'>
        & { interval: (
          { __typename?: 'DateInterval' }
          & Pick<DateInterval, 'from' | 'to'>
        ) }
      )>, responses: Array<(
        { __typename?: 'IncidentUnitResponseUpdateDetails' }
        & Pick<IncidentUnitResponseUpdateDetails, 'responseTime' | 'unitResponse'>
      )> }
    )> }
  ) }
);

export type GetEmbedDashboardUrlQueryVariables = Exact<{
  dashboardId: Scalars['String'];
}>;


export type GetEmbedDashboardUrlQuery = (
  { __typename?: 'Query' }
  & { getEmbedDashboardUrl: (
    { __typename?: 'AnalyticsReportsResponse' }
    & Pick<AnalyticsReportsResponse, 'dashboardUrl'>
  ) }
);

export type GetIncidentInsightsQueryVariables = Exact<{
  externalId?: Maybe<Scalars['String']>;
  incidentId: Scalars['Int'];
  status: IncidentStatus;
}>;


export type GetIncidentInsightsQuery = (
  { __typename?: 'Query' }
  & { getIncidentInsights?: Maybe<(
    { __typename?: 'IncidentInsights' }
    & Pick<IncidentInsights, 'persistenceType'>
    & { impact?: Maybe<(
      { __typename?: 'IncidentImpact' }
      & Pick<IncidentImpact, 'externalId' | 'incidentId' | 'level' | 'updatedAt' | 'usersFeedback'>
      & { corridors: Array<(
        { __typename?: 'IncidentImpactedCorridor' }
        & Pick<IncidentImpactedCorridor, 'currentSpd' | 'expectedSpd' | 'name'>
        & { impactedSegments: Array<(
          { __typename?: 'IncidentImpactedSegment' }
          & Pick<IncidentImpactedSegment, 'currentSpd' | 'expectedSpd' | 'forecastDecrease' | 'level' | 'linestring' | 'segmentId' | 'segmentName'>
        )> }
      )> }
    )>, priorityScore?: Maybe<(
      { __typename?: 'IncidentPriorityScore' }
      & Pick<IncidentPriorityScore, 'heavyInfluencedBy' | 'level'>
    )> }
  )> }
);

export type GetIncidentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIncidentTypesQuery = (
  { __typename?: 'Query' }
  & { getIncidentTypes: (
    { __typename?: 'AccountIncidentTypes' }
    & Pick<AccountIncidentTypes, 'incidentAbandonedVehicleSubType' | 'incidentCrashSubTypes' | 'incidentDamageSubType' | 'incidentDebrisSubTypes' | 'incidentHazardSubTypes' | 'incidentPoliceActivitySubTypes' | 'incidentStalledVehicleSubTypes' | 'incidentTrafficStopSubTypes' | 'incidentTypes'>
  ) }
);

export type GetIncidentsWithUserMitigationsByTimePeriodQueryVariables = Exact<{
  input: UserAndTimeInput;
}>;


export type GetIncidentsWithUserMitigationsByTimePeriodQuery = (
  { __typename?: 'Query' }
  & { getIncidentsWithUserMitigationsByTimePeriod: Array<(
    { __typename?: 'Incident' }
    & Pick<Incident, 'allLanesAffected' | 'atmsId' | 'attributes' | 'autoPublish' | 'cadId' | 'completedBy' | 'confirmedAt' | 'confirmedBy' | 'createdAt' | 'createdBy' | 'createdByUserName' | 'creationSource' | 'endReason' | 'endReasonComment' | 'endedAt' | 'estimatedEndTime' | 'externalId' | 'fatalities' | 'feedSource' | 'hazardRemains' | 'id' | 'injuries' | 'injurySeverities' | 'involvedVehiclesCount' | 'isAddressEstimated' | 'isConfirmed' | 'isRejected' | 'isUnconfirmed' | 'lanesStatus' | 'location' | 'multiDirectionLanesAffected' | 'nearCameras' | 'restoreAt' | 'severity' | 'startedAt' | 'status' | 'subType' | 'title' | 'trafficDisturbance' | 'type' | 'typeDescription' | 'updatedAt' | 'updatedBy'>
    & { additionalInfos: Array<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
    )>, address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ), affectedLanes: Array<(
      { __typename?: 'IncidentLane' }
      & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
    )>, associatedUnits: Array<(
      { __typename?: 'IncidentUnit' }
      & Pick<IncidentUnit, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type' | 'unitResponse' | 'updatedAt'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, driverDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, camerasInternalDetails: Array<(
      { __typename?: 'CameraInternal' }
      & Pick<CameraInternal, 'default' | 'externalId' | 'positionIndex'>
    )>, insights?: Maybe<(
      { __typename?: 'IncidentInsights' }
      & Pick<IncidentInsights, 'persistenceType'>
      & { impact?: Maybe<(
        { __typename?: 'IncidentImpact' }
        & Pick<IncidentImpact, 'externalId' | 'incidentId' | 'level' | 'updatedAt' | 'usersFeedback'>
        & { corridors: Array<(
          { __typename?: 'IncidentImpactedCorridor' }
          & Pick<IncidentImpactedCorridor, 'currentSpd' | 'expectedSpd' | 'name'>
          & { impactedSegments: Array<(
            { __typename?: 'IncidentImpactedSegment' }
            & Pick<IncidentImpactedSegment, 'currentSpd' | 'expectedSpd' | 'forecastDecrease' | 'level' | 'linestring' | 'segmentId' | 'segmentName'>
          )> }
        )> }
      )>, priorityScore?: Maybe<(
        { __typename?: 'IncidentPriorityScore' }
        & Pick<IncidentPriorityScore, 'heavyInfluencedBy' | 'level'>
      )> }
    )>, involvedVehicles: Array<(
      { __typename?: 'IncidentInvolvedVehicle' }
      & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
    )>, media: Array<(
      { __typename?: 'IncidentMedia' }
      & Pick<IncidentMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>, mergeDetails?: Maybe<(
      { __typename?: 'MergeDetails' }
      & Pick<MergeDetails, 'isBlocked' | 'targetId'>
    )>, mitigations: Array<(
      { __typename?: 'IncidentMitigation' }
      & Pick<IncidentMitigation, 'id' | 'unitDisplayId' | 'unitId' | 'unitType' | 'userId'>
      & { interval: (
        { __typename?: 'DateInterval' }
        & Pick<DateInterval, 'from' | 'to'>
      ), mitigationType: (
        { __typename?: 'MitigationType' }
        & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
      ) }
    )>, notes: Array<(
      { __typename?: 'IncidentNote' }
      & Pick<IncidentNote, 'createdAt' | 'id' | 'note' | 'updatedAt' | 'updatedBy' | 'updatedByUsername'>
    )>, reportSources: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )>, responsePlan?: Maybe<(
      { __typename?: 'ResponsePlan' }
      & Pick<ResponsePlan, 'entityId' | 'entityType' | 'id' | 'isDeleted' | 'templateId' | 'title' | 'updatedAt'>
      & { actions: Array<(
        { __typename?: 'ResponsePlanAction' }
        & Pick<ResponsePlanAction, 'actionField' | 'index' | 'isDone' | 'responsePlanActionId' | 'responsePlanId' | 'text' | 'time' | 'unit' | 'value'>
      )> }
    )>, userDetails?: Maybe<(
      { __typename?: 'IncidentUserNamesDetails' }
      & Pick<IncidentUserNamesDetails, 'completedBy' | 'confirmedBy' | 'updatedBy'>
    )> }
  )> }
);

export type GetRoadwayStatusSegmentMetricDetailsByIdQueryVariables = Exact<{
  segmentId: Scalars['Int'];
}>;


export type GetRoadwayStatusSegmentMetricDetailsByIdQuery = (
  { __typename?: 'Query' }
  & { getRoadwayStatusSegmentMetricDetailsById: Array<(
    { __typename?: 'RoadwayStatusDetailedMetricDTO' }
    & Pick<RoadwayStatusDetailedMetricDto, 'avgSpeedTotal' | 'endedAt' | 'id' | 'isDataExist' | 'isDefaultVendor' | 'isUpdated' | 'maxSpeedDeviationPercent' | 'maxSpeedDeviationSeverity' | 'nonBusesAvgSpeed' | 'nonBusesCount' | 'roadwayStatusSegmentId' | 'startedAt' | 'transportCountTotal' | 'updatedAt' | 'vendorId' | 'vendorName'>
    & { lanesData: Array<(
      { __typename?: 'RoadwayStatusLaneMetricDTO' }
      & Pick<RoadwayStatusLaneMetricDto, 'avgSpeed' | 'count' | 'id' | 'type'>
    )>, transportsData: Array<(
      { __typename?: 'RoadwayStatusTransportMetricsDTO' }
      & Pick<RoadwayStatusTransportMetricsDto, 'avgSpeed' | 'count' | 'id' | 'type'>
    )> }
  )> }
);

export type GetShiftsByUserAndTimeQueryVariables = Exact<{
  input: UserAndTimeInput;
}>;


export type GetShiftsByUserAndTimeQuery = (
  { __typename?: 'Query' }
  & { getShiftsByUserAndTime: Array<(
    { __typename?: 'ShiftDTO' }
    & Pick<ShiftDto, 'endMileage' | 'endReason' | 'endReasonNote' | 'endedAt' | 'isDeleted' | 'overtimeNote' | 'shiftId' | 'startMileage' | 'startedAt'>
    & { routeTypes: Array<(
      { __typename?: 'RouteType' }
      & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
    )>, shiftPauses: Array<(
      { __typename?: 'ShiftPauseDTO' }
      & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
    )>, shiftType?: Maybe<(
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
        & { additionalInfos: Array<(
          { __typename?: 'AdditionalInfo' }
          & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
        )>, analyticsReportsUrls?: Maybe<(
          { __typename?: 'AnalyticsReportsUrls' }
          & { fixedRouteTransitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, fspReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, incidentAnalysisReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, mitigationsReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, speedMeasurementReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, timPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, transitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, travelReliabilityReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, unitsPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>> }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
        ), groups: Array<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'id' | 'level' | 'type'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'id'>
            & { dependsOnAll?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, dependsOnAny?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, scope: (
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            ) }
          )> }
        )>, mitigationTypes: Array<(
          { __typename?: 'MitigationType' }
          & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
        )>, publicationTemplates: Array<(
          { __typename?: 'PublicationTemplate' }
          & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
        )>, publicationVariables: (
          { __typename?: 'AccountPublicationVariables' }
          & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
        ), regionalSetting: (
          { __typename?: 'RegionalSetting' }
          & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
        ), reportSources: Array<(
          { __typename?: 'ReportSource' }
          & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
        )>, routeTypes: Array<(
          { __typename?: 'RouteType' }
          & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
        )>, shiftTypes: Array<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
        )>, workspaces: (
          { __typename?: 'AccountWorkspaces' }
          & Pick<AccountWorkspaces, 'singleMultiPolygon'>
          & { workspaces: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
          )> }
        ) }
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      )> }
    ) }
  )> }
);

export type GetSubmittedByOptionsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubmittedByOptionsListQuery = (
  { __typename?: 'Query' }
  & { getSubmittedByOptionsList: Array<(
    { __typename?: 'AliasSubmitter' }
    & Pick<AliasSubmitter, 'displayName' | 'value'>
  )> }
);

export type GetWorkingHoursQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkingHoursQuery = (
  { __typename?: 'Query' }
  & { getWorkingHours: (
    { __typename?: 'WorkingHoursDto' }
    & Pick<WorkingHoursDto, 'isCustom' | 'isHideEnabled' | 'workingHoursId'>
    & { workingHoursItems: Array<(
      { __typename?: 'WorkingHoursItemDto' }
      & Pick<WorkingHoursItemDto, 'dayOfWeek'>
      & { dailyRanges: Array<(
        { __typename?: 'DailyRange' }
        & Pick<DailyRange, 'from' | 'to'>
      )> }
    )> }
  ) }
);

export type InProgressIncidentsQueryVariables = Exact<{ [key: string]: never; }>;


export type InProgressIncidentsQuery = (
  { __typename?: 'Query' }
  & { inProgressIncidents: Array<(
    { __typename?: 'Incident' }
    & Pick<Incident, 'allLanesAffected' | 'atmsId' | 'attributes' | 'autoPublish' | 'cadId' | 'completedBy' | 'confirmedAt' | 'confirmedBy' | 'createdAt' | 'createdBy' | 'createdByUserName' | 'creationSource' | 'endReason' | 'endReasonComment' | 'endedAt' | 'estimatedEndTime' | 'externalId' | 'fatalities' | 'feedSource' | 'hazardRemains' | 'id' | 'injuries' | 'injurySeverities' | 'involvedVehiclesCount' | 'isAddressEstimated' | 'isConfirmed' | 'isRejected' | 'isUnconfirmed' | 'lanesStatus' | 'location' | 'multiDirectionLanesAffected' | 'nearCameras' | 'restoreAt' | 'severity' | 'startedAt' | 'status' | 'subType' | 'title' | 'trafficDisturbance' | 'type' | 'typeDescription' | 'updatedAt' | 'updatedBy'>
    & { additionalInfos: Array<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
    )>, address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ), affectedLanes: Array<(
      { __typename?: 'IncidentLane' }
      & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
    )>, associatedUnits: Array<(
      { __typename?: 'IncidentUnit' }
      & Pick<IncidentUnit, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type' | 'unitResponse' | 'updatedAt'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, driverDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, camerasInternalDetails: Array<(
      { __typename?: 'CameraInternal' }
      & Pick<CameraInternal, 'default' | 'externalId' | 'positionIndex'>
    )>, insights?: Maybe<(
      { __typename?: 'IncidentInsights' }
      & Pick<IncidentInsights, 'persistenceType'>
      & { impact?: Maybe<(
        { __typename?: 'IncidentImpact' }
        & Pick<IncidentImpact, 'externalId' | 'incidentId' | 'level' | 'updatedAt' | 'usersFeedback'>
        & { corridors: Array<(
          { __typename?: 'IncidentImpactedCorridor' }
          & Pick<IncidentImpactedCorridor, 'currentSpd' | 'expectedSpd' | 'name'>
          & { impactedSegments: Array<(
            { __typename?: 'IncidentImpactedSegment' }
            & Pick<IncidentImpactedSegment, 'currentSpd' | 'expectedSpd' | 'forecastDecrease' | 'level' | 'linestring' | 'segmentId' | 'segmentName'>
          )> }
        )> }
      )>, priorityScore?: Maybe<(
        { __typename?: 'IncidentPriorityScore' }
        & Pick<IncidentPriorityScore, 'heavyInfluencedBy' | 'level'>
      )> }
    )>, involvedVehicles: Array<(
      { __typename?: 'IncidentInvolvedVehicle' }
      & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
    )>, media: Array<(
      { __typename?: 'IncidentMedia' }
      & Pick<IncidentMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>, mergeDetails?: Maybe<(
      { __typename?: 'MergeDetails' }
      & Pick<MergeDetails, 'isBlocked' | 'targetId'>
    )>, mitigations: Array<(
      { __typename?: 'IncidentMitigation' }
      & Pick<IncidentMitigation, 'id' | 'unitDisplayId' | 'unitId' | 'unitType' | 'userId'>
      & { interval: (
        { __typename?: 'DateInterval' }
        & Pick<DateInterval, 'from' | 'to'>
      ), mitigationType: (
        { __typename?: 'MitigationType' }
        & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
      ) }
    )>, notes: Array<(
      { __typename?: 'IncidentNote' }
      & Pick<IncidentNote, 'createdAt' | 'id' | 'note' | 'updatedAt' | 'updatedBy' | 'updatedByUsername'>
    )>, reportSources: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )>, responsePlan?: Maybe<(
      { __typename?: 'ResponsePlan' }
      & Pick<ResponsePlan, 'entityId' | 'entityType' | 'id' | 'isDeleted' | 'templateId' | 'title' | 'updatedAt'>
      & { actions: Array<(
        { __typename?: 'ResponsePlanAction' }
        & Pick<ResponsePlanAction, 'actionField' | 'index' | 'isDone' | 'responsePlanActionId' | 'responsePlanId' | 'text' | 'time' | 'unit' | 'value'>
      )> }
    )>, userDetails?: Maybe<(
      { __typename?: 'IncidentUserNamesDetails' }
      & Pick<IncidentUserNamesDetails, 'completedBy' | 'confirmedBy' | 'updatedBy'>
    )> }
  )> }
);

export type IncidentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IncidentQuery = (
  { __typename?: 'Query' }
  & { incident?: Maybe<(
    { __typename?: 'Incident' }
    & Pick<Incident, 'allLanesAffected' | 'atmsId' | 'attributes' | 'autoPublish' | 'cadId' | 'completedBy' | 'confirmedAt' | 'confirmedBy' | 'createdAt' | 'createdBy' | 'createdByUserName' | 'creationSource' | 'endReason' | 'endReasonComment' | 'endedAt' | 'estimatedEndTime' | 'externalId' | 'fatalities' | 'feedSource' | 'hazardRemains' | 'id' | 'injuries' | 'injurySeverities' | 'involvedVehiclesCount' | 'isAddressEstimated' | 'isConfirmed' | 'isRejected' | 'isUnconfirmed' | 'lanesStatus' | 'location' | 'multiDirectionLanesAffected' | 'nearCameras' | 'restoreAt' | 'severity' | 'startedAt' | 'status' | 'subType' | 'title' | 'trafficDisturbance' | 'type' | 'typeDescription' | 'updatedAt' | 'updatedBy'>
    & { additionalInfos: Array<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
    )>, address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ), affectedLanes: Array<(
      { __typename?: 'IncidentLane' }
      & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
    )>, associatedUnits: Array<(
      { __typename?: 'IncidentUnit' }
      & Pick<IncidentUnit, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type' | 'unitResponse' | 'updatedAt'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, driverDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, camerasInternalDetails: Array<(
      { __typename?: 'CameraInternal' }
      & Pick<CameraInternal, 'default' | 'externalId' | 'positionIndex'>
    )>, insights?: Maybe<(
      { __typename?: 'IncidentInsights' }
      & Pick<IncidentInsights, 'persistenceType'>
      & { impact?: Maybe<(
        { __typename?: 'IncidentImpact' }
        & Pick<IncidentImpact, 'externalId' | 'incidentId' | 'level' | 'updatedAt' | 'usersFeedback'>
        & { corridors: Array<(
          { __typename?: 'IncidentImpactedCorridor' }
          & Pick<IncidentImpactedCorridor, 'currentSpd' | 'expectedSpd' | 'name'>
          & { impactedSegments: Array<(
            { __typename?: 'IncidentImpactedSegment' }
            & Pick<IncidentImpactedSegment, 'currentSpd' | 'expectedSpd' | 'forecastDecrease' | 'level' | 'linestring' | 'segmentId' | 'segmentName'>
          )> }
        )> }
      )>, priorityScore?: Maybe<(
        { __typename?: 'IncidentPriorityScore' }
        & Pick<IncidentPriorityScore, 'heavyInfluencedBy' | 'level'>
      )> }
    )>, involvedVehicles: Array<(
      { __typename?: 'IncidentInvolvedVehicle' }
      & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
    )>, media: Array<(
      { __typename?: 'IncidentMedia' }
      & Pick<IncidentMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>, mergeDetails?: Maybe<(
      { __typename?: 'MergeDetails' }
      & Pick<MergeDetails, 'isBlocked' | 'targetId'>
    )>, mitigations: Array<(
      { __typename?: 'IncidentMitigation' }
      & Pick<IncidentMitigation, 'id' | 'unitDisplayId' | 'unitId' | 'unitType' | 'userId'>
      & { interval: (
        { __typename?: 'DateInterval' }
        & Pick<DateInterval, 'from' | 'to'>
      ), mitigationType: (
        { __typename?: 'MitigationType' }
        & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
      ) }
    )>, notes: Array<(
      { __typename?: 'IncidentNote' }
      & Pick<IncidentNote, 'createdAt' | 'id' | 'note' | 'updatedAt' | 'updatedBy' | 'updatedByUsername'>
    )>, reportSources: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )>, responsePlan?: Maybe<(
      { __typename?: 'ResponsePlan' }
      & Pick<ResponsePlan, 'entityId' | 'entityType' | 'id' | 'isDeleted' | 'templateId' | 'title' | 'updatedAt'>
      & { actions: Array<(
        { __typename?: 'ResponsePlanAction' }
        & Pick<ResponsePlanAction, 'actionField' | 'index' | 'isDone' | 'responsePlanActionId' | 'responsePlanId' | 'text' | 'time' | 'unit' | 'value'>
      )> }
    )>, userDetails?: Maybe<(
      { __typename?: 'IncidentUserNamesDetails' }
      & Pick<IncidentUserNamesDetails, 'completedBy' | 'confirmedBy' | 'updatedBy'>
    )> }
  )> }
);

export type IncidentActivityLogQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IncidentActivityLogQuery = (
  { __typename?: 'Query' }
  & { incidentActivityLog: Array<(
    { __typename?: 'ActivityLogAggregated' }
    & Pick<ActivityLogAggregated, 'timeByDay'>
    & { activitiesPerDay: Array<(
      { __typename?: 'ActivityPerDay' }
      & Pick<ActivityPerDay, 'accountName' | 'timeByMinute' | 'userName'>
      & { activitiesPerMinute: Array<(
        { __typename?: 'ActivitySection' }
        & Pick<ActivitySection, 'activityLogEntityType' | 'activityLogSectionType' | 'maxOfflineUpdatedAt'>
        & { activities: Array<(
          { __typename?: 'ActivityStructure' }
          & Pick<ActivityStructure, 'action' | 'field' | 'offlineUpdatedAt'>
          & { value: (
            { __typename?: 'AdditionalInfoField' }
            & Pick<AdditionalInfoField, 'info'>
          ) | (
            { __typename?: 'AddressField' }
            & { address: (
              { __typename?: 'Address' }
              & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
            ) }
          ) | (
            { __typename?: 'BooleanField' }
            & Pick<BooleanField, 'bool'>
          ) | (
            { __typename?: 'CameraField' }
            & Pick<CameraField, 'default' | 'externalId' | 'title'>
          ) | (
            { __typename?: 'ConstructionTypeField' }
            & Pick<ConstructionTypeField, 'constructionType'>
          ) | (
            { __typename?: 'ContactPersonField' }
            & Pick<ContactPersonField, 'email' | 'name' | 'phoneNumber'>
          ) | (
            { __typename?: 'DateTimeField' }
            & Pick<DateTimeField, 'dateTime'>
          ) | (
            { __typename?: 'DmsField' }
            & Pick<DmsField, 'dmsId' | 'title'>
          ) | (
            { __typename?: 'GeometryField' }
            & Pick<GeometryField, 'geometry'>
          ) | (
            { __typename?: 'IncidentAutoPublishField' }
            & Pick<IncidentAutoPublishField, 'autoPublish'>
          ) | (
            { __typename?: 'IncidentLaneField' }
            & { incidentLane: (
              { __typename?: 'IncidentLane' }
              & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
            ) }
          ) | (
            { __typename?: 'IncidentMediaIdField' }
            & Pick<IncidentMediaIdField, 'incidentMediaId'>
          ) | (
            { __typename?: 'IncidentSeverityField' }
            & Pick<IncidentSeverityField, 'incidentSeverity'>
          ) | (
            { __typename?: 'IncidentStatusField' }
            & Pick<IncidentStatusField, 'automaticAction' | 'endReason' | 'endReasonComment' | 'status'>
          ) | (
            { __typename?: 'IncidentSubTypeField' }
            & Pick<IncidentSubTypeField, 'subType'>
          ) | (
            { __typename?: 'IncidentTypeField' }
            & Pick<IncidentTypeField, 'incidentType'>
          ) | (
            { __typename?: 'InjurySeveritiesField' }
            & Pick<InjurySeveritiesField, 'injurySeverity'>
          ) | (
            { __typename?: 'IntField' }
            & Pick<IntField, 'n'>
          ) | (
            { __typename?: 'LanesClosureTypeField' }
            & Pick<LanesClosureTypeField, 'lanesClosureType'>
          ) | (
            { __typename?: 'LocationField' }
            & Pick<LocationField, 'point'>
          ) | (
            { __typename?: 'MitigationField' }
            & Pick<MitigationField, 'description' | 'unitDisplayId' | 'unitId'>
            & { interval?: Maybe<(
              { __typename?: 'DateInterval' }
              & Pick<DateInterval, 'from' | 'to'>
            )> }
          ) | (
            { __typename?: 'NoteField' }
            & Pick<NoteField, 'note'>
          ) | (
            { __typename?: 'NullableTextField' }
            & Pick<NullableTextField, 'nullableText'>
          ) | (
            { __typename?: 'OccupancyRangeField' }
            & Pick<OccupancyRangeField, 'occupancyRange'>
          ) | (
            { __typename?: 'PermitField' }
            & Pick<PermitField, 'permitId' | 'url'>
          ) | (
            { __typename?: 'PublishField' }
            & Pick<PublishField, 'description' | 'destinations'>
          ) | (
            { __typename?: 'RelatedEventField' }
            & Pick<RelatedEventField, 'endTime' | 'eventId' | 'relatedEventStatus' | 'startTime' | 'type'>
          ) | (
            { __typename?: 'ReportSourceField' }
            & Pick<ReportSourceField, 'accountId' | 'reportSource'>
          ) | (
            { __typename?: 'RouteTypeIdsField' }
            & Pick<RouteTypeIdsField, 'routeTypesIds'>
          ) | (
            { __typename?: 'ScheduleField' }
            & Pick<ScheduleField, 'endTime' | 'startTime'>
            & { schedule?: Maybe<(
              { __typename?: 'TrafficDisruptionScheduleDTO' }
              & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
              & { timeRanges: Array<(
                { __typename?: 'TimeRangeSchedule' }
                & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
              )> }
            )> }
          ) | (
            { __typename?: 'ShiftEndReasonField' }
            & Pick<ShiftEndReasonField, 'shiftEndReason'>
          ) | (
            { __typename?: 'ShiftTypeIdField' }
            & Pick<ShiftTypeIdField, 'shiftTypeId'>
          ) | (
            { __typename?: 'SpecialEventTypeField' }
            & Pick<SpecialEventTypeField, 'specialEventType'>
          ) | (
            { __typename?: 'TextField' }
            & Pick<TextField, 'text'>
          ) | (
            { __typename?: 'TrafficDisruptionLaneField' }
            & { trafficDisruptionLane: (
              { __typename?: 'TrafficDisruptionLane' }
              & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
            ) }
          ) | (
            { __typename?: 'TrafficDisruptionMediaIdField' }
            & Pick<TrafficDisruptionMediaIdField, 'trafficDisruptionMediaId'>
          ) | (
            { __typename?: 'TrafficDisruptionStatusField' }
            & Pick<TrafficDisruptionStatusField, 'trafficDisruptionEndReason' | 'trafficDisruptionStatus'>
          ) | (
            { __typename?: 'UnitField' }
            & Pick<UnitField, 'displayId' | 'driverId' | 'response'>
          ) | (
            { __typename?: 'UnitIdField' }
            & Pick<UnitIdField, 'shiftUnitId'>
          ) | (
            { __typename?: 'VehicleField' }
            & { vehicle: (
              { __typename?: 'IncidentInvolvedVehicle' }
              & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
            ) }
          ) | (
            { __typename?: 'VenueField' }
            & Pick<VenueField, 'venueAddress' | 'venueName'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type IncidentCamerasByPointQueryVariables = Exact<{
  input: FindStreetCamerasByPointAndRadiusInput;
}>;


export type IncidentCamerasByPointQuery = (
  { __typename?: 'Query' }
  & { incidentCamerasByPoint: Array<(
    { __typename?: 'AssociatedCamera' }
    & Pick<AssociatedCamera, 'default' | 'positionIndex'>
    & { camera: (
      { __typename?: 'StreetCamera' }
      & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
      & { address: (
        { __typename?: 'Address' }
        & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
      ) }
    ) }
  )> }
);

export type IncidentConfigurationQueryVariables = Exact<{ [key: string]: never; }>;


export type IncidentConfigurationQuery = (
  { __typename?: 'Query' }
  & { incidentConfiguration: (
    { __typename?: 'IncidentConfiguration' }
    & Pick<IncidentConfiguration, 'additionalInfoMandatory' | 'affectedLanesMandatory' | 'associatedUnitsMandatory' | 'attributeFixedValues' | 'attributesMandatory' | 'casualtiesMandatory' | 'directionMandatory' | 'incidentTypeValues' | 'notesMandatory' | 'orientationMandatory' | 'orientationValues' | 'responsePlanMandatory' | 'roadTypeValues' | 'severityMandatory' | 'severityValues' | 'sourceMandatory'>
    & { additionalInfoValues: Array<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
    )>, attributeFreeTextValues: Array<(
      { __typename?: 'IncidentAttributeStringValue' }
      & Pick<IncidentAttributeStringValue, 'checked' | 'value'>
    )>, incidentSubTypes: (
      { __typename?: 'IncidentConfigurationSubTypes' }
      & Pick<IncidentConfigurationSubTypes, 'abandonedVehicleSubTypeValues' | 'crashSubTypeValues' | 'damageSubTypeValues' | 'debrisSubTypeValues' | 'hazardSubTypeValues' | 'policeActivitySubTypeValues' | 'stalledVehicleSubTypeValues' | 'trafficStopSubTypeValues'>
    ), mitigationTypeValues: Array<(
      { __typename?: 'MitigationType' }
      & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
    )>, sourceValues: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )> }
  ) }
);

export type IncidentDetailsLogPdfExportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IncidentDetailsLogPdfExportQuery = (
  { __typename?: 'Query' }
  & { incidentDetailsLogPDFExport: (
    { __typename?: 'MediaFile' }
    & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
  ) }
);

export type IncidentMitigationAndUnitsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IncidentMitigationAndUnitsQuery = (
  { __typename?: 'Query' }
  & { incident?: Maybe<(
    { __typename?: 'Incident' }
    & Pick<Incident, 'updatedAt' | 'updatedBy'>
    & { associatedUnits: Array<(
      { __typename?: 'IncidentUnit' }
      & Pick<IncidentUnit, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type' | 'unitResponse' | 'updatedAt'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, driverDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, mitigations: Array<(
      { __typename?: 'IncidentMitigation' }
      & Pick<IncidentMitigation, 'id' | 'unitDisplayId' | 'unitId' | 'unitType' | 'userId'>
      & { interval: (
        { __typename?: 'DateInterval' }
        & Pick<DateInterval, 'from' | 'to'>
      ), mitigationType: (
        { __typename?: 'MitigationType' }
        & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
      ) }
    )> }
  )> }
);

export type IncidentTypePriorityQueryVariables = Exact<{ [key: string]: never; }>;


export type IncidentTypePriorityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'incidentTypePriority'>
);

export type IncidentsAnalyticsDetailsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IncidentsAnalyticsDetailsQuery = (
  { __typename?: 'Query' }
  & { incidentsAnalyticsDetails: (
    { __typename?: 'IncidentDetailedAnalyticsLog' }
    & Pick<IncidentDetailedAnalyticsLog, 'allLanesAffected' | 'arrivalToClearedRoadDurationSeconds' | 'atmsId' | 'attributes' | 'cadId' | 'clearedRoadToCompletedDurationSeconds' | 'createdAt' | 'createdByUserName' | 'creationSource' | 'discoverToArrivalDurationSeconds' | 'durationSec' | 'endReason' | 'endReasonComment' | 'endedAt' | 'estimatedEndTime' | 'fatalities' | 'firstUnitArrivedAt' | 'id' | 'incidentSubType' | 'injuries' | 'injurySeverities' | 'involvedVehiclesCount' | 'location' | 'severity' | 'startedAt' | 'status' | 'title' | 'type' | 'typeDescription'>
    & { additionalInfos: Array<(
      { __typename?: 'IncidentAdditionalInfoDetails' }
      & Pick<IncidentAdditionalInfoDetails, 'info'>
    )>, address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ), affectedLanes: Array<(
      { __typename?: 'IncidentLaneDetails' }
      & Pick<IncidentLaneDetails, 'closureTimeSec'>
      & { closureUpdates: Array<(
        { __typename?: 'LaneClosureUpdateDetails' }
        & Pick<LaneClosureUpdateDetails, 'isClosed' | 'updateTime'>
      )>, incidentLane: (
        { __typename?: 'IncidentLane' }
        & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
      ) }
    )>, associatedDmss: Array<(
      { __typename?: 'DmsDetails' }
      & Pick<DmsDetails, 'externalId' | 'location' | 'title'>
    )>, associatedUnits: Array<(
      { __typename?: 'IncidentUnitDetails' }
      & Pick<IncidentUnitDetails, 'displayId' | 'id' | 'type'>
      & { driverDetails?: Maybe<(
        { __typename?: 'IncidentUnitDriverDetails' }
        & Pick<IncidentUnitDriverDetails, 'accountName' | 'routeNames' | 'userId' | 'userName'>
      )>, mitigations: Array<(
        { __typename?: 'IncidentUnitMitigationDetails' }
        & Pick<IncidentUnitMitigationDetails, 'mitigationDescription' | 'mitigationId' | 'mitigationTypeId' | 'userId'>
        & { interval: (
          { __typename?: 'DateInterval' }
          & Pick<DateInterval, 'from' | 'to'>
        ) }
      )>, responses: Array<(
        { __typename?: 'IncidentUnitResponseUpdateDetails' }
        & Pick<IncidentUnitResponseUpdateDetails, 'responseTime' | 'unitResponse'>
      )> }
    )>, cameras: Array<(
      { __typename?: 'IncidentCameraDetails' }
      & Pick<IncidentCameraDetails, 'default' | 'externalId' | 'location' | 'title'>
    )>, involvedVehicles: Array<(
      { __typename?: 'IncidentInvolvedVehicle' }
      & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
    )>, media: Array<(
      { __typename?: 'IncidentMedia' }
      & Pick<IncidentMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>, notes: Array<(
      { __typename?: 'IncidentNoteDetails' }
      & Pick<IncidentNoteDetails, 'addTime' | 'text'>
    )>, relatedEvents: Array<(
      { __typename?: 'ConstructionRelatedEventDTO' }
      & Pick<ConstructionRelatedEventDto, 'entityType' | 'externalId' | 'id'>
    ) | (
      { __typename?: 'IncidentRelatedEventDTO' }
      & Pick<IncidentRelatedEventDto, 'entityType' | 'externalId' | 'id'>
    ) | (
      { __typename?: 'RoadClosureRelatedEventDTO' }
      & Pick<RoadClosureRelatedEventDto, 'entityType' | 'externalId' | 'id'>
    ) | (
      { __typename?: 'SpecialEventRelatedEventDTO' }
      & Pick<SpecialEventRelatedEventDto, 'entityType' | 'externalId' | 'id'>
    ) | (
      { __typename?: 'TrafficDisruptionRelatedEventDTO' }
      & Pick<TrafficDisruptionRelatedEventDto, 'entityType' | 'externalId' | 'id'>
    )>, reportSources: Array<(
      { __typename?: 'ReportSource' }
      & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
    )> }
  ) }
);

export type IncidentsAnalyticsLogQueryVariables = Exact<{
  input: FilterIncidentsInput;
}>;


export type IncidentsAnalyticsLogQuery = (
  { __typename?: 'Query' }
  & { incidentsAnalyticsLog: (
    { __typename?: 'AnalyticsFilteredIncidentsResultPage' }
    & Pick<AnalyticsFilteredIncidentsResultPage, 'incidentColumnNames' | 'totalNumberOfResults'>
    & { rows: Array<(
      { __typename?: 'FilteredAnalyticsView' }
      & { data: Array<(
        { __typename?: 'AnalyticsAssociatedUnitsColumn' }
        & Pick<AnalyticsAssociatedUnitsColumn, 'associatedUnits' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsAtmsIdColumn' }
        & Pick<AnalyticsAtmsIdColumn, 'atmsId' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsAttributesColumn' }
        & Pick<AnalyticsAttributesColumn, 'attributes' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCadIdColumn' }
        & Pick<AnalyticsCadIdColumn, 'cadId' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCorridorColumn' }
        & Pick<AnalyticsCorridorColumn, 'corridor' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCrossroadColumn' }
        & Pick<AnalyticsCrossroadColumn, 'crossroad' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsDirectionColumn' }
        & Pick<AnalyticsDirectionColumn, 'direction' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsEndReasonColumn' }
        & Pick<AnalyticsEndReasonColumn, 'endReason' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsEndReasonCommentColumn' }
        & Pick<AnalyticsEndReasonCommentColumn, 'endReasonComment' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsFatalitiesColumn' }
        & Pick<AnalyticsFatalitiesColumn, 'fatalities' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentAdditionalInfoColumn' }
        & Pick<AnalyticsIncidentAdditionalInfoColumn, 'additionalInfo' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentAddressColumn' }
        & Pick<AnalyticsIncidentAddressColumn, 'incidentColumnName'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) | (
        { __typename?: 'AnalyticsIncidentCreationSourceColumn' }
        & Pick<AnalyticsIncidentCreationSourceColumn, 'incidentColumnName' | 'source' | 'userName'>
      ) | (
        { __typename?: 'AnalyticsIncidentDurationColumn' }
        & Pick<AnalyticsIncidentDurationColumn, 'durationMillis' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentEndTimeColumn' }
        & Pick<AnalyticsIncidentEndTimeColumn, 'endTime' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentIdColumn' }
        & Pick<AnalyticsIncidentIdColumn, 'id' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentMitigationTypesColumn' }
        & Pick<AnalyticsIncidentMitigationTypesColumn, 'incidentColumnName' | 'mitigationTypes'>
      ) | (
        { __typename?: 'AnalyticsIncidentRelatedEventsColumn' }
        & Pick<AnalyticsIncidentRelatedEventsColumn, 'counter' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentSeverityColumn' }
        & Pick<AnalyticsIncidentSeverityColumn, 'incidentColumnName' | 'severity'>
      ) | (
        { __typename?: 'AnalyticsIncidentStartTimeColumn' }
        & Pick<AnalyticsIncidentStartTimeColumn, 'incidentColumnName' | 'startTime'>
      ) | (
        { __typename?: 'AnalyticsIncidentStatusColumn' }
        & Pick<AnalyticsIncidentStatusColumn, 'incidentColumnName' | 'status'>
      ) | (
        { __typename?: 'AnalyticsIncidentSubTypeColumn' }
        & Pick<AnalyticsIncidentSubTypeColumn, 'incidentColumnName' | 'subType'>
      ) | (
        { __typename?: 'AnalyticsIncidentTypeColumn' }
        & Pick<AnalyticsIncidentTypeColumn, 'incidentColumnName' | 'type'>
      ) | (
        { __typename?: 'AnalyticsInjuriesColumn' }
        & Pick<AnalyticsInjuriesColumn, 'incidentColumnName' | 'injuries'>
      ) | (
        { __typename?: 'AnalyticsInvolvedVehiclesColumn' }
        & Pick<AnalyticsInvolvedVehiclesColumn, 'counter' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsLaneColumn' }
        & Pick<AnalyticsLaneColumn, 'incidentColumnName'>
        & { lanes: Array<(
          { __typename?: 'IncidentLane' }
          & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
        )> }
      ) | (
        { __typename?: 'AnalyticsLanesDirectionColumn' }
        & Pick<AnalyticsLanesDirectionColumn, 'direction' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsLatitudeColumn' }
        & Pick<AnalyticsLatitudeColumn, 'incidentColumnName' | 'latitude'>
      ) | (
        { __typename?: 'AnalyticsLongitudeColumn' }
        & Pick<AnalyticsLongitudeColumn, 'incidentColumnName' | 'longitude'>
      ) | (
        { __typename?: 'AnalyticsMileMarkerColumn' }
        & Pick<AnalyticsMileMarkerColumn, 'incidentColumnName' | 'milemarker'>
      ) | (
        { __typename?: 'AnalyticsNoteColumn' }
        & Pick<AnalyticsNoteColumn, 'incidentColumnName' | 'notes'>
      ) | (
        { __typename?: 'AnalyticsOrientationColumn' }
        & Pick<AnalyticsOrientationColumn, 'incidentColumnName' | 'orientation'>
      ) | (
        { __typename?: 'AnalyticsReportSourceColumn' }
        & Pick<AnalyticsReportSourceColumn, 'incidentColumnName' | 'reportSources'>
      ) | (
        { __typename?: 'AnalyticsRoadTypeColumn' }
        & Pick<AnalyticsRoadTypeColumn, 'incidentColumnName' | 'roadType'>
      ) | (
        { __typename?: 'AnalyticsShiftDurationColumn' }
        & Pick<AnalyticsShiftDurationColumn, 'durationMillis' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftEndMileageColumn' }
        & Pick<AnalyticsShiftEndMileageColumn, 'mileage' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftEndTimeColumn' }
        & Pick<AnalyticsShiftEndTimeColumn, 'endTime' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftFirstMitigationTimeColumn' }
        & Pick<AnalyticsShiftFirstMitigationTimeColumn, 'shiftColumnName' | 'time'>
      ) | (
        { __typename?: 'AnalyticsShiftIdColumn' }
        & Pick<AnalyticsShiftIdColumn, 'shiftColumnName' | 'shiftId'>
      ) | (
        { __typename?: 'AnalyticsShiftIncidentsCountColumn' }
        & Pick<AnalyticsShiftIncidentsCountColumn, 'counter' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftIsCompleteColumn' }
        & Pick<AnalyticsShiftIsCompleteColumn, 'isComplete' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftLastMitigationTimeColumn' }
        & Pick<AnalyticsShiftLastMitigationTimeColumn, 'shiftColumnName' | 'time'>
      ) | (
        { __typename?: 'AnalyticsShiftMitigationTypesColumn' }
        & Pick<AnalyticsShiftMitigationTypesColumn, 'mitigationTypes' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftOvertimeColumn' }
        & Pick<AnalyticsShiftOvertimeColumn, 'isOvertime' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftOvertimeNoteColumn' }
        & Pick<AnalyticsShiftOvertimeNoteColumn, 'modified' | 'note' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftPausesColumn' }
        & Pick<AnalyticsShiftPausesColumn, 'counter' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftRoutesColumn' }
        & Pick<AnalyticsShiftRoutesColumn, 'modified' | 'names' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftStartMileageColumn' }
        & Pick<AnalyticsShiftStartMileageColumn, 'mileage' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftStartTimeColumn' }
        & Pick<AnalyticsShiftStartTimeColumn, 'modified' | 'shiftColumnName' | 'startTime'>
      ) | (
        { __typename?: 'AnalyticsShiftTotalMileageColumn' }
        & Pick<AnalyticsShiftTotalMileageColumn, 'mileage' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftTypeColumn' }
        & Pick<AnalyticsShiftTypeColumn, 'modified' | 'shiftColumnName' | 'shiftType'>
      ) | (
        { __typename?: 'AnalyticsShiftUnitColumn' }
        & Pick<AnalyticsShiftUnitColumn, 'displayId' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftUserNameColumn' }
        & Pick<AnalyticsShiftUserNameColumn, 'name' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDEndTimeColumn' }
        & Pick<AnalyticsTdEndTimeColumn, 'endTime' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDIdColumn' }
        & Pick<AnalyticsTdIdColumn, 'id' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDPermitIdColumn' }
        & Pick<AnalyticsTdPermitIdColumn, 'permitId' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDRelatedEventsColumn' }
        & Pick<AnalyticsTdRelatedEventsColumn, 'counter' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDStartTimeColumn' }
        & Pick<AnalyticsTdStartTimeColumn, 'startTime' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDStatusColumn' }
        & Pick<AnalyticsTdStatusColumn, 'tdColumnName' | 'tdStatus'>
      ) | (
        { __typename?: 'AnalyticsTDSubTypeColumn' }
        & Pick<AnalyticsTdSubTypeColumn, 'subType' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDTitleColumn' }
        & Pick<AnalyticsTdTitleColumn, 'tdColumnName' | 'title'>
      ) | (
        { __typename?: 'AnalyticsTDTypeColumn' }
        & Pick<AnalyticsTdTypeColumn, 'tdColumnName' | 'tdType'>
      ) | (
        { __typename?: 'AnalyticsUsersInvolvedColumn' }
        & Pick<AnalyticsUsersInvolvedColumn, 'incidentColumnName' | 'users'>
      )> }
    )> }
  ) }
);

export type IncidentsAnalyticsLogCsvExportQueryVariables = Exact<{
  input: FilterIncidentsInput;
}>;


export type IncidentsAnalyticsLogCsvExportQuery = (
  { __typename?: 'Query' }
  & { incidentsAnalyticsLogCsvExport: (
    { __typename?: 'MediaFile' }
    & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
  ) }
);

export type IncidentsAnalyticsLogCsvExportEmailQueryVariables = Exact<{
  input: FilterIncidentsInput;
}>;


export type IncidentsAnalyticsLogCsvExportEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'incidentsAnalyticsLogCsvExportEmail'>
);

export type IsResponsePlanDoneQueryVariables = Exact<{
  entityId: Scalars['Int'];
  entityType: ResponsePlanEntityType;
}>;


export type IsResponsePlanDoneQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isResponsePlanDone'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
      & { groups: Array<(
        { __typename?: 'PermissionGroup' }
        & Pick<PermissionGroup, 'id' | 'level' | 'type'>
        & { permissions: Array<(
          { __typename?: 'Permission' }
          & Pick<Permission, 'id'>
          & { dependsOnAll?: Maybe<Array<(
            { __typename?: 'Scope' }
            & Pick<Scope, 'entity' | 'modifier'>
          )>>, dependsOnAny?: Maybe<Array<(
            { __typename?: 'Scope' }
            & Pick<Scope, 'entity' | 'modifier'>
          )>>, scope: (
            { __typename?: 'Scope' }
            & Pick<Scope, 'entity' | 'modifier'>
          ) }
        )> }
      )>, additionalInfos: Array<(
        { __typename?: 'AdditionalInfo' }
        & Pick<AdditionalInfo, 'accountId' | 'id' | 'info'>
      )>, analyticsReportsUrls?: Maybe<(
        { __typename?: 'AnalyticsReportsUrls' }
        & { fixedRouteTransitReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, fspReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, incidentAnalysisReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, mitigationsReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, speedMeasurementReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, timPerformanceReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, transitReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, travelReliabilityReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>>, unitsPerformanceReport?: Maybe<Array<(
          { __typename?: 'AnalyticsReportSrc' }
          & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
        )>> }
      )>, customer: (
        { __typename?: 'Customer' }
        & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
      ), mitigationTypes: Array<(
        { __typename?: 'MitigationType' }
        & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
      )>, publicationTemplates: Array<(
        { __typename?: 'PublicationTemplate' }
        & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
      )>, publicationVariables: (
        { __typename?: 'AccountPublicationVariables' }
        & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
      ), regionalSetting: (
        { __typename?: 'RegionalSetting' }
        & Pick<RegionalSetting, 'firstDayOfWeek' | 'dateFormat' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
      ), reportSources: Array<(
        { __typename?: 'ReportSource' }
        & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
      )>, routeTypes: Array<(
        { __typename?: 'RouteType' }
        & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
      )>, shiftTypes: Array<(
        { __typename?: 'ShiftType' }
        & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
      )>, workspaces: (
        { __typename?: 'AccountWorkspaces' }
        & Pick<AccountWorkspaces, 'singleMultiPolygon'>
        & { workspaces: Array<(
          { __typename?: 'Workspace' }
          & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
        )> }
      ) }
    ), permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type'>
      & { userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  ) }
);

export type PermitsByAccountIdQueryVariables = Exact<{ [key: string]: never; }>;


export type PermitsByAccountIdQuery = (
  { __typename?: 'Query' }
  & { permitsByAccountId: Array<(
    { __typename?: 'AccountPermit' }
    & Pick<AccountPermit, 'accountId' | 'id' | 'type' | 'url'>
  )> }
);

export type RelatedEventsQueryVariables = Exact<{
  eventId: Scalars['Int'];
}>;


export type RelatedEventsQuery = (
  { __typename?: 'Query' }
  & { relatedEvents: Array<(
    { __typename?: 'ConstructionRelatedEventDTO' }
    & Pick<ConstructionRelatedEventDto, 'constructionType' | 'entityType' | 'externalId' | 'id' | 'location' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  ) | (
    { __typename?: 'IncidentRelatedEventDTO' }
    & Pick<IncidentRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'incidentStatus' | 'incidentTitle' | 'incidentType' | 'subType'>
    & { incidentAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  ) | (
    { __typename?: 'RoadClosureRelatedEventDTO' }
    & Pick<RoadClosureRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'location' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  ) | (
    { __typename?: 'SpecialEventRelatedEventDTO' }
    & Pick<SpecialEventRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'location' | 'specialEventType' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  )> }
);

export type ResponsePlansByEntityTypeQueryVariables = Exact<{
  entityType: ResponsePlanEntityType;
}>;


export type ResponsePlansByEntityTypeQuery = (
  { __typename?: 'Query' }
  & { responsePlansByEntityType: Array<(
    { __typename?: 'ResponsePlanTemplate' }
    & Pick<ResponsePlanTemplate, 'accountId' | 'entityType' | 'id' | 'title' | 'updatedAt'>
    & { actions: Array<(
      { __typename?: 'TemplateAction' }
      & Pick<TemplateAction, 'actionField' | 'index' | 'templateActionId' | 'templateId' | 'text' | 'time' | 'unit' | 'value'>
    )> }
  )> }
);

export type RoadClosureQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RoadClosureQuery = (
  { __typename?: 'Query' }
  & { roadClosure?: Maybe<(
    { __typename?: 'RoadClosure' }
    & Pick<RoadClosure, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'startTime' | 'status' | 'title' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, cameras: Array<(
      { __typename?: 'AssociatedCamera' }
      & Pick<AssociatedCamera, 'default' | 'positionIndex'>
      & { camera: (
        { __typename?: 'StreetCamera' }
        & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, dmses: Array<(
      { __typename?: 'AssociatedDMS' }
      & Pick<AssociatedDms, 'positionIndex'>
      & { dms: (
        { __typename?: 'DMS' }
        & Pick<Dms, 'editable' | 'externalId' | 'id' | 'location' | 'messages' | 'status' | 'title' | 'updatedAt'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) }
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, permit?: Maybe<(
      { __typename?: 'Permit' }
      & Pick<Permit, 'permitId' | 'url'>
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  )> }
);

export type RoadClosureActivityLogQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RoadClosureActivityLogQuery = (
  { __typename?: 'Query' }
  & { roadClosureActivityLog: Array<(
    { __typename?: 'ActivityLogAggregated' }
    & Pick<ActivityLogAggregated, 'timeByDay'>
    & { activitiesPerDay: Array<(
      { __typename?: 'ActivityPerDay' }
      & Pick<ActivityPerDay, 'accountName' | 'timeByMinute' | 'userName'>
      & { activitiesPerMinute: Array<(
        { __typename?: 'ActivitySection' }
        & Pick<ActivitySection, 'activityLogEntityType' | 'activityLogSectionType' | 'maxOfflineUpdatedAt'>
        & { activities: Array<(
          { __typename?: 'ActivityStructure' }
          & Pick<ActivityStructure, 'action' | 'field' | 'offlineUpdatedAt'>
          & { value: (
            { __typename?: 'AdditionalInfoField' }
            & Pick<AdditionalInfoField, 'info'>
          ) | (
            { __typename?: 'AddressField' }
            & { address: (
              { __typename?: 'Address' }
              & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
            ) }
          ) | (
            { __typename?: 'BooleanField' }
            & Pick<BooleanField, 'bool'>
          ) | (
            { __typename?: 'CameraField' }
            & Pick<CameraField, 'default' | 'externalId' | 'title'>
          ) | (
            { __typename?: 'ConstructionTypeField' }
            & Pick<ConstructionTypeField, 'constructionType'>
          ) | (
            { __typename?: 'ContactPersonField' }
            & Pick<ContactPersonField, 'email' | 'name' | 'phoneNumber'>
          ) | (
            { __typename?: 'DateTimeField' }
            & Pick<DateTimeField, 'dateTime'>
          ) | (
            { __typename?: 'DmsField' }
            & Pick<DmsField, 'dmsId' | 'title'>
          ) | (
            { __typename?: 'GeometryField' }
            & Pick<GeometryField, 'geometry'>
          ) | (
            { __typename?: 'IncidentAutoPublishField' }
            & Pick<IncidentAutoPublishField, 'autoPublish'>
          ) | (
            { __typename?: 'IncidentLaneField' }
            & { incidentLane: (
              { __typename?: 'IncidentLane' }
              & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
            ) }
          ) | (
            { __typename?: 'IncidentMediaIdField' }
            & Pick<IncidentMediaIdField, 'incidentMediaId'>
          ) | (
            { __typename?: 'IncidentSeverityField' }
            & Pick<IncidentSeverityField, 'incidentSeverity'>
          ) | (
            { __typename?: 'IncidentStatusField' }
            & Pick<IncidentStatusField, 'automaticAction' | 'endReason' | 'endReasonComment' | 'status'>
          ) | (
            { __typename?: 'IncidentSubTypeField' }
            & Pick<IncidentSubTypeField, 'subType'>
          ) | (
            { __typename?: 'IncidentTypeField' }
            & Pick<IncidentTypeField, 'incidentType'>
          ) | (
            { __typename?: 'InjurySeveritiesField' }
            & Pick<InjurySeveritiesField, 'injurySeverity'>
          ) | (
            { __typename?: 'IntField' }
            & Pick<IntField, 'n'>
          ) | (
            { __typename?: 'LanesClosureTypeField' }
            & Pick<LanesClosureTypeField, 'lanesClosureType'>
          ) | (
            { __typename?: 'LocationField' }
            & Pick<LocationField, 'point'>
          ) | (
            { __typename?: 'MitigationField' }
            & Pick<MitigationField, 'description' | 'unitDisplayId' | 'unitId'>
            & { interval?: Maybe<(
              { __typename?: 'DateInterval' }
              & Pick<DateInterval, 'from' | 'to'>
            )> }
          ) | (
            { __typename?: 'NoteField' }
            & Pick<NoteField, 'note'>
          ) | (
            { __typename?: 'NullableTextField' }
            & Pick<NullableTextField, 'nullableText'>
          ) | (
            { __typename?: 'OccupancyRangeField' }
            & Pick<OccupancyRangeField, 'occupancyRange'>
          ) | (
            { __typename?: 'PermitField' }
            & Pick<PermitField, 'permitId' | 'url'>
          ) | (
            { __typename?: 'PublishField' }
            & Pick<PublishField, 'description' | 'destinations'>
          ) | (
            { __typename?: 'RelatedEventField' }
            & Pick<RelatedEventField, 'endTime' | 'eventId' | 'relatedEventStatus' | 'startTime' | 'type'>
          ) | (
            { __typename?: 'ReportSourceField' }
            & Pick<ReportSourceField, 'accountId' | 'reportSource'>
          ) | (
            { __typename?: 'RouteTypeIdsField' }
            & Pick<RouteTypeIdsField, 'routeTypesIds'>
          ) | (
            { __typename?: 'ScheduleField' }
            & Pick<ScheduleField, 'endTime' | 'startTime'>
            & { schedule?: Maybe<(
              { __typename?: 'TrafficDisruptionScheduleDTO' }
              & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
              & { timeRanges: Array<(
                { __typename?: 'TimeRangeSchedule' }
                & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
              )> }
            )> }
          ) | (
            { __typename?: 'ShiftEndReasonField' }
            & Pick<ShiftEndReasonField, 'shiftEndReason'>
          ) | (
            { __typename?: 'ShiftTypeIdField' }
            & Pick<ShiftTypeIdField, 'shiftTypeId'>
          ) | (
            { __typename?: 'SpecialEventTypeField' }
            & Pick<SpecialEventTypeField, 'specialEventType'>
          ) | (
            { __typename?: 'TextField' }
            & Pick<TextField, 'text'>
          ) | (
            { __typename?: 'TrafficDisruptionLaneField' }
            & { trafficDisruptionLane: (
              { __typename?: 'TrafficDisruptionLane' }
              & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
            ) }
          ) | (
            { __typename?: 'TrafficDisruptionMediaIdField' }
            & Pick<TrafficDisruptionMediaIdField, 'trafficDisruptionMediaId'>
          ) | (
            { __typename?: 'TrafficDisruptionStatusField' }
            & Pick<TrafficDisruptionStatusField, 'trafficDisruptionEndReason' | 'trafficDisruptionStatus'>
          ) | (
            { __typename?: 'UnitField' }
            & Pick<UnitField, 'displayId' | 'driverId' | 'response'>
          ) | (
            { __typename?: 'UnitIdField' }
            & Pick<UnitIdField, 'shiftUnitId'>
          ) | (
            { __typename?: 'VehicleField' }
            & { vehicle: (
              { __typename?: 'IncidentInvolvedVehicle' }
              & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
            ) }
          ) | (
            { __typename?: 'VenueField' }
            & Pick<VenueField, 'venueAddress' | 'venueName'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type RoadClosureByAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type RoadClosureByAccountQuery = (
  { __typename?: 'Query' }
  & { roadClosureByAccount: Array<(
    { __typename?: 'Construction' }
    & Pick<Construction, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'startTime' | 'status' | 'title' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  ) | (
    { __typename?: 'RoadClosure' }
    & Pick<RoadClosure, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'startTime' | 'status' | 'title' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  ) | (
    { __typename?: 'SpecialEvent' }
    & Pick<SpecialEvent, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'startTime' | 'status' | 'title' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  )> }
);

export type RoadNamesByAccountQueryVariables = Exact<{
  subString: Scalars['String'];
}>;


export type RoadNamesByAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'roadNamesByAccount'>
);

export type RoleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RoleQuery = (
  { __typename?: 'Query' }
  & { role: (
    { __typename?: 'Role' }
    & Pick<Role, 'accountId' | 'description' | 'id' | 'name'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )> }
  ) }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'accountId' | 'description' | 'id' | 'name'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )> }
  )> }
);

export type SearchActiveEventsQueryVariables = Exact<{
  searchString: Scalars['String'];
}>;


export type SearchActiveEventsQuery = (
  { __typename?: 'Query' }
  & { searchActiveEvents: Array<(
    { __typename?: 'ConstructionRelatedEventDTO' }
    & Pick<ConstructionRelatedEventDto, 'constructionType' | 'entityType' | 'externalId' | 'id' | 'location' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  ) | (
    { __typename?: 'IncidentRelatedEventDTO' }
    & Pick<IncidentRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'incidentStatus' | 'incidentTitle' | 'incidentType' | 'subType'>
    & { incidentAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  ) | (
    { __typename?: 'RoadClosureRelatedEventDTO' }
    & Pick<RoadClosureRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'location' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  ) | (
    { __typename?: 'SpecialEventRelatedEventDTO' }
    & Pick<SpecialEventRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'location' | 'specialEventType' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  )> }
);

export type SearchCompletedEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchCompletedEventsQuery = (
  { __typename?: 'Query' }
  & { searchCompletedEvents: Array<(
    { __typename?: 'ConstructionRelatedEventDTO' }
    & Pick<ConstructionRelatedEventDto, 'constructionType' | 'entityType' | 'externalId' | 'id' | 'location' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  ) | (
    { __typename?: 'IncidentRelatedEventDTO' }
    & Pick<IncidentRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'incidentStatus' | 'incidentTitle' | 'incidentType' | 'subType'>
    & { incidentAddress: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  ) | (
    { __typename?: 'RoadClosureRelatedEventDTO' }
    & Pick<RoadClosureRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'location' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  ) | (
    { __typename?: 'SpecialEventRelatedEventDTO' }
    & Pick<SpecialEventRelatedEventDto, 'entityType' | 'externalId' | 'id' | 'location' | 'specialEventType' | 'trafficDisruptionStatus' | 'trafficDisruptionTitle'>
    & { trafficDisruptionAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )> }
  )> }
);

export type SegmentQueryVariables = Exact<{
  point: Scalars['Point'];
  radiusMeters?: Maybe<Scalars['Short']>;
}>;


export type SegmentQuery = (
  { __typename?: 'Query' }
  & { segment: (
    { __typename?: 'SegmentDetails' }
    & Pick<SegmentDetails, 'alias' | 'city' | 'county' | 'crossroadSource' | 'direction' | 'lanesQuantity' | 'milemarkers' | 'names' | 'orientation' | 'overridingCorridorAlias' | 'overridingCrossroadAlias' | 'refs' | 'roadLevelTypes' | 'roadTypes' | 'segmentId' | 'state'>
    & { crossRoads: Array<(
      { __typename?: 'SegmentsDropdownDetails' }
      & Pick<SegmentsDropdownDetails, 'distance' | 'name' | 'nearestPoint' | 'roadType'>
    )>, segmentsNames: Array<(
      { __typename?: 'SegmentsDropdownDetails' }
      & Pick<SegmentsDropdownDetails, 'distance' | 'name' | 'nearestPoint' | 'roadType'>
    )> }
  ) }
);

export type ShiftQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ShiftQuery = (
  { __typename?: 'Query' }
  & { shift?: Maybe<(
    { __typename?: 'ShiftDTO' }
    & Pick<ShiftDto, 'endMileage' | 'endReason' | 'endReasonNote' | 'endedAt' | 'isDeleted' | 'overtimeNote' | 'shiftId' | 'startMileage' | 'startedAt'>
    & { routeTypes: Array<(
      { __typename?: 'RouteType' }
      & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
    )>, shiftPauses: Array<(
      { __typename?: 'ShiftPauseDTO' }
      & Pick<ShiftPauseDto, 'endedAt' | 'isActive' | 'pauseId' | 'reason' | 'reasonNote' | 'shiftId' | 'startedAt'>
    )>, shiftType?: Maybe<(
      { __typename?: 'ShiftType' }
      & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      & { dashCameras: Array<(
        { __typename?: 'UnitDashCamera' }
        & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
      )>, userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'creationTime' | 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
      & { account: (
        { __typename?: 'Account' }
        & Pick<Account, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'hubspotCompany' | 'id' | 'mapCenter' | 'name' | 'publishChannels' | 'status'>
        & { additionalInfos: Array<(
          { __typename?: 'AdditionalInfo' }
          & Pick<AdditionalInfo, 'accountId' | 'id' | 'info' | 'isDeleted'>
        )>, analyticsReportsUrls?: Maybe<(
          { __typename?: 'AnalyticsReportsUrls' }
          & { fixedRouteTransitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, fspReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, incidentAnalysisReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, mitigationsReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, speedMeasurementReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, timPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, transitReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, travelReliabilityReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>>, unitsPerformanceReport?: Maybe<Array<(
            { __typename?: 'AnalyticsReportSrc' }
            & Pick<AnalyticsReportSrc, 'index' | 'name' | 'url'>
          )>> }
        )>, customer: (
          { __typename?: 'Customer' }
          & Pick<Customer, 'contactEmail' | 'contactName' | 'contactPhoneNumber' | 'id' | 'name'>
        ), groups: Array<(
          { __typename?: 'PermissionGroup' }
          & Pick<PermissionGroup, 'id' | 'level' | 'type'>
          & { permissions: Array<(
            { __typename?: 'Permission' }
            & Pick<Permission, 'id'>
            & { dependsOnAll?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, dependsOnAny?: Maybe<Array<(
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            )>>, scope: (
              { __typename?: 'Scope' }
              & Pick<Scope, 'entity' | 'modifier'>
            ) }
          )> }
        )>, mitigationTypes: Array<(
          { __typename?: 'MitigationType' }
          & Pick<MitigationType, 'accountId' | 'description' | 'id' | 'isDeleted'>
        )>, publicationTemplates: Array<(
          { __typename?: 'PublicationTemplate' }
          & Pick<PublicationTemplate, 'entityType' | 'id' | 'phrases' | 'template'>
        )>, publicationVariables: (
          { __typename?: 'AccountPublicationVariables' }
          & Pick<AccountPublicationVariables, 'constructionVariables' | 'incidentVariables' | 'roadClosureVariables'>
        ), regionalSetting: (
          { __typename?: 'RegionalSetting' }
          & Pick<RegionalSetting, 'dateFormat' | 'firstDayOfWeek' | 'language' | 'measureUnitType' | 'timeFormat' | 'timeZone'>
        ), reportSources: Array<(
          { __typename?: 'ReportSource' }
          & Pick<ReportSource, 'accountId' | 'id' | 'reportSource' | 'status'>
        )>, routeTypes: Array<(
          { __typename?: 'RouteType' }
          & Pick<RouteType, 'accountId' | 'area' | 'id' | 'name' | 'status'>
        )>, shiftTypes: Array<(
          { __typename?: 'ShiftType' }
          & Pick<ShiftType, 'accountId' | 'id' | 'name' | 'status'>
        )>, workspaces: (
          { __typename?: 'AccountWorkspaces' }
          & Pick<AccountWorkspaces, 'singleMultiPolygon'>
          & { workspaces: Array<(
            { __typename?: 'Workspace' }
            & Pick<Workspace, 'accountId' | 'area' | 'featureTypes' | 'id' | 'roadTypes' | 'title'>
          )> }
        ) }
      ), permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id'>
      )>, unit?: Maybe<(
        { __typename?: 'UnitVehicle' }
        & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
      )> }
    ) }
  )> }
);

export type ShiftsAnalyticsLogQueryVariables = Exact<{
  input: FilterShiftsInput;
}>;


export type ShiftsAnalyticsLogQuery = (
  { __typename?: 'Query' }
  & { shiftsAnalyticsLog: (
    { __typename?: 'AnalyticsFilteredShiftsResultPage' }
    & Pick<AnalyticsFilteredShiftsResultPage, 'shiftColumnNames' | 'totalNumberOfResults'>
    & { rows: Array<(
      { __typename?: 'FilteredAnalyticsView' }
      & { data: Array<(
        { __typename?: 'AnalyticsAssociatedUnitsColumn' }
        & Pick<AnalyticsAssociatedUnitsColumn, 'associatedUnits' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsAtmsIdColumn' }
        & Pick<AnalyticsAtmsIdColumn, 'atmsId' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsAttributesColumn' }
        & Pick<AnalyticsAttributesColumn, 'attributes' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCadIdColumn' }
        & Pick<AnalyticsCadIdColumn, 'cadId' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCorridorColumn' }
        & Pick<AnalyticsCorridorColumn, 'corridor' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCrossroadColumn' }
        & Pick<AnalyticsCrossroadColumn, 'crossroad' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsDirectionColumn' }
        & Pick<AnalyticsDirectionColumn, 'direction' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsEndReasonColumn' }
        & Pick<AnalyticsEndReasonColumn, 'endReason' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsEndReasonCommentColumn' }
        & Pick<AnalyticsEndReasonCommentColumn, 'endReasonComment' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsFatalitiesColumn' }
        & Pick<AnalyticsFatalitiesColumn, 'fatalities' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentAdditionalInfoColumn' }
        & Pick<AnalyticsIncidentAdditionalInfoColumn, 'additionalInfo' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentAddressColumn' }
        & Pick<AnalyticsIncidentAddressColumn, 'incidentColumnName'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) | (
        { __typename?: 'AnalyticsIncidentCreationSourceColumn' }
        & Pick<AnalyticsIncidentCreationSourceColumn, 'incidentColumnName' | 'source' | 'userName'>
      ) | (
        { __typename?: 'AnalyticsIncidentDurationColumn' }
        & Pick<AnalyticsIncidentDurationColumn, 'durationMillis' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentEndTimeColumn' }
        & Pick<AnalyticsIncidentEndTimeColumn, 'endTime' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentIdColumn' }
        & Pick<AnalyticsIncidentIdColumn, 'id' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentMitigationTypesColumn' }
        & Pick<AnalyticsIncidentMitigationTypesColumn, 'incidentColumnName' | 'mitigationTypes'>
      ) | (
        { __typename?: 'AnalyticsIncidentRelatedEventsColumn' }
        & Pick<AnalyticsIncidentRelatedEventsColumn, 'counter' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentSeverityColumn' }
        & Pick<AnalyticsIncidentSeverityColumn, 'incidentColumnName' | 'severity'>
      ) | (
        { __typename?: 'AnalyticsIncidentStartTimeColumn' }
        & Pick<AnalyticsIncidentStartTimeColumn, 'incidentColumnName' | 'startTime'>
      ) | (
        { __typename?: 'AnalyticsIncidentStatusColumn' }
        & Pick<AnalyticsIncidentStatusColumn, 'incidentColumnName' | 'status'>
      ) | (
        { __typename?: 'AnalyticsIncidentSubTypeColumn' }
        & Pick<AnalyticsIncidentSubTypeColumn, 'incidentColumnName' | 'subType'>
      ) | (
        { __typename?: 'AnalyticsIncidentTypeColumn' }
        & Pick<AnalyticsIncidentTypeColumn, 'incidentColumnName' | 'type'>
      ) | (
        { __typename?: 'AnalyticsInjuriesColumn' }
        & Pick<AnalyticsInjuriesColumn, 'incidentColumnName' | 'injuries'>
      ) | (
        { __typename?: 'AnalyticsInvolvedVehiclesColumn' }
        & Pick<AnalyticsInvolvedVehiclesColumn, 'counter' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsLaneColumn' }
        & Pick<AnalyticsLaneColumn, 'incidentColumnName'>
        & { lanes: Array<(
          { __typename?: 'IncidentLane' }
          & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
        )> }
      ) | (
        { __typename?: 'AnalyticsLanesDirectionColumn' }
        & Pick<AnalyticsLanesDirectionColumn, 'direction' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsLatitudeColumn' }
        & Pick<AnalyticsLatitudeColumn, 'incidentColumnName' | 'latitude'>
      ) | (
        { __typename?: 'AnalyticsLongitudeColumn' }
        & Pick<AnalyticsLongitudeColumn, 'incidentColumnName' | 'longitude'>
      ) | (
        { __typename?: 'AnalyticsMileMarkerColumn' }
        & Pick<AnalyticsMileMarkerColumn, 'incidentColumnName' | 'milemarker'>
      ) | (
        { __typename?: 'AnalyticsNoteColumn' }
        & Pick<AnalyticsNoteColumn, 'incidentColumnName' | 'notes'>
      ) | (
        { __typename?: 'AnalyticsOrientationColumn' }
        & Pick<AnalyticsOrientationColumn, 'incidentColumnName' | 'orientation'>
      ) | (
        { __typename?: 'AnalyticsReportSourceColumn' }
        & Pick<AnalyticsReportSourceColumn, 'incidentColumnName' | 'reportSources'>
      ) | (
        { __typename?: 'AnalyticsRoadTypeColumn' }
        & Pick<AnalyticsRoadTypeColumn, 'incidentColumnName' | 'roadType'>
      ) | (
        { __typename?: 'AnalyticsShiftDurationColumn' }
        & Pick<AnalyticsShiftDurationColumn, 'durationMillis' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftEndMileageColumn' }
        & Pick<AnalyticsShiftEndMileageColumn, 'mileage' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftEndTimeColumn' }
        & Pick<AnalyticsShiftEndTimeColumn, 'endTime' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftFirstMitigationTimeColumn' }
        & Pick<AnalyticsShiftFirstMitigationTimeColumn, 'shiftColumnName' | 'time'>
      ) | (
        { __typename?: 'AnalyticsShiftIdColumn' }
        & Pick<AnalyticsShiftIdColumn, 'shiftColumnName' | 'shiftId'>
      ) | (
        { __typename?: 'AnalyticsShiftIncidentsCountColumn' }
        & Pick<AnalyticsShiftIncidentsCountColumn, 'counter' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftIsCompleteColumn' }
        & Pick<AnalyticsShiftIsCompleteColumn, 'isComplete' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftLastMitigationTimeColumn' }
        & Pick<AnalyticsShiftLastMitigationTimeColumn, 'shiftColumnName' | 'time'>
      ) | (
        { __typename?: 'AnalyticsShiftMitigationTypesColumn' }
        & Pick<AnalyticsShiftMitigationTypesColumn, 'mitigationTypes' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftOvertimeColumn' }
        & Pick<AnalyticsShiftOvertimeColumn, 'isOvertime' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftOvertimeNoteColumn' }
        & Pick<AnalyticsShiftOvertimeNoteColumn, 'modified' | 'note' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftPausesColumn' }
        & Pick<AnalyticsShiftPausesColumn, 'counter' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftRoutesColumn' }
        & Pick<AnalyticsShiftRoutesColumn, 'modified' | 'names' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftStartMileageColumn' }
        & Pick<AnalyticsShiftStartMileageColumn, 'mileage' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftStartTimeColumn' }
        & Pick<AnalyticsShiftStartTimeColumn, 'modified' | 'shiftColumnName' | 'startTime'>
      ) | (
        { __typename?: 'AnalyticsShiftTotalMileageColumn' }
        & Pick<AnalyticsShiftTotalMileageColumn, 'mileage' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftTypeColumn' }
        & Pick<AnalyticsShiftTypeColumn, 'modified' | 'shiftColumnName' | 'shiftType'>
      ) | (
        { __typename?: 'AnalyticsShiftUnitColumn' }
        & Pick<AnalyticsShiftUnitColumn, 'displayId' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftUserNameColumn' }
        & Pick<AnalyticsShiftUserNameColumn, 'name' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDEndTimeColumn' }
        & Pick<AnalyticsTdEndTimeColumn, 'endTime' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDIdColumn' }
        & Pick<AnalyticsTdIdColumn, 'id' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDPermitIdColumn' }
        & Pick<AnalyticsTdPermitIdColumn, 'permitId' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDRelatedEventsColumn' }
        & Pick<AnalyticsTdRelatedEventsColumn, 'counter' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDStartTimeColumn' }
        & Pick<AnalyticsTdStartTimeColumn, 'startTime' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDStatusColumn' }
        & Pick<AnalyticsTdStatusColumn, 'tdColumnName' | 'tdStatus'>
      ) | (
        { __typename?: 'AnalyticsTDSubTypeColumn' }
        & Pick<AnalyticsTdSubTypeColumn, 'subType' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDTitleColumn' }
        & Pick<AnalyticsTdTitleColumn, 'tdColumnName' | 'title'>
      ) | (
        { __typename?: 'AnalyticsTDTypeColumn' }
        & Pick<AnalyticsTdTypeColumn, 'tdColumnName' | 'tdType'>
      ) | (
        { __typename?: 'AnalyticsUsersInvolvedColumn' }
        & Pick<AnalyticsUsersInvolvedColumn, 'incidentColumnName' | 'users'>
      )> }
    )> }
  ) }
);

export type ShiftsAnalyticsLogCsvExportQueryVariables = Exact<{
  input: FilterShiftsInput;
}>;


export type ShiftsAnalyticsLogCsvExportQuery = (
  { __typename?: 'Query' }
  & { shiftsAnalyticsLogCsvExport: (
    { __typename?: 'MediaFile' }
    & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
  ) }
);

export type ShiftsAnalyticsLogCsvExportEmailQueryVariables = Exact<{
  input: FilterShiftsInput;
}>;


export type ShiftsAnalyticsLogCsvExportEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'shiftsAnalyticsLogCsvExportEmail'>
);

export type SpecialEventQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SpecialEventQuery = (
  { __typename?: 'Query' }
  & { specialEvent?: Maybe<(
    { __typename?: 'SpecialEvent' }
    & Pick<SpecialEvent, 'allLanesAffected' | 'createdAt' | 'createdBy' | 'description' | 'endTime' | 'externalId' | 'feedPriorityType' | 'id' | 'isAllDay' | 'lanesClosureType' | 'location' | 'multiDirectionLanesAffected' | 'occupancy' | 'occupancyRange' | 'otherTypeDescription' | 'startTime' | 'status' | 'title' | 'type' | 'updatedAt' | 'updatedBy'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    )>, affectedLanes?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionLane' }
      & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
    )>>, contactPerson?: Maybe<(
      { __typename?: 'TrafficDisruptionContactPerson' }
      & Pick<TrafficDisruptionContactPerson, 'email' | 'name' | 'phoneNumber'>
    )>, media?: Maybe<Array<(
      { __typename?: 'TrafficDisruptionMedia' }
      & Pick<TrafficDisruptionMedia, 'id'>
      & { media: (
        { __typename?: 'MediaFile' }
        & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
      ) }
    )>>, responsePlan?: Maybe<(
      { __typename?: 'ResponsePlan' }
      & Pick<ResponsePlan, 'entityId' | 'entityType' | 'id' | 'isDeleted' | 'templateId' | 'title' | 'updatedAt'>
      & { actions: Array<(
        { __typename?: 'ResponsePlanAction' }
        & Pick<ResponsePlanAction, 'actionField' | 'index' | 'isDone' | 'responsePlanActionId' | 'responsePlanId' | 'text' | 'time' | 'unit' | 'value'>
      )> }
    )>, schedule?: Maybe<(
      { __typename?: 'TrafficDisruptionScheduleDTO' }
      & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
      & { timeRanges: Array<(
        { __typename?: 'TimeRangeSchedule' }
        & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
      )> }
    )>, venue?: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'address' | 'name'>
    )>, workzoneDataExchange?: Maybe<(
      { __typename?: 'WorkzoneDataExchangeDTO' }
      & Pick<WorkzoneDataExchangeDto, 'bbox' | 'beginningCrossStreet' | 'direction' | 'endingCrossStreet' | 'isEndDateVerified' | 'isEndPositionVerified' | 'isStartDateVerified' | 'isStartPositionVerified' | 'locationMethod' | 'roadNames' | 'vehicleImpact'>
    )> }
  )> }
);

export type SpecialEventActivityLogQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SpecialEventActivityLogQuery = (
  { __typename?: 'Query' }
  & { specialEventActivityLog: Array<(
    { __typename?: 'ActivityLogAggregated' }
    & Pick<ActivityLogAggregated, 'timeByDay'>
    & { activitiesPerDay: Array<(
      { __typename?: 'ActivityPerDay' }
      & Pick<ActivityPerDay, 'accountName' | 'timeByMinute' | 'userName'>
      & { activitiesPerMinute: Array<(
        { __typename?: 'ActivitySection' }
        & Pick<ActivitySection, 'activityLogEntityType' | 'activityLogSectionType' | 'maxOfflineUpdatedAt'>
        & { activities: Array<(
          { __typename?: 'ActivityStructure' }
          & Pick<ActivityStructure, 'action' | 'field' | 'offlineUpdatedAt'>
          & { value: (
            { __typename?: 'AdditionalInfoField' }
            & Pick<AdditionalInfoField, 'info'>
          ) | (
            { __typename?: 'AddressField' }
            & { address: (
              { __typename?: 'Address' }
              & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
            ) }
          ) | (
            { __typename?: 'BooleanField' }
            & Pick<BooleanField, 'bool'>
          ) | (
            { __typename?: 'CameraField' }
            & Pick<CameraField, 'default' | 'externalId' | 'title'>
          ) | (
            { __typename?: 'ConstructionTypeField' }
            & Pick<ConstructionTypeField, 'constructionType'>
          ) | (
            { __typename?: 'ContactPersonField' }
            & Pick<ContactPersonField, 'email' | 'name' | 'phoneNumber'>
          ) | (
            { __typename?: 'DateTimeField' }
            & Pick<DateTimeField, 'dateTime'>
          ) | (
            { __typename?: 'DmsField' }
            & Pick<DmsField, 'dmsId' | 'title'>
          ) | (
            { __typename?: 'GeometryField' }
            & Pick<GeometryField, 'geometry'>
          ) | (
            { __typename?: 'IncidentAutoPublishField' }
            & Pick<IncidentAutoPublishField, 'autoPublish'>
          ) | (
            { __typename?: 'IncidentLaneField' }
            & { incidentLane: (
              { __typename?: 'IncidentLane' }
              & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
            ) }
          ) | (
            { __typename?: 'IncidentMediaIdField' }
            & Pick<IncidentMediaIdField, 'incidentMediaId'>
          ) | (
            { __typename?: 'IncidentSeverityField' }
            & Pick<IncidentSeverityField, 'incidentSeverity'>
          ) | (
            { __typename?: 'IncidentStatusField' }
            & Pick<IncidentStatusField, 'automaticAction' | 'endReason' | 'endReasonComment' | 'status'>
          ) | (
            { __typename?: 'IncidentSubTypeField' }
            & Pick<IncidentSubTypeField, 'subType'>
          ) | (
            { __typename?: 'IncidentTypeField' }
            & Pick<IncidentTypeField, 'incidentType'>
          ) | (
            { __typename?: 'InjurySeveritiesField' }
            & Pick<InjurySeveritiesField, 'injurySeverity'>
          ) | (
            { __typename?: 'IntField' }
            & Pick<IntField, 'n'>
          ) | (
            { __typename?: 'LanesClosureTypeField' }
            & Pick<LanesClosureTypeField, 'lanesClosureType'>
          ) | (
            { __typename?: 'LocationField' }
            & Pick<LocationField, 'point'>
          ) | (
            { __typename?: 'MitigationField' }
            & Pick<MitigationField, 'description' | 'unitDisplayId' | 'unitId'>
            & { interval?: Maybe<(
              { __typename?: 'DateInterval' }
              & Pick<DateInterval, 'from' | 'to'>
            )> }
          ) | (
            { __typename?: 'NoteField' }
            & Pick<NoteField, 'note'>
          ) | (
            { __typename?: 'NullableTextField' }
            & Pick<NullableTextField, 'nullableText'>
          ) | (
            { __typename?: 'OccupancyRangeField' }
            & Pick<OccupancyRangeField, 'occupancyRange'>
          ) | (
            { __typename?: 'PermitField' }
            & Pick<PermitField, 'permitId' | 'url'>
          ) | (
            { __typename?: 'PublishField' }
            & Pick<PublishField, 'description' | 'destinations'>
          ) | (
            { __typename?: 'RelatedEventField' }
            & Pick<RelatedEventField, 'endTime' | 'eventId' | 'relatedEventStatus' | 'startTime' | 'type'>
          ) | (
            { __typename?: 'ReportSourceField' }
            & Pick<ReportSourceField, 'accountId' | 'reportSource'>
          ) | (
            { __typename?: 'RouteTypeIdsField' }
            & Pick<RouteTypeIdsField, 'routeTypesIds'>
          ) | (
            { __typename?: 'ScheduleField' }
            & Pick<ScheduleField, 'endTime' | 'startTime'>
            & { schedule?: Maybe<(
              { __typename?: 'TrafficDisruptionScheduleDTO' }
              & Pick<TrafficDisruptionScheduleDto, 'timeZone'>
              & { timeRanges: Array<(
                { __typename?: 'TimeRangeSchedule' }
                & Pick<TimeRangeSchedule, 'fromDayOfWeekLocal' | 'fromTimeMinutesLocal' | 'toDayOfWeekLocal' | 'toTimeMinutesLocal'>
              )> }
            )> }
          ) | (
            { __typename?: 'ShiftEndReasonField' }
            & Pick<ShiftEndReasonField, 'shiftEndReason'>
          ) | (
            { __typename?: 'ShiftTypeIdField' }
            & Pick<ShiftTypeIdField, 'shiftTypeId'>
          ) | (
            { __typename?: 'SpecialEventTypeField' }
            & Pick<SpecialEventTypeField, 'specialEventType'>
          ) | (
            { __typename?: 'TextField' }
            & Pick<TextField, 'text'>
          ) | (
            { __typename?: 'TrafficDisruptionLaneField' }
            & { trafficDisruptionLane: (
              { __typename?: 'TrafficDisruptionLane' }
              & Pick<TrafficDisruptionLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type'>
            ) }
          ) | (
            { __typename?: 'TrafficDisruptionMediaIdField' }
            & Pick<TrafficDisruptionMediaIdField, 'trafficDisruptionMediaId'>
          ) | (
            { __typename?: 'TrafficDisruptionStatusField' }
            & Pick<TrafficDisruptionStatusField, 'trafficDisruptionEndReason' | 'trafficDisruptionStatus'>
          ) | (
            { __typename?: 'UnitField' }
            & Pick<UnitField, 'displayId' | 'driverId' | 'response'>
          ) | (
            { __typename?: 'UnitIdField' }
            & Pick<UnitIdField, 'shiftUnitId'>
          ) | (
            { __typename?: 'VehicleField' }
            & { vehicle: (
              { __typename?: 'IncidentInvolvedVehicle' }
              & Pick<IncidentInvolvedVehicle, 'color' | 'id' | 'licensePlateNumber' | 'make' | 'model' | 'state' | 'type'>
            ) }
          ) | (
            { __typename?: 'VenueField' }
            & Pick<VenueField, 'venueAddress' | 'venueName'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type StreetCamerasByAreaQueryVariables = Exact<{
  input: FindStreetCamerasByAreaInput;
}>;


export type StreetCamerasByAreaQuery = (
  { __typename?: 'Query' }
  & { streetCamerasByArea: Array<(
    { __typename?: 'StreetCamera' }
    & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  )> }
);

export type StreetCamerasByPointQueryVariables = Exact<{
  input: FindStreetCamerasByPointAndRadiusInput;
}>;


export type StreetCamerasByPointQuery = (
  { __typename?: 'Query' }
  & { streetCamerasByPoint: Array<(
    { __typename?: 'StreetCamera' }
    & Pick<StreetCamera, 'externalId' | 'id' | 'imageUrl' | 'isVideo' | 'location' | 'status' | 'streamType' | 'title' | 'videoUrl' | 'workspaces'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
    ) }
  )> }
);

export type TrafficDisruptionAnalyticsLogQueryVariables = Exact<{
  input: FilterTrafficDisruptionInput;
}>;


export type TrafficDisruptionAnalyticsLogQuery = (
  { __typename?: 'Query' }
  & { trafficDisruptionAnalyticsLog: (
    { __typename?: 'AnalyticsFilteredTrafficDisruptionsResultPage' }
    & Pick<AnalyticsFilteredTrafficDisruptionsResultPage, 'tdColumnNames' | 'totalNumberOfResults'>
    & { rows: Array<(
      { __typename?: 'FilteredAnalyticsView' }
      & { data: Array<(
        { __typename?: 'AnalyticsAssociatedUnitsColumn' }
        & Pick<AnalyticsAssociatedUnitsColumn, 'associatedUnits' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsAtmsIdColumn' }
        & Pick<AnalyticsAtmsIdColumn, 'atmsId' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsAttributesColumn' }
        & Pick<AnalyticsAttributesColumn, 'attributes' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCadIdColumn' }
        & Pick<AnalyticsCadIdColumn, 'cadId' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCorridorColumn' }
        & Pick<AnalyticsCorridorColumn, 'corridor' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsCrossroadColumn' }
        & Pick<AnalyticsCrossroadColumn, 'crossroad' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsDirectionColumn' }
        & Pick<AnalyticsDirectionColumn, 'direction' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsEndReasonColumn' }
        & Pick<AnalyticsEndReasonColumn, 'endReason' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsEndReasonCommentColumn' }
        & Pick<AnalyticsEndReasonCommentColumn, 'endReasonComment' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsFatalitiesColumn' }
        & Pick<AnalyticsFatalitiesColumn, 'fatalities' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentAdditionalInfoColumn' }
        & Pick<AnalyticsIncidentAdditionalInfoColumn, 'additionalInfo' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentAddressColumn' }
        & Pick<AnalyticsIncidentAddressColumn, 'incidentColumnName'>
        & { address: (
          { __typename?: 'Address' }
          & Pick<Address, 'city' | 'corridor' | 'county' | 'crossroad' | 'direction' | 'milemarker' | 'orientation' | 'point' | 'roadLevelType' | 'roadType' | 'segmentId' | 'state'>
        ) }
      ) | (
        { __typename?: 'AnalyticsIncidentCreationSourceColumn' }
        & Pick<AnalyticsIncidentCreationSourceColumn, 'incidentColumnName' | 'source' | 'userName'>
      ) | (
        { __typename?: 'AnalyticsIncidentDurationColumn' }
        & Pick<AnalyticsIncidentDurationColumn, 'durationMillis' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentEndTimeColumn' }
        & Pick<AnalyticsIncidentEndTimeColumn, 'endTime' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentIdColumn' }
        & Pick<AnalyticsIncidentIdColumn, 'id' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentMitigationTypesColumn' }
        & Pick<AnalyticsIncidentMitigationTypesColumn, 'incidentColumnName' | 'mitigationTypes'>
      ) | (
        { __typename?: 'AnalyticsIncidentRelatedEventsColumn' }
        & Pick<AnalyticsIncidentRelatedEventsColumn, 'counter' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsIncidentSeverityColumn' }
        & Pick<AnalyticsIncidentSeverityColumn, 'incidentColumnName' | 'severity'>
      ) | (
        { __typename?: 'AnalyticsIncidentStartTimeColumn' }
        & Pick<AnalyticsIncidentStartTimeColumn, 'incidentColumnName' | 'startTime'>
      ) | (
        { __typename?: 'AnalyticsIncidentStatusColumn' }
        & Pick<AnalyticsIncidentStatusColumn, 'incidentColumnName' | 'status'>
      ) | (
        { __typename?: 'AnalyticsIncidentSubTypeColumn' }
        & Pick<AnalyticsIncidentSubTypeColumn, 'incidentColumnName' | 'subType'>
      ) | (
        { __typename?: 'AnalyticsIncidentTypeColumn' }
        & Pick<AnalyticsIncidentTypeColumn, 'incidentColumnName' | 'type'>
      ) | (
        { __typename?: 'AnalyticsInjuriesColumn' }
        & Pick<AnalyticsInjuriesColumn, 'incidentColumnName' | 'injuries'>
      ) | (
        { __typename?: 'AnalyticsInvolvedVehiclesColumn' }
        & Pick<AnalyticsInvolvedVehiclesColumn, 'counter' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsLaneColumn' }
        & Pick<AnalyticsLaneColumn, 'incidentColumnName'>
        & { lanes: Array<(
          { __typename?: 'IncidentLane' }
          & Pick<IncidentLane, 'direction' | 'id' | 'isAffected' | 'isClosed' | 'number' | 'positionIndex' | 'roadType' | 'type' | 'updatedAt'>
        )> }
      ) | (
        { __typename?: 'AnalyticsLanesDirectionColumn' }
        & Pick<AnalyticsLanesDirectionColumn, 'direction' | 'incidentColumnName'>
      ) | (
        { __typename?: 'AnalyticsLatitudeColumn' }
        & Pick<AnalyticsLatitudeColumn, 'incidentColumnName' | 'latitude'>
      ) | (
        { __typename?: 'AnalyticsLongitudeColumn' }
        & Pick<AnalyticsLongitudeColumn, 'incidentColumnName' | 'longitude'>
      ) | (
        { __typename?: 'AnalyticsMileMarkerColumn' }
        & Pick<AnalyticsMileMarkerColumn, 'incidentColumnName' | 'milemarker'>
      ) | (
        { __typename?: 'AnalyticsNoteColumn' }
        & Pick<AnalyticsNoteColumn, 'incidentColumnName' | 'notes'>
      ) | (
        { __typename?: 'AnalyticsOrientationColumn' }
        & Pick<AnalyticsOrientationColumn, 'incidentColumnName' | 'orientation'>
      ) | (
        { __typename?: 'AnalyticsReportSourceColumn' }
        & Pick<AnalyticsReportSourceColumn, 'incidentColumnName' | 'reportSources'>
      ) | (
        { __typename?: 'AnalyticsRoadTypeColumn' }
        & Pick<AnalyticsRoadTypeColumn, 'incidentColumnName' | 'roadType'>
      ) | (
        { __typename?: 'AnalyticsShiftDurationColumn' }
        & Pick<AnalyticsShiftDurationColumn, 'durationMillis' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftEndMileageColumn' }
        & Pick<AnalyticsShiftEndMileageColumn, 'mileage' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftEndTimeColumn' }
        & Pick<AnalyticsShiftEndTimeColumn, 'endTime' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftFirstMitigationTimeColumn' }
        & Pick<AnalyticsShiftFirstMitigationTimeColumn, 'shiftColumnName' | 'time'>
      ) | (
        { __typename?: 'AnalyticsShiftIdColumn' }
        & Pick<AnalyticsShiftIdColumn, 'shiftColumnName' | 'shiftId'>
      ) | (
        { __typename?: 'AnalyticsShiftIncidentsCountColumn' }
        & Pick<AnalyticsShiftIncidentsCountColumn, 'counter' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftIsCompleteColumn' }
        & Pick<AnalyticsShiftIsCompleteColumn, 'isComplete' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftLastMitigationTimeColumn' }
        & Pick<AnalyticsShiftLastMitigationTimeColumn, 'shiftColumnName' | 'time'>
      ) | (
        { __typename?: 'AnalyticsShiftMitigationTypesColumn' }
        & Pick<AnalyticsShiftMitigationTypesColumn, 'mitigationTypes' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftOvertimeColumn' }
        & Pick<AnalyticsShiftOvertimeColumn, 'isOvertime' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftOvertimeNoteColumn' }
        & Pick<AnalyticsShiftOvertimeNoteColumn, 'modified' | 'note' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftPausesColumn' }
        & Pick<AnalyticsShiftPausesColumn, 'counter' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftRoutesColumn' }
        & Pick<AnalyticsShiftRoutesColumn, 'modified' | 'names' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftStartMileageColumn' }
        & Pick<AnalyticsShiftStartMileageColumn, 'mileage' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftStartTimeColumn' }
        & Pick<AnalyticsShiftStartTimeColumn, 'modified' | 'shiftColumnName' | 'startTime'>
      ) | (
        { __typename?: 'AnalyticsShiftTotalMileageColumn' }
        & Pick<AnalyticsShiftTotalMileageColumn, 'mileage' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftTypeColumn' }
        & Pick<AnalyticsShiftTypeColumn, 'modified' | 'shiftColumnName' | 'shiftType'>
      ) | (
        { __typename?: 'AnalyticsShiftUnitColumn' }
        & Pick<AnalyticsShiftUnitColumn, 'displayId' | 'modified' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsShiftUserNameColumn' }
        & Pick<AnalyticsShiftUserNameColumn, 'name' | 'shiftColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDEndTimeColumn' }
        & Pick<AnalyticsTdEndTimeColumn, 'endTime' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDIdColumn' }
        & Pick<AnalyticsTdIdColumn, 'id' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDPermitIdColumn' }
        & Pick<AnalyticsTdPermitIdColumn, 'permitId' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDRelatedEventsColumn' }
        & Pick<AnalyticsTdRelatedEventsColumn, 'counter' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDStartTimeColumn' }
        & Pick<AnalyticsTdStartTimeColumn, 'startTime' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDStatusColumn' }
        & Pick<AnalyticsTdStatusColumn, 'tdColumnName' | 'tdStatus'>
      ) | (
        { __typename?: 'AnalyticsTDSubTypeColumn' }
        & Pick<AnalyticsTdSubTypeColumn, 'subType' | 'tdColumnName'>
      ) | (
        { __typename?: 'AnalyticsTDTitleColumn' }
        & Pick<AnalyticsTdTitleColumn, 'tdColumnName' | 'title'>
      ) | (
        { __typename?: 'AnalyticsTDTypeColumn' }
        & Pick<AnalyticsTdTypeColumn, 'tdColumnName' | 'tdType'>
      ) | (
        { __typename?: 'AnalyticsUsersInvolvedColumn' }
        & Pick<AnalyticsUsersInvolvedColumn, 'incidentColumnName' | 'users'>
      )> }
    )> }
  ) }
);

export type TrafficDisruptionAnalyticsLogCsvExportQueryVariables = Exact<{
  input: FilterTrafficDisruptionInput;
}>;


export type TrafficDisruptionAnalyticsLogCsvExportQuery = (
  { __typename?: 'Query' }
  & { trafficDisruptionAnalyticsLogCsvExport: (
    { __typename?: 'MediaFile' }
    & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
  ) }
);

export type TrafficDisruptionAnalyticsLogCsvExportEmailQueryVariables = Exact<{
  input: FilterTrafficDisruptionInput;
}>;


export type TrafficDisruptionAnalyticsLogCsvExportEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'trafficDisruptionAnalyticsLogCsvExportEmail'>
);

export type TrafficDisruptionDetailsLogPdfExportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TrafficDisruptionDetailsLogPdfExportQuery = (
  { __typename?: 'Query' }
  & { trafficDisruptionDetailsLogPDFExport: (
    { __typename?: 'MediaFile' }
    & Pick<MediaFile, 'expiration' | 'fileSize' | 'isDocument' | 'key' | 'source' | 'url'>
  ) }
);

export type UnitQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UnitQuery = (
  { __typename?: 'Query' }
  & { unit?: Maybe<(
    { __typename?: 'UnitVehicle' }
    & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
    & { dashCameras: Array<(
      { __typename?: 'UnitDashCamera' }
      & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
    )>, userDetails?: Maybe<(
      { __typename?: 'UserDetails' }
      & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
    )> }
  )> }
);

export type UnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsQuery = (
  { __typename?: 'Query' }
  & { units: Array<(
    { __typename?: 'UnitVehicle' }
    & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
    & { dashCameras: Array<(
      { __typename?: 'UnitDashCamera' }
      & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
    )>, userDetails?: Maybe<(
      { __typename?: 'UserDetails' }
      & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
    )> }
  )> }
);

export type UnitsByWorkspacesQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsByWorkspacesQuery = (
  { __typename?: 'Query' }
  & { unitsByWorkspaces: Array<(
    { __typename?: 'UnitVehicle' }
    & Pick<UnitVehicle, 'accountId' | 'activityStatuses' | 'displayId' | 'externalId' | 'id' | 'isBusy' | 'status' | 'type'>
    & { dashCameras: Array<(
      { __typename?: 'UnitDashCamera' }
      & Pick<UnitDashCamera, 'imageUrl' | 'isVideo' | 'position' | 'streamType' | 'videoUrl'>
    )>, userDetails?: Maybe<(
      { __typename?: 'UserDetails' }
      & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
    )> }
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type'>
      & { userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  )> }
);

export type UserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRolesQuery = (
  { __typename?: 'Query' }
  & { userRoles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'accountId' | 'description' | 'id' | 'name'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{
  optionalDateTimePeriodInput: OptionalDateTimePeriodInput;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'id' | 'name' | 'phoneNumber' | 'status' | 'unitRelationType'>
    & { permissions: Array<(
      { __typename?: 'Permission' }
      & Pick<Permission, 'id'>
      & { dependsOnAll?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, dependsOnAny?: Maybe<Array<(
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      )>>, scope: (
        { __typename?: 'Scope' }
        & Pick<Scope, 'entity' | 'modifier'>
      ) }
    )>, unit?: Maybe<(
      { __typename?: 'UnitVehicle' }
      & Pick<UnitVehicle, 'accountId' | 'displayId' | 'externalId' | 'id' | 'status' | 'type'>
      & { userDetails?: Maybe<(
        { __typename?: 'UserDetails' }
        & Pick<UserDetails, 'name' | 'unitRelationType' | 'userId'>
      )> }
    )> }
  )> }
);

export type VenuesQueryVariables = Exact<{
  point: Scalars['Point'];
  radiusMeters?: Maybe<Scalars['Int']>;
}>;


export type VenuesQuery = (
  { __typename?: 'Query' }
  & { venues: Array<(
    { __typename?: 'VenueDetails' }
    & Pick<VenueDetails, 'address' | 'location' | 'name' | 'type'>
  )> }
);

export type WeatherAlertsSupportedTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type WeatherAlertsSupportedTypesQuery = (
  { __typename?: 'Query' }
  & { weatherAlertsSupportedTypes: Array<(
    { __typename?: 'WeatherAlertSupportedType' }
    & Pick<WeatherAlertSupportedType, 'subTypes' | 'type'>
  )> }
);

export type WorkspacesRoadReferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspacesRoadReferencesQuery = (
  { __typename?: 'Query' }
  & { workspacesRoadReferences: (
    { __typename?: 'RoadReferences' }
    & Pick<RoadReferences, 'refs'>
  ) }
);

export const AddHistoricMitigationsToIncidentDocument = gql`
    mutation addHistoricMitigationsToIncident($input: CreateShiftIncidentMitigationInput!) {
  addHistoricMitigationsToIncident(input: $input) {
    errMsg
    errorCode
    incidentId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddHistoricMitigationsToIncidentGQL extends Apollo.Mutation<AddHistoricMitigationsToIncidentMutation, AddHistoricMitigationsToIncidentMutationVariables> {
    document = AddHistoricMitigationsToIncidentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignUnitToUserDocument = gql`
    mutation assignUnitToUser($input: SetUserUnitInput!) {
  assignUnitToUser(input: $input) {
    email
    id
    name
    phoneNumber
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
    status
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    unitRelationType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignUnitToUserGQL extends Apollo.Mutation<AssignUnitToUserMutation, AssignUnitToUserMutationVariables> {
    document = AssignUnitToUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompleteConstructionDocument = gql`
    mutation completeConstruction($id: Int!) {
  completeConstruction(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompleteConstructionGQL extends Apollo.Mutation<CompleteConstructionMutation, CompleteConstructionMutationVariables> {
    document = CompleteConstructionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompleteIncidentDocument = gql`
    mutation completeIncident($input: EndIncidentInput!) {
  completeIncident(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompleteIncidentGQL extends Apollo.Mutation<CompleteIncidentMutation, CompleteIncidentMutationVariables> {
    document = CompleteIncidentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompleteRoadClosureDocument = gql`
    mutation completeRoadClosure($id: Int!) {
  completeRoadClosure(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompleteRoadClosureGQL extends Apollo.Mutation<CompleteRoadClosureMutation, CompleteRoadClosureMutationVariables> {
    document = CompleteRoadClosureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompleteSpecialEventDocument = gql`
    mutation completeSpecialEvent($id: Int!) {
  completeSpecialEvent(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompleteSpecialEventGQL extends Apollo.Mutation<CompleteSpecialEventMutation, CompleteSpecialEventMutationVariables> {
    document = CompleteSpecialEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmIncidentDocument = gql`
    mutation confirmIncident($id: Int!, $offlineUpdatedAt: DateTime) {
  confirmIncident(id: $id, offlineUpdatedAt: $offlineUpdatedAt)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmIncidentGQL extends Apollo.Mutation<ConfirmIncidentMutation, ConfirmIncidentMutationVariables> {
    document = ConfirmIncidentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAdditionalInfoDocument = gql`
    mutation createAdditionalInfo($input: CreateAdditionalInfoInput!) {
  createAdditionalInfo(input: $input) {
    accountId
    id
    info
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAdditionalInfoGQL extends Apollo.Mutation<CreateAdditionalInfoMutation, CreateAdditionalInfoMutationVariables> {
    document = CreateAdditionalInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateConstructionDocument = gql`
    mutation createConstruction($input: CreateConstructionInput!) {
  createConstruction(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    dmses {
      dms {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        editable
        externalId
        id
        location
        messages
        status
        title
        updatedAt
      }
      positionIndex
    }
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    otherTypeDescription
    permit {
      permitId
      url
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    type
    updatedAt
    updatedBy
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateConstructionGQL extends Apollo.Mutation<CreateConstructionMutation, CreateConstructionMutationVariables> {
    document = CreateConstructionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDetailedShiftDocument = gql`
    mutation createDetailedShift($input: CreateDetailedShiftInput!) {
  createDetailedShift(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDetailedShiftGQL extends Apollo.Mutation<CreateDetailedShiftMutation, CreateDetailedShiftMutationVariables> {
    document = CreateDetailedShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateIncidentDocument = gql`
    mutation createIncident($input: CreateIncidentMutationInput!) {
  createIncident(input: $input) {
    additionalInfos {
      accountId
      id
      info
      isDeleted
    }
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
      updatedAt
    }
    allLanesAffected
    associatedUnits {
      accountId
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      driverDetails {
        name
        unitRelationType
        userId
      }
      externalId
      id
      status
      type
      unitResponse
      updatedAt
    }
    atmsId
    attributes
    autoPublish
    cadId
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    camerasInternalDetails {
      default
      externalId
      positionIndex
    }
    completedBy
    confirmedAt
    confirmedBy
    createdAt
    createdBy
    createdByUserName
    creationSource
    endReason
    endReasonComment
    endedAt
    estimatedEndTime
    externalId
    fatalities
    feedSource
    hazardRemains
    id
    injuries
    injurySeverities
    insights {
      impact {
        corridors {
          currentSpd
          expectedSpd
          impactedSegments {
            currentSpd
            expectedSpd
            forecastDecrease
            level
            linestring
            segmentId
            segmentName
          }
          name
        }
        externalId
        incidentId
        level
        updatedAt
        usersFeedback
      }
      persistenceType
      priorityScore {
        heavyInfluencedBy
        level
      }
    }
    involvedVehicles {
      color
      id
      licensePlateNumber
      make
      model
      state
      type
    }
    involvedVehiclesCount
    isAddressEstimated
    isConfirmed
    isRejected
    isUnconfirmed
    lanesStatus
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    mergeDetails {
      isBlocked
      targetId
    }
    mitigations {
      id
      interval {
        from
        to
      }
      mitigationType {
        accountId
        description
        id
        isDeleted
      }
      unitDisplayId
      unitId
      unitType
      userId
    }
    multiDirectionLanesAffected
    nearCameras
    notes {
      createdAt
      id
      note
      updatedAt
      updatedBy
      updatedByUsername
    }
    reportSources {
      accountId
      id
      reportSource
      status
    }
    responsePlan {
      actions {
        actionField
        index
        isDone
        responsePlanActionId
        responsePlanId
        text
        time
        unit
        value
      }
      entityId
      entityType
      id
      isDeleted
      templateId
      title
      updatedAt
    }
    restoreAt
    severity
    startedAt
    status
    subType
    title
    trafficDisturbance
    type
    typeDescription
    updatedAt
    updatedBy
    userDetails {
      completedBy
      confirmedBy
      updatedBy
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateIncidentGQL extends Apollo.Mutation<CreateIncidentMutation, CreateIncidentMutationVariables> {
    document = CreateIncidentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMitigationTypeDocument = gql`
    mutation createMitigationType($input: CreateMitigationTypeInput!) {
  createMitigationType(input: $input) {
    accountId
    description
    id
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMitigationTypeGQL extends Apollo.Mutation<CreateMitigationTypeMutation, CreateMitigationTypeMutationVariables> {
    document = CreateMitigationTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateReportSourceDocument = gql`
    mutation createReportSource($input: CreateReportSourceInput!) {
  createReportSource(input: $input) {
    accountId
    id
    reportSource
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateReportSourceGQL extends Apollo.Mutation<CreateReportSourceMutation, CreateReportSourceMutationVariables> {
    document = CreateReportSourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateResponsePlanTemplateDocument = gql`
    mutation createResponsePlanTemplate($input: CreateResponsePlanTemplateInput!) {
  createResponsePlanTemplate(input: $input) {
    accountId
    actions {
      actionField
      index
      templateActionId
      templateId
      text
      time
      unit
      value
    }
    entityType
    id
    title
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateResponsePlanTemplateGQL extends Apollo.Mutation<CreateResponsePlanTemplateMutation, CreateResponsePlanTemplateMutationVariables> {
    document = CreateResponsePlanTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRoadClosureDocument = gql`
    mutation createRoadClosure($input: CreateRoadClosureInput!) {
  createRoadClosure(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    dmses {
      dms {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        editable
        externalId
        id
        location
        messages
        status
        title
        updatedAt
      }
      positionIndex
    }
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    permit {
      permitId
      url
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    updatedAt
    updatedBy
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRoadClosureGQL extends Apollo.Mutation<CreateRoadClosureMutation, CreateRoadClosureMutationVariables> {
    document = CreateRoadClosureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRoleDocument = gql`
    mutation createRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    accountId
    description
    id
    name
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRoleGQL extends Apollo.Mutation<CreateRoleMutation, CreateRoleMutationVariables> {
    document = CreateRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRouteTypeDocument = gql`
    mutation createRouteType($input: CreateRouteTypeInput!) {
  createRouteType(input: $input) {
    accountId
    area
    id
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRouteTypeGQL extends Apollo.Mutation<CreateRouteTypeMutation, CreateRouteTypeMutationVariables> {
    document = CreateRouteTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateShiftDocument = gql`
    mutation createShift($input: CreateShiftInput!) {
  createShift(input: $input) {
    endMileage
    endReason
    endReasonNote
    endedAt
    isDeleted
    overtimeNote
    routeTypes {
      accountId
      area
      id
      name
      status
    }
    shiftId
    shiftPauses {
      endedAt
      isActive
      pauseId
      reason
      reasonNote
      shiftId
      startedAt
    }
    shiftType {
      accountId
      id
      name
      status
    }
    startMileage
    startedAt
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    user {
      account {
        additionalInfos {
          accountId
          id
          info
          isDeleted
        }
        analyticsReportsUrls {
          fixedRouteTransitReport {
            index
            name
            url
          }
          fspReport {
            index
            name
            url
          }
          incidentAnalysisReport {
            index
            name
            url
          }
          mitigationsReport {
            index
            name
            url
          }
          speedMeasurementReport {
            index
            name
            url
          }
          timPerformanceReport {
            index
            name
            url
          }
          transitReport {
            index
            name
            url
          }
          travelReliabilityReport {
            index
            name
            url
          }
          unitsPerformanceReport {
            index
            name
            url
          }
        }
        contactEmail
        contactName
        contactPhoneNumber
        customer {
          contactEmail
          contactName
          contactPhoneNumber
          id
          name
        }
        groups {
          id
          level
          permissions {
            dependsOnAll {
              entity
              modifier
            }
            dependsOnAny {
              entity
              modifier
            }
            id
            scope {
              entity
              modifier
            }
          }
          type
        }
        hubspotCompany
        id
        mapCenter
        mitigationTypes {
          accountId
          description
          id
          isDeleted
        }
        name
        publicationTemplates {
          entityType
          id
          phrases
          template
        }
        publicationVariables {
          constructionVariables
          incidentVariables
          roadClosureVariables
        }
        publishChannels
        regionalSetting {
          dateFormat
          firstDayOfWeek
          language
          measureUnitType
          timeFormat
          timeZone
        }
        reportSources {
          accountId
          id
          reportSource
          status
        }
        routeTypes {
          accountId
          area
          id
          name
          status
        }
        shiftTypes {
          accountId
          id
          name
          status
        }
        status
        workspaces {
          singleMultiPolygon
          workspaces {
            accountId
            area
            featureTypes
            id
            roadTypes
            title
          }
        }
      }
      creationTime
      email
      id
      name
      permissions {
        id
      }
      phoneNumber
      status
      unit {
        accountId
        activityStatuses
        displayId
        externalId
        id
        isBusy
        status
        type
      }
      unitRelationType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShiftGQL extends Apollo.Mutation<CreateShiftMutation, CreateShiftMutationVariables> {
    document = CreateShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateShiftTypeDocument = gql`
    mutation createShiftType($input: CreateShiftTypeInput!) {
  createShiftType(input: $input) {
    accountId
    id
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShiftTypeGQL extends Apollo.Mutation<CreateShiftTypeMutation, CreateShiftTypeMutationVariables> {
    document = CreateShiftTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSpecialEventDocument = gql`
    mutation createSpecialEvent($input: CreateSpecialEventInput!) {
  createSpecialEvent(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    occupancy
    occupancyRange
    otherTypeDescription
    responsePlan {
      actions {
        actionField
        index
        isDone
        responsePlanActionId
        responsePlanId
        text
        time
        unit
        value
      }
      entityId
      entityType
      id
      isDeleted
      templateId
      title
      updatedAt
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    type
    updatedAt
    updatedBy
    venue {
      address
      name
    }
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSpecialEventGQL extends Apollo.Mutation<CreateSpecialEventMutation, CreateSpecialEventMutationVariables> {
    document = CreateSpecialEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    account {
      additionalInfos {
        accountId
        id
        info
        isDeleted
      }
      analyticsReportsUrls {
        fixedRouteTransitReport {
          index
          name
          url
        }
        fspReport {
          index
          name
          url
        }
        incidentAnalysisReport {
          index
          name
          url
        }
        mitigationsReport {
          index
          name
          url
        }
        speedMeasurementReport {
          index
          name
          url
        }
        timPerformanceReport {
          index
          name
          url
        }
        transitReport {
          index
          name
          url
        }
        travelReliabilityReport {
          index
          name
          url
        }
        unitsPerformanceReport {
          index
          name
          url
        }
      }
      contactEmail
      contactName
      contactPhoneNumber
      customer {
        contactEmail
        contactName
        contactPhoneNumber
        id
        name
      }
      groups {
        id
        level
        permissions {
          dependsOnAll {
            entity
            modifier
          }
          dependsOnAny {
            entity
            modifier
          }
          id
          scope {
            entity
            modifier
          }
        }
        type
      }
      hubspotCompany
      id
      mapCenter
      mitigationTypes {
        accountId
        description
        id
        isDeleted
      }
      name
      publicationTemplates {
        entityType
        id
        phrases
        template
      }
      publicationVariables {
        constructionVariables
        incidentVariables
        roadClosureVariables
      }
      publishChannels
      regionalSetting {
        dateFormat
        firstDayOfWeek
        language
        measureUnitType
        timeFormat
        timeZone
      }
      reportSources {
        accountId
        id
        reportSource
        status
      }
      routeTypes {
        accountId
        area
        id
        name
        status
      }
      shiftTypes {
        accountId
        id
        name
        status
      }
      status
      workspaces {
        singleMultiPolygon
        workspaces {
          accountId
          area
          featureTypes
          id
          roadTypes
          title
        }
      }
    }
    creationTime
    email
    id
    name
    permissions {
      id
    }
    phoneNumber
    status
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    unitRelationType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAccountPublishDetailsDocument = gql`
    mutation deleteAccountPublishDetails($input: DeleteAccountPublicationChannelInput!) {
  deleteAccountPublishDetails(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAccountPublishDetailsGQL extends Apollo.Mutation<DeleteAccountPublishDetailsMutation, DeleteAccountPublishDetailsMutationVariables> {
    document = DeleteAccountPublishDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAdditionalInfoDocument = gql`
    mutation deleteAdditionalInfo($id: Int!) {
  deleteAdditionalInfo(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAdditionalInfoGQL extends Apollo.Mutation<DeleteAdditionalInfoMutation, DeleteAdditionalInfoMutationVariables> {
    document = DeleteAdditionalInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteConstructionsNonProdDocument = gql`
    mutation deleteConstructionsNonProd($input: DeleteTrafficDisruptionsInput!) {
  deleteConstructionsNonProd(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteConstructionsNonProdGQL extends Apollo.Mutation<DeleteConstructionsNonProdMutation, DeleteConstructionsNonProdMutationVariables> {
    document = DeleteConstructionsNonProdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteIncidentMediaDocument = gql`
    mutation deleteIncidentMedia($input: DeleteIncidentMediaInput!) {
  deleteIncidentMedia(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteIncidentMediaGQL extends Apollo.Mutation<DeleteIncidentMediaMutation, DeleteIncidentMediaMutationVariables> {
    document = DeleteIncidentMediaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteIncidentsNonProdDocument = gql`
    mutation deleteIncidentsNonProd($input: DeleteIncidentsInput!) {
  deleteIncidentsNonProd(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteIncidentsNonProdGQL extends Apollo.Mutation<DeleteIncidentsNonProdMutation, DeleteIncidentsNonProdMutationVariables> {
    document = DeleteIncidentsNonProdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMitigationTypeDocument = gql`
    mutation deleteMitigationType($id: Int!) {
  deleteMitigationType(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMitigationTypeGQL extends Apollo.Mutation<DeleteMitigationTypeMutation, DeleteMitigationTypeMutationVariables> {
    document = DeleteMitigationTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReportSourceDocument = gql`
    mutation deleteReportSource($id: Int!) {
  deleteReportSource(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReportSourceGQL extends Apollo.Mutation<DeleteReportSourceMutation, DeleteReportSourceMutationVariables> {
    document = DeleteReportSourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteResponsePlanTemplateDocument = gql`
    mutation deleteResponsePlanTemplate($templateId: Int!) {
  deleteResponsePlanTemplate(templateId: $templateId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteResponsePlanTemplateGQL extends Apollo.Mutation<DeleteResponsePlanTemplateMutation, DeleteResponsePlanTemplateMutationVariables> {
    document = DeleteResponsePlanTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteRoadClosuresNonProdDocument = gql`
    mutation deleteRoadClosuresNonProd($input: DeleteTrafficDisruptionsInput!) {
  deleteRoadClosuresNonProd(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRoadClosuresNonProdGQL extends Apollo.Mutation<DeleteRoadClosuresNonProdMutation, DeleteRoadClosuresNonProdMutationVariables> {
    document = DeleteRoadClosuresNonProdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteRoleDocument = gql`
    mutation deleteRole($input: DeleteRoleInput!) {
  deleteRole(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRoleGQL extends Apollo.Mutation<DeleteRoleMutation, DeleteRoleMutationVariables> {
    document = DeleteRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteRouteTypeDocument = gql`
    mutation deleteRouteType($input: DeleteRouteTypeInput!) {
  deleteRouteType(input: $input) {
    accountId
    area
    id
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRouteTypeGQL extends Apollo.Mutation<DeleteRouteTypeMutation, DeleteRouteTypeMutationVariables> {
    document = DeleteRouteTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteShiftDocument = gql`
    mutation deleteShift($id: Int!) {
  deleteShift(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteShiftGQL extends Apollo.Mutation<DeleteShiftMutation, DeleteShiftMutationVariables> {
    document = DeleteShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteShiftTypeDocument = gql`
    mutation deleteShiftType($input: DeleteShiftTypeInput!) {
  deleteShiftType(input: $input) {
    accountId
    id
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteShiftTypeGQL extends Apollo.Mutation<DeleteShiftTypeMutation, DeleteShiftTypeMutationVariables> {
    document = DeleteShiftTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSpecialEventsNonProdDocument = gql`
    mutation deleteSpecialEventsNonProd($input: DeleteTrafficDisruptionsInput!) {
  deleteSpecialEventsNonProd(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSpecialEventsNonProdGQL extends Apollo.Mutation<DeleteSpecialEventsNonProdMutation, DeleteSpecialEventsNonProdMutationVariables> {
    document = DeleteSpecialEventsNonProdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTrafficDisruptionMediaDocument = gql`
    mutation deleteTrafficDisruptionMedia($input: DeleteTrafficDisruptionMediaInput!) {
  deleteTrafficDisruptionMedia(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTrafficDisruptionMediaGQL extends Apollo.Mutation<DeleteTrafficDisruptionMediaMutation, DeleteTrafficDisruptionMediaMutationVariables> {
    document = DeleteTrafficDisruptionMediaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DisassociateUnitFromIncidentDocument = gql`
    mutation disassociateUnitFromIncident($input: DeleteIncidentUnitInput!) {
  disassociateUnitFromIncident(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DisassociateUnitFromIncidentGQL extends Apollo.Mutation<DisassociateUnitFromIncidentMutation, DisassociateUnitFromIncidentMutationVariables> {
    document = DisassociateUnitFromIncidentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EndShiftDocument = gql`
    mutation endShift($input: EndShiftInput!) {
  endShift(input: $input) {
    endMileage
    endReason
    endReasonNote
    endedAt
    isDeleted
    overtimeNote
    routeTypes {
      accountId
      area
      id
      name
      status
    }
    shiftId
    shiftPauses {
      endedAt
      isActive
      pauseId
      reason
      reasonNote
      shiftId
      startedAt
    }
    shiftType {
      accountId
      id
      name
      status
    }
    startMileage
    startedAt
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    user {
      account {
        additionalInfos {
          accountId
          id
          info
          isDeleted
        }
        analyticsReportsUrls {
          fixedRouteTransitReport {
            index
            name
            url
          }
          fspReport {
            index
            name
            url
          }
          incidentAnalysisReport {
            index
            name
            url
          }
          mitigationsReport {
            index
            name
            url
          }
          speedMeasurementReport {
            index
            name
            url
          }
          timPerformanceReport {
            index
            name
            url
          }
          transitReport {
            index
            name
            url
          }
          travelReliabilityReport {
            index
            name
            url
          }
          unitsPerformanceReport {
            index
            name
            url
          }
        }
        contactEmail
        contactName
        contactPhoneNumber
        customer {
          contactEmail
          contactName
          contactPhoneNumber
          id
          name
        }
        groups {
          id
          level
          permissions {
            dependsOnAll {
              entity
              modifier
            }
            dependsOnAny {
              entity
              modifier
            }
            id
            scope {
              entity
              modifier
            }
          }
          type
        }
        hubspotCompany
        id
        mapCenter
        mitigationTypes {
          accountId
          description
          id
          isDeleted
        }
        name
        publicationTemplates {
          entityType
          id
          phrases
          template
        }
        publicationVariables {
          constructionVariables
          incidentVariables
          roadClosureVariables
        }
        publishChannels
        regionalSetting {
          dateFormat
          firstDayOfWeek
          language
          measureUnitType
          timeFormat
          timeZone
        }
        reportSources {
          accountId
          id
          reportSource
          status
        }
        routeTypes {
          accountId
          area
          id
          name
          status
        }
        shiftTypes {
          accountId
          id
          name
          status
        }
        status
        workspaces {
          singleMultiPolygon
          workspaces {
            accountId
            area
            featureTypes
            id
            roadTypes
            title
          }
        }
      }
      creationTime
      email
      id
      name
      permissions {
        id
      }
      phoneNumber
      status
      unit {
        accountId
        activityStatuses
        displayId
        externalId
        id
        isBusy
        status
        type
      }
      unitRelationType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EndShiftGQL extends Apollo.Mutation<EndShiftMutation, EndShiftMutationVariables> {
    document = EndShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EndShiftPauseDocument = gql`
    mutation endShiftPause($id: Int!) {
  endShiftPause(id: $id) {
    endedAt
    isActive
    pauseId
    reason
    reasonNote
    shiftId
    startedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EndShiftPauseGQL extends Apollo.Mutation<EndShiftPauseMutation, EndShiftPauseMutationVariables> {
    document = EndShiftPauseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LogMediaAccessDocument = gql`
    mutation logMediaAccess($input: CreateMediaAccessLogInput!) {
  logMediaAccess(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LogMediaAccessGQL extends Apollo.Mutation<LogMediaAccessMutation, LogMediaAccessMutationVariables> {
    document = LogMediaAccessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LogMediaAccessedDocument = gql`
    mutation logMediaAccessed($input: CreateMediaAccessedLogInput!) {
  logMediaAccessed(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LogMediaAccessedGQL extends Apollo.Mutation<LogMediaAccessedMutation, LogMediaAccessedMutationVariables> {
    document = LogMediaAccessedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LogoutGQL extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    document = LogoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublishConstructionDocument = gql`
    mutation publishConstruction($input: PublishInput!) {
  publishConstruction(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublishConstructionGQL extends Apollo.Mutation<PublishConstructionMutation, PublishConstructionMutationVariables> {
    document = PublishConstructionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublishIncidentDocument = gql`
    mutation publishIncident($input: PublishInput!) {
  publishIncident(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublishIncidentGQL extends Apollo.Mutation<PublishIncidentMutation, PublishIncidentMutationVariables> {
    document = PublishIncidentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublishRoadClosureDocument = gql`
    mutation publishRoadClosure($input: PublishInput!) {
  publishRoadClosure(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublishRoadClosureGQL extends Apollo.Mutation<PublishRoadClosureMutation, PublishRoadClosureMutationVariables> {
    document = PublishRoadClosureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublishSpecialEventDocument = gql`
    mutation publishSpecialEvent($input: PublishInput!) {
  publishSpecialEvent(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublishSpecialEventGQL extends Apollo.Mutation<PublishSpecialEventMutation, PublishSpecialEventMutationVariables> {
    document = PublishSpecialEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RejectIncidentDocument = gql`
    mutation rejectIncident($input: EndIncidentInput!) {
  rejectIncident(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RejectIncidentGQL extends Apollo.Mutation<RejectIncidentMutation, RejectIncidentMutationVariables> {
    document = RejectIncidentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenewIncidentMediaUrlDocument = gql`
    mutation renewIncidentMediaUrl($id: Int!) {
  renewIncidentMediaUrl(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenewIncidentMediaUrlGQL extends Apollo.Mutation<RenewIncidentMediaUrlMutation, RenewIncidentMediaUrlMutationVariables> {
    document = RenewIncidentMediaUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenewTrafficDisruptionMediaUrlDocument = gql`
    mutation renewTrafficDisruptionMediaUrl($id: Int!) {
  renewTrafficDisruptionMediaUrl(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenewTrafficDisruptionMediaUrlGQL extends Apollo.Mutation<RenewTrafficDisruptionMediaUrlMutation, RenewTrafficDisruptionMediaUrlMutationVariables> {
    document = RenewTrafficDisruptionMediaUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RerouteFixedBusRouteDocument = gql`
    mutation rerouteFixedBusRoute($id: Int!, $reroute: Boolean!) {
  rerouteFixedBusRoute(id: $id, reroute: $reroute)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RerouteFixedBusRouteGQL extends Apollo.Mutation<RerouteFixedBusRouteMutation, RerouteFixedBusRouteMutationVariables> {
    document = RerouteFixedBusRouteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetFeedbackDocument = gql`
    mutation setFeedback($userFeedBackInput: UserFeedBackInput!) {
  setFeedback(userFeedBackInput: $userFeedBackInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetFeedbackGQL extends Apollo.Mutation<SetFeedbackMutation, SetFeedbackMutationVariables> {
    document = SetFeedbackDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetRolePermissionsDocument = gql`
    mutation setRolePermissions($input: SetRolePermissionsInput!) {
  setRolePermissions(input: $input) {
    accountId
    description
    id
    name
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetRolePermissionsGQL extends Apollo.Mutation<SetRolePermissionsMutation, SetRolePermissionsMutationVariables> {
    document = SetRolePermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetUserRolesDocument = gql`
    mutation setUserRoles($input: SetUserRolesInput!) {
  setUserRoles(input: $input) {
    email
    id
    name
    phoneNumber
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
    status
    unit {
      accountId
      displayId
      externalId
      id
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    unitRelationType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetUserRolesGQL extends Apollo.Mutation<SetUserRolesMutation, SetUserRolesMutationVariables> {
    document = SetUserRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartShiftPauseDocument = gql`
    mutation startShiftPause($input: CreateShiftPauseInput!) {
  startShiftPause(input: $input) {
    endedAt
    isActive
    pauseId
    reason
    reasonNote
    shiftId
    startedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartShiftPauseGQL extends Apollo.Mutation<StartShiftPauseMutation, StartShiftPauseMutationVariables> {
    document = StartShiftPauseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnassignUnitToUserDocument = gql`
    mutation unassignUnitToUser($input: DeleteUserUnitInput!) {
  unassignUnitToUser(input: $input) {
    email
    id
    name
    phoneNumber
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
    status
    unit {
      accountId
      displayId
      externalId
      id
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    unitRelationType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnassignUnitToUserGQL extends Apollo.Mutation<UnassignUnitToUserMutation, UnassignUnitToUserMutationVariables> {
    document = UnassignUnitToUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UndoCompleteDocument = gql`
    mutation undoComplete($id: Int!, $offlineUpdatedAt: DateTime) {
  undoComplete(id: $id, offlineUpdatedAt: $offlineUpdatedAt)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UndoCompleteGQL extends Apollo.Mutation<UndoCompleteMutation, UndoCompleteMutationVariables> {
    document = UndoCompleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UndoRejectDocument = gql`
    mutation undoReject($id: Int!, $offlineUpdatedAt: DateTime) {
  undoReject(id: $id, offlineUpdatedAt: $offlineUpdatedAt)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UndoRejectGQL extends Apollo.Mutation<UndoRejectMutation, UndoRejectMutationVariables> {
    document = UndoRejectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccountPermitsDocument = gql`
    mutation updateAccountPermits($input: [AccountPermitInput!]!) {
  updateAccountPermits(input: $input) {
    accountId
    id
    type
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAccountPermitsGQL extends Apollo.Mutation<UpdateAccountPermitsMutation, UpdateAccountPermitsMutationVariables> {
    document = UpdateAccountPermitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateActionStatusDocument = gql`
    mutation updateActionStatus($actionId: Int!, $status: Boolean!) {
  updateActionStatus(actionId: $actionId, status: $status)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateActionStatusGQL extends Apollo.Mutation<UpdateActionStatusMutation, UpdateActionStatusMutationVariables> {
    document = UpdateActionStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAdditionalInfoDocument = gql`
    mutation updateAdditionalInfo($input: UpdateAdditionalInfoInput!) {
  updateAdditionalInfo(input: $input) {
    accountId
    id
    info
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAdditionalInfoGQL extends Apollo.Mutation<UpdateAdditionalInfoMutation, UpdateAdditionalInfoMutationVariables> {
    document = UpdateAdditionalInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateConstructionDocument = gql`
    mutation updateConstruction($input: UpdateConstructionInput!) {
  updateConstruction(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    dmses {
      dms {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        editable
        externalId
        id
        location
        messages
        status
        title
        updatedAt
      }
      positionIndex
    }
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    otherTypeDescription
    permit {
      permitId
      url
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    type
    updatedAt
    updatedBy
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateConstructionGQL extends Apollo.Mutation<UpdateConstructionMutation, UpdateConstructionMutationVariables> {
    document = UpdateConstructionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDetailedShiftDocument = gql`
    mutation updateDetailedShift($input: UpdateDetailedShiftInput!) {
  updateDetailedShift(input: $input) {
    endMileage
    endReason
    endReasonNote
    endedAt
    isDeleted
    overtimeNote
    routeTypes {
      accountId
      area
      id
      name
      status
    }
    shiftId
    shiftPauses {
      endedAt
      isActive
      pauseId
      reason
      reasonNote
      shiftId
      startedAt
    }
    shiftType {
      accountId
      id
      name
      status
    }
    startMileage
    startedAt
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    user {
      account {
        additionalInfos {
          accountId
          id
          info
          isDeleted
        }
        analyticsReportsUrls {
          fixedRouteTransitReport {
            index
            name
            url
          }
          fspReport {
            index
            name
            url
          }
          incidentAnalysisReport {
            index
            name
            url
          }
          mitigationsReport {
            index
            name
            url
          }
          speedMeasurementReport {
            index
            name
            url
          }
          timPerformanceReport {
            index
            name
            url
          }
          transitReport {
            index
            name
            url
          }
          travelReliabilityReport {
            index
            name
            url
          }
          unitsPerformanceReport {
            index
            name
            url
          }
        }
        contactEmail
        contactName
        contactPhoneNumber
        customer {
          contactEmail
          contactName
          contactPhoneNumber
          id
          name
        }
        groups {
          id
          level
          permissions {
            dependsOnAll {
              entity
              modifier
            }
            dependsOnAny {
              entity
              modifier
            }
            id
            scope {
              entity
              modifier
            }
          }
          type
        }
        hubspotCompany
        id
        mapCenter
        mitigationTypes {
          accountId
          description
          id
          isDeleted
        }
        name
        publicationTemplates {
          entityType
          id
          phrases
          template
        }
        publicationVariables {
          constructionVariables
          incidentVariables
          roadClosureVariables
        }
        publishChannels
        regionalSetting {
          dateFormat
          firstDayOfWeek
          language
          measureUnitType
          timeFormat
          timeZone
        }
        reportSources {
          accountId
          id
          reportSource
          status
        }
        routeTypes {
          accountId
          area
          id
          name
          status
        }
        shiftTypes {
          accountId
          id
          name
          status
        }
        status
        workspaces {
          singleMultiPolygon
          workspaces {
            accountId
            area
            featureTypes
            id
            roadTypes
            title
          }
        }
      }
      creationTime
      email
      id
      name
      permissions {
        id
      }
      phoneNumber
      status
      unit {
        accountId
        activityStatuses
        displayId
        externalId
        id
        isBusy
        status
        type
      }
      unitRelationType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDetailedShiftGQL extends Apollo.Mutation<UpdateDetailedShiftMutation, UpdateDetailedShiftMutationVariables> {
    document = UpdateDetailedShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGovDeliveryPublishDetailsDocument = gql`
    mutation updateGovDeliveryPublishDetails($input: UpdateGovDeliveryPublicationChannelInput!) {
  updateGovDeliveryPublishDetails(input: $input) {
    accountId
    destination
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGovDeliveryPublishDetailsGQL extends Apollo.Mutation<UpdateGovDeliveryPublishDetailsMutation, UpdateGovDeliveryPublishDetailsMutationVariables> {
    document = UpdateGovDeliveryPublishDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIncidentConfigurationDocument = gql`
    mutation updateIncidentConfiguration($input: UpdateIncidentConfigurationInput!) {
  updateIncidentConfiguration(input: $input) {
    additionalInfoMandatory
    additionalInfoValues {
      accountId
      id
      info
      isDeleted
    }
    affectedLanesMandatory
    associatedUnitsMandatory
    attributeFixedValues
    attributeFreeTextValues {
      checked
      value
    }
    attributesMandatory
    casualtiesMandatory
    directionMandatory
    incidentSubTypes {
      abandonedVehicleSubTypeValues
      crashSubTypeValues
      damageSubTypeValues
      debrisSubTypeValues
      hazardSubTypeValues
      policeActivitySubTypeValues
      stalledVehicleSubTypeValues
      trafficStopSubTypeValues
    }
    incidentTypeValues
    mitigationTypeValues {
      accountId
      description
      id
      isDeleted
    }
    notesMandatory
    orientationMandatory
    orientationValues
    responsePlanMandatory
    roadTypeValues
    severityMandatory
    severityValues
    sourceMandatory
    sourceValues {
      accountId
      id
      reportSource
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIncidentConfigurationGQL extends Apollo.Mutation<UpdateIncidentConfigurationMutation, UpdateIncidentConfigurationMutationVariables> {
    document = UpdateIncidentConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIncidentMitigationsDocument = gql`
    mutation updateIncidentMitigations($input: UpdateIncidentMitigationsInput!) {
  updateIncidentMitigations(input: $input) {
    id
    interval {
      from
      to
    }
    mitigationType {
      accountId
      description
      id
      isDeleted
    }
    unitDisplayId
    unitId
    unitType
    userId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIncidentMitigationsGQL extends Apollo.Mutation<UpdateIncidentMitigationsMutation, UpdateIncidentMitigationsMutationVariables> {
    document = UpdateIncidentMitigationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIncidentOneTimeDocument = gql`
    mutation updateIncidentOneTime($input: IncidentInput!) {
  updateIncidentOneTime(input: $input) {
    additionalInfos {
      accountId
      id
      info
      isDeleted
    }
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
      updatedAt
    }
    allLanesAffected
    associatedUnits {
      accountId
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      driverDetails {
        name
        unitRelationType
        userId
      }
      externalId
      id
      status
      type
      unitResponse
      updatedAt
    }
    atmsId
    attributes
    autoPublish
    cadId
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    camerasInternalDetails {
      default
      externalId
      positionIndex
    }
    completedBy
    confirmedAt
    confirmedBy
    createdAt
    createdBy
    createdByUserName
    creationSource
    endReason
    endReasonComment
    endedAt
    estimatedEndTime
    externalId
    fatalities
    feedSource
    hazardRemains
    id
    injuries
    injurySeverities
    insights {
      impact {
        corridors {
          currentSpd
          expectedSpd
          impactedSegments {
            currentSpd
            expectedSpd
            forecastDecrease
            level
            linestring
            segmentId
            segmentName
          }
          name
        }
        externalId
        incidentId
        level
        updatedAt
        usersFeedback
      }
      persistenceType
      priorityScore {
        heavyInfluencedBy
        level
      }
    }
    involvedVehicles {
      color
      id
      licensePlateNumber
      make
      model
      state
      type
    }
    involvedVehiclesCount
    isAddressEstimated
    isConfirmed
    isRejected
    isUnconfirmed
    lanesStatus
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    mergeDetails {
      isBlocked
      targetId
    }
    mitigations {
      id
      interval {
        from
        to
      }
      mitigationType {
        accountId
        description
        id
        isDeleted
      }
      unitDisplayId
      unitId
      unitType
      userId
    }
    multiDirectionLanesAffected
    nearCameras
    notes {
      createdAt
      id
      note
      updatedAt
      updatedBy
      updatedByUsername
    }
    reportSources {
      accountId
      id
      reportSource
      status
    }
    responsePlan {
      actions {
        actionField
        index
        isDone
        responsePlanActionId
        responsePlanId
        text
        time
        unit
        value
      }
      entityId
      entityType
      id
      isDeleted
      templateId
      title
      updatedAt
    }
    restoreAt
    severity
    startedAt
    status
    subType
    title
    trafficDisturbance
    type
    typeDescription
    updatedAt
    updatedBy
    userDetails {
      completedBy
      confirmedBy
      updatedBy
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIncidentOneTimeGQL extends Apollo.Mutation<UpdateIncidentOneTimeMutation, UpdateIncidentOneTimeMutationVariables> {
    document = UpdateIncidentOneTimeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIncidentTypePriorityDocument = gql`
    mutation updateIncidentTypePriority($input: [IncidentType!]!) {
  updateIncidentTypePriority(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIncidentTypePriorityGQL extends Apollo.Mutation<UpdateIncidentTypePriorityMutation, UpdateIncidentTypePriorityMutationVariables> {
    document = UpdateIncidentTypePriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIncidentUnitResponseDocument = gql`
    mutation updateIncidentUnitResponse($input: UpdateIncidentUnitInput!) {
  updateIncidentUnitResponse(input: $input) {
    accountId
    dashCameras {
      imageUrl
      isVideo
      position
      streamType
      videoUrl
    }
    displayId
    driverDetails {
      name
      unitRelationType
      userId
    }
    externalId
    id
    status
    type
    unitResponse
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIncidentUnitResponseGQL extends Apollo.Mutation<UpdateIncidentUnitResponseMutation, UpdateIncidentUnitResponseMutationVariables> {
    document = UpdateIncidentUnitResponseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMitigationTypeDocument = gql`
    mutation updateMitigationType($input: UpdateMitigationTypeInput!) {
  updateMitigationType(input: $input) {
    accountId
    description
    id
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMitigationTypeGQL extends Apollo.Mutation<UpdateMitigationTypeMutation, UpdateMitigationTypeMutationVariables> {
    document = UpdateMitigationTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePublicationMessageTemplateDocument = gql`
    mutation updatePublicationMessageTemplate($input: UpdatePublicationTemplateInput!) {
  updatePublicationMessageTemplate(input: $input) {
    entityType
    id
    phrases
    template
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePublicationMessageTemplateGQL extends Apollo.Mutation<UpdatePublicationMessageTemplateMutation, UpdatePublicationMessageTemplateMutationVariables> {
    document = UpdatePublicationMessageTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReportSourceDocument = gql`
    mutation updateReportSource($input: UpdateReportSourceInput!) {
  updateReportSource(input: $input) {
    accountId
    id
    reportSource
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReportSourceGQL extends Apollo.Mutation<UpdateReportSourceMutation, UpdateReportSourceMutationVariables> {
    document = UpdateReportSourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateResponsePlanTemplateDocument = gql`
    mutation updateResponsePlanTemplate($input: UpdateResponsePlanTemplateInput!) {
  updateResponsePlanTemplate(input: $input) {
    accountId
    actions {
      actionField
      index
      templateActionId
      templateId
      text
      time
      unit
      value
    }
    entityType
    id
    title
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateResponsePlanTemplateGQL extends Apollo.Mutation<UpdateResponsePlanTemplateMutation, UpdateResponsePlanTemplateMutationVariables> {
    document = UpdateResponsePlanTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRoadClosureDocument = gql`
    mutation updateRoadClosure($input: UpdateRoadClosureInput!) {
  updateRoadClosure(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    dmses {
      dms {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        editable
        externalId
        id
        location
        messages
        status
        title
        updatedAt
      }
      positionIndex
    }
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    permit {
      permitId
      url
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    updatedAt
    updatedBy
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRoadClosureGQL extends Apollo.Mutation<UpdateRoadClosureMutation, UpdateRoadClosureMutationVariables> {
    document = UpdateRoadClosureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRoleDocument = gql`
    mutation updateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    accountId
    description
    id
    name
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRoleGQL extends Apollo.Mutation<UpdateRoleMutation, UpdateRoleMutationVariables> {
    document = UpdateRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRouteTypeDocument = gql`
    mutation updateRouteType($input: UpdateRouteTypeInput!) {
  updateRouteType(input: $input) {
    accountId
    area
    id
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRouteTypeGQL extends Apollo.Mutation<UpdateRouteTypeMutation, UpdateRouteTypeMutationVariables> {
    document = UpdateRouteTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShiftDocument = gql`
    mutation updateShift($input: UpdateShiftInput!) {
  updateShift(input: $input) {
    endMileage
    endReason
    endReasonNote
    endedAt
    isDeleted
    overtimeNote
    routeTypes {
      accountId
      area
      id
      name
      status
    }
    shiftId
    shiftPauses {
      endedAt
      isActive
      pauseId
      reason
      reasonNote
      shiftId
      startedAt
    }
    shiftType {
      accountId
      id
      name
      status
    }
    startMileage
    startedAt
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    user {
      account {
        additionalInfos {
          accountId
          id
          info
          isDeleted
        }
        analyticsReportsUrls {
          fixedRouteTransitReport {
            index
            name
            url
          }
          fspReport {
            index
            name
            url
          }
          incidentAnalysisReport {
            index
            name
            url
          }
          mitigationsReport {
            index
            name
            url
          }
          speedMeasurementReport {
            index
            name
            url
          }
          timPerformanceReport {
            index
            name
            url
          }
          transitReport {
            index
            name
            url
          }
          travelReliabilityReport {
            index
            name
            url
          }
          unitsPerformanceReport {
            index
            name
            url
          }
        }
        contactEmail
        contactName
        contactPhoneNumber
        customer {
          contactEmail
          contactName
          contactPhoneNumber
          id
          name
        }
        groups {
          id
          level
          permissions {
            dependsOnAll {
              entity
              modifier
            }
            dependsOnAny {
              entity
              modifier
            }
            id
            scope {
              entity
              modifier
            }
          }
          type
        }
        hubspotCompany
        id
        mapCenter
        mitigationTypes {
          accountId
          description
          id
          isDeleted
        }
        name
        publicationTemplates {
          entityType
          id
          phrases
          template
        }
        publicationVariables {
          constructionVariables
          incidentVariables
          roadClosureVariables
        }
        publishChannels
        regionalSetting {
          dateFormat
          firstDayOfWeek
          language
          measureUnitType
          timeFormat
          timeZone
        }
        reportSources {
          accountId
          id
          reportSource
          status
        }
        routeTypes {
          accountId
          area
          id
          name
          status
        }
        shiftTypes {
          accountId
          id
          name
          status
        }
        status
        workspaces {
          singleMultiPolygon
          workspaces {
            accountId
            area
            featureTypes
            id
            roadTypes
            title
          }
        }
      }
      creationTime
      email
      id
      name
      permissions {
        id
      }
      phoneNumber
      status
      unit {
        accountId
        activityStatuses
        displayId
        externalId
        id
        isBusy
        status
        type
      }
      unitRelationType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShiftGQL extends Apollo.Mutation<UpdateShiftMutation, UpdateShiftMutationVariables> {
    document = UpdateShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShiftTypeDocument = gql`
    mutation updateShiftType($input: UpdateShiftTypeInput!) {
  updateShiftType(input: $input) {
    accountId
    id
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShiftTypeGQL extends Apollo.Mutation<UpdateShiftTypeMutation, UpdateShiftTypeMutationVariables> {
    document = UpdateShiftTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSpecialEventDocument = gql`
    mutation updateSpecialEvent($input: UpdateSpecialEventInput!) {
  updateSpecialEvent(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    occupancy
    occupancyRange
    otherTypeDescription
    responsePlan {
      actions {
        actionField
        index
        isDone
        responsePlanActionId
        responsePlanId
        text
        time
        unit
        value
      }
      entityId
      entityType
      id
      isDeleted
      templateId
      title
      updatedAt
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    type
    updatedAt
    updatedBy
    venue {
      address
      name
    }
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSpecialEventGQL extends Apollo.Mutation<UpdateSpecialEventMutation, UpdateSpecialEventMutationVariables> {
    document = UpdateSpecialEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTrafficDisruptionLanesDocument = gql`
    mutation updateTrafficDisruptionLanes($input: UpdateTrafficDisruptionLanesInput!) {
  updateTrafficDisruptionLanes(input: $input) {
    direction
    id
    isAffected
    isClosed
    number
    positionIndex
    roadType
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTrafficDisruptionLanesGQL extends Apollo.Mutation<UpdateTrafficDisruptionLanesMutation, UpdateTrafficDisruptionLanesMutationVariables> {
    document = UpdateTrafficDisruptionLanesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTwitterPublicationDetailsDocument = gql`
    mutation updateTwitterPublicationDetails($input: UpdateTwitterPublicationChannelInput!) {
  updateTwitterPublicationDetails(input: $input) {
    accountId
    destination
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTwitterPublicationDetailsGQL extends Apollo.Mutation<UpdateTwitterPublicationDetailsMutation, UpdateTwitterPublicationDetailsMutationVariables> {
    document = UpdateTwitterPublicationDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUnitLocationDocument = gql`
    mutation updateUnitLocation($input: UpdateUnitLocationInput!) {
  updateUnitLocation(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUnitLocationGQL extends Apollo.Mutation<UpdateUnitLocationMutation, UpdateUnitLocationMutationVariables> {
    document = UpdateUnitLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    email
    id
    name
    phoneNumber
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
    status
    unit {
      accountId
      displayId
      externalId
      id
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    unitRelationType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateWorkingHoursConfigurationDocument = gql`
    mutation updateWorkingHoursConfiguration($input: UpdateWorkingHoursInput!) {
  updateWorkingHoursConfiguration(input: $input) {
    isCustom
    isHideEnabled
    workingHoursId
    workingHoursItems {
      dailyRanges {
        from
        to
      }
      dayOfWeek
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWorkingHoursConfigurationGQL extends Apollo.Mutation<UpdateWorkingHoursConfigurationMutation, UpdateWorkingHoursConfigurationMutationVariables> {
    document = UpdateWorkingHoursConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountDocument = gql`
    query account($id: Int!) {
  account(id: $id) {
    additionalInfos {
      accountId
      id
      info
      isDeleted
    }
    analyticsReportsUrls {
      fixedRouteTransitReport {
        index
        name
        url
      }
      fspReport {
        index
        name
        url
      }
      incidentAnalysisReport {
        index
        name
        url
      }
      mitigationsReport {
        index
        name
        url
      }
      speedMeasurementReport {
        index
        name
        url
      }
      timPerformanceReport {
        index
        name
        url
      }
      transitReport {
        index
        name
        url
      }
      travelReliabilityReport {
        index
        name
        url
      }
      unitsPerformanceReport {
        index
        name
        url
      }
    }
    contactEmail
    contactName
    contactPhoneNumber
    customer {
      contactEmail
      contactName
      contactPhoneNumber
      id
      name
    }
    groups {
      id
      level
      permissions {
        dependsOnAll {
          entity
          modifier
        }
        dependsOnAny {
          entity
          modifier
        }
        id
        scope {
          entity
          modifier
        }
      }
      type
    }
    hubspotCompany
    id
    mapCenter
    mitigationTypes {
      accountId
      description
      id
      isDeleted
    }
    name
    publicationTemplates {
      entityType
      id
      phrases
      template
    }
    publicationVariables {
      constructionVariables
      incidentVariables
      roadClosureVariables
    }
    publishChannels
    regionalSetting {
      dateFormat
      firstDayOfWeek
      language
      measureUnitType
      timeFormat
      timeZone
    }
    reportSources {
      accountId
      id
      reportSource
      status
    }
    routeTypes {
      accountId
      area
      id
      name
      status
    }
    shiftTypes {
      accountId
      id
      name
      status
    }
    status
    workspaces {
      singleMultiPolygon
      workspaces {
        accountId
        area
        featureTypes
        id
        roadTypes
        title
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountGQL extends Apollo.Query<AccountQuery, AccountQueryVariables> {
    document = AccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountAdditionalInfosDocument = gql`
    query accountAdditionalInfos {
  accountAdditionalInfos {
    accountId
    id
    info
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountAdditionalInfosGQL extends Apollo.Query<AccountAdditionalInfosQuery, AccountAdditionalInfosQueryVariables> {
    document = AccountAdditionalInfosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountMitigationTypesDocument = gql`
    query accountMitigationTypes($getAll: Boolean!) {
  accountMitigationTypes(getAll: $getAll) {
    accountId
    description
    id
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountMitigationTypesGQL extends Apollo.Query<AccountMitigationTypesQuery, AccountMitigationTypesQueryVariables> {
    document = AccountMitigationTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountPublicationChannelsDocument = gql`
    query accountPublicationChannels {
  accountPublicationChannels {
    accountId
    destination
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountPublicationChannelsGQL extends Apollo.Query<AccountPublicationChannelsQuery, AccountPublicationChannelsQueryVariables> {
    document = AccountPublicationChannelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountReportSourceDocument = gql`
    query accountReportSource($id: Int!) {
  accountReportSource(id: $id) {
    accountId
    id
    reportSource
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountReportSourceGQL extends Apollo.Query<AccountReportSourceQuery, AccountReportSourceQueryVariables> {
    document = AccountReportSourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountReportSourcesDocument = gql`
    query accountReportSources {
  accountReportSources {
    accountId
    id
    reportSource
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountReportSourcesGQL extends Apollo.Query<AccountReportSourcesQuery, AccountReportSourcesQueryVariables> {
    document = AccountReportSourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountRouteTypesDocument = gql`
    query accountRouteTypes {
  accountRouteTypes {
    accountId
    area
    id
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountRouteTypesGQL extends Apollo.Query<AccountRouteTypesQuery, AccountRouteTypesQueryVariables> {
    document = AccountRouteTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountShiftTypesDocument = gql`
    query accountShiftTypes {
  accountShiftTypes {
    accountId
    id
    name
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountShiftTypesGQL extends Apollo.Query<AccountShiftTypesQuery, AccountShiftTypesQueryVariables> {
    document = AccountShiftTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AffectedTransitServicesDocument = gql`
    query affectedTransitServices {
  affectedTransitServices {
    affectedFixedBusRoutes {
      affectedBusStopsCount
      affectingEvents {
        additionalInfo {
          ... on CongestionAffectingTransitAdditionalInfo {
            lengthMeters
            type
          }
          ... on IncidentAffectingTransitAdditionalInfo {
            incidentSubtype
            incidentType
            type
          }
          ... on TrafficDisruptionAffectingTransitAdditionalInfo {
            title
            type
          }
          ... on WeatherAlertAffectingTransitAdditionalInfo {
            severity
            type
            weatherAlertSubtype
            weatherAlertType
          }
        }
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        affectedBusStops
        affectingEntityType
        affectingEventId
        durationMinutes
        estimatedEndTime
        startTime
      }
      color
      durationMinutes
      estimatedEndTime
      isRerouted
      routeAccountId
      routeExternalId
      routeId
      routeName
      routeNumber
    }
    affectedTransitUnits {
      affectingEvents {
        additionalInfo {
          ... on CongestionAffectingTransitAdditionalInfo {
            lengthMeters
            type
          }
          ... on IncidentAffectingTransitAdditionalInfo {
            incidentSubtype
            incidentType
            type
          }
          ... on TrafficDisruptionAffectingTransitAdditionalInfo {
            title
            type
          }
          ... on WeatherAlertAffectingTransitAdditionalInfo {
            severity
            type
            weatherAlertSubtype
            weatherAlertType
          }
        }
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        affectedBusStops
        affectingEntityType
        affectingEventId
        durationMinutes
        estimatedEndTime
        startTime
      }
      displayId
      durationMinutes
      estimatedEndTime
      type
      unitAccountId
      unitExternalId
      unitId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AffectedTransitServicesGQL extends Apollo.Query<AffectedTransitServicesQuery, AffectedTransitServicesQueryVariables> {
    document = AffectedTransitServicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AliasChangesLogDocument = gql`
    query aliasChangesLog($input: FilterSegmentAliasInput!) {
  aliasChangesLog(input: $input) {
    aliasColumnNames
    rows {
      data {
        ... on AliasColumn {
          alias
          segmentAliasLogColumnName
        }
        ... on IdColumn {
          id
          segmentAliasLogColumnName
        }
        ... on IncidentCoordinatesColumn {
          incidentCoordinates
          segmentAliasLogColumnName
        }
        ... on OriginalLocationNameColumn {
          originalLocationName
          segmentAliasLogColumnName
        }
        ... on SubmittedAtColumn {
          segmentAliasLogColumnName
          submittedAt
        }
        ... on SubmittedByColumn {
          segmentAliasLogColumnName
          submittedBy
        }
        ... on TypeColumn {
          segmentAliasLogColumnName
          type
        }
      }
    }
    totalNumberOfResults
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AliasChangesLogGQL extends Apollo.Query<AliasChangesLogQuery, AliasChangesLogQueryVariables> {
    document = AliasChangesLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllDmSsDocument = gql`
    query allDMSs {
  allDMSs {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    editable
    externalId
    id
    location
    messages
    status
    title
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllDmSsGQL extends Apollo.Query<AllDmSsQuery, AllDmSsQueryVariables> {
    document = AllDmSsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssetsByLocationDocument = gql`
    query assetsByLocation($input: LocationInput!) {
  assetsByLocation(input: $input) {
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    dmses {
      dms {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        editable
        externalId
        id
        location
        messages
        status
        title
        updatedAt
      }
      positionIndex
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssetsByLocationGQL extends Apollo.Query<AssetsByLocationQuery, AssetsByLocationQueryVariables> {
    document = AssetsByLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BusStopDetailsDocument = gql`
    query busStopDetails($id: Int!) {
  busStopDetails(id: $id) {
    activeBuses {
      accountId
      busId
      displayId
      eta
      route {
        color
        number
        routeId
      }
      status
      statusInMinutes
    }
    displayId
    id
    name
    routes {
      color
      number
      routeId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BusStopDetailsGQL extends Apollo.Query<BusStopDetailsQuery, BusStopDetailsQueryVariables> {
    document = BusStopDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BusStopsDocument = gql`
    query busStops($input: FindBusStopViewsInput!) {
  busStops(input: $input) {
    busStopId
    displayId
    hasBusRouteFromContextAccount
    location
    routes {
      color
      number
      routeId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BusStopsGQL extends Apollo.Query<BusStopsQuery, BusStopsQueryVariables> {
    document = BusStopsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompletedConstructionsDocument = gql`
    query completedConstructions {
  completedConstructions {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    allLanesAffected
    createdAt
    endTime
    externalId
    groupKey
    id
    lanesClosureType
    layerType
    location
    multiDirectionLanesAffected
    otherTypeDescription
    startTime
    status
    title
    type
    updatedAt
    workspaces
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletedConstructionsGQL extends Apollo.Query<CompletedConstructionsQuery, CompletedConstructionsQueryVariables> {
    document = CompletedConstructionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompletedRoadClosuresDocument = gql`
    query completedRoadClosures {
  completedRoadClosures {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    allLanesAffected
    createdAt
    endTime
    externalId
    groupKey
    id
    lanesClosureType
    layerType
    location
    multiDirectionLanesAffected
    startTime
    status
    title
    updatedAt
    workspaces
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletedRoadClosuresGQL extends Apollo.Query<CompletedRoadClosuresQuery, CompletedRoadClosuresQueryVariables> {
    document = CompletedRoadClosuresDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompletedSpecialEventsDocument = gql`
    query completedSpecialEvents {
  completedSpecialEvents {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    allLanesAffected
    createdAt
    endTime
    externalId
    groupKey
    id
    lanesClosureType
    layerType
    location
    multiDirectionLanesAffected
    occupancy
    occupancyRange
    otherTypeDescription
    startTime
    status
    title
    type
    updatedAt
    venueAddress
    venueName
    workspaces
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletedSpecialEventsGQL extends Apollo.Query<CompletedSpecialEventsQuery, CompletedSpecialEventsQueryVariables> {
    document = CompletedSpecialEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConstructionDocument = gql`
    query construction($id: Int!) {
  construction(id: $id) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    dmses {
      dms {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        editable
        externalId
        id
        location
        messages
        status
        title
        updatedAt
      }
      positionIndex
    }
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    otherTypeDescription
    permit {
      permitId
      url
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    type
    updatedAt
    updatedBy
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConstructionGQL extends Apollo.Query<ConstructionQuery, ConstructionQueryVariables> {
    document = ConstructionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConstructionActivityLogDocument = gql`
    query constructionActivityLog($id: Int!) {
  constructionActivityLog(id: $id) {
    activitiesPerDay {
      accountName
      activitiesPerMinute {
        activities {
          action
          field
          offlineUpdatedAt
          value {
            ... on AdditionalInfoField {
              info
            }
            ... on AddressField {
              address {
                city
                corridor
                county
                crossroad
                direction
                milemarker
                orientation
                point
                roadLevelType
                roadType
                segmentId
                state
              }
            }
            ... on BooleanField {
              bool
            }
            ... on CameraField {
              default
              externalId
              title
            }
            ... on ConstructionTypeField {
              constructionType
            }
            ... on ContactPersonField {
              email
              name
              phoneNumber
            }
            ... on DateTimeField {
              dateTime
            }
            ... on DmsField {
              dmsId
              title
            }
            ... on GeometryField {
              geometry
            }
            ... on IncidentAutoPublishField {
              autoPublish
            }
            ... on IncidentLaneField {
              incidentLane {
                direction
                id
                isAffected
                isClosed
                number
                positionIndex
                roadType
                type
                updatedAt
              }
            }
            ... on IncidentMediaIdField {
              incidentMediaId
            }
            ... on IncidentSeverityField {
              incidentSeverity
            }
            ... on IncidentStatusField {
              automaticAction
              endReason
              endReasonComment
              status
            }
            ... on IncidentSubTypeField {
              subType
            }
            ... on IncidentTypeField {
              incidentType
            }
            ... on InjurySeveritiesField {
              injurySeverity
            }
            ... on IntField {
              n
            }
            ... on LanesClosureTypeField {
              lanesClosureType
            }
            ... on LocationField {
              point
            }
            ... on MitigationField {
              description
              interval {
                from
                to
              }
              unitDisplayId
              unitId
            }
            ... on NoteField {
              note
            }
            ... on NullableTextField {
              nullableText
            }
            ... on OccupancyRangeField {
              occupancyRange
            }
            ... on PermitField {
              permitId
              url
            }
            ... on PublishField {
              description
              destinations
            }
            ... on RelatedEventField {
              endTime
              eventId
              relatedEventStatus
              startTime
              type
            }
            ... on ReportSourceField {
              accountId
              reportSource
            }
            ... on RouteTypeIdsField {
              routeTypesIds
            }
            ... on ScheduleField {
              endTime
              schedule {
                timeRanges {
                  fromDayOfWeekLocal
                  fromTimeMinutesLocal
                  toDayOfWeekLocal
                  toTimeMinutesLocal
                }
                timeZone
              }
              startTime
            }
            ... on ShiftEndReasonField {
              shiftEndReason
            }
            ... on ShiftTypeIdField {
              shiftTypeId
            }
            ... on SpecialEventTypeField {
              specialEventType
            }
            ... on TextField {
              text
            }
            ... on TrafficDisruptionLaneField {
              trafficDisruptionLane {
                direction
                id
                isAffected
                isClosed
                number
                positionIndex
                roadType
                type
              }
            }
            ... on TrafficDisruptionMediaIdField {
              trafficDisruptionMediaId
            }
            ... on TrafficDisruptionStatusField {
              trafficDisruptionEndReason
              trafficDisruptionStatus
            }
            ... on UnitField {
              displayId
              driverId
              response
            }
            ... on UnitIdField {
              shiftUnitId
            }
            ... on VehicleField {
              vehicle {
                color
                id
                licensePlateNumber
                make
                model
                state
                type
              }
            }
            ... on VenueField {
              venueAddress
              venueName
            }
          }
        }
        activityLogEntityType
        activityLogSectionType
        maxOfflineUpdatedAt
      }
      timeByMinute
      userName
    }
    timeByDay
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConstructionActivityLogGQL extends Apollo.Query<ConstructionActivityLogQuery, ConstructionActivityLogQueryVariables> {
    document = ConstructionActivityLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CustomerDocument = gql`
    query customer($id: Int!) {
  customer(id: $id) {
    contactEmail
    contactName
    contactPhoneNumber
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerGQL extends Apollo.Query<CustomerQuery, CustomerQueryVariables> {
    document = CustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DmsDocument = gql`
    query dms($id: Int!) {
  dms(id: $id) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    editable
    externalId
    id
    location
    messages
    status
    title
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DmsGQL extends Apollo.Query<DmsQuery, DmsQueryVariables> {
    document = DmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DmssByAccountDocument = gql`
    query dmssByAccount {
  dmssByAccount {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    editable
    externalId
    id
    location
    messages
    status
    title
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DmssByAccountGQL extends Apollo.Query<DmssByAccountQuery, DmssByAccountQueryVariables> {
    document = DmssByAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindAccountActiveUnitsAndDriversDocument = gql`
    query findAccountActiveUnitsAndDrivers {
  findAccountActiveUnitsAndDrivers {
    drivers {
      account {
        additionalInfos {
          accountId
          id
          info
          isDeleted
        }
        analyticsReportsUrls {
          fixedRouteTransitReport {
            index
            name
            url
          }
          fspReport {
            index
            name
            url
          }
          incidentAnalysisReport {
            index
            name
            url
          }
          mitigationsReport {
            index
            name
            url
          }
          speedMeasurementReport {
            index
            name
            url
          }
          timPerformanceReport {
            index
            name
            url
          }
          transitReport {
            index
            name
            url
          }
          travelReliabilityReport {
            index
            name
            url
          }
          unitsPerformanceReport {
            index
            name
            url
          }
        }
        contactEmail
        contactName
        contactPhoneNumber
        customer {
          contactEmail
          contactName
          contactPhoneNumber
          id
          name
        }
        groups {
          id
          level
          permissions {
            dependsOnAll {
              entity
              modifier
            }
            dependsOnAny {
              entity
              modifier
            }
            id
            scope {
              entity
              modifier
            }
          }
          type
        }
        hubspotCompany
        id
        mapCenter
        mitigationTypes {
          accountId
          description
          id
          isDeleted
        }
        name
        publicationTemplates {
          entityType
          id
          phrases
          template
        }
        publicationVariables {
          constructionVariables
          incidentVariables
          roadClosureVariables
        }
        publishChannels
        regionalSetting {
          dateFormat
          firstDayOfWeek
          language
          measureUnitType
          timeFormat
          timeZone
        }
        reportSources {
          accountId
          id
          reportSource
          status
        }
        routeTypes {
          accountId
          area
          id
          name
          status
        }
        shiftTypes {
          accountId
          id
          name
          status
        }
        status
        workspaces {
          singleMultiPolygon
          workspaces {
            accountId
            area
            featureTypes
            id
            roadTypes
            title
          }
        }
      }
      creationTime
      email
      id
      name
      permissions {
        id
      }
      phoneNumber
      status
      unit {
        accountId
        activityStatuses
        dashCameras {
          imageUrl
          isVideo
          position
          streamType
          videoUrl
        }
        displayId
        externalId
        id
        isBusy
        status
        type
        userDetails {
          name
          unitRelationType
          userId
        }
      }
      unitRelationType
    }
    units {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindAccountActiveUnitsAndDriversGQL extends Apollo.Query<FindAccountActiveUnitsAndDriversQuery, FindAccountActiveUnitsAndDriversQueryVariables> {
    document = FindAccountActiveUnitsAndDriversDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindLatestIncidentsDocument = gql`
    query findLatestIncidents {
  findLatestIncidents {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
      updatedAt
    }
    allLanesAffected
    appended
    associatedUnits {
      accountId
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      driverDetails {
        name
        unitRelationType
        userId
      }
      externalId
      id
      status
      type
      unitResponse
      updatedAt
    }
    atmsId
    cadId
    confirmedAt
    confirmedBy
    createdAt
    createdBy
    createdByUserName
    creationSource
    externalId
    groupKey
    id
    indications
    isFullClosure
    isUnconfirmed
    lanesStatus
    layerType
    location
    mergeDetails {
      isBlocked
      targetId
    }
    mitigatedAccounts
    multiDirectionLanesAffected
    nearCameras
    priorityScore {
      level
      score
    }
    roadClosure
    severity
    startedAt
    status
    subType
    title
    type
    typeDescription
    updatedAt
    updatedBy
    withinWorkingHours
    workspaces
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindLatestIncidentsGQL extends Apollo.Query<FindLatestIncidentsQuery, FindLatestIncidentsQueryVariables> {
    document = FindLatestIncidentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindLatestReleaseNotesDocument = gql`
    query findLatestReleaseNotes($pastMonths: Int!) {
  findLatestReleaseNotes(pastMonths: $pastMonths) {
    features {
      description
      id
      imageUrl
      positionIndex
      releaseNoteId
      title
      updatedAt
    }
    generatedReleaseId
    id
    imageUrl
    releaseLanguage
    releaseName
    releaseTitle
    scheduleReleaseDate
    tags
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindLatestReleaseNotesGQL extends Apollo.Query<FindLatestReleaseNotesQuery, FindLatestReleaseNotesQueryVariables> {
    document = FindLatestReleaseNotesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindRelatedIncidentsByPointRadiusDocument = gql`
    query findRelatedIncidentsByPointRadius($input: FindIncidentsInPointRadiusInput!) {
  findRelatedIncidentsByPointRadius(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    id
    status
    subType
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindRelatedIncidentsByPointRadiusGQL extends Apollo.Query<FindRelatedIncidentsByPointRadiusQuery, FindRelatedIncidentsByPointRadiusQueryVariables> {
    document = FindRelatedIncidentsByPointRadiusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindUsersRolesDocument = gql`
    query findUsersRoles {
  findUsersRoles {
    email
    phoneNumber
    roles {
      accountId
      description
      id
      name
      permissions {
        dependsOnAll {
          entity
          modifier
        }
        dependsOnAny {
          entity
          modifier
        }
        id
        scope {
          entity
          modifier
        }
      }
    }
    userId
    userName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindUsersRolesGQL extends Apollo.Query<FindUsersRolesQuery, FindUsersRolesQueryVariables> {
    document = FindUsersRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FixedBusRouteDetailsDocument = gql`
    query fixedBusRouteDetails($id: Int!) {
  fixedBusRouteDetails(id: $id) {
    id
    isRerouted
    number
    patterns {
      buses {
        displayId
        id
        status
        statusInMinutes
      }
      direction
      id
      name
      numberOfStops
      stops {
        buses {
          displayId
          id
          nextEtaInMinutes
          patternId
          status
          statusInMinutes
        }
        displayId
        id
        name
        nextEtaInMinutes
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FixedBusRouteDetailsGQL extends Apollo.Query<FixedBusRouteDetailsQuery, FixedBusRouteDetailsQueryVariables> {
    document = FixedBusRouteDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FixedBusRoutesDocument = gql`
    query fixedBusRoutes {
  fixedBusRoutes {
    accountId
    accountName
    color
    externalId
    id
    name
    number
    path
    patterns {
      direction
      externalId
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FixedBusRoutesGQL extends Apollo.Query<FixedBusRoutesQuery, FixedBusRoutesQueryVariables> {
    document = FixedBusRoutesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCurrentShiftDocument = gql`
    query getCurrentShift {
  getCurrentShift {
    endMileage
    endReason
    endReasonNote
    endedAt
    isDeleted
    overtimeNote
    routeTypes {
      accountId
      area
      id
      name
      status
    }
    shiftId
    shiftPauses {
      endedAt
      isActive
      pauseId
      reason
      reasonNote
      shiftId
      startedAt
    }
    shiftType {
      accountId
      id
      name
      status
    }
    startMileage
    startedAt
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    user {
      account {
        additionalInfos {
          accountId
          id
          info
          isDeleted
        }
        analyticsReportsUrls {
          fixedRouteTransitReport {
            index
            name
            url
          }
          fspReport {
            index
            name
            url
          }
          incidentAnalysisReport {
            index
            name
            url
          }
          mitigationsReport {
            index
            name
            url
          }
          speedMeasurementReport {
            index
            name
            url
          }
          timPerformanceReport {
            index
            name
            url
          }
          transitReport {
            index
            name
            url
          }
          travelReliabilityReport {
            index
            name
            url
          }
          unitsPerformanceReport {
            index
            name
            url
          }
        }
        contactEmail
        contactName
        contactPhoneNumber
        customer {
          contactEmail
          contactName
          contactPhoneNumber
          id
          name
        }
        groups {
          id
          level
          permissions {
            dependsOnAll {
              entity
              modifier
            }
            dependsOnAny {
              entity
              modifier
            }
            id
            scope {
              entity
              modifier
            }
          }
          type
        }
        hubspotCompany
        id
        mapCenter
        mitigationTypes {
          accountId
          description
          id
          isDeleted
        }
        name
        publicationTemplates {
          entityType
          id
          phrases
          template
        }
        publicationVariables {
          constructionVariables
          incidentVariables
          roadClosureVariables
        }
        publishChannels
        regionalSetting {
          dateFormat
          firstDayOfWeek
          language
          measureUnitType
          timeFormat
          timeZone
        }
        reportSources {
          accountId
          id
          reportSource
          status
        }
        routeTypes {
          accountId
          area
          id
          name
          status
        }
        shiftTypes {
          accountId
          id
          name
          status
        }
        status
        workspaces {
          singleMultiPolygon
          workspaces {
            accountId
            area
            featureTypes
            id
            roadTypes
            title
          }
        }
      }
      creationTime
      email
      id
      name
      permissions {
        id
      }
      phoneNumber
      status
      unit {
        accountId
        activityStatuses
        displayId
        externalId
        id
        isBusy
        status
        type
      }
      unitRelationType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCurrentShiftGQL extends Apollo.Query<GetCurrentShiftQuery, GetCurrentShiftQueryVariables> {
    document = GetCurrentShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDetailedShiftDocument = gql`
    query getDetailedShift($id: Int!) {
  getDetailedShift(id: $id) {
    baseData {
      endMileage
      endReason
      endReasonNote
      endedAt
      isDeleted
      overtimeNote
      routeTypes {
        accountId
        area
        id
        name
        status
      }
      shiftId
      shiftPauses {
        endedAt
        isActive
        pauseId
        reason
        reasonNote
        shiftId
        startedAt
      }
      shiftType {
        accountId
        id
        name
        status
      }
      startMileage
      startedAt
      unit {
        accountId
        activityStatuses
        dashCameras {
          imageUrl
          isVideo
          position
          streamType
          videoUrl
        }
        displayId
        externalId
        id
        isBusy
        status
        type
        userDetails {
          name
          unitRelationType
          userId
        }
      }
      user {
        account {
          additionalInfos {
            accountId
            id
            info
            isDeleted
          }
          analyticsReportsUrls {
            fixedRouteTransitReport {
              index
              name
              url
            }
            fspReport {
              index
              name
              url
            }
            incidentAnalysisReport {
              index
              name
              url
            }
            mitigationsReport {
              index
              name
              url
            }
            speedMeasurementReport {
              index
              name
              url
            }
            timPerformanceReport {
              index
              name
              url
            }
            transitReport {
              index
              name
              url
            }
            travelReliabilityReport {
              index
              name
              url
            }
            unitsPerformanceReport {
              index
              name
              url
            }
          }
          contactEmail
          contactName
          contactPhoneNumber
          customer {
            contactEmail
            contactName
            contactPhoneNumber
            id
            name
          }
          groups {
            id
            level
            permissions {
              dependsOnAll {
                entity
                modifier
              }
              dependsOnAny {
                entity
                modifier
              }
              id
              scope {
                entity
                modifier
              }
            }
            type
          }
          hubspotCompany
          id
          mapCenter
          mitigationTypes {
            accountId
            description
            id
            isDeleted
          }
          name
          publicationTemplates {
            entityType
            id
            phrases
            template
          }
          publicationVariables {
            constructionVariables
            incidentVariables
            roadClosureVariables
          }
          publishChannels
          regionalSetting {
            dateFormat
            firstDayOfWeek
            language
            measureUnitType
            timeFormat
            timeZone
          }
          reportSources {
            accountId
            id
            reportSource
            status
          }
          routeTypes {
            accountId
            area
            id
            name
            status
          }
          shiftTypes {
            accountId
            id
            name
            status
          }
          status
          workspaces {
            singleMultiPolygon
            workspaces {
              accountId
              area
              featureTypes
              id
              roadTypes
              title
            }
          }
        }
        creationTime
        email
        id
        name
        permissions {
          id
        }
        phoneNumber
        status
        unit {
          accountId
          activityStatuses
          displayId
          externalId
          id
          isBusy
          status
          type
        }
        unitRelationType
      }
    }
    durationMillis
    durationMillisRounded
    endedAt
    endedAtRounded
    firstIncidentMitigationTime
    incidentsDetails {
      address {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      firstMitigationTime
      incidentId
      lastMitigationTime
      location
      mitigations {
        interval {
          from
          to
        }
        mitigationDescription
        mitigationId
        mitigationTypeId
        userId
      }
      responses {
        responseTime
        unitResponse
      }
      subType
      type
    }
    isOvertime
    lastIncidentMitigationTime
    startedAt
    startedAtRounded
    totalBreaksDurationMillis
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDetailedShiftGQL extends Apollo.Query<GetDetailedShiftQuery, GetDetailedShiftQueryVariables> {
    document = GetDetailedShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmbedDashboardUrlDocument = gql`
    query getEmbedDashboardUrl($dashboardId: String!) {
  getEmbedDashboardUrl(dashboardId: $dashboardId) {
    dashboardUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmbedDashboardUrlGQL extends Apollo.Query<GetEmbedDashboardUrlQuery, GetEmbedDashboardUrlQueryVariables> {
    document = GetEmbedDashboardUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetIncidentInsightsDocument = gql`
    query getIncidentInsights($externalId: String, $incidentId: Int!, $status: IncidentStatus!) {
  getIncidentInsights(
    externalId: $externalId
    incidentId: $incidentId
    status: $status
  ) {
    impact {
      corridors {
        currentSpd
        expectedSpd
        impactedSegments {
          currentSpd
          expectedSpd
          forecastDecrease
          level
          linestring
          segmentId
          segmentName
        }
        name
      }
      externalId
      incidentId
      level
      updatedAt
      usersFeedback
    }
    persistenceType
    priorityScore {
      heavyInfluencedBy
      level
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetIncidentInsightsGQL extends Apollo.Query<GetIncidentInsightsQuery, GetIncidentInsightsQueryVariables> {
    document = GetIncidentInsightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetIncidentTypesDocument = gql`
    query getIncidentTypes {
  getIncidentTypes {
    incidentAbandonedVehicleSubType
    incidentCrashSubTypes
    incidentDamageSubType
    incidentDebrisSubTypes
    incidentHazardSubTypes
    incidentPoliceActivitySubTypes
    incidentStalledVehicleSubTypes
    incidentTrafficStopSubTypes
    incidentTypes
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetIncidentTypesGQL extends Apollo.Query<GetIncidentTypesQuery, GetIncidentTypesQueryVariables> {
    document = GetIncidentTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetIncidentsWithUserMitigationsByTimePeriodDocument = gql`
    query getIncidentsWithUserMitigationsByTimePeriod($input: UserAndTimeInput!) {
  getIncidentsWithUserMitigationsByTimePeriod(input: $input) {
    additionalInfos {
      accountId
      id
      info
      isDeleted
    }
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
      updatedAt
    }
    allLanesAffected
    associatedUnits {
      accountId
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      driverDetails {
        name
        unitRelationType
        userId
      }
      externalId
      id
      status
      type
      unitResponse
      updatedAt
    }
    atmsId
    attributes
    autoPublish
    cadId
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    camerasInternalDetails {
      default
      externalId
      positionIndex
    }
    completedBy
    confirmedAt
    confirmedBy
    createdAt
    createdBy
    createdByUserName
    creationSource
    endReason
    endReasonComment
    endedAt
    estimatedEndTime
    externalId
    fatalities
    feedSource
    hazardRemains
    id
    injuries
    injurySeverities
    insights {
      impact {
        corridors {
          currentSpd
          expectedSpd
          impactedSegments {
            currentSpd
            expectedSpd
            forecastDecrease
            level
            linestring
            segmentId
            segmentName
          }
          name
        }
        externalId
        incidentId
        level
        updatedAt
        usersFeedback
      }
      persistenceType
      priorityScore {
        heavyInfluencedBy
        level
      }
    }
    involvedVehicles {
      color
      id
      licensePlateNumber
      make
      model
      state
      type
    }
    involvedVehiclesCount
    isAddressEstimated
    isConfirmed
    isRejected
    isUnconfirmed
    lanesStatus
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    mergeDetails {
      isBlocked
      targetId
    }
    mitigations {
      id
      interval {
        from
        to
      }
      mitigationType {
        accountId
        description
        id
        isDeleted
      }
      unitDisplayId
      unitId
      unitType
      userId
    }
    multiDirectionLanesAffected
    nearCameras
    notes {
      createdAt
      id
      note
      updatedAt
      updatedBy
      updatedByUsername
    }
    reportSources {
      accountId
      id
      reportSource
      status
    }
    responsePlan {
      actions {
        actionField
        index
        isDone
        responsePlanActionId
        responsePlanId
        text
        time
        unit
        value
      }
      entityId
      entityType
      id
      isDeleted
      templateId
      title
      updatedAt
    }
    restoreAt
    severity
    startedAt
    status
    subType
    title
    trafficDisturbance
    type
    typeDescription
    updatedAt
    updatedBy
    userDetails {
      completedBy
      confirmedBy
      updatedBy
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetIncidentsWithUserMitigationsByTimePeriodGQL extends Apollo.Query<GetIncidentsWithUserMitigationsByTimePeriodQuery, GetIncidentsWithUserMitigationsByTimePeriodQueryVariables> {
    document = GetIncidentsWithUserMitigationsByTimePeriodDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRoadwayStatusSegmentMetricDetailsByIdDocument = gql`
    query getRoadwayStatusSegmentMetricDetailsById($segmentId: Int!) {
  getRoadwayStatusSegmentMetricDetailsById(segmentId: $segmentId) {
    avgSpeedTotal
    endedAt
    id
    isDataExist
    isDefaultVendor
    isUpdated
    lanesData {
      avgSpeed
      count
      id
      type
    }
    maxSpeedDeviationPercent
    maxSpeedDeviationSeverity
    nonBusesAvgSpeed
    nonBusesCount
    roadwayStatusSegmentId
    startedAt
    transportCountTotal
    transportsData {
      avgSpeed
      count
      id
      type
    }
    updatedAt
    vendorId
    vendorName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRoadwayStatusSegmentMetricDetailsByIdGQL extends Apollo.Query<GetRoadwayStatusSegmentMetricDetailsByIdQuery, GetRoadwayStatusSegmentMetricDetailsByIdQueryVariables> {
    document = GetRoadwayStatusSegmentMetricDetailsByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetShiftsByUserAndTimeDocument = gql`
    query getShiftsByUserAndTime($input: UserAndTimeInput!) {
  getShiftsByUserAndTime(input: $input) {
    endMileage
    endReason
    endReasonNote
    endedAt
    isDeleted
    overtimeNote
    routeTypes {
      accountId
      area
      id
      name
      status
    }
    shiftId
    shiftPauses {
      endedAt
      isActive
      pauseId
      reason
      reasonNote
      shiftId
      startedAt
    }
    shiftType {
      accountId
      id
      name
      status
    }
    startMileage
    startedAt
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    user {
      account {
        additionalInfos {
          accountId
          id
          info
          isDeleted
        }
        analyticsReportsUrls {
          fixedRouteTransitReport {
            index
            name
            url
          }
          fspReport {
            index
            name
            url
          }
          incidentAnalysisReport {
            index
            name
            url
          }
          mitigationsReport {
            index
            name
            url
          }
          speedMeasurementReport {
            index
            name
            url
          }
          timPerformanceReport {
            index
            name
            url
          }
          transitReport {
            index
            name
            url
          }
          travelReliabilityReport {
            index
            name
            url
          }
          unitsPerformanceReport {
            index
            name
            url
          }
        }
        contactEmail
        contactName
        contactPhoneNumber
        customer {
          contactEmail
          contactName
          contactPhoneNumber
          id
          name
        }
        groups {
          id
          level
          permissions {
            dependsOnAll {
              entity
              modifier
            }
            dependsOnAny {
              entity
              modifier
            }
            id
            scope {
              entity
              modifier
            }
          }
          type
        }
        hubspotCompany
        id
        mapCenter
        mitigationTypes {
          accountId
          description
          id
          isDeleted
        }
        name
        publicationTemplates {
          entityType
          id
          phrases
          template
        }
        publicationVariables {
          constructionVariables
          incidentVariables
          roadClosureVariables
        }
        publishChannels
        regionalSetting {
          dateFormat
          firstDayOfWeek
          language
          measureUnitType
          timeFormat
          timeZone
        }
        reportSources {
          accountId
          id
          reportSource
          status
        }
        routeTypes {
          accountId
          area
          id
          name
          status
        }
        shiftTypes {
          accountId
          id
          name
          status
        }
        status
        workspaces {
          singleMultiPolygon
          workspaces {
            accountId
            area
            featureTypes
            id
            roadTypes
            title
          }
        }
      }
      creationTime
      email
      id
      name
      permissions {
        id
      }
      phoneNumber
      status
      unit {
        accountId
        activityStatuses
        displayId
        externalId
        id
        isBusy
        status
        type
      }
      unitRelationType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetShiftsByUserAndTimeGQL extends Apollo.Query<GetShiftsByUserAndTimeQuery, GetShiftsByUserAndTimeQueryVariables> {
    document = GetShiftsByUserAndTimeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubmittedByOptionsListDocument = gql`
    query getSubmittedByOptionsList {
  getSubmittedByOptionsList {
    displayName
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubmittedByOptionsListGQL extends Apollo.Query<GetSubmittedByOptionsListQuery, GetSubmittedByOptionsListQueryVariables> {
    document = GetSubmittedByOptionsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetWorkingHoursDocument = gql`
    query getWorkingHours {
  getWorkingHours {
    isCustom
    isHideEnabled
    workingHoursId
    workingHoursItems {
      dailyRanges {
        from
        to
      }
      dayOfWeek
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetWorkingHoursGQL extends Apollo.Query<GetWorkingHoursQuery, GetWorkingHoursQueryVariables> {
    document = GetWorkingHoursDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InProgressIncidentsDocument = gql`
    query inProgressIncidents {
  inProgressIncidents {
    additionalInfos {
      accountId
      id
      info
      isDeleted
    }
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
      updatedAt
    }
    allLanesAffected
    associatedUnits {
      accountId
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      driverDetails {
        name
        unitRelationType
        userId
      }
      externalId
      id
      status
      type
      unitResponse
      updatedAt
    }
    atmsId
    attributes
    autoPublish
    cadId
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    camerasInternalDetails {
      default
      externalId
      positionIndex
    }
    completedBy
    confirmedAt
    confirmedBy
    createdAt
    createdBy
    createdByUserName
    creationSource
    endReason
    endReasonComment
    endedAt
    estimatedEndTime
    externalId
    fatalities
    feedSource
    hazardRemains
    id
    injuries
    injurySeverities
    insights {
      impact {
        corridors {
          currentSpd
          expectedSpd
          impactedSegments {
            currentSpd
            expectedSpd
            forecastDecrease
            level
            linestring
            segmentId
            segmentName
          }
          name
        }
        externalId
        incidentId
        level
        updatedAt
        usersFeedback
      }
      persistenceType
      priorityScore {
        heavyInfluencedBy
        level
      }
    }
    involvedVehicles {
      color
      id
      licensePlateNumber
      make
      model
      state
      type
    }
    involvedVehiclesCount
    isAddressEstimated
    isConfirmed
    isRejected
    isUnconfirmed
    lanesStatus
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    mergeDetails {
      isBlocked
      targetId
    }
    mitigations {
      id
      interval {
        from
        to
      }
      mitigationType {
        accountId
        description
        id
        isDeleted
      }
      unitDisplayId
      unitId
      unitType
      userId
    }
    multiDirectionLanesAffected
    nearCameras
    notes {
      createdAt
      id
      note
      updatedAt
      updatedBy
      updatedByUsername
    }
    reportSources {
      accountId
      id
      reportSource
      status
    }
    responsePlan {
      actions {
        actionField
        index
        isDone
        responsePlanActionId
        responsePlanId
        text
        time
        unit
        value
      }
      entityId
      entityType
      id
      isDeleted
      templateId
      title
      updatedAt
    }
    restoreAt
    severity
    startedAt
    status
    subType
    title
    trafficDisturbance
    type
    typeDescription
    updatedAt
    updatedBy
    userDetails {
      completedBy
      confirmedBy
      updatedBy
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InProgressIncidentsGQL extends Apollo.Query<InProgressIncidentsQuery, InProgressIncidentsQueryVariables> {
    document = InProgressIncidentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentDocument = gql`
    query incident($id: Int!) {
  incident(id: $id) {
    additionalInfos {
      accountId
      id
      info
      isDeleted
    }
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
      updatedAt
    }
    allLanesAffected
    associatedUnits {
      accountId
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      driverDetails {
        name
        unitRelationType
        userId
      }
      externalId
      id
      status
      type
      unitResponse
      updatedAt
    }
    atmsId
    attributes
    autoPublish
    cadId
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    camerasInternalDetails {
      default
      externalId
      positionIndex
    }
    completedBy
    confirmedAt
    confirmedBy
    createdAt
    createdBy
    createdByUserName
    creationSource
    endReason
    endReasonComment
    endedAt
    estimatedEndTime
    externalId
    fatalities
    feedSource
    hazardRemains
    id
    injuries
    injurySeverities
    insights {
      impact {
        corridors {
          currentSpd
          expectedSpd
          impactedSegments {
            currentSpd
            expectedSpd
            forecastDecrease
            level
            linestring
            segmentId
            segmentName
          }
          name
        }
        externalId
        incidentId
        level
        updatedAt
        usersFeedback
      }
      persistenceType
      priorityScore {
        heavyInfluencedBy
        level
      }
    }
    involvedVehicles {
      color
      id
      licensePlateNumber
      make
      model
      state
      type
    }
    involvedVehiclesCount
    isAddressEstimated
    isConfirmed
    isRejected
    isUnconfirmed
    lanesStatus
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    mergeDetails {
      isBlocked
      targetId
    }
    mitigations {
      id
      interval {
        from
        to
      }
      mitigationType {
        accountId
        description
        id
        isDeleted
      }
      unitDisplayId
      unitId
      unitType
      userId
    }
    multiDirectionLanesAffected
    nearCameras
    notes {
      createdAt
      id
      note
      updatedAt
      updatedBy
      updatedByUsername
    }
    reportSources {
      accountId
      id
      reportSource
      status
    }
    responsePlan {
      actions {
        actionField
        index
        isDone
        responsePlanActionId
        responsePlanId
        text
        time
        unit
        value
      }
      entityId
      entityType
      id
      isDeleted
      templateId
      title
      updatedAt
    }
    restoreAt
    severity
    startedAt
    status
    subType
    title
    trafficDisturbance
    type
    typeDescription
    updatedAt
    updatedBy
    userDetails {
      completedBy
      confirmedBy
      updatedBy
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentGQL extends Apollo.Query<IncidentQuery, IncidentQueryVariables> {
    document = IncidentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentActivityLogDocument = gql`
    query incidentActivityLog($id: Int!) {
  incidentActivityLog(id: $id) {
    activitiesPerDay {
      accountName
      activitiesPerMinute {
        activities {
          action
          field
          offlineUpdatedAt
          value {
            ... on AdditionalInfoField {
              info
            }
            ... on AddressField {
              address {
                city
                corridor
                county
                crossroad
                direction
                milemarker
                orientation
                point
                roadLevelType
                roadType
                segmentId
                state
              }
            }
            ... on BooleanField {
              bool
            }
            ... on CameraField {
              default
              externalId
              title
            }
            ... on ConstructionTypeField {
              constructionType
            }
            ... on ContactPersonField {
              email
              name
              phoneNumber
            }
            ... on DateTimeField {
              dateTime
            }
            ... on DmsField {
              dmsId
              title
            }
            ... on GeometryField {
              geometry
            }
            ... on IncidentAutoPublishField {
              autoPublish
            }
            ... on IncidentLaneField {
              incidentLane {
                direction
                id
                isAffected
                isClosed
                number
                positionIndex
                roadType
                type
                updatedAt
              }
            }
            ... on IncidentMediaIdField {
              incidentMediaId
            }
            ... on IncidentSeverityField {
              incidentSeverity
            }
            ... on IncidentStatusField {
              automaticAction
              endReason
              endReasonComment
              status
            }
            ... on IncidentSubTypeField {
              subType
            }
            ... on IncidentTypeField {
              incidentType
            }
            ... on InjurySeveritiesField {
              injurySeverity
            }
            ... on IntField {
              n
            }
            ... on LanesClosureTypeField {
              lanesClosureType
            }
            ... on LocationField {
              point
            }
            ... on MitigationField {
              description
              interval {
                from
                to
              }
              unitDisplayId
              unitId
            }
            ... on NoteField {
              note
            }
            ... on NullableTextField {
              nullableText
            }
            ... on OccupancyRangeField {
              occupancyRange
            }
            ... on PermitField {
              permitId
              url
            }
            ... on PublishField {
              description
              destinations
            }
            ... on RelatedEventField {
              endTime
              eventId
              relatedEventStatus
              startTime
              type
            }
            ... on ReportSourceField {
              accountId
              reportSource
            }
            ... on RouteTypeIdsField {
              routeTypesIds
            }
            ... on ScheduleField {
              endTime
              schedule {
                timeRanges {
                  fromDayOfWeekLocal
                  fromTimeMinutesLocal
                  toDayOfWeekLocal
                  toTimeMinutesLocal
                }
                timeZone
              }
              startTime
            }
            ... on ShiftEndReasonField {
              shiftEndReason
            }
            ... on ShiftTypeIdField {
              shiftTypeId
            }
            ... on SpecialEventTypeField {
              specialEventType
            }
            ... on TextField {
              text
            }
            ... on TrafficDisruptionLaneField {
              trafficDisruptionLane {
                direction
                id
                isAffected
                isClosed
                number
                positionIndex
                roadType
                type
              }
            }
            ... on TrafficDisruptionMediaIdField {
              trafficDisruptionMediaId
            }
            ... on TrafficDisruptionStatusField {
              trafficDisruptionEndReason
              trafficDisruptionStatus
            }
            ... on UnitField {
              displayId
              driverId
              response
            }
            ... on UnitIdField {
              shiftUnitId
            }
            ... on VehicleField {
              vehicle {
                color
                id
                licensePlateNumber
                make
                model
                state
                type
              }
            }
            ... on VenueField {
              venueAddress
              venueName
            }
          }
        }
        activityLogEntityType
        activityLogSectionType
        maxOfflineUpdatedAt
      }
      timeByMinute
      userName
    }
    timeByDay
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentActivityLogGQL extends Apollo.Query<IncidentActivityLogQuery, IncidentActivityLogQueryVariables> {
    document = IncidentActivityLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentCamerasByPointDocument = gql`
    query incidentCamerasByPoint($input: FindStreetCamerasByPointAndRadiusInput!) {
  incidentCamerasByPoint(input: $input) {
    camera {
      address {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      externalId
      id
      imageUrl
      isVideo
      location
      status
      streamType
      title
      videoUrl
      workspaces
    }
    default
    positionIndex
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentCamerasByPointGQL extends Apollo.Query<IncidentCamerasByPointQuery, IncidentCamerasByPointQueryVariables> {
    document = IncidentCamerasByPointDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentConfigurationDocument = gql`
    query incidentConfiguration {
  incidentConfiguration {
    additionalInfoMandatory
    additionalInfoValues {
      accountId
      id
      info
      isDeleted
    }
    affectedLanesMandatory
    associatedUnitsMandatory
    attributeFixedValues
    attributeFreeTextValues {
      checked
      value
    }
    attributesMandatory
    casualtiesMandatory
    directionMandatory
    incidentSubTypes {
      abandonedVehicleSubTypeValues
      crashSubTypeValues
      damageSubTypeValues
      debrisSubTypeValues
      hazardSubTypeValues
      policeActivitySubTypeValues
      stalledVehicleSubTypeValues
      trafficStopSubTypeValues
    }
    incidentTypeValues
    mitigationTypeValues {
      accountId
      description
      id
      isDeleted
    }
    notesMandatory
    orientationMandatory
    orientationValues
    responsePlanMandatory
    roadTypeValues
    severityMandatory
    severityValues
    sourceMandatory
    sourceValues {
      accountId
      id
      reportSource
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentConfigurationGQL extends Apollo.Query<IncidentConfigurationQuery, IncidentConfigurationQueryVariables> {
    document = IncidentConfigurationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentDetailsLogPdfExportDocument = gql`
    query incidentDetailsLogPDFExport($id: Int!) {
  incidentDetailsLogPDFExport(id: $id) {
    expiration
    fileSize
    isDocument
    key
    source
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentDetailsLogPdfExportGQL extends Apollo.Query<IncidentDetailsLogPdfExportQuery, IncidentDetailsLogPdfExportQueryVariables> {
    document = IncidentDetailsLogPdfExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentMitigationAndUnitsDocument = gql`
    query incidentMitigationAndUnits($id: Int!) {
  incident(id: $id) {
    associatedUnits {
      accountId
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      driverDetails {
        name
        unitRelationType
        userId
      }
      externalId
      id
      status
      type
      unitResponse
      updatedAt
    }
    mitigations {
      id
      interval {
        from
        to
      }
      mitigationType {
        accountId
        description
        id
        isDeleted
      }
      unitDisplayId
      unitId
      unitType
      userId
    }
    updatedAt
    updatedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentMitigationAndUnitsGQL extends Apollo.Query<IncidentMitigationAndUnitsQuery, IncidentMitigationAndUnitsQueryVariables> {
    document = IncidentMitigationAndUnitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentTypePriorityDocument = gql`
    query incidentTypePriority {
  incidentTypePriority
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentTypePriorityGQL extends Apollo.Query<IncidentTypePriorityQuery, IncidentTypePriorityQueryVariables> {
    document = IncidentTypePriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentsAnalyticsDetailsDocument = gql`
    query incidentsAnalyticsDetails($id: Int!) {
  incidentsAnalyticsDetails(id: $id) {
    additionalInfos {
      info
    }
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      closureTimeSec
      closureUpdates {
        isClosed
        updateTime
      }
      incidentLane {
        direction
        id
        isAffected
        isClosed
        number
        positionIndex
        roadType
        type
        updatedAt
      }
    }
    allLanesAffected
    arrivalToClearedRoadDurationSeconds
    associatedDmss {
      externalId
      location
      title
    }
    associatedUnits {
      displayId
      driverDetails {
        accountName
        routeNames
        userId
        userName
      }
      id
      mitigations {
        interval {
          from
          to
        }
        mitigationDescription
        mitigationId
        mitigationTypeId
        userId
      }
      responses {
        responseTime
        unitResponse
      }
      type
    }
    atmsId
    attributes
    cadId
    cameras {
      default
      externalId
      location
      title
    }
    clearedRoadToCompletedDurationSeconds
    createdAt
    createdByUserName
    creationSource
    discoverToArrivalDurationSeconds
    durationSec
    endReason
    endReasonComment
    endedAt
    estimatedEndTime
    fatalities
    firstUnitArrivedAt
    id
    incidentSubType
    injuries
    injurySeverities
    involvedVehicles {
      color
      id
      licensePlateNumber
      make
      model
      state
      type
    }
    involvedVehiclesCount
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    notes {
      addTime
      text
    }
    relatedEvents {
      entityType
      externalId
      id
    }
    reportSources {
      accountId
      id
      reportSource
      status
    }
    severity
    startedAt
    status
    title
    type
    typeDescription
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentsAnalyticsDetailsGQL extends Apollo.Query<IncidentsAnalyticsDetailsQuery, IncidentsAnalyticsDetailsQueryVariables> {
    document = IncidentsAnalyticsDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentsAnalyticsLogDocument = gql`
    query incidentsAnalyticsLog($input: FilterIncidentsInput!) {
  incidentsAnalyticsLog(input: $input) {
    incidentColumnNames
    rows {
      data {
        ... on AnalyticsAssociatedUnitsColumn {
          associatedUnits
          incidentColumnName
        }
        ... on AnalyticsAtmsIdColumn {
          atmsId
          incidentColumnName
        }
        ... on AnalyticsAttributesColumn {
          attributes
          incidentColumnName
        }
        ... on AnalyticsCadIdColumn {
          cadId
          incidentColumnName
        }
        ... on AnalyticsCorridorColumn {
          corridor
          incidentColumnName
        }
        ... on AnalyticsCrossroadColumn {
          crossroad
          incidentColumnName
        }
        ... on AnalyticsDirectionColumn {
          direction
          incidentColumnName
        }
        ... on AnalyticsEndReasonColumn {
          endReason
          incidentColumnName
        }
        ... on AnalyticsEndReasonCommentColumn {
          endReasonComment
          incidentColumnName
        }
        ... on AnalyticsFatalitiesColumn {
          fatalities
          incidentColumnName
        }
        ... on AnalyticsIncidentAdditionalInfoColumn {
          additionalInfo
          incidentColumnName
        }
        ... on AnalyticsIncidentAddressColumn {
          address {
            city
            corridor
            county
            crossroad
            direction
            milemarker
            orientation
            point
            roadLevelType
            roadType
            segmentId
            state
          }
          incidentColumnName
        }
        ... on AnalyticsIncidentCreationSourceColumn {
          incidentColumnName
          source
          userName
        }
        ... on AnalyticsIncidentDurationColumn {
          durationMillis
          incidentColumnName
        }
        ... on AnalyticsIncidentEndTimeColumn {
          endTime
          incidentColumnName
        }
        ... on AnalyticsIncidentIdColumn {
          id
          incidentColumnName
        }
        ... on AnalyticsIncidentMitigationTypesColumn {
          incidentColumnName
          mitigationTypes
        }
        ... on AnalyticsIncidentRelatedEventsColumn {
          counter
          incidentColumnName
        }
        ... on AnalyticsIncidentSeverityColumn {
          incidentColumnName
          severity
        }
        ... on AnalyticsIncidentStartTimeColumn {
          incidentColumnName
          startTime
        }
        ... on AnalyticsIncidentStatusColumn {
          incidentColumnName
          status
        }
        ... on AnalyticsIncidentSubTypeColumn {
          incidentColumnName
          subType
        }
        ... on AnalyticsIncidentTypeColumn {
          incidentColumnName
          type
        }
        ... on AnalyticsInjuriesColumn {
          incidentColumnName
          injuries
        }
        ... on AnalyticsInvolvedVehiclesColumn {
          counter
          incidentColumnName
        }
        ... on AnalyticsLaneColumn {
          incidentColumnName
          lanes {
            direction
            id
            isAffected
            isClosed
            number
            positionIndex
            roadType
            type
            updatedAt
          }
        }
        ... on AnalyticsLanesDirectionColumn {
          direction
          incidentColumnName
        }
        ... on AnalyticsLatitudeColumn {
          incidentColumnName
          latitude
        }
        ... on AnalyticsLongitudeColumn {
          incidentColumnName
          longitude
        }
        ... on AnalyticsMileMarkerColumn {
          incidentColumnName
          milemarker
        }
        ... on AnalyticsNoteColumn {
          incidentColumnName
          notes
        }
        ... on AnalyticsOrientationColumn {
          incidentColumnName
          orientation
        }
        ... on AnalyticsReportSourceColumn {
          incidentColumnName
          reportSources
        }
        ... on AnalyticsRoadTypeColumn {
          incidentColumnName
          roadType
        }
        ... on AnalyticsShiftDurationColumn {
          durationMillis
          shiftColumnName
        }
        ... on AnalyticsShiftEndMileageColumn {
          mileage
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftEndTimeColumn {
          endTime
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftFirstMitigationTimeColumn {
          shiftColumnName
          time
        }
        ... on AnalyticsShiftIdColumn {
          shiftColumnName
          shiftId
        }
        ... on AnalyticsShiftIncidentsCountColumn {
          counter
          shiftColumnName
        }
        ... on AnalyticsShiftIsCompleteColumn {
          isComplete
          shiftColumnName
        }
        ... on AnalyticsShiftLastMitigationTimeColumn {
          shiftColumnName
          time
        }
        ... on AnalyticsShiftMitigationTypesColumn {
          mitigationTypes
          shiftColumnName
        }
        ... on AnalyticsShiftOvertimeColumn {
          isOvertime
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftOvertimeNoteColumn {
          modified
          note
          shiftColumnName
        }
        ... on AnalyticsShiftPausesColumn {
          counter
          shiftColumnName
        }
        ... on AnalyticsShiftRoutesColumn {
          modified
          names
          shiftColumnName
        }
        ... on AnalyticsShiftStartMileageColumn {
          mileage
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftStartTimeColumn {
          modified
          shiftColumnName
          startTime
        }
        ... on AnalyticsShiftTotalMileageColumn {
          mileage
          shiftColumnName
        }
        ... on AnalyticsShiftTypeColumn {
          modified
          shiftColumnName
          shiftType
        }
        ... on AnalyticsShiftUnitColumn {
          displayId
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftUserNameColumn {
          name
          shiftColumnName
        }
        ... on AnalyticsTDEndTimeColumn {
          endTime
          tdColumnName
        }
        ... on AnalyticsTDIdColumn {
          id
          tdColumnName
        }
        ... on AnalyticsTDPermitIdColumn {
          permitId
          tdColumnName
        }
        ... on AnalyticsTDRelatedEventsColumn {
          counter
          tdColumnName
        }
        ... on AnalyticsTDStartTimeColumn {
          startTime
          tdColumnName
        }
        ... on AnalyticsTDStatusColumn {
          tdColumnName
          tdStatus
        }
        ... on AnalyticsTDSubTypeColumn {
          subType
          tdColumnName
        }
        ... on AnalyticsTDTitleColumn {
          tdColumnName
          title
        }
        ... on AnalyticsTDTypeColumn {
          tdColumnName
          tdType
        }
        ... on AnalyticsUsersInvolvedColumn {
          incidentColumnName
          users
        }
      }
    }
    totalNumberOfResults
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentsAnalyticsLogGQL extends Apollo.Query<IncidentsAnalyticsLogQuery, IncidentsAnalyticsLogQueryVariables> {
    document = IncidentsAnalyticsLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentsAnalyticsLogCsvExportDocument = gql`
    query incidentsAnalyticsLogCsvExport($input: FilterIncidentsInput!) {
  incidentsAnalyticsLogCsvExport(input: $input) {
    expiration
    fileSize
    isDocument
    key
    source
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentsAnalyticsLogCsvExportGQL extends Apollo.Query<IncidentsAnalyticsLogCsvExportQuery, IncidentsAnalyticsLogCsvExportQueryVariables> {
    document = IncidentsAnalyticsLogCsvExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IncidentsAnalyticsLogCsvExportEmailDocument = gql`
    query incidentsAnalyticsLogCsvExportEmail($input: FilterIncidentsInput!) {
  incidentsAnalyticsLogCsvExportEmail(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IncidentsAnalyticsLogCsvExportEmailGQL extends Apollo.Query<IncidentsAnalyticsLogCsvExportEmailQuery, IncidentsAnalyticsLogCsvExportEmailQueryVariables> {
    document = IncidentsAnalyticsLogCsvExportEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IsResponsePlanDoneDocument = gql`
    query isResponsePlanDone($entityId: Int!, $entityType: ResponsePlanEntityType!) {
  isResponsePlanDone(entityId: $entityId, entityType: $entityType)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IsResponsePlanDoneGQL extends Apollo.Query<IsResponsePlanDoneQuery, IsResponsePlanDoneQueryVariables> {
    document = IsResponsePlanDoneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query me {
  me {
    account {
      groups {
        id
        level
        permissions {
          dependsOnAll {
            entity
            modifier
          }
          dependsOnAny {
            entity
            modifier
          }
          id
          scope {
            entity
            modifier
          }
        }
        type
      }
      additionalInfos {
        accountId
        id
        info
      }
      analyticsReportsUrls {
        fixedRouteTransitReport {
          index
          name
          url
        }
        fspReport {
          index
          name
          url
        }
        incidentAnalysisReport {
          index
          name
          url
        }
        mitigationsReport {
          index
          name
          url
        }
        speedMeasurementReport {
          index
          name
          url
        }
        timPerformanceReport {
          index
          name
          url
        }
        transitReport {
          index
          name
          url
        }
        travelReliabilityReport {
          index
          name
          url
        }
        unitsPerformanceReport {
          index
          name
          url
        }
      }
      contactEmail
      contactName
      contactPhoneNumber
      customer {
        contactEmail
        contactName
        contactPhoneNumber
        id
        name
      }
      id
      mapCenter
      mitigationTypes {
        accountId
        description
        id
        isDeleted
      }
      name
      publicationTemplates {
        entityType
        id
        phrases
        template
      }
      publicationVariables {
        constructionVariables
        incidentVariables
        roadClosureVariables
      }
      publishChannels
      regionalSetting {
        firstDayOfWeek
        dateFormat
        language
        measureUnitType
        timeFormat
        timeZone
      }
      reportSources {
        accountId
        id
        reportSource
        status
      }
      routeTypes {
        accountId
        area
        id
        name
        status
      }
      shiftTypes {
        accountId
        id
        name
        status
      }
      status
      workspaces {
        singleMultiPolygon
        workspaces {
          accountId
          area
          featureTypes
          id
          roadTypes
          title
        }
      }
    }
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
    email
    id
    name
    phoneNumber
    status
    unit {
      accountId
      displayId
      externalId
      id
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    unitRelationType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PermitsByAccountIdDocument = gql`
    query permitsByAccountId {
  permitsByAccountId {
    accountId
    id
    type
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PermitsByAccountIdGQL extends Apollo.Query<PermitsByAccountIdQuery, PermitsByAccountIdQueryVariables> {
    document = PermitsByAccountIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RelatedEventsDocument = gql`
    query relatedEvents($eventId: Int!) {
  relatedEvents(eventId: $eventId) {
    ... on ConstructionRelatedEventDTO {
      constructionType
      entityType
      externalId
      id
      location
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
    ... on IncidentRelatedEventDTO {
      entityType
      externalId
      id
      incidentAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      incidentStatus
      incidentTitle
      incidentType
      subType
    }
    ... on RoadClosureRelatedEventDTO {
      entityType
      externalId
      id
      location
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
    ... on SpecialEventRelatedEventDTO {
      entityType
      externalId
      id
      location
      specialEventType
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RelatedEventsGQL extends Apollo.Query<RelatedEventsQuery, RelatedEventsQueryVariables> {
    document = RelatedEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResponsePlansByEntityTypeDocument = gql`
    query responsePlansByEntityType($entityType: ResponsePlanEntityType!) {
  responsePlansByEntityType(entityType: $entityType) {
    accountId
    actions {
      actionField
      index
      templateActionId
      templateId
      text
      time
      unit
      value
    }
    entityType
    id
    title
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResponsePlansByEntityTypeGQL extends Apollo.Query<ResponsePlansByEntityTypeQuery, ResponsePlansByEntityTypeQueryVariables> {
    document = ResponsePlansByEntityTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoadClosureDocument = gql`
    query roadClosure($id: Int!) {
  roadClosure(id: $id) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    cameras {
      camera {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        externalId
        id
        imageUrl
        isVideo
        location
        status
        streamType
        title
        videoUrl
        workspaces
      }
      default
      positionIndex
    }
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    dmses {
      dms {
        address {
          city
          corridor
          county
          crossroad
          direction
          milemarker
          orientation
          point
          roadLevelType
          roadType
          segmentId
          state
        }
        editable
        externalId
        id
        location
        messages
        status
        title
        updatedAt
      }
      positionIndex
    }
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    permit {
      permitId
      url
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    updatedAt
    updatedBy
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoadClosureGQL extends Apollo.Query<RoadClosureQuery, RoadClosureQueryVariables> {
    document = RoadClosureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoadClosureActivityLogDocument = gql`
    query roadClosureActivityLog($id: Int!) {
  roadClosureActivityLog(id: $id) {
    activitiesPerDay {
      accountName
      activitiesPerMinute {
        activities {
          action
          field
          offlineUpdatedAt
          value {
            ... on AdditionalInfoField {
              info
            }
            ... on AddressField {
              address {
                city
                corridor
                county
                crossroad
                direction
                milemarker
                orientation
                point
                roadLevelType
                roadType
                segmentId
                state
              }
            }
            ... on BooleanField {
              bool
            }
            ... on CameraField {
              default
              externalId
              title
            }
            ... on ConstructionTypeField {
              constructionType
            }
            ... on ContactPersonField {
              email
              name
              phoneNumber
            }
            ... on DateTimeField {
              dateTime
            }
            ... on DmsField {
              dmsId
              title
            }
            ... on GeometryField {
              geometry
            }
            ... on IncidentAutoPublishField {
              autoPublish
            }
            ... on IncidentLaneField {
              incidentLane {
                direction
                id
                isAffected
                isClosed
                number
                positionIndex
                roadType
                type
                updatedAt
              }
            }
            ... on IncidentMediaIdField {
              incidentMediaId
            }
            ... on IncidentSeverityField {
              incidentSeverity
            }
            ... on IncidentStatusField {
              automaticAction
              endReason
              endReasonComment
              status
            }
            ... on IncidentSubTypeField {
              subType
            }
            ... on IncidentTypeField {
              incidentType
            }
            ... on InjurySeveritiesField {
              injurySeverity
            }
            ... on IntField {
              n
            }
            ... on LanesClosureTypeField {
              lanesClosureType
            }
            ... on LocationField {
              point
            }
            ... on MitigationField {
              description
              interval {
                from
                to
              }
              unitDisplayId
              unitId
            }
            ... on NoteField {
              note
            }
            ... on NullableTextField {
              nullableText
            }
            ... on OccupancyRangeField {
              occupancyRange
            }
            ... on PermitField {
              permitId
              url
            }
            ... on PublishField {
              description
              destinations
            }
            ... on RelatedEventField {
              endTime
              eventId
              relatedEventStatus
              startTime
              type
            }
            ... on ReportSourceField {
              accountId
              reportSource
            }
            ... on RouteTypeIdsField {
              routeTypesIds
            }
            ... on ScheduleField {
              endTime
              schedule {
                timeRanges {
                  fromDayOfWeekLocal
                  fromTimeMinutesLocal
                  toDayOfWeekLocal
                  toTimeMinutesLocal
                }
                timeZone
              }
              startTime
            }
            ... on ShiftEndReasonField {
              shiftEndReason
            }
            ... on ShiftTypeIdField {
              shiftTypeId
            }
            ... on SpecialEventTypeField {
              specialEventType
            }
            ... on TextField {
              text
            }
            ... on TrafficDisruptionLaneField {
              trafficDisruptionLane {
                direction
                id
                isAffected
                isClosed
                number
                positionIndex
                roadType
                type
              }
            }
            ... on TrafficDisruptionMediaIdField {
              trafficDisruptionMediaId
            }
            ... on TrafficDisruptionStatusField {
              trafficDisruptionEndReason
              trafficDisruptionStatus
            }
            ... on UnitField {
              displayId
              driverId
              response
            }
            ... on UnitIdField {
              shiftUnitId
            }
            ... on VehicleField {
              vehicle {
                color
                id
                licensePlateNumber
                make
                model
                state
                type
              }
            }
            ... on VenueField {
              venueAddress
              venueName
            }
          }
        }
        activityLogEntityType
        activityLogSectionType
        maxOfflineUpdatedAt
      }
      timeByMinute
      userName
    }
    timeByDay
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoadClosureActivityLogGQL extends Apollo.Query<RoadClosureActivityLogQuery, RoadClosureActivityLogQueryVariables> {
    document = RoadClosureActivityLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoadClosureByAccountDocument = gql`
    query roadClosureByAccount {
  roadClosureByAccount {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    updatedAt
    updatedBy
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoadClosureByAccountGQL extends Apollo.Query<RoadClosureByAccountQuery, RoadClosureByAccountQueryVariables> {
    document = RoadClosureByAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoadNamesByAccountDocument = gql`
    query roadNamesByAccount($subString: String!) {
  roadNamesByAccount(subString: $subString)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoadNamesByAccountGQL extends Apollo.Query<RoadNamesByAccountQuery, RoadNamesByAccountQueryVariables> {
    document = RoadNamesByAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoleDocument = gql`
    query role($id: Int!) {
  role(id: $id) {
    accountId
    description
    id
    name
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoleGQL extends Apollo.Query<RoleQuery, RoleQueryVariables> {
    document = RoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RolesDocument = gql`
    query roles {
  roles {
    accountId
    description
    id
    name
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RolesGQL extends Apollo.Query<RolesQuery, RolesQueryVariables> {
    document = RolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchActiveEventsDocument = gql`
    query searchActiveEvents($searchString: String!) {
  searchActiveEvents(searchString: $searchString) {
    ... on ConstructionRelatedEventDTO {
      constructionType
      entityType
      externalId
      id
      location
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
    ... on IncidentRelatedEventDTO {
      entityType
      externalId
      id
      incidentAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      incidentStatus
      incidentTitle
      incidentType
      subType
    }
    ... on RoadClosureRelatedEventDTO {
      entityType
      externalId
      id
      location
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
    ... on SpecialEventRelatedEventDTO {
      entityType
      externalId
      id
      location
      specialEventType
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchActiveEventsGQL extends Apollo.Query<SearchActiveEventsQuery, SearchActiveEventsQueryVariables> {
    document = SearchActiveEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchCompletedEventsDocument = gql`
    query searchCompletedEvents {
  searchCompletedEvents {
    ... on ConstructionRelatedEventDTO {
      constructionType
      entityType
      externalId
      id
      location
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
    ... on IncidentRelatedEventDTO {
      entityType
      externalId
      id
      incidentAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      incidentStatus
      incidentTitle
      incidentType
      subType
    }
    ... on RoadClosureRelatedEventDTO {
      entityType
      externalId
      id
      location
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
    ... on SpecialEventRelatedEventDTO {
      entityType
      externalId
      id
      location
      specialEventType
      trafficDisruptionAddress {
        city
        corridor
        county
        crossroad
        direction
        milemarker
        orientation
        point
        roadLevelType
        roadType
        segmentId
        state
      }
      trafficDisruptionStatus
      trafficDisruptionTitle
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCompletedEventsGQL extends Apollo.Query<SearchCompletedEventsQuery, SearchCompletedEventsQueryVariables> {
    document = SearchCompletedEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SegmentDocument = gql`
    query segment($point: Point!, $radiusMeters: Short) {
  segment(point: $point, radiusMeters: $radiusMeters) {
    alias
    city
    county
    crossRoads {
      distance
      name
      nearestPoint
      roadType
    }
    crossroadSource
    direction
    lanesQuantity
    milemarkers
    names
    orientation
    overridingCorridorAlias
    overridingCrossroadAlias
    refs
    roadLevelTypes
    roadTypes
    segmentId
    segmentsNames {
      distance
      name
      nearestPoint
      roadType
    }
    state
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SegmentGQL extends Apollo.Query<SegmentQuery, SegmentQueryVariables> {
    document = SegmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShiftDocument = gql`
    query shift($id: Int!) {
  shift(id: $id) {
    endMileage
    endReason
    endReasonNote
    endedAt
    isDeleted
    overtimeNote
    routeTypes {
      accountId
      area
      id
      name
      status
    }
    shiftId
    shiftPauses {
      endedAt
      isActive
      pauseId
      reason
      reasonNote
      shiftId
      startedAt
    }
    shiftType {
      accountId
      id
      name
      status
    }
    startMileage
    startedAt
    unit {
      accountId
      activityStatuses
      dashCameras {
        imageUrl
        isVideo
        position
        streamType
        videoUrl
      }
      displayId
      externalId
      id
      isBusy
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    user {
      account {
        additionalInfos {
          accountId
          id
          info
          isDeleted
        }
        analyticsReportsUrls {
          fixedRouteTransitReport {
            index
            name
            url
          }
          fspReport {
            index
            name
            url
          }
          incidentAnalysisReport {
            index
            name
            url
          }
          mitigationsReport {
            index
            name
            url
          }
          speedMeasurementReport {
            index
            name
            url
          }
          timPerformanceReport {
            index
            name
            url
          }
          transitReport {
            index
            name
            url
          }
          travelReliabilityReport {
            index
            name
            url
          }
          unitsPerformanceReport {
            index
            name
            url
          }
        }
        contactEmail
        contactName
        contactPhoneNumber
        customer {
          contactEmail
          contactName
          contactPhoneNumber
          id
          name
        }
        groups {
          id
          level
          permissions {
            dependsOnAll {
              entity
              modifier
            }
            dependsOnAny {
              entity
              modifier
            }
            id
            scope {
              entity
              modifier
            }
          }
          type
        }
        hubspotCompany
        id
        mapCenter
        mitigationTypes {
          accountId
          description
          id
          isDeleted
        }
        name
        publicationTemplates {
          entityType
          id
          phrases
          template
        }
        publicationVariables {
          constructionVariables
          incidentVariables
          roadClosureVariables
        }
        publishChannels
        regionalSetting {
          dateFormat
          firstDayOfWeek
          language
          measureUnitType
          timeFormat
          timeZone
        }
        reportSources {
          accountId
          id
          reportSource
          status
        }
        routeTypes {
          accountId
          area
          id
          name
          status
        }
        shiftTypes {
          accountId
          id
          name
          status
        }
        status
        workspaces {
          singleMultiPolygon
          workspaces {
            accountId
            area
            featureTypes
            id
            roadTypes
            title
          }
        }
      }
      creationTime
      email
      id
      name
      permissions {
        id
      }
      phoneNumber
      status
      unit {
        accountId
        activityStatuses
        displayId
        externalId
        id
        isBusy
        status
        type
      }
      unitRelationType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShiftGQL extends Apollo.Query<ShiftQuery, ShiftQueryVariables> {
    document = ShiftDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShiftsAnalyticsLogDocument = gql`
    query shiftsAnalyticsLog($input: FilterShiftsInput!) {
  shiftsAnalyticsLog(input: $input) {
    rows {
      data {
        ... on AnalyticsAssociatedUnitsColumn {
          associatedUnits
          incidentColumnName
        }
        ... on AnalyticsAtmsIdColumn {
          atmsId
          incidentColumnName
        }
        ... on AnalyticsAttributesColumn {
          attributes
          incidentColumnName
        }
        ... on AnalyticsCadIdColumn {
          cadId
          incidentColumnName
        }
        ... on AnalyticsCorridorColumn {
          corridor
          incidentColumnName
        }
        ... on AnalyticsCrossroadColumn {
          crossroad
          incidentColumnName
        }
        ... on AnalyticsDirectionColumn {
          direction
          incidentColumnName
        }
        ... on AnalyticsEndReasonColumn {
          endReason
          incidentColumnName
        }
        ... on AnalyticsEndReasonCommentColumn {
          endReasonComment
          incidentColumnName
        }
        ... on AnalyticsFatalitiesColumn {
          fatalities
          incidentColumnName
        }
        ... on AnalyticsIncidentAdditionalInfoColumn {
          additionalInfo
          incidentColumnName
        }
        ... on AnalyticsIncidentAddressColumn {
          address {
            city
            corridor
            county
            crossroad
            direction
            milemarker
            orientation
            point
            roadLevelType
            roadType
            segmentId
            state
          }
          incidentColumnName
        }
        ... on AnalyticsIncidentCreationSourceColumn {
          incidentColumnName
          source
          userName
        }
        ... on AnalyticsIncidentDurationColumn {
          durationMillis
          incidentColumnName
        }
        ... on AnalyticsIncidentEndTimeColumn {
          endTime
          incidentColumnName
        }
        ... on AnalyticsIncidentIdColumn {
          id
          incidentColumnName
        }
        ... on AnalyticsIncidentMitigationTypesColumn {
          incidentColumnName
          mitigationTypes
        }
        ... on AnalyticsIncidentRelatedEventsColumn {
          counter
          incidentColumnName
        }
        ... on AnalyticsIncidentSeverityColumn {
          incidentColumnName
          severity
        }
        ... on AnalyticsIncidentStartTimeColumn {
          incidentColumnName
          startTime
        }
        ... on AnalyticsIncidentStatusColumn {
          incidentColumnName
          status
        }
        ... on AnalyticsIncidentSubTypeColumn {
          incidentColumnName
          subType
        }
        ... on AnalyticsIncidentTypeColumn {
          incidentColumnName
          type
        }
        ... on AnalyticsInjuriesColumn {
          incidentColumnName
          injuries
        }
        ... on AnalyticsInvolvedVehiclesColumn {
          counter
          incidentColumnName
        }
        ... on AnalyticsLaneColumn {
          incidentColumnName
          lanes {
            direction
            id
            isAffected
            isClosed
            number
            positionIndex
            roadType
            type
            updatedAt
          }
        }
        ... on AnalyticsLanesDirectionColumn {
          direction
          incidentColumnName
        }
        ... on AnalyticsLatitudeColumn {
          incidentColumnName
          latitude
        }
        ... on AnalyticsLongitudeColumn {
          incidentColumnName
          longitude
        }
        ... on AnalyticsMileMarkerColumn {
          incidentColumnName
          milemarker
        }
        ... on AnalyticsNoteColumn {
          incidentColumnName
          notes
        }
        ... on AnalyticsOrientationColumn {
          incidentColumnName
          orientation
        }
        ... on AnalyticsReportSourceColumn {
          incidentColumnName
          reportSources
        }
        ... on AnalyticsRoadTypeColumn {
          incidentColumnName
          roadType
        }
        ... on AnalyticsShiftDurationColumn {
          durationMillis
          shiftColumnName
        }
        ... on AnalyticsShiftEndMileageColumn {
          mileage
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftEndTimeColumn {
          endTime
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftFirstMitigationTimeColumn {
          shiftColumnName
          time
        }
        ... on AnalyticsShiftIdColumn {
          shiftColumnName
          shiftId
        }
        ... on AnalyticsShiftIncidentsCountColumn {
          counter
          shiftColumnName
        }
        ... on AnalyticsShiftIsCompleteColumn {
          isComplete
          shiftColumnName
        }
        ... on AnalyticsShiftLastMitigationTimeColumn {
          shiftColumnName
          time
        }
        ... on AnalyticsShiftMitigationTypesColumn {
          mitigationTypes
          shiftColumnName
        }
        ... on AnalyticsShiftOvertimeColumn {
          isOvertime
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftOvertimeNoteColumn {
          modified
          note
          shiftColumnName
        }
        ... on AnalyticsShiftPausesColumn {
          counter
          shiftColumnName
        }
        ... on AnalyticsShiftRoutesColumn {
          modified
          names
          shiftColumnName
        }
        ... on AnalyticsShiftStartMileageColumn {
          mileage
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftStartTimeColumn {
          modified
          shiftColumnName
          startTime
        }
        ... on AnalyticsShiftTotalMileageColumn {
          mileage
          shiftColumnName
        }
        ... on AnalyticsShiftTypeColumn {
          modified
          shiftColumnName
          shiftType
        }
        ... on AnalyticsShiftUnitColumn {
          displayId
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftUserNameColumn {
          name
          shiftColumnName
        }
        ... on AnalyticsTDEndTimeColumn {
          endTime
          tdColumnName
        }
        ... on AnalyticsTDIdColumn {
          id
          tdColumnName
        }
        ... on AnalyticsTDPermitIdColumn {
          permitId
          tdColumnName
        }
        ... on AnalyticsTDRelatedEventsColumn {
          counter
          tdColumnName
        }
        ... on AnalyticsTDStartTimeColumn {
          startTime
          tdColumnName
        }
        ... on AnalyticsTDStatusColumn {
          tdColumnName
          tdStatus
        }
        ... on AnalyticsTDSubTypeColumn {
          subType
          tdColumnName
        }
        ... on AnalyticsTDTitleColumn {
          tdColumnName
          title
        }
        ... on AnalyticsTDTypeColumn {
          tdColumnName
          tdType
        }
        ... on AnalyticsUsersInvolvedColumn {
          incidentColumnName
          users
        }
      }
    }
    shiftColumnNames
    totalNumberOfResults
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShiftsAnalyticsLogGQL extends Apollo.Query<ShiftsAnalyticsLogQuery, ShiftsAnalyticsLogQueryVariables> {
    document = ShiftsAnalyticsLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShiftsAnalyticsLogCsvExportDocument = gql`
    query shiftsAnalyticsLogCsvExport($input: FilterShiftsInput!) {
  shiftsAnalyticsLogCsvExport(input: $input) {
    expiration
    fileSize
    isDocument
    key
    source
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShiftsAnalyticsLogCsvExportGQL extends Apollo.Query<ShiftsAnalyticsLogCsvExportQuery, ShiftsAnalyticsLogCsvExportQueryVariables> {
    document = ShiftsAnalyticsLogCsvExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShiftsAnalyticsLogCsvExportEmailDocument = gql`
    query shiftsAnalyticsLogCsvExportEmail($input: FilterShiftsInput!) {
  shiftsAnalyticsLogCsvExportEmail(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShiftsAnalyticsLogCsvExportEmailGQL extends Apollo.Query<ShiftsAnalyticsLogCsvExportEmailQuery, ShiftsAnalyticsLogCsvExportEmailQueryVariables> {
    document = ShiftsAnalyticsLogCsvExportEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SpecialEventDocument = gql`
    query specialEvent($id: Int!) {
  specialEvent(id: $id) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    affectedLanes {
      direction
      id
      isAffected
      isClosed
      number
      positionIndex
      roadType
      type
    }
    allLanesAffected
    contactPerson {
      email
      name
      phoneNumber
    }
    createdAt
    createdBy
    description
    endTime
    externalId
    feedPriorityType
    id
    isAllDay
    lanesClosureType
    location
    media {
      id
      media {
        expiration
        fileSize
        isDocument
        key
        source
        url
      }
    }
    multiDirectionLanesAffected
    occupancy
    occupancyRange
    otherTypeDescription
    responsePlan {
      actions {
        actionField
        index
        isDone
        responsePlanActionId
        responsePlanId
        text
        time
        unit
        value
      }
      entityId
      entityType
      id
      isDeleted
      templateId
      title
      updatedAt
    }
    schedule {
      timeRanges {
        fromDayOfWeekLocal
        fromTimeMinutesLocal
        toDayOfWeekLocal
        toTimeMinutesLocal
      }
      timeZone
    }
    startTime
    status
    title
    type
    updatedAt
    updatedBy
    venue {
      address
      name
    }
    workzoneDataExchange {
      bbox
      beginningCrossStreet
      direction
      endingCrossStreet
      isEndDateVerified
      isEndPositionVerified
      isStartDateVerified
      isStartPositionVerified
      locationMethod
      roadNames
      vehicleImpact
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SpecialEventGQL extends Apollo.Query<SpecialEventQuery, SpecialEventQueryVariables> {
    document = SpecialEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SpecialEventActivityLogDocument = gql`
    query specialEventActivityLog($id: Int!) {
  specialEventActivityLog(id: $id) {
    activitiesPerDay {
      accountName
      activitiesPerMinute {
        activities {
          action
          field
          offlineUpdatedAt
          value {
            ... on AdditionalInfoField {
              info
            }
            ... on AddressField {
              address {
                city
                corridor
                county
                crossroad
                direction
                milemarker
                orientation
                point
                roadLevelType
                roadType
                segmentId
                state
              }
            }
            ... on BooleanField {
              bool
            }
            ... on CameraField {
              default
              externalId
              title
            }
            ... on ConstructionTypeField {
              constructionType
            }
            ... on ContactPersonField {
              email
              name
              phoneNumber
            }
            ... on DateTimeField {
              dateTime
            }
            ... on DmsField {
              dmsId
              title
            }
            ... on GeometryField {
              geometry
            }
            ... on IncidentAutoPublishField {
              autoPublish
            }
            ... on IncidentLaneField {
              incidentLane {
                direction
                id
                isAffected
                isClosed
                number
                positionIndex
                roadType
                type
                updatedAt
              }
            }
            ... on IncidentMediaIdField {
              incidentMediaId
            }
            ... on IncidentSeverityField {
              incidentSeverity
            }
            ... on IncidentStatusField {
              automaticAction
              endReason
              endReasonComment
              status
            }
            ... on IncidentSubTypeField {
              subType
            }
            ... on IncidentTypeField {
              incidentType
            }
            ... on InjurySeveritiesField {
              injurySeverity
            }
            ... on IntField {
              n
            }
            ... on LanesClosureTypeField {
              lanesClosureType
            }
            ... on LocationField {
              point
            }
            ... on MitigationField {
              description
              interval {
                from
                to
              }
              unitDisplayId
              unitId
            }
            ... on NoteField {
              note
            }
            ... on NullableTextField {
              nullableText
            }
            ... on OccupancyRangeField {
              occupancyRange
            }
            ... on PermitField {
              permitId
              url
            }
            ... on PublishField {
              description
              destinations
            }
            ... on RelatedEventField {
              endTime
              eventId
              relatedEventStatus
              startTime
              type
            }
            ... on ReportSourceField {
              accountId
              reportSource
            }
            ... on RouteTypeIdsField {
              routeTypesIds
            }
            ... on ScheduleField {
              endTime
              schedule {
                timeRanges {
                  fromDayOfWeekLocal
                  fromTimeMinutesLocal
                  toDayOfWeekLocal
                  toTimeMinutesLocal
                }
                timeZone
              }
              startTime
            }
            ... on ShiftEndReasonField {
              shiftEndReason
            }
            ... on ShiftTypeIdField {
              shiftTypeId
            }
            ... on SpecialEventTypeField {
              specialEventType
            }
            ... on TextField {
              text
            }
            ... on TrafficDisruptionLaneField {
              trafficDisruptionLane {
                direction
                id
                isAffected
                isClosed
                number
                positionIndex
                roadType
                type
              }
            }
            ... on TrafficDisruptionMediaIdField {
              trafficDisruptionMediaId
            }
            ... on TrafficDisruptionStatusField {
              trafficDisruptionEndReason
              trafficDisruptionStatus
            }
            ... on UnitField {
              displayId
              driverId
              response
            }
            ... on UnitIdField {
              shiftUnitId
            }
            ... on VehicleField {
              vehicle {
                color
                id
                licensePlateNumber
                make
                model
                state
                type
              }
            }
            ... on VenueField {
              venueAddress
              venueName
            }
          }
        }
        activityLogEntityType
        activityLogSectionType
        maxOfflineUpdatedAt
      }
      timeByMinute
      userName
    }
    timeByDay
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SpecialEventActivityLogGQL extends Apollo.Query<SpecialEventActivityLogQuery, SpecialEventActivityLogQueryVariables> {
    document = SpecialEventActivityLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StreetCamerasByAreaDocument = gql`
    query streetCamerasByArea($input: FindStreetCamerasByAreaInput!) {
  streetCamerasByArea(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    externalId
    id
    imageUrl
    isVideo
    location
    status
    streamType
    title
    videoUrl
    workspaces
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StreetCamerasByAreaGQL extends Apollo.Query<StreetCamerasByAreaQuery, StreetCamerasByAreaQueryVariables> {
    document = StreetCamerasByAreaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StreetCamerasByPointDocument = gql`
    query streetCamerasByPoint($input: FindStreetCamerasByPointAndRadiusInput!) {
  streetCamerasByPoint(input: $input) {
    address {
      city
      corridor
      county
      crossroad
      direction
      milemarker
      orientation
      point
      roadLevelType
      roadType
      segmentId
      state
    }
    externalId
    id
    imageUrl
    isVideo
    location
    status
    streamType
    title
    videoUrl
    workspaces
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StreetCamerasByPointGQL extends Apollo.Query<StreetCamerasByPointQuery, StreetCamerasByPointQueryVariables> {
    document = StreetCamerasByPointDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrafficDisruptionAnalyticsLogDocument = gql`
    query trafficDisruptionAnalyticsLog($input: FilterTrafficDisruptionInput!) {
  trafficDisruptionAnalyticsLog(input: $input) {
    rows {
      data {
        ... on AnalyticsAssociatedUnitsColumn {
          associatedUnits
          incidentColumnName
        }
        ... on AnalyticsAtmsIdColumn {
          atmsId
          incidentColumnName
        }
        ... on AnalyticsAttributesColumn {
          attributes
          incidentColumnName
        }
        ... on AnalyticsCadIdColumn {
          cadId
          incidentColumnName
        }
        ... on AnalyticsCorridorColumn {
          corridor
          incidentColumnName
        }
        ... on AnalyticsCrossroadColumn {
          crossroad
          incidentColumnName
        }
        ... on AnalyticsDirectionColumn {
          direction
          incidentColumnName
        }
        ... on AnalyticsEndReasonColumn {
          endReason
          incidentColumnName
        }
        ... on AnalyticsEndReasonCommentColumn {
          endReasonComment
          incidentColumnName
        }
        ... on AnalyticsFatalitiesColumn {
          fatalities
          incidentColumnName
        }
        ... on AnalyticsIncidentAdditionalInfoColumn {
          additionalInfo
          incidentColumnName
        }
        ... on AnalyticsIncidentAddressColumn {
          address {
            city
            corridor
            county
            crossroad
            direction
            milemarker
            orientation
            point
            roadLevelType
            roadType
            segmentId
            state
          }
          incidentColumnName
        }
        ... on AnalyticsIncidentCreationSourceColumn {
          incidentColumnName
          source
          userName
        }
        ... on AnalyticsIncidentDurationColumn {
          durationMillis
          incidentColumnName
        }
        ... on AnalyticsIncidentEndTimeColumn {
          endTime
          incidentColumnName
        }
        ... on AnalyticsIncidentIdColumn {
          id
          incidentColumnName
        }
        ... on AnalyticsIncidentMitigationTypesColumn {
          incidentColumnName
          mitigationTypes
        }
        ... on AnalyticsIncidentRelatedEventsColumn {
          counter
          incidentColumnName
        }
        ... on AnalyticsIncidentSeverityColumn {
          incidentColumnName
          severity
        }
        ... on AnalyticsIncidentStartTimeColumn {
          incidentColumnName
          startTime
        }
        ... on AnalyticsIncidentStatusColumn {
          incidentColumnName
          status
        }
        ... on AnalyticsIncidentSubTypeColumn {
          incidentColumnName
          subType
        }
        ... on AnalyticsIncidentTypeColumn {
          incidentColumnName
          type
        }
        ... on AnalyticsInjuriesColumn {
          incidentColumnName
          injuries
        }
        ... on AnalyticsInvolvedVehiclesColumn {
          counter
          incidentColumnName
        }
        ... on AnalyticsLaneColumn {
          incidentColumnName
          lanes {
            direction
            id
            isAffected
            isClosed
            number
            positionIndex
            roadType
            type
            updatedAt
          }
        }
        ... on AnalyticsLanesDirectionColumn {
          direction
          incidentColumnName
        }
        ... on AnalyticsLatitudeColumn {
          incidentColumnName
          latitude
        }
        ... on AnalyticsLongitudeColumn {
          incidentColumnName
          longitude
        }
        ... on AnalyticsMileMarkerColumn {
          incidentColumnName
          milemarker
        }
        ... on AnalyticsNoteColumn {
          incidentColumnName
          notes
        }
        ... on AnalyticsOrientationColumn {
          incidentColumnName
          orientation
        }
        ... on AnalyticsReportSourceColumn {
          incidentColumnName
          reportSources
        }
        ... on AnalyticsRoadTypeColumn {
          incidentColumnName
          roadType
        }
        ... on AnalyticsShiftDurationColumn {
          durationMillis
          shiftColumnName
        }
        ... on AnalyticsShiftEndMileageColumn {
          mileage
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftEndTimeColumn {
          endTime
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftFirstMitigationTimeColumn {
          shiftColumnName
          time
        }
        ... on AnalyticsShiftIdColumn {
          shiftColumnName
          shiftId
        }
        ... on AnalyticsShiftIncidentsCountColumn {
          counter
          shiftColumnName
        }
        ... on AnalyticsShiftIsCompleteColumn {
          isComplete
          shiftColumnName
        }
        ... on AnalyticsShiftLastMitigationTimeColumn {
          shiftColumnName
          time
        }
        ... on AnalyticsShiftMitigationTypesColumn {
          mitigationTypes
          shiftColumnName
        }
        ... on AnalyticsShiftOvertimeColumn {
          isOvertime
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftOvertimeNoteColumn {
          modified
          note
          shiftColumnName
        }
        ... on AnalyticsShiftPausesColumn {
          counter
          shiftColumnName
        }
        ... on AnalyticsShiftRoutesColumn {
          modified
          names
          shiftColumnName
        }
        ... on AnalyticsShiftStartMileageColumn {
          mileage
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftStartTimeColumn {
          modified
          shiftColumnName
          startTime
        }
        ... on AnalyticsShiftTotalMileageColumn {
          mileage
          shiftColumnName
        }
        ... on AnalyticsShiftTypeColumn {
          modified
          shiftColumnName
          shiftType
        }
        ... on AnalyticsShiftUnitColumn {
          displayId
          modified
          shiftColumnName
        }
        ... on AnalyticsShiftUserNameColumn {
          name
          shiftColumnName
        }
        ... on AnalyticsTDEndTimeColumn {
          endTime
          tdColumnName
        }
        ... on AnalyticsTDIdColumn {
          id
          tdColumnName
        }
        ... on AnalyticsTDPermitIdColumn {
          permitId
          tdColumnName
        }
        ... on AnalyticsTDRelatedEventsColumn {
          counter
          tdColumnName
        }
        ... on AnalyticsTDStartTimeColumn {
          startTime
          tdColumnName
        }
        ... on AnalyticsTDStatusColumn {
          tdColumnName
          tdStatus
        }
        ... on AnalyticsTDSubTypeColumn {
          subType
          tdColumnName
        }
        ... on AnalyticsTDTitleColumn {
          tdColumnName
          title
        }
        ... on AnalyticsTDTypeColumn {
          tdColumnName
          tdType
        }
        ... on AnalyticsUsersInvolvedColumn {
          incidentColumnName
          users
        }
      }
    }
    tdColumnNames
    totalNumberOfResults
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TrafficDisruptionAnalyticsLogGQL extends Apollo.Query<TrafficDisruptionAnalyticsLogQuery, TrafficDisruptionAnalyticsLogQueryVariables> {
    document = TrafficDisruptionAnalyticsLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrafficDisruptionAnalyticsLogCsvExportDocument = gql`
    query trafficDisruptionAnalyticsLogCsvExport($input: FilterTrafficDisruptionInput!) {
  trafficDisruptionAnalyticsLogCsvExport(input: $input) {
    expiration
    fileSize
    isDocument
    key
    source
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TrafficDisruptionAnalyticsLogCsvExportGQL extends Apollo.Query<TrafficDisruptionAnalyticsLogCsvExportQuery, TrafficDisruptionAnalyticsLogCsvExportQueryVariables> {
    document = TrafficDisruptionAnalyticsLogCsvExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrafficDisruptionAnalyticsLogCsvExportEmailDocument = gql`
    query trafficDisruptionAnalyticsLogCsvExportEmail($input: FilterTrafficDisruptionInput!) {
  trafficDisruptionAnalyticsLogCsvExportEmail(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TrafficDisruptionAnalyticsLogCsvExportEmailGQL extends Apollo.Query<TrafficDisruptionAnalyticsLogCsvExportEmailQuery, TrafficDisruptionAnalyticsLogCsvExportEmailQueryVariables> {
    document = TrafficDisruptionAnalyticsLogCsvExportEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrafficDisruptionDetailsLogPdfExportDocument = gql`
    query trafficDisruptionDetailsLogPDFExport($id: Int!) {
  trafficDisruptionDetailsLogPDFExport(id: $id) {
    expiration
    fileSize
    isDocument
    key
    source
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TrafficDisruptionDetailsLogPdfExportGQL extends Apollo.Query<TrafficDisruptionDetailsLogPdfExportQuery, TrafficDisruptionDetailsLogPdfExportQueryVariables> {
    document = TrafficDisruptionDetailsLogPdfExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnitDocument = gql`
    query unit($id: Int!) {
  unit(id: $id) {
    accountId
    activityStatuses
    dashCameras {
      imageUrl
      isVideo
      position
      streamType
      videoUrl
    }
    displayId
    externalId
    id
    isBusy
    status
    type
    userDetails {
      name
      unitRelationType
      userId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnitGQL extends Apollo.Query<UnitQuery, UnitQueryVariables> {
    document = UnitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnitsDocument = gql`
    query units {
  units {
    accountId
    activityStatuses
    dashCameras {
      imageUrl
      isVideo
      position
      streamType
      videoUrl
    }
    displayId
    externalId
    id
    isBusy
    status
    type
    userDetails {
      name
      unitRelationType
      userId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnitsGQL extends Apollo.Query<UnitsQuery, UnitsQueryVariables> {
    document = UnitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnitsByWorkspacesDocument = gql`
    query unitsByWorkspaces {
  unitsByWorkspaces {
    accountId
    activityStatuses
    dashCameras {
      imageUrl
      isVideo
      position
      streamType
      videoUrl
    }
    displayId
    externalId
    id
    isBusy
    status
    type
    userDetails {
      name
      unitRelationType
      userId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnitsByWorkspacesGQL extends Apollo.Query<UnitsByWorkspacesQuery, UnitsByWorkspacesQueryVariables> {
    document = UnitsByWorkspacesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserDocument = gql`
    query user($id: Int!) {
  user(id: $id) {
    email
    id
    name
    phoneNumber
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
    status
    unit {
      accountId
      displayId
      externalId
      id
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    unitRelationType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserGQL extends Apollo.Query<UserQuery, UserQueryVariables> {
    document = UserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserRolesDocument = gql`
    query userRoles {
  userRoles {
    accountId
    description
    id
    name
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UserRolesGQL extends Apollo.Query<UserRolesQuery, UserRolesQueryVariables> {
    document = UserRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users($optionalDateTimePeriodInput: OptionalDateTimePeriodInput!) {
  users(optionalDateTimePeriodInput: $optionalDateTimePeriodInput) {
    email
    id
    name
    phoneNumber
    permissions {
      dependsOnAll {
        entity
        modifier
      }
      dependsOnAny {
        entity
        modifier
      }
      id
      scope {
        entity
        modifier
      }
    }
    status
    unit {
      accountId
      displayId
      externalId
      id
      status
      type
      userDetails {
        name
        unitRelationType
        userId
      }
    }
    unitRelationType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VenuesDocument = gql`
    query venues($point: Point!, $radiusMeters: Int) {
  venues(point: $point, radiusMeters: $radiusMeters) {
    address
    location
    name
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VenuesGQL extends Apollo.Query<VenuesQuery, VenuesQueryVariables> {
    document = VenuesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WeatherAlertsSupportedTypesDocument = gql`
    query weatherAlertsSupportedTypes {
  weatherAlertsSupportedTypes {
    subTypes
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WeatherAlertsSupportedTypesGQL extends Apollo.Query<WeatherAlertsSupportedTypesQuery, WeatherAlertsSupportedTypesQueryVariables> {
    document = WeatherAlertsSupportedTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WorkspacesRoadReferencesDocument = gql`
    query workspacesRoadReferences {
  workspacesRoadReferences {
    refs
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WorkspacesRoadReferencesGQL extends Apollo.Query<WorkspacesRoadReferencesQuery, WorkspacesRoadReferencesQueryVariables> {
    document = WorkspacesRoadReferencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }