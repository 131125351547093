import { Injectable } from '@angular/core';
import { IncidentsQuery, LocalStorageKeys, LocalStorageService } from '@wc-core';
import { OnboardingTourName } from '@wc/wc-core/src/lib/types/tour';
import { union as _union } from 'lodash';
import { map, skip, take } from 'rxjs/operators';
import { FeedbackType, SetFeedbackGQL, UserFeedBackInput } from '../models';
import { CustomRxOperatorsService } from './custom-rx-operators.service';

type FeedbackCacheMap = Partial<Record<FeedbackType, number[]>> & Partial<Record<OnboardingTourName, boolean | null>>;
@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  clientSideFeedbackMap: FeedbackCacheMap = {
    [FeedbackType.IncidentPriorityScore]: [],
    [OnboardingTourName.DataHubTour]: null,
    [OnboardingTourName.LiveMapTour]: null,
    [OnboardingTourName.LayersPanelTour]: null,
    [OnboardingTourName.TransitTour]: null,
    [OnboardingTourName.SettingsTour]: null,
  };
  constructor(
    private setFeedbackGQL: SetFeedbackGQL,
    private customOperators: CustomRxOperatorsService,
    private localStorageService: LocalStorageService,
    private incidentsQuery: IncidentsQuery
  ) {
    this.initFeedbackCache();
  }

  sendUserFeedback(userFeedBackInput: UserFeedBackInput) {
    return this.setFeedbackGQL.mutate({ userFeedBackInput }).pipe(
      map(res => res.data?.setFeedback),
      this.customOperators.catchGqlErrors()
    );
  }

  saveFeedbackLocally<T extends FeedbackType | OnboardingTourName>(
    type: T,
    value: T extends FeedbackType ? number : boolean
  ) {
    if (typeof value === 'number') {
      this.clientSideFeedbackMap[type as FeedbackType] = _union(this.clientSideFeedbackMap[type as FeedbackType], [
        value,
      ]);
    } else {
      this.clientSideFeedbackMap[type as OnboardingTourName] = value;
    }
    this.localStorageService.set(LocalStorageKeys.ClientSideFeedbackMap, this.clientSideFeedbackMap);
  }

  initFeedbackCache() {
    this.incidentsQuery
      .selectAll()
      .pipe(skip(1), take(1))
      .subscribe(incidents => {
        const current = this.localStorageService.get(LocalStorageKeys.ClientSideFeedbackMap) as FeedbackCacheMap;

        if (current.INCIDENT_PRIORITY_SCORE || current.INCIDENT_INSIGHT_PERSISTENCE_SCORE) {
          const incidentsIds = incidents.map(incident => incident.id);
          current.INCIDENT_PRIORITY_SCORE = current.INCIDENT_PRIORITY_SCORE?.filter(id => incidentsIds.includes(id));
          current.INCIDENT_INSIGHT_PERSISTENCE_SCORE = current.INCIDENT_INSIGHT_PERSISTENCE_SCORE?.filter(id =>
            incidentsIds.includes(id)
          );
        } else {
          current.INCIDENT_PRIORITY_SCORE = [];
          current.INCIDENT_INSIGHT_PERSISTENCE_SCORE = [];
        }
        this.clientSideFeedbackMap = current;
        this.localStorageService.set(LocalStorageKeys.ClientSideFeedbackMap, this.clientSideFeedbackMap);
      });
  }
}
