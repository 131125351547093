import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FocusDirectiveModule } from '@wc/wc-common/src';
import { WcInvalidExpansionCssClassDirectiveModule } from '@wc/wc-common/src/lib/directives/wc-invalid-expansion-css-class.directive.module';
import { CheckboxModule } from '../../../form-components/checkbox/checkbox.module';
import { AutocompleteModule } from '../../autocomplete/autocomplete.module';
import { WcTooltipModule } from '../../tooltip/tooltip.module';
import { WcButtonModule } from '../../wc-button/wc-button.module';
import { WcIconModule } from '../../wc-icon/wc-icon.module';
import { RelatedEventCardComponentModule } from '../related-event-card/related-event-card.module';
import { RelatedEventsFormComponent } from './related-events-form.component';

@NgModule({
  declarations: [RelatedEventsFormComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    WcIconModule,
    AutocompleteModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    WcTooltipModule,
    RelatedEventCardComponentModule,
    CheckboxModule,
    WcInvalidExpansionCssClassDirectiveModule,
    FocusDirectiveModule,
    WcButtonModule,
    MatCheckboxModule,
  ],
  exports: [RelatedEventsFormComponent],
})
export class RelatedEventsFormModule {}
