import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  IncidentRelatedEventDto,
  LayerType,
  RelatedEventEntityTypeDto,
  RelatedEventsGQL,
  RelatedEventUnionDto,
  SearchCompletedEventsGQL,
  TrafficDisruptionRelatedEventDto,
} from '@wc/core';
import { addressToString } from '@wc/wc-common/src';
import {
  FeaturesPropsByLayerName,
  generateFeatureID,
  wcFeatureProperties,
  WcGeometryEnum,
  WcMapViewerService,
} from '@wc/wc-map-viewer/src';
import { EntityStyleStatusEnum, LiveMapLayerNames } from '@wc/wc-models/src';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FormFieldOption } from '../../lib/base';

const MIN_SEARCH_LENGTH = 3;

export const RelatedEventEntityTypeToLayerMap = {
  [RelatedEventEntityTypeDto.Incident]: LayerType.Incident,
  [RelatedEventEntityTypeDto.Construction]: LayerType.Construction,
  [RelatedEventEntityTypeDto.RoadClosure]: LayerType.RoadClosure,
  [RelatedEventEntityTypeDto.SpecialEvent]: LayerType.SpecialEvent,
};

@Injectable({
  providedIn: 'root',
})
export class RelatedEventsService {
  private _cachedCompletedEvents: FormFieldOption[] | null = null;
  private _visibleCompletedIds: string[] = [];
  get cachedCompletedEvents() {
    return this._cachedCompletedEvents;
  }

  constructor(
    private translateService: TranslateService,
    private readonly relatedEventsGQL: RelatedEventsGQL,
    private readonly searchCompletedEventsGQL: SearchCompletedEventsGQL,
    private mapViewerService: WcMapViewerService
  ) {}

  getRelatedEvents(eventId: number): Observable<RelatedEventUnionDto[]> {
    return this.relatedEventsGQL.fetch({ eventId: eventId }).pipe(
      map(res => {
        if (res.errors) throw { errorCode: res.errors[0].extensions?.statusCode };
        return res.data.relatedEvents as unknown as RelatedEventUnionDto[];
      })
    );
  }

  getCompletedEvents(searchTerm: string): Observable<FormFieldOption[]> {
    if (searchTerm.length < MIN_SEARCH_LENGTH) return of([]);

    if (!this._cachedCompletedEvents) {
      return this.searchCompletedEventsGQL.fetch().pipe(
        map(results => results.data.searchCompletedEvents),
        map(results => results.map(entity => this.mapEventToOption(entity as RelatedEventUnionDto))),
        tap(res => {
          this._cachedCompletedEvents = res;
        }),
        map(results => {
          return this.filterBySearchTerm(results, searchTerm);
        })
      );
    }
    return of(this._cachedCompletedEvents).pipe(
      map(results => {
        return this.filterBySearchTerm(results, searchTerm);
      })
    );
  }

  clearCachedCompletedEvents() {
    this._cachedCompletedEvents = null;
  }

  mapEventToOption(entity: RelatedEventUnionDto): FormFieldOption<unknown, RelatedEventUnionDto> {
    return {
      startIcon: this.iconName(entity).toLowerCase(),
      value: entity.id,
      displayName: `${entity.id} | ${addressToString(
        this.translateService,
        entity['incidentAddress'] || entity['trafficDisruptionAddress'],
        entity['trafficDisruptionTitle']
      )}`,
      data: entity,
    };
  }

  iconName(event: RelatedEventUnionDto) {
    if (event.entityType === RelatedEventEntityTypeDto.Incident) {
      return (event as IncidentRelatedEventDto).incidentType;
    }
    return event.entityType;
  }

  addCompletedEntitiesToMap(entities: RelatedEventUnionDto[]) {
    const features: FeaturesPropsByLayerName<string> = {};
    entities.forEach(entity => {
      const layerType = RelatedEventEntityTypeToLayerMap[entity.entityType];
      const isIncident = layerType === LayerType.Incident;
      const relatedAsIncident = entity as IncidentRelatedEventDto;
      const relatedAsTD = entity as Exclude<RelatedEventUnionDto, IncidentRelatedEventDto>;
      const featureLayerName = isIncident
        ? LiveMapLayerNames[layerType][relatedAsIncident.incidentType.toLowerCase()]
        : LiveMapLayerNames[layerType];

      const wcFeatureProps: wcFeatureProperties<string> = {
        id: entity.id,
        coordinates: isIncident
          ? relatedAsIncident.incidentAddress.point.coordinates
          : relatedAsTD.location.coordinates,
        geomType: isIncident ? WcGeometryEnum.POINT : (entity as TrafficDisruptionRelatedEventDto).location.type,
        entitySubType: featureLayerName,
        entityType: layerType,
        parentLayerName: featureLayerName,
        isVisible: true,
        entityStatusForStyle: EntityStyleStatusEnum.completed,
        isRelatedEvent: true,
        styleKey: featureLayerName,
        show: true,
      };

      features[featureLayerName] ??= [];
      features[featureLayerName].push(wcFeatureProps);

      this._visibleCompletedIds.push(generateFeatureID(layerType, entity.id));
    });

    this.mapViewerService.handleModifiedData(features);
  }

  removeAllCompletedEntitiesFromMap() {
    this.mapViewerService.handleRemovedData(this._visibleCompletedIds);
    this._visibleCompletedIds = [];
  }

  private filterBySearchTerm(items: FormFieldOption[], searchTerm: string): FormFieldOption[] {
    if (!searchTerm || searchTerm.trim() === '') {
      return [];
    }
    const normalizedSearchTerm = searchTerm.toLowerCase().trim();
    return items.filter(item => {
      return item.displayName?.includes(normalizedSearchTerm);
    });
  }
}
