/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Inject, Injectable, Injector, Optional, ProviderToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getCenterOfBounds } from 'geolib';
import { cloneDeep } from 'lodash';
import { buffer, createEmpty } from 'ol/extent';
import Feature from 'ol/Feature';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import Select, { SelectEvent } from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import * as olMap from 'ol/Map';
import { toLonLat } from 'ol/proj';
import { StyleLike } from 'ol/style/Style';
import View from 'ol/View';
import { from, interval, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, map, pairwise, switchMap, take } from 'rxjs/operators';
import { createExtentFromCoordinate, flatCoordinates, isValidLatLong, MapBasicInteractionNames } from '..';
import { InteractionsEnum, InteractionsEvents } from '../enums';
import { mapViewerConfigToken, mapViewerEnvConfigToken, wcMapToken } from '../injection-tokens';
import {
  mapViewerEnvConfig,
  StyleOptionsPerGeometryType,
  wcFeatureProperties,
  WcMapConfigModel,
  wcMapInteraction,
} from '../interfaces';
import { SERVICE_PROVIDERS, SERVICE_PROVIDERS_CLASS_NAMES } from '../providers';
import {
  EditGeometryOptions,
  FeatureEvent,
  FeaturesPropsByLayerName,
  LocationOptions,
  LocationUpdate,
  MapControls,
  normalizedModifiedData,
  normalizedRemovedData,
  StaticLayerOptionNames,
  wcCoordinate,
  wcCoordinateTypes,
  WcGeometryType,
  WcLayerOptions,
} from '../types';
import { RightClickEvent } from './classes/custom-interactions/right-click';
import { WcFeature } from './classes/wc-feature.class';
import { generateFeatureID } from './layer-utils/feature-utils';
import { ControlsService } from './private-services/controls.service';
import { DrawService } from './private-services/draw.service';
import { FeaturesService } from './private-services/features.service';
import { InteractionsService } from './private-services/interactions.service';
import { LayersService } from './private-services/layers.service';
import { LocationService } from './private-services/location.service';
import { StaticLayersService } from './private-services/static-layers.service';
import { StyleService } from './private-services/style.service';

@Injectable({
  providedIn: 'root',
})
export class WcMapViewerService {
  private injector!: Injector;
  private layersService!: LayersService;
  private featuresService!: FeaturesService;
  private locationService!: LocationService;
  private interactionsService!: InteractionsService;
  private controlsService!: ControlsService;
  private staticLayersService!: StaticLayersService;
  private styleService!: StyleService;
  private drawService!: DrawService;
  private config!: WcMapConfigModel<string, wcCoordinateTypes, string>;
  private _map;
  private ready = new ReplaySubject<boolean>(1);
  private mapContainerResized = new Subject<boolean>();
  ready$ = this.ready.asObservable();
  mapContainerResized$ = this.mapContainerResized.asObservable();
  hoverEvent$!: Observable<FeatureEvent>;
  leaveEvent$!: Observable<FeatureEvent>;
  selectEvent$!: Observable<FeatureEvent>;
  unselectEvent$!: Observable<FeatureEvent>;
  rightClickEvent$!: Observable<RightClickEvent>;
  dragEndEvent$!: Observable<FeatureEvent>;
  dragStartEvent$!: Observable<FeatureEvent>;
  zoomEndEvent$!: Observable<FeatureEvent>;
  moveEndEvent$!: Observable<FeatureEvent>;
  nextTargetMapCenter$!: Observable<wcCoordinateTypes | null>;
  modifyEndEvent$!: Observable<FeatureEvent>;
  drawEndEvent$!: Observable<FeatureEvent>;
  centerButtonClick$!: Observable<LocationUpdate>;
  private observer!: ResizeObserver;

  constructor(
    @Inject(mapViewerEnvConfigToken) @Optional() private mapViewerEnvConfig: mapViewerEnvConfig,
    private translateService: TranslateService
  ) {
    this._map = new olMap.default({});
    if (!mapViewerEnvConfig) {
      this.mapViewerEnvConfig = {} as any;
    }
    this.loadMapTranslationOnChange();
  }

  private get getSelectedFeature(): WcFeature<string> | undefined | Feature {
    return (<Select>this.interactionsService?.getInteraction('select'))?.getFeatures().getArray()[0];
  }

  setFeaturesWithStyleContext(featureIds: string[], context: string) {
    const notFoundFeatures: string[] = [];
    const features = featureIds
      .map(id => this.featuresService.getFeatureById(id))
      .filter((f, idx) => {
        if (!f) {
          notFoundFeatures.push(featureIds[idx]);
          return false;
        } else return true;
      }) as WcFeature<string>[];

    this.styleService.setFeaturesWithStyleContext(features, context);
    if (notFoundFeatures.length) {
      console.error(`Could not match features for the following ids: ${notFoundFeatures}`);
    }
  }

  get selectedFeatureId(): string | undefined {
    return this.getSelectedFeature?.getId() as string | undefined;
  }

  get selectedFeatureParentLayerName(): string | undefined {
    if (!this.getSelectedFeature) return undefined;
    const feature =
      this.getSelectedFeature instanceof WcFeature
        ? this.getSelectedFeature
        : this.getSelectedFeature?.getProperties().features[0];
    return feature.get('parentLayerName');
  }

  get nextTargetMapCenterValue() {
    return this.locationService.nextTargetMapCenterValue;
  }

  get featurePropUpdate$() {
    return this.featuresService.featurePropUpdate$;
  }

  get layerPropUpdate$() {
    return this.layersService.layerPropUpdate$;
  }

  get allFeatureIds() {
    return this.featuresService.allFeatureIds;
  }

  get allVisibleFeatureIdsAndTypes(): { id: string | undefined; entitySubType: string }[] {
    return this.featuresService.allVisibleFeatureProps
      .filter(({ parentLayerName }) => this.layersService.getLayerByName(parentLayerName)?.getVisible())
      .map(({ featureId, entitySubType }) => ({ id: featureId, entitySubType }));
  }

  get featureBeingEdited() {
    return this.drawService.featureBeingEdited;
  }

  get layerBeingLoaded$() {
    return this.staticLayersService?.layerBeingLoaded$;
  }

  getViewExtentCoordinates(bufferValue: number = 10): wcCoordinate[] {
    // Returns array with 4 converted points from corners of the view
    const mapExtent = this._map.getView().calculateExtent();
    const extent = createEmpty();
    // Adds buffer to the view to include additional data from defined borders
    buffer(mapExtent, bufferValue, extent);
    const viewPolygon: Polygon = fromExtent(extent);
    return viewPolygon?.getCoordinates()[0].map((_coords: number[]) => toLonLat(_coords));
  }

  init<T extends string, Y extends wcCoordinateTypes, FStatuses extends string>(
    target: HTMLElement,
    config: WcMapConfigModel<T, Y, FStatuses>
  ): Observable<boolean> {
    this._map = new olMap.default({});
    this._map.once('postrender', () => {
      this._map.updateSize();
      this.locationService?.updateMapExtentWithItsDefaultBoundaries();
      this.ready.next(true);
    });
    this.config = cloneDeep(config);
    this._map.setView(new View({ maxZoom: this.config.maxZoom || 23 }));
    this.createInjectors(this.config);
    this.startInteractionsSubscribers();
    this.startLocationSubscribers();
    this.emitWhenMapSizeReady(target, this._map);
    this.locationService.updateNextTargetMapCenter(this.config.centerOptions.mapCenter);
    this.setMapCenter(this.config.centerOptions.mapCenter);
    this.setInteractions(config.interactions);
    this.setControls(config.controls);
    this.initStaticLayers([this.config.baseMapLayerType || 'vectorMapMAPBOX']);
    this.featurePropUpdate$
      .pipe(
        debounceTime(0)
        // this solution works prefect as long as the clustered layers are rarely changed.
        // when a better solution will be needed, it will have to be on preservation of the style itself
        //when the clustered is re-evaluated, it's loosing it's previous context (e.g, selected state).
      )
      .subscribe(value => this.layersService.getClusterSourceLayerName(value.parentLayerName)?.changed());

    this.observer = new ResizeObserver(() => {
      this._map.updateSize();
      this.mapContainerResized.next();
    });
    this.observer.observe(target as Element);

    this.renderMap();
    return this.ready$;
  }

  renderMap() {
    this._map
      .getLayers()
      .getArray()
      .forEach(l => l.changed());

    this._map.updateSize();
  }

  getFeatureProps<T extends string>(featureId: string): wcFeatureProperties<T> | undefined {
    const featureProps = this.featuresService.getFeatureById(featureId)?.getProperties();
    return featureProps ? (featureProps as wcFeatureProperties<T>) : undefined;
  }

  getStaticLayerFactoryObservable<T extends StaticLayerOptionNames>(name: T) {
    return this.staticLayersService?.getStaticLayerFactory$(name);
  }

  updateLayersVisibility<T extends { name: string; checked: boolean }>(layersVisibility: T[]): void {
    layersVisibility.forEach(({ name, checked }) => {
      if (!this.layersService.getLayerByName(name)) {
        this.handleModifiedLayer(name);
      }

      this.layersService.getLayerByName(name)?.setVisible(checked);
      this.layersService.emitLayerPropChanged({ isVisible: checked, layerName: name });
    });
    this._map.updateSize();
  }

  initStaticLayers(staticLayers: StaticLayerOptionNames[]): void {
    this.staticLayersService?.initStaticLayersFactory(staticLayers);
  }

  updateMapData<T extends string, LNames extends string>(
    modified: normalizedModifiedData<T>,
    removed: normalizedRemovedData
  ) {
    this.handleModifiedData(modified.featuresPropsByLayerName);
    this.handleRemovedData(removed);
  }

  setInteractions(interactions: wcMapInteraction[] | undefined | null): void {
    if (interactions === undefined) return;
    if (interactions === null) {
      this.interactionsService.removeAllInteractions();
      return;
    }
    this.interactionsService.addInteractions(interactions);
  }

  updateMapCenter(coords: wcCoordinate, options?: LocationOptions): void {
    this.config.centerOptions.mapCenter = coords;
    this.setMapCenter(this.config.centerOptions.mapCenter, options);
  }

  setInteractionsState(interactionsStates: { name: MapBasicInteractionNames; isActive: boolean }[]): void {
    interactionsStates.forEach(({ name, isActive }) => {
      this.interactionsService.setInteractionState(name, isActive);
    });
  }

  setControls(controls: MapControls[] | undefined | null) {
    if (controls === undefined) return;
    this.controlsService.removeAllControls();
    if (controls !== null) {
      this.setControlsStatus(controls.map(control => ({ name: control, isVisible: true })));
    }
  }

  setControlsStatus(controlsStates: { name: MapControls; isVisible: boolean }[]): void {
    controlsStates.forEach(({ name, isVisible }) => {
      this.controlsService.setControlState(name, isVisible);
    });
  }

  setMapCenter(
    coordinates: wcCoordinateTypes | wcCoordinateTypes[],
    options?: LocationOptions
  ): Observable<LocationUpdate> {
    const prevAndCurrentLocation: LocationUpdate = {},
      locationUpdate = new Subject<LocationUpdate>();

    prevAndCurrentLocation.prev = this.getMapCenter();
    if (!(coordinates || Array.isArray(coordinates))) {
      return of(prevAndCurrentLocation);
    }
    // Check if coordinate are fitting to type Point
    if (isValidLatLong(coordinates)) {
      this.locationService.setCenter(coordinates as wcCoordinate, this.config.centerOptions, options);
    } else {
      const flatCoords = flatCoordinates(coordinates as wcCoordinate[][]);
      const extent = createExtentFromCoordinate(flatCoords);

      this.locationService.fitMapViewToExtent(extent, options);
    }
    // We are using setTimeout because the map re-evaluating it's center only after animation as ended
    setTimeout(() => {
      prevAndCurrentLocation.curr = this.getMapCenter();
      locationUpdate.next(prevAndCurrentLocation);
      locationUpdate.complete();
    }, (options?.duration || 0) + 100);

    return locationUpdate.asObservable();
  }

  updateMapConfig<T extends string, Y extends wcCoordinateTypes, FStatuses extends string>(
    config: Partial<WcMapConfigModel<T, Y, FStatuses>>
  ) {
    this.config = {
      ...this.config,
      ...config,
    };
  }

  getMapCenter(): wcCoordinate {
    return this.locationService.getMapCenter();
  }

  getMapCenterPoint(): wcCoordinate {
    const coordinatesFlat = flatCoordinates(this.config.centerOptions.mapCenter).map(coords => ({
      latitude: coords[0],
      longitude: coords[1],
    }));

    const res = getCenterOfBounds(coordinatesFlat);
    return [res.latitude, res.longitude];
  }

  getMapZoomLevel(): number {
    return this.locationService.getMapZoomLevel();
  }

  createLayer(layerName: string, options?: WcLayerOptions<string>): VectorLayer | undefined {
    return this.layersService.createLayer(layerName, options);
  }

  updateFeatureProp<T extends keyof wcFeatureProperties>(
    featureId: string,
    propName: T,
    value: T extends 'isVisible' | 'isRelatedEvent' ? boolean : string,
    emitEvent = true
  ) {
    const layerName = this.featuresService.getFeatureById(featureId)?.get('parentLayerName');
    this.featuresService.updateFeatureProp(
      featureId,
      propName,
      value,
      !!this.layersService.getClusterSourceLayerName(layerName),
      emitEvent
    );
  }

  createFeature<T extends string>(featureProps: wcFeatureProperties<T>): WcFeature<T> {
    return this.featuresService.createFeature(featureProps);
  }

  createNewGeometry(
    geometryType: WcGeometryType,
    coords: wcCoordinateTypes | null,
    createStyleArgs?: StyleOptionsPerGeometryType,
    modifyOptions: EditGeometryOptions = {}
  ) {
    const createStyle: StyleLike = createStyleArgs?.[geometryType]?.map(style =>
      this.styleService.transformStylePropToStyle(style)
    ) as StyleLike;

    this.drawService.create(geometryType, coords, createStyle, {
      showReference: modifyOptions.showReference || this.config.editFeatureOption?.showReference,
      editStyleArgs: modifyOptions.editStyleArgs || this.config.editFeatureOption?.editStyleArgs,
      referenceStyleArgs: modifyOptions.referenceStyleArgs || this.config.editFeatureOption?.referenceStyleArgs,
    });
  }

  stopCreatingNewGeometry() {
    this.drawService.stopCreate();
  }

  refreshMapSize() {
    this._map.updateSize();
    this.setMapCenter(this.config.centerOptions.mapCenter);
  }

  editFeatureGeometry(featureId: string, options: EditGeometryOptions = {}, type: WcGeometryType) {
    const feature = this.featuresService.getFeatureById(featureId);
    if (!feature) {
      throw new Error(`Could not find feature with Id: ${featureId}, cannot start edit ${feature}`);
    }
    this.drawService.edit(feature, options, type);
  }

  stopEditGeometry(keepChanges = true) {
    const changes = this.drawService.stopEdit();

    if (keepChanges && changes?.geometry?.coordinates) {
      const feature = this.featuresService.getFeatureById(changes.featureId);
      feature?.setGeometryByType(changes.geometry.type, changes.geometry.coordinates);
    }
  }

  selectFeature(featureId: string, emitEvent = true): WcFeature<string> | undefined {
    const f = this.featuresService.getFeatureById(featureId) as WcFeature<string> | undefined;
    const selectedFeatures = (<Select>this.interactionsService.getInteraction('select'))?.getFeatures();
    if (!f) {
      console.error('Could not find feature with featureId:', featureId);
      return;
    }
    if (selectedFeatures?.getArray().some(sf => sf.getId() === f.getId())) return f;

    (<Select>this.interactionsService.getInteraction('select'))?.getFeatures().push(f);
    if (emitEvent) {
      this.interactionsService.selectEvent({
        selected: [f],
        deselected: [],
      } as unknown as SelectEvent);
    }

    return f;
  }

  unselectFeature(): void {
    const selectedFeaturesCollection = (<Select>this.interactionsService?.getInteraction('select'))?.getFeatures();
    if (!selectedFeaturesCollection) return;

    const selectedFeature = selectedFeaturesCollection.getArray()[0];

    this.interactionsService.selectEvent({
      deselected: [selectedFeature],
      selected: [],
    } as unknown as SelectEvent);

    selectedFeaturesCollection.remove(selectedFeature);
    this.interactionsService.overlayHandler(InteractionsEnum.select, undefined);
    this.interactionsService
      .getInteraction('select')
      ?.dispatchEvent(
        new SelectEvent(InteractionsEnum.select as any, [], [selectedFeature], { type: 'manualDeselect' } as any)
      );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  onMapEvent(eventName: string, fn: (p: any) => any) {
    this._map.on(eventName, fn);
  }

  setOverlayToFeaturePosition(interactionName: InteractionsEnum, featureId: string) {
    this.interactionsService.overlayHandler(interactionName, this.featuresService.getFeatureById(featureId));
  }

  private loadMapTranslationOnChange() {
    const loadedTranslations = {};
    this.translateService.onLangChange
      .pipe(
        map(({ lang }) => lang),
        filter(lang => !loadedTranslations[lang]),
        switchMap(lang => from(import(`../i18n/${lang}.json`)).pipe(map(trans => ({ trans, lang }))))
      )
      .subscribe(({ trans, lang }) => {
        loadedTranslations[lang] = true;
        this.translateService.setTranslation(lang, trans, true);
      });
  }

  private emitWhenMapSizeReady(target: HTMLElement, map: olMap.default) {
    map.setTarget(target);
    if (map.getSize()?.includes(0)) {
      this.updateMapSize(target);
    }
  }

  handleModifiedData<T extends string>(modifiedFeaturesByLayer: FeaturesPropsByLayerName<T>): void {
    const visibleFeaturesProps: wcFeatureProperties<T>[] = [],
      notVisibleLayersProps: wcFeatureProperties<T>[] = [];
    Object.keys(modifiedFeaturesByLayer).forEach(layerName => {
      this.handleModifiedLayer(layerName).getVisible()
        ? visibleFeaturesProps.push(...modifiedFeaturesByLayer[layerName])
        : notVisibleLayersProps.push(...modifiedFeaturesByLayer[layerName]);
    });
    this.handleModifiedLayerFeatures(visibleFeaturesProps);
    setTimeout(() => this.handleModifiedLayerFeatures(notVisibleLayersProps));
  }

  private handleModifiedLayer(layerName: string): VectorLayer {
    let layer = this.layersService.getLayerByName(layerName);
    if (!layer) {
      const options: Partial<WcLayerOptions<string>> = {
        ...this.config.layers?.[layerName],
        isVisible: !!this.config.defaultLayersVisibility?.[layerName] || this.config.layers?.[layerName]?.isVisible,
      };
      layer = this.layersService.createLayer(layerName, options);
      layer.setStyle(
        this.styleService.styleFunction.bind({
          layerName,
          styleService: this.styleService,
          context: 'default',
          featureBeingEdited: this.featureBeingEdited,
        })
      );
      this.layersService.addLayerToMap(layer);
    }
    return layer;
  }

  private handleModifiedLayerFeatures<T extends string>(featuresProps: wcFeatureProperties<T>[]) {
    featuresProps.forEach(featureProps => {
      const existedFeature = this.featuresService.getFeatureById(
        generateFeatureID(featureProps.entityType, featureProps.id)
      );
      existedFeature ? this.handleFeatureUpdate(existedFeature, featureProps) : this.handleNewFeature(featureProps);
    });
  }

  private handleNewFeature<T extends string>(featureProps: wcFeatureProperties<T>): void {
    const newFeature = this.createFeature(featureProps);
    this.layersService.addFeaturesToLayer(featureProps.parentLayerName, [newFeature]);
  }

  private handleFeatureUpdate<T extends string>(feature: WcFeature<T>, newFeatureProps: wcFeatureProperties<T>): void {
    const prevParentLaterNAme = feature.getWcFeatureProp('parentLayerName');
    if (prevParentLaterNAme !== newFeatureProps.parentLayerName) {
      this.handleFeatureSubTypeChanged(feature, prevParentLaterNAme, newFeatureProps.parentLayerName);
    }
    this.featuresService.updateFeature(feature, newFeatureProps);
  }

  handleRemovedData<T extends string, LNames extends string>(featureIds: normalizedRemovedData): void {
    featureIds?.forEach(id => {
      const feature = this.featuresService.getFeatureById(id);
      if (feature) {
        const layerName = feature?.getWcFeatureProp('parentLayerName');
        this.layersService.removeFeaturesFromLayer(layerName, [feature]);
      }
    });
    this.featuresService.deleteFeatures(featureIds);
  }

  private handleFeatureSubTypeChanged<T extends string>(
    feature: WcFeature<T>,
    prevParentLayerName: string,
    currParentLayerName: string
  ): void {
    this.layersService.removeFeaturesFromLayer(prevParentLayerName, [feature]);
    this.layersService.addFeaturesToLayer(currParentLayerName, [feature]);
  }

  public updateMapSize(target: HTMLElement): void {
    const seconds = interval(500);
    const mapSizeRetry = seconds.pipe(take(10)).subscribe(
      () => {
        this._map.setSize([target.clientWidth, target.clientHeight]);
        mapSizeRetry.unsubscribe();
      },
      err => console.error(err)
    );
  }

  private createInjectors(config: WcMapConfigModel<string, wcCoordinateTypes, string>): void {
    let provider!: ProviderToken<unknown>, name!: string;
    this.injector = Injector.create({
      providers: [
        { provide: wcMapToken, useValue: this._map },
        { provide: mapViewerEnvConfigToken, useValue: this.mapViewerEnvConfig },
        { provide: mapViewerConfigToken, useValue: config },
        { provide: TranslateService, useValue: this.translateService },
        ...SERVICE_PROVIDERS,
      ],
    });
    SERVICE_PROVIDERS.forEach((value, idx) => {
      provider = value.provide;
      const originalClassName = SERVICE_PROVIDERS_CLASS_NAMES[idx];
      name = originalClassName.charAt(0).toLocaleLowerCase() + originalClassName.slice(1);
      this[name] = this.injector.get(provider);
    });
  }

  whatToGiveToThisFunction() {
    return this.config.centerOptions.mapCenter;
  }

  addOverlayToInteraction(interactionName: wcMapInteraction, elm: HTMLElement): void {
    this.interactionsService.addOverlayToInteraction(interactionName, elm);
  }

  private startLocationSubscribers() {
    try {
      this.nextTargetMapCenter$ = this.locationService.nextTargetMapCenter$;
    } catch (error) {
      console.error('could not apply location events');
    }
  }

  private startInteractionsSubscribers(): void {
    try {
      this.hoverEvent$ = (<Observable<FeatureEvent>>this.interactionsService.eventSubscriber$)?.pipe(
        filter(data => !!(data.eventType === InteractionsEvents.hover && data.targets))
      );

      this.selectEvent$ = (<Observable<FeatureEvent>>this.interactionsService.eventSubscriber$).pipe(
        filter(({ eventType, targets }) => !!(eventType === InteractionsEvents.select && targets))
      );

      this.leaveEvent$ = (<Observable<FeatureEvent>>this.interactionsService.eventSubscriber$)?.pipe(
        pairwise(),
        filter(([pV, _]) => !!pV.targets),
        map(([_, cV]) => cV),
        filter(({ eventType }) => eventType === InteractionsEvents.hover)
      );

      this.unselectEvent$ = (<Observable<FeatureEvent>>this.interactionsService.eventSubscriber$).pipe(
        filter(({ eventType }) => eventType === InteractionsEvents.unselect)
      );
      this.rightClickEvent$ = (<Observable<RightClickEvent>>this.interactionsService.eventSubscriber$).pipe(
        filter(({ type }) => type === InteractionsEvents.rightClick)
      );
      this.moveEndEvent$ = (<Observable<FeatureEvent>>this.interactionsService.eventSubscriber$).pipe(
        filter(({ eventType }) => eventType === InteractionsEvents.moveEnd)
      );

      this.dragStartEvent$ = (<Observable<FeatureEvent>>this.interactionsService.eventSubscriber$).pipe(
        filter(({ eventType }) => eventType === InteractionsEvents.moveStart)
      );

      this.zoomEndEvent$ = (<Observable<FeatureEvent>>this.interactionsService.eventSubscriber$).pipe(
        filter(({ eventType }) => eventType === InteractionsEvents.resolution)
      );

      this.dragEndEvent$ = (<Observable<FeatureEvent>>this.interactionsService.eventSubscriber$).pipe(
        filter(({ eventType }) => eventType === InteractionsEvents.pointerDrag),
        switchMap(() => this.moveEndEvent$.pipe(take(1)))
      );

      this.modifyEndEvent$ = this.drawService.drawEvent$.pipe(
        filter(({ eventType }) => eventType === InteractionsEvents.modifyend)
      );

      this.drawEndEvent$ = this.drawService.drawEvent$.pipe(
        filter(({ eventType }) => eventType === InteractionsEvents.drawend)
      );
    } catch (error) {
      console.error('could not apply interactions');
    }
  }

  destroy() {
    /**
     * @description When this is in timeOut, the ready.next(false) makes unwanted behaviors, like the following link:
     * @link https://app.shortcut.com/rekor/story/68776/e2e-livemap-refactor-regression-we-can-t-g[…]ahub-or-settings-after-viewing-any-incident-we-have-redirect
     * @todo need to make all place in the app use ready from live-map.service, and not here directly, and be filtered by ready === true
     */
    setTimeout(() => {
      this._map = null;
      this.observer.disconnect();
      this.ready.next(false);
    }, 0);
  }
}

/**
 * @todo
 1) add language observable to module configuration.
 2) add location observable to module configuration.
 3) take all the overrides from vendors/_ol.scss and move them into _wc-map.scss. after that  delete this file.
 */
