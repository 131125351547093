import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesServiceV2, LiveMapService } from '@wc-core';
import {
  ConstructionRelatedEventDto,
  IncidentRelatedEventDto,
  IncidentStatus,
  RelatedEventEntityTypeDto,
  RelatedEventUnionDto,
  SpecialEventRelatedEventDto,
  TrafficDisruptionStatus,
} from '@wc/core';
import { addressToString, getLayerName } from '@wc/wc-common/src';
import { DesktopUiStateService } from 'apps/desktop/src/app/shared/desktop-ui-state.service';
import { RelatedEventEntityTypeToLayerMap, RelatedEventsService } from '../related-events.service';

const defaultTranslateKeyMap = {
  [RelatedEventEntityTypeDto.Incident]: 'incidentTypes.',
  [RelatedEventEntityTypeDto.Construction]: 'constructionType.',
  [RelatedEventEntityTypeDto.RoadClosure]: 'trafficDisruptionTypes.',
  [RelatedEventEntityTypeDto.SpecialEvent]: 'specialEventType.',
};

@Component({
  selector: 'wc-related-event-card',
  templateUrl: './related-event-card.component.html',
  styleUrls: ['./related-event-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedEventCardComponent implements OnInit {
  @Input() event!: RelatedEventUnionDto;
  @Input() navigateToEventOnClick = false;
  private _uiEvent!: {
    id: number;
    type: string;
    iconName: string;
    status: IncidentStatus | TrafficDisruptionStatus;
    parsedAddress: string;
    subtype?: string;
  };

  get uiEvent() {
    return this._uiEvent;
  }

  constructor(
    private translateService: TranslateService,
    private relatedEventsService: RelatedEventsService,
    private desktopUiService: DesktopUiStateService,
    private entitiesService: EntitiesServiceV2,
    private liveMapService: LiveMapService
  ) {}

  ngOnInit(): void {
    this._uiEvent = {
      id: this.event.id,
      iconName: this.relatedEventsService.iconName(this.event).toLowerCase(),
      type: defaultTranslateKeyMap[this.event.entityType] + this.getType(),
      status: this.event['incidentStatus'] || this.event['trafficDisruptionStatus'],
      parsedAddress: addressToString(
        this.translateService,
        this.event['incidentAddress'] || this.event['trafficDisruptionAddress'],
        this.event['trafficDisruptionTitle']
      ),
      subtype: (this.event as IncidentRelatedEventDto).subType as string,
    };
  }

  private getType() {
    if (this.event.entityType === RelatedEventEntityTypeDto.Incident) {
      return (this.event as IncidentRelatedEventDto).incidentType;
    } else if (this.event.entityType === RelatedEventEntityTypeDto.Construction) {
      return (this.event as ConstructionRelatedEventDto).constructionType;
    } else if (this.event.entityType === RelatedEventEntityTypeDto.SpecialEvent) {
      return (this.event as SpecialEventRelatedEventDto).specialEventType;
    } else {
      return this.event.entityType;
    }
  }

  navigateToEvent() {
    if (!this.navigateToEventOnClick) return;

    if (
      (this.event as IncidentRelatedEventDto).incidentStatus === IncidentStatus.Completed ||
      (this.event as IncidentRelatedEventDto).incidentStatus === IncidentStatus.Rejected ||
      (this.event as ConstructionRelatedEventDto).trafficDisruptionStatus === TrafficDisruptionStatus.Completed
    ) {
      this.desktopUiService.openSideBarAndNavigate(
        RelatedEventEntityTypeToLayerMap[this.event.entityType],
        this.event.id,
        ''
      );
      setTimeout(() => {
        this.entitiesService.emitNewEntitiesDiff({
          modified: {
            [RelatedEventEntityTypeToLayerMap[this.event.entityType]]: [{ id: +this.event.id, show: true }],
          },
        });
      }, 1000);
    } else {
      this.liveMapService.selectFeatureAndOverlay(
        RelatedEventEntityTypeToLayerMap[this.event.entityType],
        this.event.id
      );
    }

    /**
     * In the parent related-event-form we go back to cached layers state when navigate to new event
     * This layer will be activated manually after the cached layers are restored
     */
    setTimeout(() => {
      this.activateEntityLayers();
    }, 1000);
  }

  activateEntityLayers() {
    this.liveMapService.setLayersVisibilityUpdates([
      {
        name: getLayerName(
          RelatedEventEntityTypeToLayerMap[this.event.entityType],
          (this.event as IncidentRelatedEventDto).incidentType
        ),
        checked: true,
      },
    ]);
  }
}
