import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AppFeatureEnum, HeapAnalyticsService, SplitIOService } from '@wc-core';
import {
  EntityType,
  FeedbackType,
  ImpactLevel,
  IncidentImpactedCorridor,
  IncidentInsights,
  IncidentStatus,
  IncidentStore,
  MeasureUnitType,
} from '@wc/core';
import { FeedbackService } from '@wc/core/services/feedback.service';
import { PermissionsFacadeService } from '@wc/permissions/domain/src';
import { hiddenCorridorNames, InsightService } from '@wc/wc-core/src/lib/services/insight.service';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'wc-insights-panel',
  templateUrl: './insights-panel.component.html',
  styleUrls: ['./insights-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsPanelComponent implements OnInit, OnDestroy {
  @ViewChild('insightPanel') insightPanel?: MatExpansionPanel;
  @Input() entityType!: EntityType;
  @Input() showPersistenceAndPriority = false;
  @Input() externalId?: string;
  @Input() incidentStatus: IncidentStatus = IncidentStatus.Unconfirmed;

  private _insight?: IncidentInsights;
  appFeaturesEnum = AppFeatureEnum;
  feedbackTypeEnum = FeedbackType;
  incidentStatusEnum = IncidentStatus;
  impactLevel = ImpactLevel;
  measureUnitTypeEnum = MeasureUnitType;
  hoveredMapSegmentId$!: Observable<string | undefined>;
  corridorColors: string[] = [];
  _entityId = -1;
  noImpactData = false;
  isShowEmptyState = false;
  feedbackPriorityScoreDone?: boolean;
  feedbackPersistenceDone?: boolean;

  @Input() set incidentInsight(incidentInsight: IncidentInsights | undefined) {
    this._insight = cloneDeep(incidentInsight);
    if (this._insight) {
      this._insight.impact?.corridors.forEach((corridor, i) => {
        this.corridorColors[i] = this.insightService.getInsightHexColor(
          100 - (corridor.currentSpd / corridor.expectedSpd) * 100
        );
      });
      if (this.showPanel && this.insightPanel?.opened) {
        this.toggleShowImpactOnMap(true);
      }
      this.incidentStore.setIncidentHasInsight(true);
      this.noImpactData = !this._insight.impact;
    }
    this.calcEmptyState();
  }

  get incidentInsightValue() {
    return this._insight;
  }

  @Input() isHebrew = false;

  get visibleCorridors() {
    return this._insight?.impact
      ? this._insight.impact.corridors.filter(c => (c.name ? !hiddenCorridorNames.has(c.name) : true))
      : [];
  }

  get isActiveImpact() {
    return this.permissionsFacadeService.hasPermission('TRAFFIC_IMPACT:READ');
  }

  get showPanel() {
    return !this.noImpactData && this.isActiveImpact;
  }

  calcEmptyState() {
    this.isShowEmptyState = false;
    if (!this._insight) {
      this.isShowEmptyState = true;
    } else if (
      (this.noImpactData || !this.isActiveImpact) &&
      !this._insight.persistenceType &&
      !this._insight?.priorityScore &&
      !this.showPersistenceAndPriority
    ) {
      this.isShowEmptyState = true;
    }
  }

  get hasIncidentUpdatePermission() {
    return this.permissionsFacadeService.hasPermission('INCIDENT:UPDATE');
  }

  @Input() set entityId(id: number) {
    if (id) {
      this._entityId = id;
      this.insightService.removeSegmentsFromMap();
      this.resetFeedback();
    }
  }

  constructor(
    private readonly heapService: HeapAnalyticsService,
    private insightService: InsightService,
    private incidentStore: IncidentStore,
    public splitIOService: SplitIOService,
    private permissionsFacadeService: PermissionsFacadeService,
    private cdr: ChangeDetectorRef,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit(): void {
    this.resetFeedback();
    this._insight = cloneDeep(this._insight);
    this.hoveredMapSegmentId$ = this.insightService.hoveredSegment$.pipe(
      map(segmentId => {
        if (segmentId) {
          const parentCorridor = this._insight?.impact?.corridors.find(corridor =>
            corridor.impactedSegments.find(s => s.segmentId === segmentId)
          );
          return parentCorridor ? (parentCorridor.name as string) : undefined;
        }
        return undefined;
      })
    );
  }

  toggleShowImpactOnMap(open: boolean) {
    if (open) {
      this.insightService.addSegmentsToMap(this._insight?.impact?.corridors || []);
    } else {
      this.insightService.removeSegmentsFromMap();
    }

    const classPrefix = 'heap-desktop-insights-panel-show-on-map-';
    const classSuffix = open ? 'checked' : 'unchecked';

    this.heapService.trackUserSpecificAction(`${classPrefix}${classSuffix}`);
  }

  corridorHovered(corridor?: IncidentImpactedCorridor) {
    this.insightService.highlightCorridorSegmentFeatures(corridor);
  }

  updateFeedbackImpact(feedback: boolean) {
    if (this._insight?.impact) {
      this._insight.impact.usersFeedback = feedback;
      this.cdr.markForCheck();
    }
  }

  updateFeedbackPriority() {
    this.feedbackPriorityScoreDone = true;
  }

  updateFeedbackPersistenceScore() {
    this.feedbackPersistenceDone = true;
  }

  private resetFeedback() {
    this.feedbackPriorityScoreDone = this.feedbackService.clientSideFeedbackMap.INCIDENT_PRIORITY_SCORE?.includes(
      this._entityId
    );
    this.feedbackPersistenceDone =
      this.feedbackService.clientSideFeedbackMap.INCIDENT_INSIGHT_PERSISTENCE_SCORE?.includes(this._entityId);
  }

  priorityLevelPanelOpened() {
    this.heapService.trackUserSpecificAction('bi-analytics-view-incident-open-priority-panel');
  }

  ngOnDestroy(): void {
    this.incidentStore.setIncidentHasInsight(false);
    this.insightService.removeSegmentsFromMap();
  }
}
