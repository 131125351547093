<div class="related-card-component" *ngIf="event">
  <button class="related-card-component__button" type="button" tabindex="0" wcFocusStyles (click)="navigateToEvent()">
    <div class="related-card-component__card">
      <div (click)="navigateToEvent()" class="related-card-component__icon">
        <wc-icon [iconName]="uiEvent.iconName"> </wc-icon>
      </div>
      <div class="related-card-component__content">
        <div class="related-card-component__details">
          <span class="related-card-component__id">{{ uiEvent.id }}</span>
          <span class="related-card-component__separator">|</span>
          <span class="related-card-component__type">{{ uiEvent.type | translate }}</span>
          <ng-container *ngIf="uiEvent.subtype">
            <span class="related-card-component__separator">|</span>
            <span class="related-card-component__subtype">{{ 'incidentSubTypes.' + uiEvent.subtype | translate }}</span>
          </ng-container>
        </div>
        <div class="related-card-component__address">
          <strong>{{ uiEvent.parsedAddress }}</strong>
        </div>
      </div>

      <div class="related-card-component__status related-card-component__status--{{ uiEvent.status }}">
        <span>{{ 'statusType.' + uiEvent.status | translate }}</span>
        <i class="incident-status-icon" [class]="'icon-' + uiEvent.status.toLowerCase()"></i>
      </div>
    </div>
  </button>
</div>
