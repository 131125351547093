import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, NgZone } from '@angular/core';
import { ActiveEntities, DiffParams, EntitiesDiff, LayerPanelStoreItem, ModifiedUIEntities } from '@wc/wc-models';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
// import { ActiveEntities, DiffParams, EntitiesDiff, ModifiedUIEntities } from '@wc/wc-models/src';
import { Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, delay, expand, map, tap } from 'rxjs/operators';
import { environment } from '../../../../core/environments/environment';
import { LayerType } from '../../../../core/models/gql.models';
import { CustomRxOperatorsService } from './custom-rx-operators.service';
import { LocalStorageKeys, LocalStorageService } from './local-storage.service';

export type layerTypeUnion = `${LayerType}`;
export type layerTypeTuple = Partial<[layerTypeUnion]>;

export const APP_LAYER_TYPES = new InjectionToken<layerTypeTuple>('the layer types the current application needs', {
  factory: () => Object.values(LayerType) as layerTypeTuple,
  providedIn: 'root',
});

@Injectable({
  providedIn: 'root',
})
export class EntitiesServiceV2 {
  private params: DiffParams = {};
  private _lastLayerTypeFilters: layerTypeTuple = [];
  private filterLayerTypes = `filterLayerTypes`;
  private diffSubject = new ReplaySubject<EntitiesDiff>();
  public getDiff$ = this.diffSubject.asObservable();
  private UISubject = new Subject<ModifiedUIEntities>();
  public getUIDiff$ = this.UISubject.asObservable();
  private activeEntityIds = new ReplaySubject<ActiveEntities>(1);
  public activeEntityIds$ = this.activeEntityIds.asObservable();
  private subscriptions: Subscription[] = [];
  private _lastUpdatedAt: number | undefined = 0;

  get lastUpdatedAt() {
    return this._lastUpdatedAt;
  }

  private set lastUpdatedAt(value) {
    this._lastUpdatedAt = value;
  }

  private readonly regularInterval = 5000;
  constructor(
    private http: HttpClient,
    private customRxOperators: CustomRxOperatorsService,
    private localStorageService: LocalStorageService,
    private ngZone: NgZone,
    @Inject(APP_LAYER_TYPES) private layerTypes: layerTypeTuple
  ) {}

  get lastLayerTypeFilters() {
    return this._lastLayerTypeFilters;
  }
  updateUrlParams(params: DiffParams) {
    this.params = params;
  }

  getDiffOnce(layerTypesToFilterBy: []) {
    this.http
      .get<EntitiesDiff>(this.getUpdatedUrl(layerTypesToFilterBy, 0))
      .pipe(tap(() => (this._lastLayerTypeFilters = layerTypesToFilterBy)))
      .subscribe(entitiesDiff => this.diffSubject.next(entitiesDiff));
  }

  emitNewUIDiff(UIDiff: ModifiedUIEntities): void {
    this.UISubject.next(UIDiff);
  }

  emitNewEntitiesDiff(entitiesDiff: EntitiesDiff): void {
    this.diffSubject.next(entitiesDiff);
  }

  setUiEntitiesAsActive(activeEntities: ActiveEntities) {
    this.activeEntityIds.next(activeEntities);
  }

  startEntitiesDiffPolling() {
    this.unSubscribeAll();

    // this solves the issue when leaving live-map and going back, the features cannot be seen.
    //Also this is affective solution, its  not too efficient.
    // 2 possibilities:
    // 1) to save the layers and the features and just re-assign them back. gotcha is you might miss some data, so this need more think through.
    // 2) make more sense is to make each store emit a dif that is already stored on local machine.
    this.lastUpdatedAt = 0;

    const initialLayersCheckedStatus = this.getInitialLayPanelStateByLayerTypes();

    this._lastLayerTypeFilters = [...initialLayersCheckedStatus.checked] as layerTypeTuple;
    let interval = 2000;
    const sub = this.ngZone.run(() =>
      this.http
        .get<EntitiesDiff>(this.getUpdatedUrl(this._lastLayerTypeFilters))
        .pipe(
          tap(({ updatedAt }) => {
            if (this._lastLayerTypeFilters.length) {
              this._lastLayerTypeFilters = [...initialLayersCheckedStatus.unChecked] as layerTypeTuple;
            } else {
              this.lastUpdatedAt = updatedAt;
              interval = this.regularInterval;
            }
          }),
          expand(() =>
            this.http.get<any>(this.getUpdatedUrl(this._lastLayerTypeFilters)).pipe(
              this.catchDiffError(),
              delay(interval),
              this.customRxOperators.tapOnce(() => {
                this._lastLayerTypeFilters = [];
                interval = this.regularInterval;
              }),
              map(({ modified, removed, updatedAt }) => ({
                modified: Object.keys(modified).reduce((acc, key) => {
                  if (modified[key].length) {
                    acc[key] = modified[key];
                  }
                  return acc;
                }, {}),
                removed: removed
                  ? Object.keys(removed).reduce((acc, key) => {
                      if (removed[key].length) {
                        acc[key] = removed[key];
                      }
                      return acc;
                    }, {})
                  : undefined,
                updatedAt,
              })),

              tap(({ updatedAt }) => {
                this._lastUpdatedAt = updatedAt;
              })
            )
          ),
          this.catchDiffError()
        )
        .subscribe(entitiesDiff => this.emitNewEntitiesDiff(entitiesDiff))
    );
    this.subscriptions.push(sub);
  }

  getSavedViewedAffectedServicesID(type: 'affectedFixedBusRoutes' | 'affectedTransitUnits') {
    const viewedAffectedServices = this.localStorageService.get(LocalStorageKeys.ViewedAffectedServices);
    return viewedAffectedServices ? (viewedAffectedServices[type] as number[]) : [];
  }

  unSubscribeAll() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  private catchDiffError() {
    return catchError<EntitiesDiff, Observable<EntitiesDiff>>(() =>
      of({ modified: {}, removed: {}, updatedAt: this.lastUpdatedAt })
    );
  }

  private getUpdatedUrl(layerTypes: layerTypeTuple = [], lastUpdatedAtOverride?: number): string {
    const routeFilterByShift = this.params.filterRoutesByShiftId
      ? `&filterRoutesByShiftId='${this.params.filterRoutesByShiftId}`
      : '';
    const filterLayerTypesParam = layerTypes.reduce((acc, curr) => {
      return `${acc}&${this.filterLayerTypes}=${curr}`;
    }, '');
    return `${environment.new_live_map_api_url}/diff?lastUpdatedAt=${
      lastUpdatedAtOverride ?? this.lastUpdatedAt
    }${routeFilterByShift}${filterLayerTypesParam}`;
  }

  private getInitialLayPanelStateByLayerTypes() {
    const layerStoreItems =
      (this.localStorageService.get(LocalStorageKeys.LayerPanelStoreItemsMap) as LayerPanelStoreItem[]) || [];

    return layerStoreItems
      .filter(layer => this.isValidBackendLayerType(layer.name))
      .filter(layer => layer.name !== 'INCIDENT-crash')
      .reduce(
        (acc, curr) => {
          if (curr.checked) {
            const layerName = curr.name.split('-')[0] as LayerType;
            acc.checked.add(layerName);
            acc.unChecked.delete(layerName);
          }
          return acc;
        },
        {
          checked: new Set<layerTypeUnion>(),
          unChecked: new Set(this.layerTypes),
        }
      );
  }

  private isValidBackendLayerType(name: string) {
    const layerName = name.split('-')[0];

    return this.layerTypes.includes(layerName as LayerType);
  }
}
