/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { EntityActions, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { IncidentStoreEntity, IncidentUIEntity, ModifiedEntities, RemovedEntities } from '@wc/wc-models';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getLayerName } from 'wc-common';
import { IncidentStatus, IncidentView, LayerType } from '../../../../../../core/models/gql.models';
import { LiveMapQuery } from '../../live-map/live-map.query';
import { IncidentsStore, IncidentState, IncidentUIState } from './incidents.store';

@Injectable({ providedIn: 'root' })
export class IncidentsQuery extends QueryEntity<IncidentState> {
  readonly layerTypeName = LayerType.Incident;
  ui!: EntityUIQuery<IncidentUIState>;
  constructor(protected store: IncidentsStore, private liveMapQuery: LiveMapQuery) {
    super(store);
    this.createUIQuery();
  }

  get visibleNeedActionIncidents() {
    return this.liveMapQuery
      .getVisibleEntitiesIdsByLayerType(this.layerTypeName)
      .pipe(switchMap(visibleIds => this.selectIncidents(visibleIds, [IncidentStatus.Unconfirmed])));
  }

  get visibleNeedActionIncidentsCardView(): Observable<Partial<IncidentView & IncidentUIEntity>[]> {
    return combineLatest([this.ui.selectAll(), this.visibleNeedActionIncidents]).pipe(
      map(([_, incidents]) => incidents.map(incident => ({ ...incident, ...this.ui.getEntity(incident.id) })))
    );
  }

  get visibleInProgressIncidents() {
    return this.liveMapQuery
      .getVisibleEntitiesIdsByLayerType(this.layerTypeName)
      .pipe(switchMap(visibleIds => this.selectIncidents(visibleIds, [IncidentStatus.Confirmed])));
  }

  get visibleInProgressIncidentsCardView(): Observable<Partial<IncidentView & IncidentUIEntity>[]> {
    return this.visibleInProgressIncidents.pipe(
      map(incidents => incidents.map(incident => ({ ...incident, ...this.ui.getEntity(incident.id) })))
    );
  }

  get completedIncidents() {
    return combineLatest([
      this.liveMapQuery.currentCheckedLayerNames.asQuery$,
      this.liveMapQuery.currentCheckedWorkSpaces.asQuery$,
    ]).pipe(
      switchMap(([visibleLayerNames, workSpacesIds]) => {
        return this.selectAll().pipe(
          map(incidents =>
            incidents.filter(({ status, type, workspaces }) => {
              if (status === IncidentStatus.Completed || status == IncidentStatus.Rejected) {
                if (!workspaces?.length) {
                  return true;
                }

                if (
                  visibleLayerNames.includes(getLayerName(this.layerTypeName, type)) &&
                  workspaces.some(id => workSpacesIds.includes(id))
                ) {
                  return true;
                }
              }

              return false;
            })
          )
        );
      })
    );
  }

  get allIncidentsValue() {
    const entities = this.getValue().entities || {};
    return {
      asArray: Object.values(entities),
      asObject: entities,
    };
  }

  get allIncidents() {
    return this.selectAll();
  }

  get allUnconfirmedIncidents$() {
    return this.selectAll().pipe(
      map(incidents => incidents.filter(({ status }) => status === IncidentStatus.Unconfirmed))
    );
  }

  get modifiedIncidents$(): Observable<ModifiedEntities> {
    return this.selectEntityAction([EntityActions.Update, EntityActions.Add]).pipe(
      map(({ ids }) => ids.map(id => this.getEntity(id)).filter(e => !!e) as IncidentStoreEntity[]),
      map(incidents => ({
        [this.layerTypeName]: incidents,
      }))
    );
  }

  get removedIncidents$(): Observable<RemovedEntities> {
    return this.selectEntityAction([EntityActions.Remove]).pipe(map(({ ids }) => ({ [this.layerTypeName]: ids })));
  }

  getUiIncidents(): IncidentUIEntity[] {
    return this.ui.getAll();
  }

  selectIncidents(ids: number[], statuses: IncidentStatus[] = []) {
    return this.selectMany(ids).pipe(
      map(visibleIncidents => visibleIncidents.filter(({ status }) => statuses.some(s => s === status)))
    );
  }
}
