<ng-container *ngIf="featureToggleOn">
  <div class="related-events-component">
    <mat-expansion-panel
      [wcInvalidExpansionCssClass]="isParentFormSubmitted && form.invalid"
      class="incident-section"
      [expanded]="expandedPanel | async">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <wc-icon class="section-icon" iconName="project-diagram"></wc-icon>
          <span *ngIf="showRequired" class="required-section"> * </span>
          <span> {{ 'relatedEvents.title' | translate }} ({{ filteredViewRelatedEvents.length }})</span>
          <wc-icon
            *ngIf="showRequired"
            class="related-events-component__info-icon"
            [wcTooltipText]="'relatedEvents.relatedEventMandatoryForSecondaryMsg' | translate"
            iconName="question-circle">
          </wc-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="related-events-component__body">
        <ng-container *ngIf="!editMode && filteredViewRelatedEvents.length === 0; else content">
          <div class="related-events-component__empty-state">
            <i class="related-events-component__empty-state-icon"> </i>
            <div class="related-events-component__empty-state--header">
              {{ 'relatedEvents.noRelatedEvents' | translate }}
            </div>
            <div class="related-events-component__empty-state--sub-header">
              {{ 'relatedEvents.noRelatedEventsInfo' | translate }}
            </div>
          </div>
        </ng-container>
        <ng-template #content>
          <mat-checkbox
            class="related-events-component__show-on-map"
            #showCheckbox
            wcFocusStyles
            *ngIf="!editMode && !dataHubMode"
            (change)="toggleShowRelatedOnMap($event.checked)">
            {{ 'relatedEvents.showOnlyRelatedOnMap' | translate }}</mat-checkbox
          >

          <wc-autocomplete
            #autoComplete
            *ngIf="editMode"
            [wcTooltipText]="'relatedEvents.upTo6PopUp' | translate"
            [wcTooltipDisabled]="filteredViewRelatedEvents.length < maxRelatedEvents"
            [wcTooltipOffsetSkidding]="-10"
            wcTooltipPlacement="right"
            queryDataKey="searchActiveEvents"
            fetchValueKey="searchString"
            [isDisabled]="filteredViewRelatedEvents.length > maxRelatedEvents - 1"
            [fetchedOptionsMapper]="mapper"
            (onOptionSelected)="addEvent($event)"
            [optionsQuery]="$any(searchActiveEventsGQL)"
            [formControl]="autocompleteFormControl"
            [formFieldData]="{
              placeholder: 'relatedEvents.searchEvent' | translate
            }"></wc-autocomplete>

          <div class="related-events-component__events-list">
            <div
              class="related-events-component__event-container"
              *ngFor="let item of filteredViewRelatedEvents; let i = index">
              <wc-related-event-card
                [navigateToEventOnClick]="!editMode && !dataHubMode"
                [event]="item"></wc-related-event-card>
              <button
                class="related-events-component__remove-icon"
                (click)="removeEvent(item.id, i)"
                *ngIf="editMode"
                rekor-icon>
                <wc-icon iconName="close-new"> </wc-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </mat-expansion-panel>
  </div>
</ng-container>
