import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { wcMapToken } from '../../injection-tokens';
import { wcFeatureProperties } from '../../interfaces';
import { FeaturePropChanges } from '../../types';
import { WcFeature } from '../classes/wc-feature.class';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private _featuresMap = new Map<string, WcFeature<string>>();

  private featurePropUpdated = new Subject<FeaturePropChanges>();
  featurePropUpdate$ = this.featurePropUpdated.asObservable();

  constructor(@Inject(wcMapToken) private wcMap) {}

  getFeatureById<T extends string>(id: string | undefined): WcFeature<T> | undefined {
    return !id ? undefined : this._featuresMap.get(id);
  }

  get allFeatureIds() {
    return Array.from(this._featuresMap.keys());
  }

  get allVisibleFeatureProps(): wcFeatureProperties<string>[] {
    return Array.from(this._featuresMap.values())
      .filter(f => f.isVisible)
      .map(f => f.getProperties()) as wcFeatureProperties<string>[];
  }

  createFeature<T extends string>(props: wcFeatureProperties<T>): WcFeature<T> {
    const feature = new WcFeature(props);
    this.setFeatureDictionary(feature);
    this.featurePropUpdated.next({
      featureId: feature.getId() as string,
      parentLayerName: props.parentLayerName,
      propName: 'all',
      preV: undefined,
      curV: props,
    });
    return feature;
  }

  updateFeature(feature: WcFeature<any>, fProps: wcFeatureProperties<string>) {
    const preV = feature.getProperties();
    feature.update(fProps);
    this.featurePropUpdated.next({
      featureId: feature.getId() as string,
      parentLayerName: feature.get('parentLayerName'),
      propName: 'all',
      preV,
      curV: fProps,
    });
  }

  updateFeatureProp<T extends keyof wcFeatureProperties>(
    featureId: string,
    propName: T,
    value: T extends 'isVisible' | 'isRelatedEvent' ? boolean : string,
    isSilent: boolean,
    emitEvent: boolean
  ) {
    this.getFeatureById(featureId)?.set(propName as string, value, isSilent);

    if (emitEvent) {
      this.featurePropUpdated.next({
        featureId,
        parentLayerName: this._featuresMap.get(featureId)?.get('parentLayerName'),
        propName,
        preV: this.getFeatureById(featureId)?.get(propName),
        curV: value,
      });
    }
  }

  deleteFeatures(featureIds: string[]): void {
    featureIds.forEach(id => this._featuresMap.delete(id));
  }

  private setFeatureDictionary<T extends string>(feature: WcFeature<T>) {
    const featureId = feature.getId();
    if (!(typeof featureId === 'string' && featureId.split('|').length === 2)) {
      console.error(`featureId: ${featureId} is not a valid featureId`);
      return;
    }
    this._featuresMap.set(featureId, feature);
  }
}
