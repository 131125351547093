import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AutocompleteFetchService {
  isBelowThreshold = true;
  isFetching = false;
  isFetchError = false;

  getFilteredOptionsObservable(
    autocompleteValueChanges: Observable<string>,
    dataKey: string,
    minSubstringLength: number,
    cdr: ChangeDetectorRef,
    query?: Query,
    fetchValueKey: string = 'subString'
  ) {
    if (!query) {
      throw new Error('query is required');
    }
    if (!dataKey) {
      throw new Error('dataKey is required');
    }

    return autocompleteValueChanges.pipe(
      tap(value => {
        this.isBelowThreshold = value.length < minSubstringLength;
        this.setIsFetching(true);
        this.isFetchError = false;
        cdr.markForCheck();
      }),
      switchMap(value =>
        this.isBelowThreshold
          ? of([])
          : query.fetch({ [fetchValueKey]: value }).pipe(
              map(res => {
                if (res.errors) {
                  console.error(res.errors);
                  this.setIsFetching(false);
                  this.isFetchError = true;
                  cdr.markForCheck();
                  return [];
                } else {
                  return res.data[dataKey];
                }
              })
            )
      ),
      tap(() => {
        this.setIsFetching(false);
      })
    );
  }

  private setIsFetching(isFetching: boolean) {
    this.isFetching = isFetching;
  }
}
